import React from 'react'
import './SetupIntro.css'
const SetupIntro = () => {
  return (
    <>
    <section id="ccc_intro"><h1 className="ccc_title">DOWNLOAD THE BEST IPTV APP</h1> <h2 className="ccc_subtitle">Our apps work flawlessly on tv, desktop, mobile and browser.</h2></section>
    
    </>
  )
}

export default SetupIntro