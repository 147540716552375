export const ChannelNames = [
    [
        "BBC Red Button - Event Only",
        "PPV BOX OFFICE - Event Only",
        "Sky Store - Event Only",
        "Replay Premier League - Event Only",
        "Willow TV - Event Only",
        "Scottish Championship - Event Only",
        "Sky Sports Red Button - Event Only",
        "WORLD SPORTS - Event Only",
        "EFL iFollow - Event Only",
        "Premier League - Event Only",
        "Football Events - Event Only",
        "Cup Football - Event Only",
        "PEACOCK EVENTS - Event Only",
        "MOLA TV - Event Only",
        "DAZN UK (Events Only) - Event Only",
        "DAZN CA - NOW HK - Event Only",
        "OPTUS - SPARK - Event Only",
        "FLOW RUSH SPORTS - Event Only",
        "AMAZON EVENTS - Event Only",
        "STAN SPORTS (UEFA GAMES) - Event Only",
        "SETANTA (Live Events Only) - Event Only",
        "NIFL- LOI - GAAGO - Event Only",
        "BeoSport GAA - Event Only",
        "FA PLAYER (WSL) - Event Only",
        "FA PLAYER (WSL) - Event Only",
        "UFC EVENTS - Event Only",
        "PPV EVENTS (SET 1) - Event Only",
        "PPV EVENTS (SET 2) - Event Only",
        "MATCHROOM EVENTS - Event Only",
        "RACING EVENTS - Event Only",
        "FITE TV - Event Only",
        "F1 TV (Events Only) - Event Only",
        "ES FOOTTERS (Bundesliga & More) - Event Only",
        "RUGBY PASS - Event Only",
        "MOTOGP / MOTORSPORTS - Event Only",
        "FHD Channels - Event Only",
        "FloSports - Event Only",
        "ESPN+ - Event Only",
        "ESPN+ - Event Only",
        "NBA - Event Only",
        "NFL - Event Only",
        "NHL - Event Only",
        "MLB - Event Only",
        "MLS - Event Only",
        "NCAAF - Event Only",
        "NRL - AFL - Event Only",
        "Sky Store",
        "Netflix Movies",
        "Apple TV SERIES",
        "Disney+ SERIES",
        "Prime Video SERIES",
        "UNetflix Series",
        "Kids Special",
    ],
    [
        "UK: BBC One HD (1080P)",
        "UK: BBC One HD (720P)",
        "UK: BBC One SD",
        "UK: BBC One Wales HD (1080P)",
        "UK: BBC One Scotland HD",
        "UK: BBC Two HD (1080P)",
        "UK: BBC Two HD (720P)",
        "UK: BBC Two SD",
        "UK: BBC Four HD (1080P)",
        "UK: BBC Four SD (7 PM Live)",
        "UK: BBC Alba",
        "UK: Channel 4 HD (1080P)",
        "UK: Channel 4 HD (720P)",
        "UK: Channel 4 SD",
        "UK: Channel 4 +1",
        "UK: Channel 5 HD (1080P)",
        "UK: Channel 5 HD (720P)",
        "UK: Channel 5 SD",
        "UK: Channel 5 +1",
        "UK: ITV HD (1080P)",
        "UK: ITV HD (720P)",
        "UK: ITV +1",
        "UK: ITV 2 HD (1080P)",
        "UK: ITV 2 HD (720P)",
        "UK: ITV2 +1",
        "UK: ITV 3 HD (1080P)",
        "UK: ITV 3 HD (720P)",
        "UK: ITV3 +1",
        "UK: ITV 4 HD (1080P)",
        "UK: ITV 4 HD (720P)",
        "UK: ITV4 +1",
        "UK: ITVBe HD",
        "UK: More4 HD (1080P)",
        "UK: More4 HD (720P)",
        "UK: More4 +1",
        "UK: Film4 HD (1080P)",
        "UK: Film4 HD (720P)",
        "UK: Film4 +1",
        "UK: Sky Max HD (1080P)",
        "UK: Sky Max HD",
        "UK: Sky Replay SD",
        "UK: Sky Showcase HD (1080P)",
        "UK: Sky Showcase HD (720P)",
        "UK: Sky Atlantic HD (1080P)",
        "UK: Sky Atlantic HD (720P)",
        "UK: Sky Atlantic +1",
        "UK: Sky Witness HD (1080P)",
        "UK: Sky Witness HD (720P)",
        "UK: Sky Arts HD (1080P)",
        "UK: Sky Arts",
        "UK: Sky Crime HD (1080P)",
        "UK: Sky Crime HD (720P)",
        "UK: Sky Crime +1",
        "UK: SyFy HD (1080P)",
        "UK: SyFy HD (720P)",
        "UK: Alibi HD (1080P)",
        "UK: Alibi HD (720P)",
        "UK: Alibi +1",
        "UK: Sky Comedy HD (1080P)",
        "UK: Comedy Central HD (1080P)",
        "UK: Comedy Central HD (720P)",
        "UK: Comedy Central +1",
        "UK: Comedy Xtra SD",
        "UK: Dave HD (1080P)",
        "UK: Dave",
        "UK: Dave ja vu",
        "UK: E4 HD (1080P)",
        "UK: E4 HD (720P)",
        "UK: E4 +1",
        "UK: UTV HD (1080P)",
        "UK: MTV HD (1080P)",
        "UK: MTV HD (720P)",
        "UK: 4Seven",
        "UK: TLC HD (1080P)",
        "UK: TLC HD (720P)",
        "UK: TLC SD",
        "UK: TLC +1",
        "UK: Paramount Network",
        "UK: W! HD (1080P)",
        "UK: W! HD (720P)",
        "UK: W +1",
        "UK: STV HD (1080P)",
        "UK: S4C HD",
        "UK: S4C SD",
        "UK: E! HD (1080P)",
        "UK: E! HD (720P)",
        "UK: Gold HD (1080P)",
        "UK: Gold HD (720P)",
        "UK: Gold +1",
        "UK: HGTV SD",
        "UK: HGTV +1",
        "UK: Food Network",
        "UK: Food Network +1",
        "UK: Foodxp",
        "UK: CBS Justice",
        "UK: CBS Drama SD",
        "UK: CBS Reality SD",
        "UK: CBS Reality +1",
        "UK: Drama SD",
        "UK: Drama +1",
        "UK: Dmax SD",
        "UK: Dmax+1",
        "UK: Really SD",
        "UK: Pick SD",
        "UK: Horse & Country",
        "UK: Quest HD (1080P)",
        "UK: Quest HD (720P)",
        "UK: Quest +1",
        "UK: Quest Red",
        "UK: Quest Red +1",
        "UK: 5Select",
        "UK: 5USA SD",
        "UK: 5 USA +1",
        "UK: 5Star SD",
        "UK: 5 Star +1",
        "UK: Challenge TV",
        "UK: London Live",
        "UK: Together TV",
        "UK: Eden HD (1080P)",
        "UK: Eden +1",
        "UK: Smithsonian HD (1080P)",
        "UK: Smithsonian HD (720P)",
        "UK: PBS America SD",
        "UK: Yesterday",
        "UK: Yesterday +1",
        "UK: Blaze SD",
        "UK: BBC Scotland HD (1080P)",
        "UK: Sky Witness +1",
        "UK: BBC Three HD (1080P)",
        "UK: BBC Two Wales HD (1080P)",
        "UK: Travel Channel SD",
        "UK: Court TV SD",
        "UK: Live 360 SD",
        "UK: ITV4 +1",
        "UK: TravelXP",
    ],
    [
        "UK-R: BBC ALBA",
        "UK-R: BBC News",
        "UK-R: BBC One",
        "UK-R: BBC One CI",
        "UK-R: BBC One E Mid",
        "UK-R: BBC One East E",
        "UK-R: BBC One East Midlands",
        "UK-R: BBC One East W",
        "UK-R: BBC One Lon",
        "UK-R: BBC One N West",
        "UK-R: BBC One NE&C",
        "UK-R: BBC One NI",
        "UK-R: BBC One Oxford",
        "UK-R: BBC One S East",
        "UK-R: BBC One S West",
        "UK-R: BBC One Scotland",
        "UK-R: BBC One South",
        "UK-R: BBC One W Mid",
        "UK-R: BBC One Wales",
        "UK-R: BBC One West",
        "UK-R: BBC One Yk&Li",
        "UK-R: BBC One Yorks",
        "UK-R: BBC Scotland",
        "UK-R: BBC Two England",
        "UK-R: BBC Two NI",
        "UK-R: BBC Two Wales",
        "UK-R: ITV Anglia East",
        "UK-R: ITV Anglia West",
        "UK-R: ITV Border England",
        "UK-R: ITV Central East",
        "UK-R: ITV Central West",
        "UK-R: ITV Granada",
        "UK-R: ITV London",
        "UK-R: ITV Meridian East",
        "UK-R: ITV Meridian North",
        "UK-R: ITV Meridian South Coast",
        "UK-R: ITV Meridian Thames Valley",
        "UK-R: ITV Tyne Tees",
        "UK-R: ITV Wales",
        "UK-R: ITV West Country East",
        "UK-R: ITV West Country West",
        "UK-R: ITV Yorkshire East",
        "UK-R: ITV Yorkshire West",
        "UK-R: ITV Border Scotland",
        "UK-R: ITV Central West (SD)",
        "UK-R: ITV Channel TV (SD)",
        "UK-R: ITV Granada (SD)",
        "UK-R: ITV London (SD)",
        "UK-R: ITV Meridian East (SD)",
        "UK-R: ITV Wales (SD)",
        "UK-R: Channel 4 South & East SD",
        "UK-R: Channel 4 Scotland SD",
        "UK-R: Channel 4 North SD",
        "UK-R: Channel 4 Midlands SD",
    ],
    [
        "UK: Sky News HD",
        "UK: CNN HD",
        "UK: BBC Parliament",
        "UK: BBC News HD (1080P)",
        "UK: BBC News HD (720P)",
        "UK: GB News HD (1080P)",
        "UK: Euronews SD",
        "UK: Al Jazeera News English",
        "UK: Bloomberg HD",
        "UK: NHK World HD",
        "UK: TRT World",
        "UK: Geo News",
        "UK: Talk Tv HD (1080P)",
        "UK: CNBC SD",
        "UK: NBC News Now",
        "UK: Neo News SD",
        "UK: TVC News",
        "UK: Arise News",
        "UK: WION",
        "UK: Good News TV",
    ],
    [
        "UK: Sky Documentaries HD (1080P)",
        "UK: Sky Nature HD (1080P)",
        "UK: Discovery HD (1080P)",
        "UK: Discovery HD (720P)",
        "UK: Discovery +1",
        "UK: Discovery History SD",
        "UK: Discovery Science",
        "UK: Discovery Turbo",
        "UK: Discovery Turbo +1",
        "UK: Investigation Discovery SD",
        "UK: Investigation Discovery +1",
        "UK: Crime & Investigation HD (1080P)",
        "UK: Crime & Investigation HD (720P)",
        "UK: Crime & Investigation SD",
        "UK: Animal Planet HD (1080P)",
        "UK: Animal Planet +1",
        "UK: Nat Geo HD (1080P)",
        "UK: Nat Geo HD (720P)",
        "UK: Nat Geo Wild HD (1080P)",
        "UK: Nat Geo Wild HD (720P)",
        "UK: Sky History HD (1080P)",
        "UK: Sky History",
        "UK: SKY H2 HD (1080P)",
        "UK: SKY H2 HD (720P)",
        "UK: SKY H2 SD",
        "UK: Animal Planet HD (720P)",
        "UK: Animal Planet SD",
    ],
    [
        "UK: Sky Cinema Select HD (1080P)",
        "UK: Sky Cinema Select HD",
        "UK: Sky Cinema Select SD",
        "UK: Sky Cinema Action HD (1080P)",
        "UK: Sky Cinema Action HD",
        "UK: Sky Cinema Action SD",
        "UK: Sky Cinema Premiere HD (1080P)",
        "UK: Sky Cinema Premiere HD",
        "UK: Sky Cinema Premiere SD",
        "UK: Sky Cinema Greats HD (1080P)",
        "UK: Sky Cinema Greats HD",
        "UK: Sky Cinema Greats SD",
        "UK: Sky Cinema Thriller HD (1080P)",
        "UK: Sky Cinema Thriller HD",
        "UK: Sky Cinema Thriller SD",
        "UK: Sky Cinema Drama HD (1080P)",
        "UK: Sky Cinema Drama HD",
        "UK: Sky Cinema Drama SD",
        "UK: Sky Cinema Scifi & Horror HD (1080P)",
        "UK: Sky Cinema Scifi & Horror HD",
        "UK: Sky Cinema Scifi & Horror SD",
        "UK: Sky Cinema Comedy HD (1080P)",
        "UK: Sky Cinema Comedy HD",
        "UK: Sky Cinema Comedy SD",
        "UK: Sky Cinema Family HD (1080P)",
        "UK: Sky Cinema Family HD",
        "UK: Sky Cinema Family SD",
        "UK: Sky Cinema Animation HD (1080P)",
        "UK: Sky Cinema Animation HD",
        "UK: Sky Cinema Animation SD",
        "UK: Sky Cinema Hits HD (1080P)",
        "UK: Sky Cinema Hits HD",
        "UK: Sky Cinema Hits SD",
        "UK: TCM HD",
        "UK: TCM +1",
        "UK: Horror Channel",
        "UK: Horror Channel +1",
        "UK: Great! TV",
        "UK: Great! TV Channel +1",
        "UK: Great! Movies",
        "UK: Great! Movies +1",
        "UK: Great! Movies Action",
        "UK: Great! Movies Action +1",
        "UK: Great! Movies Classic",
        "UK: Great! Movies Classic +1",
        "UK: Rok",
        "UK: Talking Pictures TV",
        "UK: Movies 24",
        "UK: Movies 24 +",
    ],
    [
        "UK: CBBC HD (1080P)",
        "UK: CBBC HD (720P)",
        "UK: CITV",
        "UK: Cartoon Network HD (1080P)",
        "UK: Cartoon Network HD (720P)",
        "UK: Cartoon Network +1",
        "UK: Nickelodeon HD (1080P)",
        "UK: Nickelodeon HD (720P)",
        "UK: Nickelodeon SD",
        "UK: Nickelodeon +1",
        "UK: Nicktoons SD",
        "UK: Boomerang HD (1080P)",
        "UK: Boomerang HD (720P)",
        "UK: Boomerang +1",
        "UK: Nick Jr HD (1080P)",
        "UK: Nick Jr HD (720P)",
        "UK: Nick Jr Too",
        "UK: Cartoonito",
        "UK: CBeebies HD (1080P)",
        "UK: Baby TV SD",
        "UK: Tiny POP SD",
        "UK: Tiny Pop+1",
        "UK: POP",
        "UK: POP +1",
        "UK: POP Max SD",
        "UK: POP Max +1",
    ],
    [
        "UK: UTV HD (1080P)",
        "UK: BBC One Northern Ireland HD",
        "UK: RTE One HD (1080P)",
        "UK: RTE One HD (720P)",
        "UK: RTE 2 HD (1080P)",
        "UK: RTE 2 HD",
        "UK: RTE JR",
        "UK: RTE News Now",
        "UK: TG4 HD (1080P)",
        "UK: TG4 HD (720P)",
        "UK: Virgin Media Sports HD",
        "UK: Virgin One HD (1080P)",
        "UK: Virgin One HD (720P)",
        "UK: Virgin Two HD (1080P)",
        "UK: Virgin Two HD (720P)",
        "UK: Virgin Three HD (720P)",
        "UK: Virgin Media Extra 1 HD",
        "UK: Virgin Media Extra 2 HD",
    ],
    [
        "PK: A Sports HD",
        "UK: Colors HD (1080P)",
        "UK: Zee TV HD (1080P)",
        "UK: Zee Cinema HD",
        "UK: Utsav Plus HD (1080P)",
        "UK: Utsav Plus HD (720P)",
        "UK: Utsav Gold HD (1080P)",
        "UK: Utsav Gold HD (720P)",
        "UK: Sony TV HD (1080P)",
        "UK: Sony TV HD (720P)",
        "UK: Sony Max HD (1080P)",
        "UK: Sony Max HD (720P)",
        "UK: Sony SAB",
        "UK: Utsav Bharat",
        "UK: B4U Movies",
        "UK: ARY Family",
        "UK: NDTV 24x7",
        "UK: Colors Rishtey",
        "UK: AAJ TAK",
        "UK: Geo TV",
        "UK: 92 News",
        "UK: Hum Europe",
        "UK: Hidayat TV",
        "UK: Aastha TV",
        "UK: Sikh Channel",
        "UK: Akaal Channel",
        "UK: Hum Masala",
        "UK: Brit Asia TV",
        "UK: PTC Punjabi",
        "UK: Sangat TV",
        "UK: Ahlulbayt TV",
        "UK: Venus TV",
        "UK: iON TV",
        "UK: Sanskar TV",
        "UK: B4U Music",
        "UK: Geo News",
        "UK: KTV",
        "UK: Pitaara SD",
        "UK: Kanshi TV",
        "UK: Neo News SD",
        "UK: Ahlebait TV",
        "UK: ATN Bangla",
        "UK: Colors Gujrati",
        "UK: Colors Cineplex",
        "UK: Zee TV SD",
        "UK: Sony Max 2",
        "UK: Love World HD",
        "UK: WION",
        "UK: PTV Global",
        "UK: New Vision TV",
        "ZEE5: Zee Salaam",
        "ZEE5: Zee Rajasthan News",
        "ZEE5: Zee Madhya Pradesh Chat",
        "ZEE5: Zee Bihar Jharkhand",
        "ZEE5: Zee Business",
        "ZEE5: Zee Hindustan",
        "ZEE5: Ghanta TV",
        "ZEE5: Zee News",
        "ZEE5: Zee Talkies",
        "ZEE5: Zee Classic",
        "ZEE5: Zee Cinema LU",
        "ZEE5: Zee Cinema UK",
        "IN: Star Sports Hindi 1 HD",
    ],
    [
        "|PK| ARY QTV",
        "|PK| ARY DIGITAL",
        "|PK| ARY NEWS",
        "|PK| ARY MUSIK",
        "|PK| ARY ZINDAGI",
        "|PK| HUM TV",
        "|PK| HUM SITARAY",
        "|PK| HUM NEWS HD",
        "|PK| CAPITAL TV",
        "|PK| 92 News HD",
        "|PK| TV ONE",
        "|PK| GEO TV",
        "|PK| GEO SUPER",
        "|PK| GEO NEWS",
        "|PK| GEO KAHANI",
        "|PK| GEO KAHANI",
        "|PK| PTV GLOBAL",
        "|PK| PTV NATIONAL",
        "|PK| PTV HOME",
        "|PK| PTV NEWS",
        "|PK| PTV WORLD",
        "|PK| PTV SPORTS",
        "|PK| TEN SPORTS",
        "|PK| DUNYA NEWS",
        "|PK| MADNI TV",
        "|PK| DAWN NEWS",
        "|PK| WASEB TV",
        "|PK| PASHTO 1",
        "|PK| 24 NEWS HD",
        "|PK| 8XM",
        "|PK| A PLUS",
        "|PK| AAJ ENTERTAINEMENT",
        "|PK| AAJ NEWS",
        "|PK| ABB TAKK",
        "|PK| APNA CHANNEL",
        "|PK| ARUJ TV",
        "|PK| ATV",
        "|PK| AWAZ TV",
        "|PK| CITY 41",
        "|PK| CITY 42",
        "|PK| FILM WORLD",
        "|PK| FILMAZIA HD",
        "|PK| JALWA",
        "|PK| KAY2 TV",
        "|PK| KHYBER NEWS TV",
        "|PK| KHYBER TV",
        "|PK| KTN",
        "|PK| NEWS ONE",
        "|PK| ROZE NEWS",
        "|PK| SEE TV",
        "|PK| SUCH TV",
        "|PK| EXPRESS NEWS",
        "|PK| BOL NEWS HD",
        "|PK| DSPORT HD",
        "PK: 24 News HD",
        "PK: 92 News HD",
        "PK: Aaj News HD",
        "PK: Aap News HD",
        "PK: GNN News HD",
        "PK: Ary Digital HD",
        "PK: Ary Zindagi HD",
        "PK: Hum TV HD",
        "PK: Hum News HD",
        "PK: HBO HD",
        "PK: Filmworld HD",
        "PK: Express Entertainment HD",
        "PK: 8X Music",
        "PK: Public News HD",
        "PK: Discovery Pakistan HD",
        "PK: Samaa TV",
        "PK: A Sports HD",
    ],
    [
        "UK : Sky Sports Red Button",
        "UK: Sky Sports News HD (1080P)",
        "UK: Sky Sports News HD (720P)",
        "UK: Sky Sports Main Event HD (1080P)",
        "UK: Sky Sports Main Event HD (720P)",
        "UK: Sky Sports Main Event SD",
        "UK: Sky Sports Premier League HD (1080P)",
        "UK: Sky Sports Premier League HD (720P)",
        "UK: Sky Sports Premier League SD",
        "UK: Sky Sports Football HD (1080P)",
        "UK: Sky Sports Football HD (720P)",
        "UK: Sky Sports Football SD",
        "UK: Sky Sports Cricket HD (1080P)",
        "UK: Sky Sports Cricket HD (720P)",
        "UK: Sky Sports Cricket SD",
        "UK: Sky Sports Golf HD (1080P)",
        "UK: Sky Sports Golf HD (720P)",
        "UK: Sky Sports Golf SD",
        "UK: Sky Sports Action HD (1080P)",
        "UK: Sky Sports Action HD (720P)",
        "UK: Sky Sports Action SD",
        "UK: Sky Sports Arena HD (1080P)",
        "UK: Sky Sports Arena HD (720P)",
        "UK: Sky Sports Arena SD",
        "UK: Sky Sports Mix HD (1080P)",
        "UK: Sky Sports Mix HD (720P)",
        "UK: Sky Sports F1 HD (1080P)",
        "UK: Sky Sports F1 HD (720P)",
        "UK: Sky Sports Racing HD (1080P)",
        "UK: Sky Sports Racing HD (720P)",
        "UK: Sky Sports F1 SD",
        "UK: Sky Sports Racing SD",
        "UK: Sky Sports Mix SD",
        "UK: Sky Sports News SD",
    ],
    [
        "UK: Sky Sports Main Event UHD (50FPS)",
        "UK: Sky Sports Premier League UHD (50FPS)",
        "UK: Sky Sports Football UHD (50FPS)",
        "UK: Sky Sports Cricket UHD (50FPS)",
        "UK: Sky Sports Golf UHD (50FPS)",
        "UK: Sky Sports F1 UHD (50FPS)",
        "UK: Sky Sports Action UHD (50FPS)",
        "UK: Sky Sports Arena UHD (50FPS)",
        "UK: Sky Sports Racing UHD (50FPS)",
        "UK: Sky Sports Mix UHD (50FPS)",
        "UK: Sky Sports News UHD (50FPS)",
    ],
    [
        "UK: BT Sport 1 HD (50 FPS)",
        "UK: BT Sport 1 HD (1080P)",
        "UK: BT Sport 1 HD (720P)",
        "UK: BT Sport 1 SD",
        "UK: BT Sport 2 HD (50 FPS)",
        "UK: BT Sport 2 HD (1080P)",
        "UK: BT Sport 2 HD (720P)",
        "UK: BT Sport 2 SD",
        "UK: BT Sport 3 HD (50 FPS)",
        "UK: BT Sport 3 HD (1080P)",
        "UK: BT Sport 3 HD (720P)",
        "UK: BT Sport 3 SD",
        "UK: BT Sport ESPN HD (50 FPS)",
        "UK: BT Sport ESPN HD (1080P)",
        "UK: BT Sport ESPN HD (720P)",
        "UK: BT Sport Extra 1",
        "UK: BT Sport Extra 2",
        "UK: BT Sport Extra 3",
        "UK: BT Sport Extra 4",
        "UK: BT Sport Extra 5",
        "UK: BT Sport Extra 6",
    ],
    [
        "UK: Box Nation HD (1080P)",
        "UK: PDC Darts 1 (Only Live for Events)",
        "UK: PDC Darts 2 (Only Live for Events)",
        "UK: Eurosport 1 HD (1080P)",
        "UK: Eurosport 1 HD",
        "UK: Eurosport 2 HD (1080P)",
        "UK: Eurosport 2 HD",
        "UK: Racing TV HD (1080P)",
        "UK: Racing TV HD (720P)",
        "UK: MUTV HD",
        "UK: Liverpool FCTV HD",
        "UK: LaLiga TV HD (1080P)",
        "UK: Premier Sports 1 HD (1080P)",
        "UK: Premier Sports 1 HD",
        "UK: Premier Sports 2 HD (1080P)",
        "UK: Premier Sports 2 HD",
        "UK: FreeSports HD (1080P)",
        "UK: FreeSports HD (720P)",
        "UK: FreeSports SD",
        "UK: SportyStuff HD (1080P)",
        "UK: GINX eSports TV",
        "MY: Astro Cricket HD",
        "UK: LaLiga TV HD (720P)",
        "IN: Star Sports 1 FHD",
        "IN: Star Sports 2 FHD",
        "IN: Star Sports Hindi 1 HD",
        "IN: Sony Ten 1 HD",
        "IN: Sony Ten 2 HD",
        "IN: Sony Ten 3 HD",
        "IN: Sony Six HD",
        "MY: Astro Eurosports",
        "MY: Astro eGG Network",
        "MY: Astro Super Sport 1HD",
        "MY: Astro Super Sport 2 HD",
        "MY: Astro Super Sport 3 HD",
        "MY: Astro Super Sport 4 HD",
        "MY: Astro Super Sport 5 HD",
        "MY: Astro Arena 1 HD",
        "MY: Astro Arena 2 HD",
        "MY: Astro Bein Sports HD",
        "MY: Astro Bein Sports Max HD",
        "US: PokerGo",
        "IN: Sony Ten 4 HD",
    ],
    [
        "AU: Racing.com",
        "AU: Fox Sports 507",
        "AU: Fox Sports 506",
        "AU: Fox Sports 505",
        "AU: Fox Sports 504",
        "AU: Fox Sports 503",
        "AU: Fox Sports 502",
        "AU: Fox Sports 501",
        "AU: ESPN 2",
        "AU: ESPN 1",
        "AU: beIN Sports 3",
        "AU: beIN Sports 2",
        "AU: beIN Sports 1",
        "NZ SKY Sport SELECT",
        "NZ SKY Sport 9",
        "NZ SKY Sport 8",
        "NZ SKY Sport 7",
        "NZ SKY Sport 6",
        "NZ SKY Sport 5",
        "NZ SKY Sport 4",
        "NZ SKY Sport 3",
        "NZ SKY Sport 2",
        "NZ SKY Sport 1",
        "AU: Fox Sports News HD",
    ],
    [
        "St Mirren (Backup)",
        "Kilmarnock (Backup)",
        "Motherwell (Backup)",
        "Aberdeen (Backup)",
        "Celtic (Backup)",
        "Dundee United (Backup)",
        "Hamilton Academical (Backup)",
        "Hibernian (Backup)",
        "Livingston (Backup)",
        "Rangers (Backup)",
        "Ross County (Backup)",
        "St Johnstone (Backup)",
        "Hearts (Backup)",
        "Falkirk FC (Backup)",
        "Dundee FC (Backup)",
        "CELTIC TV",
        "RANGERS TV",
        "ABERDEEN TV",
        "HIBERNIAN TV",
        "ST JOHNSTONE TV",
        "MOTHERWELL TV",
        "KILMARNOCK TV",
        "ROSS COUNTY TV",
        "ST MIRREN TV",
        "DUNDEE UTD TV",
        "HEARTS TV",
        "QUEEN'S PARK TV",
        "PARTICK THISTLE TV",
        "SPFL 1: ",
        "SPFL 2: ",
        "SPFL 3: ",
        "SPFL 4: ",
        "SPFL 5: ",
        "ABERDEEN TV (Backup)",
    ],
    [
        "EFL | Huddersfield Town",
        "EFL | Hull City",
        "EFL | Luton Town",
        "EFL | Preston North End",
        "EFL | Rotherham United",
        "EFL | Accrington Stanley",
        "EFL | Barnsley",
        "EFL | Bolton Wanderers",
        "EFL | Bristol Rovers",
        "EFL | Burton Albion",
        "EFL | Cambridge United",
        "EFL | Cheltenham Town",
        "EFL | Exeter City",
        "EFL | Fleetwood Town",
        "EFL | Ipswich Town",
        "EFL | Lincoln City",
        "EFL | Milton Keynes Dons",
        "EFL | Morecambe",
        "EFL | Oxford United",
        "EFL | Port Vale",
        "EFL | Portsmouth",
        "EFL | Sheffield Wednesday",
        "EFL | Shrewsbury Town",
        "EFL | AFC Wimbledon",
        "EFL | Barrow AFC",
        "EFL | Bradford City",
        "EFL | Carlisle United",
        "EFL | Colchester United",
        "EFL | Crawley Town",
        "EFL | Crewe Alexandra",
        "EFL | Doncaster Rovers",
        "EFL | Gillingham",
        "EFL | Grimsby Town",
        "EFL | Hartlepool United",
        "EFL | Mansfield Town",
        "EFL | Newport County",
        "EFL | Northampton Town",
        "EFL | Rochdale",
        "EFL | Stevenage",
        "EFL | Stockport County",
        "EFL | Sutton United",
        "EFL | Swindon Town",
        "EFL | Tranmere Rovers",
        "EFL | Walsall",
        "EFL | Milwall",
        "EFL | Salford City",
        "EFL | Harrogate Town",
        "EFL | Forest Green Rovers",
        "EFL | Wycombe Wanderers",
        "EFL | Wigan Athletic",
        "EFL | Stoke City",
        "EFL | Sunderland",
        "EFL | Plymouth Argyle",
        "EFL | Charlton Athletic",
        "EFL | West Bromwich Albion",
        "EFL | Swansea City",
        "EFL | Sheffield United",
        "EFL | Reading",
        "EFL | Queens Park Rangers",
        "EFL | Peterborough United",
        "EFL | Watford",
        "EFL | Middlesbrough",
        "EFL | Norwich City",
        "EFL | Derby County",
        "EFL | Cardiff City",
        "EFL | Burnley",
        "EFL | Bristol City",
        "EFL | Blackpool",
        "EFL | Blackburn Rovers",
        "EFL | Birmingham City",
        "EFL | Leyton Orient",
        "EFL | Coventry City",
    ],
    [
        "Peacock Live | WWE Network",
        "Peacock Live | Premier League",
        "Peacock Live | NBC Sports",
        "Peacock Live | NBC News Chicago",
        "Peacock Live | NBC News LA",
        "Peacock Live | NBC News New York",
        "Peacock Live | NBC News Boston",
        "Peacock Live | NBC News Philadelphia",
        "Peacock Live | NBC News Florida",
        "Peacock Live | NBC News Now",
        "Peacock Live | Golf Pass",
        "Peacock Live | Today All Day",
        "Peacock Live | Dateline 24/7",
        "Peacock Live | NFL Network Channel",
        "Peacock Live | SNL Vault",
        "Peacock Live | Fallon Tonight",
        "Peacock Live | Telemundo Al Dia",
        "Peacock Live | Loop Country",
        "Peacock Live | Loop Classic",
        "Peacock Live | Loop Latino",
        "Peacock Live | Circle",
        "Peacock Live | NBC LX",
        "Peacock Live | LOL Network",
        "Peacock Live | AFV Family",
        "Peacock Live | The Bob Ross Channel",
        "Peacock Live | This Old House",
        "Peacock Live | Rotten Tomatoes",
    ],
    [
        "UK: SIS One",
        "UK: SIS Two",
        "UK: SIS Racing",
        "UK: Ladbrokes TV 1",
        "UK: Ladbrokes TV 2",
        "UK: Ladbrokes TV 3",
        "UK: Coral TV 1",
        "UK: Coral TV 2",
        "UK: Paddy Power TV 1",
        "UK: Paddy Power TV 3",
        "UK: William Hill BTV",
        "UK: William Hill TV 1",
        "UK: William Hill TV 2",
        "UK: Betfred TV",
        "UK: Paddy Power TV 2",
        "UK: William Hill BTV 2",
    ],
    [
        "UK: MTV Base",
        "UK: MTV Hits",
        "UK: MTV Classic",
        "UK: MTV Music",
        "UK: Box Hits",
        "UK: Kerrang SD",
        "UK: Kiss",
        "UK: Magic",
        "UK: The box",
        "UK: Now 70s",
        "UK: Now 80s",
        "UK: Now 90s",
        "UK: Trace Hits",
        "UK: 4Music",
        "UK: Clubland TV",
        "UK: Spotlight",
        "Music Choice | Holiday Yule Logs",
        "Music Choice | Hit List",
        "Music Choice | Hip-Hop and R&B",
        "Music Choice | Teen Beats",
        "Music Choice | Today's Country: CMA Awards",
        "Music Choice | Pop Hits",
        "Music Choice | Pop & Country",
        "Music Choice | 90's",
        "Music Choice | R&B Soul",
        "Music Choice | Rock",
        "Music Choice | R&B Soul",
        "Music Choice | Music Choice Max",
        "Music Choice | Dance / EDM",
        "Music Choice | Y2K",
        "Music Choice | Pop Latino",
        "Music Choice | Rap",
        "Music Choice | Throwback Jamz",
        "Music Choice | Rap 2K",
        "Music Choice | Musica Urbana",
        "Music Choice | Tropicales",
        "Music Choice | Mexicana",
        "Music Choice | Alternative",
        "Music Choice | Kidz Only!",
        "Music Choice | Kidz Bop",
        "Music Choice | Toddler Tunes",
        "UK: MTV Live HD",
        "UK: Club MTV",
        "UK: Trace Vault",
        "UK: Trace Urban",
    ],
    [
        "UK: British Muslim",
        "UK: Islam Channel",
        "UK: Madani TV",
        "UK: Noor TV",
        "UK: Love World",
        "UK: Eman Channel",
        "UK: Iqra TV",
        "UK: Faith TV",
        "UK: GOD",
        "UK: KICC TV",
        "UK: The Word Network",
        "UK: Islam TV",
        "UK: Daystar HD (1080P)",
        "UK: GOD Channel SD",
    ],
    [
        "UK: QVC",
        "UK: QVC +1",
        "UK: QVC Style",
        "UK: QVC Beauty",
        "UK: QVC Extra",
        "UK: The Jewellery Channel",
        "UK: Jewellery Maker",
        "UK: Ideal World HD (1080P)",
        "UK: The Craft Store TV",
        "UK: Create And Craft",
        "UK: Gems TV",
        "UK: Best Direct SD",
        "UK: Ideal Extra SD",
        "UK: Craft Extra",
        "UK: JML Direct SD",
    ],
    [
        "SuperSport Tennis",
        "SuperSport Rugby",
        "SuperSport Play",
        "SuperSport Motorsport",
        "SuperSport MaXimo",
        "SuperSport Grandstand",
        "SuperSport Golf",
        "SuperSport Extra 5",
        "SuperSport Extra 4",
        "SuperSport Extra 3",
        "SuperSport Extra 2",
        "SuperSport Extra 1",
        "SuperSport ESPN 2",
        "SuperSport ESPN",
        "SuperSport Cricket",
        "SuperSport Blitz",
        "SuperSport Action",
        "DSTV WWE",
        "DSTV Teletrack",
        "DSTV GiNX",
        "SuperSport Variety 4",
        "SuperSport Variety 3",
        "SuperSport Variety 2",
        "SuperSport Variety 1",
        "SuperSport Football",
        "SuperSport La Liga",
        "SuperSport Premier League",
        "SuperSport PSL",
    ],
    [
        "beIN Sports Premium 1 hd",
        "beIN Sports Premium 2 hd",
        "beIN Sports Premium 3 hd",
        "beIN Sports 1 Ar",
        "beIN Sports 2 Ar",
        "beIN Sports 3 Ar",
        "beIN Sports 4 Ar",
        "beIN Sports 5 Ar",
        "beIN Sports 6 Ar",
        "beIN Sports 7 Ar",
        "beIN Sports 1 En",
        "beIN Sports 2 En",
        "beIN Sports 3 En",
        "beIN Sports 1 FR",
        "beIN Sports 2 FR",
        "beIN Sports 3 FR",
        "beIN Sports Xtra 1",
        "beIN Sports Xtra 2",
        "AR: BeIN Sports AFC HD",
        "AR: BeIN Sports AFC 1 HD",
        "AR: BeIN Sports AFC 2 HD",
        "AR: BeIN Sports AFC 3 HD",
    ],
    [
        "24/7: Exponiendo Infieles",
        "24/7: Caso Cerrado Inmigracion",
        "24/7: Caso Cerrado La Familia 1",
        "24/7: Caso Cerrado La Familia 2",
        "24/7: Caso Cerrado La Familia 3",
        "24/7: Laura Bozzo",
        "24/7: Miguelito (Chile)",
        "24/7: Lo Que Callamos Las Mujeres",
        "24/7: Mayday Catastrofes Aereas",
        "24/7: Alienigenas Ancestrales",
        "24/7: El Mundo De Beakman Temp 1",
        "24/7: El Mundo De Beakman Temp 2",
        "24/7: El Mundo De Beakman Temp 3",
        "24/7: El Mundo De Beakman Temp 4",
        "24/7: Castle Temp 1",
        "24/7: Castle Temp 2",
        "24/7: Castle Temp 3",
        "24/7: Castle Temp 4",
        "24/7: Castle Temp 5",
        "24/7: Castle Temp 6",
        "24/7: Castle Temp 7",
        "24/7: CSI Miami Temp 1-5",
        "24/7: CSI Miami Temp 6-10",
        "24/7: NCIS Los Angeles",
        "24/7: FBI",
        "24/7: Los Magnificos Temp 1",
        "24/7: Los Magnificos Temp 2",
        "24/7: El Auto Fantastico",
        "24/7: El Auto Fantastico (2008)",
        "24/7: MacGiver (2016) Temp 1",
        "24/7: MacGiver (2016) Temp 2",
        "24/7: ER Sala de Urgencias",
        "24/7: Baywatch",
        "24/7: El Zorro (1956)",
        "24/7: Walker Texas Ranger",
        "24/7: Mr Bean",
        "24/7: Porcel Y Olmedo",
        "24/7: La India Maria",
        "24/7: Cantinflas Parte 1 (18 Peliculas)",
        "24/7: Cantinflas Parte 2 (19 Peliculas)",
        "24/7: Cantinflas (Animada)",
        "24/7: Los 3 Chiflados",
        "24/7: Pedro Infante",
        "24/7: El Chapulin Colorado",
        "24/7: El Chavo del 8",
        "24/7: El Chavo del 8 Serie Animada",
        "24/7: El Principe del Rap Temp 1",
        "24/7: El Principe del Rap Temp 2",
        "24/7: El Principe del Rap Temp 3",
        "24/7: El Principe del Rap Temp 4",
        "24/7: El Principe del Rap Temp 5",
        "24/7: El Principe del Rap Temp 6",
        "24/7: Hechizada Temp 1",
        "24/7: Hechizada Temp 2",
        "24/7: Hechizada Temp 3",
        "24/7: Hechizada Temp 4",
        "24/7: Hechizada Temp 5",
        "24/7: Hechizada Temp 6",
        "24/7: Mi Bella Genio Temp 1",
        "24/7: La Niñera Temp 1",
        "24/7: Friends Temp 1",
        "24/7: Friends Temp 2",
        "24/7: Malcolm el de en Medio",
        "24/7: Hannah Montana Temp 1",
        "24/7: Paso A Paso",
        "24/7: La Hora Pico",
        "24/7: Una Familia de Diez",
        "24/7: La Familia Peluche",
        "24/7: ALF",
        "24/7: Vecinos Temp 1",
        "24/7: Vecinos Temp 2",
        "24/7: Vecinos Temp 3",
        "24/7: Vecinos Temp 4",
        "24/7: Vecinos Temp 5",
        "24/7: Full House Temp 1",
        "24/7: Full House Temp 2",
        "24/7: La Familia Ingalls",
        "24/7: La Familia Ingalls FHD",
        "24/7: Xena La Princesa Guerrera",
        "24/7: Hercules Los Viajes Legendarios",
        "24/7: Liveman",
        "24/7: El Narrador de Cuentos",
        "24/7: 1000 Maneras de Morir",
        "24/7: Oxlack Investigador Paranormal",
        "24/7: Contacto Extranormal",
        "24/7: Historias de Ultratumba",
        "24/7: Cuentos de la Cripta Animada",
        "24/7: Ataque a los Titanes SUB",
        "24/7: Paradise PD",
        "24/7: PJ Masks: Heroes en pijamas",
        "24/7: Dragones de Berck",
        "24/7: Los Vengadores (Unidos)",
        "24/7: Rapido y Furioso (Espias a todo gas)",
        "24/7: Guardianes de la Galaxia (Animado)",
        "24/7: Gravity Falls",
        "24/7: Brickleberry (+15)",
        "24/7: Mr Pickles",
        "24/7: Barney y sus Amigos",
        "24/7: Automan",
        "24/7: Animaniacs",
        "24/7: Autobus Magico",
        "24/7: Bubble Guppies",
        "24/7: Coraje el Perro Cobarde",
        "24/7: Digimon Adventure",
        "24/7: Donde esta Wally",
        "24/7: Dora la Exploradora",
        "24/7: Dora y sus Amigos en la Ciudad",
        "24/7: El Agente Oso",
        "24/7: El Bebe Jefazo Vuelta al Curro",
        "24/7: El Laboratorio de Dexter",
        "24/7: Equipo Umizoomi",
        "24/7: Fabulas de Esopo",
        "24/7: Garfield",
        "24/7: La Casa de Mickey Mouse",
        "24/7: La Casa de Mickey Mouse Full HD",
        "24/7: La Doctora Juguete",
        "24/7: La Pantera Rosa",
        "24/7: Las Pistas de Blue",
        "24/7: Los Picapiedras",
        "24/7: Los Picapiedras (Pequeños)",
        "24/7: Futurama",
        "24/7: Futurama HD",
        "24/7: Futurama Full HD",
        "24/7: Los Simpsons Noche de Brujas",
        "24/7: Los Simpsons Temp 1-5",
        "24/7: Los Simpsons HD",
        "24/7: Los Simpsons Full HD",
        "24/7: South Park Temp 1-5",
        "24/7: South Park Full HD",
        "24/7: Padre de Familia Temp 1-5",
        "24/7: Padre de Familia HD",
        "24/7: Padre de Familia Full HD",
        "24/7: Paw Patroll",
        "24/7: Peppa Pig",
        "24/7: Transformers Mastersforce (1988)",
        "24/7: Hey Arnold",
        "24/7: Hombres de Negro Serie Animada",
        "24/7: Los Pitufos",
        "24/7: Dragon Ball (Vers Extendida)",
        "24/7: Dragon Ball Z Los Saiyajin",
        "24/7: Dragon Ball Z Freezer",
        "24/7: Dragon Ball Z Cell",
        "24/7: Dragon Ball Z Majin Boo",
        "24/7: Dragon Ball GT",
        "24/7: She-Ra La Princesa del Poder",
        "24/7: He-Man y los Masters del Universo",
        "24/7: Capitan America y Los Planetarios",
        "24/7: Tom Y Jerry",
        "24/7: Tom Y Jerry Kids",
        "24/7: Pato Aventura",
        "24/7: Sailor Moon",
        "24/7: Las Guerreras Magicas",
        "24/7: Las Tortugas Ninja",
        "24/7: Angry Birds Toons",
        "24/7: Aladino",
        "24/7: Birdman",
        "24/7: Gargolas Heroes Goticos",
        "24/7: Spiderman",
        "24/7: Super Mario Bross 3",
        "24/7: Super Campeones (Captain Tsubasa)",
        "24/7: Los Castores Cascarrabias",
        "24/7: Saint Seiya Torneo Galactico",
        "24/7: Saint Seiya Las 12 Casas",
        "24/7: Saint Seiya Asgard",
        "24/7: Saint Seiya Poseidon",
        "24/7: Saint Seiya Hades",
        "24/7: Beast Wars",
        "24/7: Beast Wars Machines",
        "24/7: Gi Joe",
        "24/7: Rugrats Aventura en Pañales",
        "24/7: Las Chicas Superpoderosas",
        "24/7: Capitan Cabernicola",
        "24/7: Jhonny Bravo",
        "24/7: La Vaca y el Pollito",
        "24/7: Los Años Maravillosos",
        "24/7: Scooby Doo",
        "24/7: Don Gato y su Pandilla",
        "24/7: Gasparin y sus Amigos",
        "24/7: Las Aventuras de Tin Tin",
        "24/7: Los Supersonicos",
        "24/7: Inspector Gadget",
        "24/7: El Oso Yogui",
        "24/7: Los Muppet Babys",
        "24/7: Candy Candy",
        "24/7: La Familia Meñique",
        "24/7: La Pequeña Lulu",
        "24/7: Un Show Mas",
        "24/7: El Reto de los Super Amigos",
        "24/7: Fenomenoide",
        "24/7: La Hormiga y el Oso Hormiguero",
        "24/7: El Pais de Donkey Kong",
        "24/7: Pinguinos de Madagascar",
        "24/7: El Super Libro",
        "24/7: Los 7 Pecados Capitales",
        "24/7: Timon & Pumbaa",
        "24/7: La Casa de los Dibujos",
        "24/7: El Gallo Claudio",
        "24/7: Halcones Galacticos",
        "24/7: Pinky y Cerebro",
        "24/7: Heathcliff",
        "24/7: Jackie Chan (Animada)",
        "24/7: La Tonta Bruja",
        "24/7: Beetlejuice",
        "24/7: Sonic X",
        "24/7: Street Sharks",
        "24/7: Samurai Jack",
        "24/7: Generador Rex",
        "24/7: Los Gatos Samurai",
        "24/7: Sakura Card Captor",
        "24/7: Los Osos Gummi",
        "24/7: La Liga del Dragon VHSRip",
        "24/7: Megaman NT Warrior",
        "24/7: Los Padrinos Magicos",
        "24/7: Thundercats",
        "24/7: Tarzan Animado",
        "24/7: Samurai X",
        "24/7: Mighty Ducks",
        "24/7: 101 Dalmatas Animada",
        "24/7: Los Backyardigans",
        "24/7: Robotech",
        "24/7: Pocoyo",
        "24/7: Clasicos de Disney (Latino)",
        "24/7: Los Simpsons (ES)",
        "24/7: Los Simpsons HD (ES)",
        "24/7: Futurama (ES)",
        "24/7: Futurama HD (ES)",
        "24/7: Padre de Familia (ES)",
        "24/7: Ben Y Holly (ES)",
        "24/7: Fabulas de Disney (ES)",
        "24/7: Patrulla Canina (ES)",
        "24/7: El Principe de Bel Air (ES)",
        "24/7: La Que se Avecina (ES)",
        "24/7: Futurama (EN)",
        "24/7: Futurama HD (EN)",
        "24/7: Treehouse of Horror (EN)",
        "24/7: The Simpsons Season 1-5 (EN)",
        "24/7: Agent Oso (EN)",
        "24/7: South Park (EN)",
        "24/7: Paw Patrol (EN)",
        "24/7: 100 Humans",
        "24/7: Almost Paradise",
        "24/7: Lois And Clark",
        "24/7: MasterChef Australia",
        "24/7: Mister Winner",
        "24/7: Murder 24 7",
        "24/7: Murdered by Morning",
        "24/7: My 600-lb Life",
        "24/7: My Brilliant Friend",
        "24/7: Mysteries of the Abandoned",
        "24/7: Nailed It",
        "24/7: Naked Attraction",
        "24/7: New Eden",
        "24/7: Night on Earth",
        "24/7: Onisciente",
        "24/7: Our Cartoon President",
        "24/7: Paradise PD",
        "24/7: Paramedics",
        "24/7: Puerta 7",
        "24/7: Queen Sono",
        "24/7: Race for the White House",
        "24/7: Sacred Games",
        "24/7: Sanditon",
        "24/7: Santa Clarita Diet",
        "24/7: Save Me",
        "24/7: Scarecrow and Mrs. King",
        "24/7: Schitt's Creek",
        "24/7: Schooled",
        "24/7: Self Made Inspired by the Life of Madam C J Walker",
        "24/7: Sex Explained",
        "24/7: Shakespeare and Hathaway Private Investigators",
        "24/7: Sick of It",
        "24/7: Special OPS",
        "24/7: Spectros",
        "24/7: Spring Baking Championship",
        "24/7: Sunderland Til I Die",
        "24/7: Super Dragon Ball Heroes",
        "24/7: Superstar Kungfu 1",
        "24/7: Talking Dead",
        "24/7: Taskmaster",
        "24/7: Team Kaylie",
        "24/7: Ted Bundy Falling for a Killer",
        "24/7: Teen Mom 2",
        "24/7: Teenage Mutant Ninja Turtles",
        "24/7: Temptation Island",
        "24/7: The 100",
        "24/7: The Act",
        "24/7: The Affair",
        "24/7: The Amazing Race",
        "24/7: The Bachelor",
        "24/7: The Bachelorette",
        "24/7: The Bay",
        "24/7: The Big Narstie Show",
        "24/7: The Biggest Loser",
        "24/7: The Blacklist",
        "24/7: The Bold Type",
        "24/7: The Challenge",
        "24/7: The Chi",
        "24/7: The Code",
        "24/7: The Conners",
        "24/7: The Cool Kids",
        "24/7: The Cosby Show",
        "24/7: The Curse of Civil War Gold",
        "24/7: The Curse of Oak Island",
        "24/7: The Dresden Files",
        "24/7: The Enemy Within",
        "24/7: The English Game",
        "24/7: The Expanse",
        "24/7: The Family Chantel",
        "24/7: The Feed",
        "24/7: The Fix",
        "24/7: The Flash",
        "24/7: The Gifted",
        "24/7: The Glades",
        "24/7: The Goldbergs",
        "24/7: The Good Doctor",
        "24/7: The Good Fight",
        "24/7: The Good Fight",
        "24/7: The Good Karma Hospital",
        "24/7: The Good Place",
        "24/7: The Goop Lab",
        "24/7: The Graham Norton Show",
        "24/7: The Grand Tour",
        "24/7: The Handmaid's Tale",
        "24/7: The Healing Powers of Dude",
        "24/7: The Heights",
        "24/7: The Hills: New Beginnings",
        "24/7: The Hotel Inspector",
        "24/7: The Hunt",
        "24/7: The Iliza Shlesinger Sketch Show",
        "24/7: The Jim Jefferies Show",
        "24/7: The Jonathan Ross Show",
        "24/7: The Joy of Painting",
        "24/7: The Kids Are Alright",
        "24/7: The King of Queens",
        "24/7: The Kominsky Method",
        "24/7: The Last Alaskans",
        "24/7: The Last Leg",
        "24/7: The Last O G",
        "24/7: The Last O.G.",
        "24/7: The Magicians",
        "24/7: The Man in the High Castle",
        "24/7: The Masked Singer",
        "24/7: The Mechanism",
        "24/7: The Miniaturist",
        "24/7: The Most Dangerous Animal of All",
        "24/7: The Murders",
        "24/7: The Name of the Rose",
        "24/7: The Neighborhood",
        "24/7: The Nest",
        "24/7: The OA",
        "24/7: The Oath",
        "24/7: The Order",
        "24/7: The Orville",
        "24/7: The Other Two",
        "24/7: The Owl House",
        "24/7: The Passage",
        "24/7: The Plot Against America",
        "24/7: The Powerpuff Girls",
        "24/7: The Profit",
        "24/7: The Protector",
        "24/7: The Protector",
        "24/7: The Purge",
        "24/7: The Rain",
        "24/7: The Real Housewives of Atlanta",
        "24/7: The Real Housewives of Beverly Hills",
        "24/7: The Real Housewives of Cheshire",
        "24/7: The Real Housewives of Dallas",
        "24/7: The Real Housewives of New York City",
        "24/7: The Real Housewives of Orange County",
        "24/7: The Real Housewives of Potomac",
        "24/7: The Resident",
        "24/7: The Righteous Gemstones",
        "24/7: The Rookie",
        "24/7: The Simpsons",
        "24/7: The Sinner",
        "24/7: The Society",
        "24/7: The Sopranos",
        "24/7: The Split",
        "24/7: The Story of God with Morgan Freeman",
        "24/7: The Supervet",
        "24/7: The Test A New Era for Australias Team",
        "24/7: The Titan Games",
        "24/7: The Transformers",
        "24/7: The Twilight Zone",
        "24/7: The Umbrella Academy",
        "24/7: The Unicorn",
        "24/7: The Victim",
        "24/7: The Village",
        "24/7: The Virginian",
        "24/7: The Voice",
        "24/7: The Walking Dead",
        "24/7: The Weekly",
        "24/7: The Widow",
        "24/7: The Witnesses",
        "24/7: The Worst Witch",
        "24/7: This Country",
        "24/7: Three Busy Debras",
        "24/7: Timeless",
        "24/7: Tom and Jerry Tales",
        "24/7: Tribal",
        "24/7: Twenties",
        "24/7: Tyler Perry's The Haves and the Have Nots",
        "24/7: Tyler Perrys Young Dylan",
        "24/7: Tyson Fury The Gypsy King",
        "24/7: Ugly Delicious",
        "24/7: Unorthodox",
        "24/7: Unstoppable",
        "24/7: Utopia Falls",
        "24/7: Vera",
        "24/7: Vikings",
        "24/7: Walking Britains Lost Railways",
        "24/7: Whose Line Is It Anyway",
        "24/7: Jurassic Park/World Collection",
        "24/7: Knight Rider Collection",
        "24/7: Teen Wolf S05-S06 Collection",
        "24/7: #FreeRayshawn Colection",
        "24/7: 'Allo 'Allo! Collection",
        "24/7: 12 Monkeys Collection",
        "24/7: 13 Reasons Why Collection",
        "24/7: 2 Broke Girls Collection",
        "24/7: 21 Jump Street Collection",
        "24/7: 28 Days/Weeks Collection",
        "24/7: 30 for 30 S01 Collection",
        "24/7: 30 for 30 S02 Collection",
        "24/7: 30 for 30 S03 Collection",
        "24/7: 300 Collection",
        "24/7: 3rd Rock from the Sun Collection",
        "24/7: 48 Hours Collection",
        "24/7: 50 States Of Fright Collection",
        "24/7: 60 Days In Collection",
        "24/7: 60 Minutes Collection",
        "24/7: 9-1-1 Collection",
        "24/7: 9-1-1 Lonestar Collection",
        "24/7: 90's Hip Hop Mix",
        "24/7: 90's R&B Mix",
        "24/7: A Different World S01-S03 Collection",
        "24/7: A Different World S04-S06 Collection",
        "24/7: A Nightmare on Elm Street Collection",
        "24/7: According to Jim Season 1-3 pt1. Collection",
        "24/7: According to Jim Season 3 pt 2-5Collection",
        "24/7: According to Jim Season 6-8 Collection",
        "24/7: Adam Sandler Collection",
        "24/7: Adam-12 Season 1-2 Collection",
        "24/7: Air Bud and Air Buddies Collection",
        "24/7: Alf Collection",
        "24/7: Alfred Hitchcock Presents Collection",
        "24/7: All Grown Up Collection",
        "24/7: All in the Family Collection",
        "24/7: All Rise Collection",
        "24/7: All Round to Mrs. Brown's Collection",
        "24/7: Almighty Collection",
        "24/7: Almost Human Collection",
        "24/7: Altered Carbon Collection",
        "24/7: Alvin And The Chipmunks Collection",
        "24/7: Alvin and the Chipmunks TV Collection",
        "24/7: Amazing Spider-Man (1977) Collection",
        "24/7: Ambitions Collection",
        "24/7: America's Funniest Home Videos Collection",
        "24/7: American Chopper Collection",
        "24/7: American Dad Collection",
        "24/7: American Gods Collection",
        "24/7: American Horror Story Season 1-3 Collection",
        "24/7: American Horror Story Season 4-6 Collection",
        "24/7: American Horror Story Season 7-9 Collection",
        "24/7: American Pickers Collection",
        "24/7: American Pie Collection",
        "24/7: American Vandal Collection",
        "24/7: An American Tail Movie Collection",
        "24/7: Anchorman Collection",
        "24/7: Andi Mack Collection",
        "24/7: Are You Afraid of the Dark Season 1-3 Collection",
        "24/7: Are You Afraid of the Dark Season 4-7 Collection",
        "24/7: Auf Widersehen Pet Season 1-2 Collection",
        "24/7: Austin Powers Collection",
        "24/7: Automan (1983) Collection",
        "24/7: Avengers Collection",
        "24/7: Back to The Future Collection",
        "24/7: Bad Blood Collection",
        "24/7: Bad Boys Collection",
        "24/7: Bad Mothers Collection",
        "24/7: Bad Santa Collection",
        "24/7: Ballers Collection",
        "24/7: Band of Brothers Collection",
        "24/7: Banshee Collection",
        "24/7: Barbershop Collection",
        "24/7: Barney & Friends Collection",
        "24/7: Batman (1966) Collection",
        "24/7: Baywatch Season 1-2 Collection",
        "24/7: Beavis and Butt-Head Collection",
        "24/7: Beetlejuice Collection",
        "24/7: Being Human (UK) Collection",
        "24/7: Being Human Collection",
        "24/7: Ben 10 Alien Force Collection",
        "24/7: Ben 10 Collection",
        "24/7: Ben 10 Ultimate Collection",
        "24/7: Ben and Holly's Little Kingdom Collection",
        "24/7: Benny Hill Show Collection",
        "24/7: Best NHL Goals of all Time Collection",
        "24/7: Best of In Living Collor Music Videos",
        "24/7: Best Of The Best Collection",
        "24/7: Better Call Saul Collection",
        "24/7: Bewitched Collection",
        "24/7: Big Brother Collection",
        "24/7: Big Momma's House Collection",
        "24/7: Bill & Ted Collection",
        "24/7: Billions Collection",
        "24/7: Bionic Six Collection",
        "24/7: Black Dynamite Collection",
        "24/7: Black Jesus Collection",
        "24/7: Blade Collection",
        "24/7: Blair Witch Collection",
        "24/7: Blaze and the Monster Machines",
        "24/7: Blindspot Collection",
        "24/7: Blood & Treasure Collection",
        "24/7: Blood Ties S01 Collection",
        "24/7: Blossom Season 1-2 Collection",
        "24/7: Blue Bloods Collection",
        "24/7: Bob Marley - The Legend Live",
        "24/7: Bob Ross: The Joy of Painting Collection",
        "24/7: Bob's Burgers Collection",
        "24/7: Boomerang Collection",
        "24/7: Borgen Collection",
        "24/7: Bosko Collection",
        "24/7: Boss Baby Back in Business Collection",
        "24/7: Botched Season 2 Collection",
        "24/7: Bourne Collection",
        "24/7: Boyz II Men - The Total Package Tour",
        "24/7: Brad Pitt Collection",
        "24/7: Brandy & Ray J A family Business Collection",
        "24/7: Braxton Family Values Collection",
        "24/7: Breaking Bad S01-S03 Collection",
        "24/7: Breaking Bad S04-S05 Collection",
        "24/7: Briarpath Collection",
        "24/7: Bring The Funny Collection",
        "24/7: Bruce Lee Collection",
        "24/7: Bubble Guppies Collection",
        "24/7: Buck Rogers Collection",
        "24/7: Buddy vs Duff Collection",
        "24/7: Bugs Bunny Collection",
        "24/7: Bull Collection",
        "24/7: Bulletproof Collection",
        "24/7: Bumfights Collection",
        "24/7: Caillou Collection",
        "24/7: Calming Streams Collection",
        "24/7: Calming Waves Collection",
        "24/7: Campfire Collection",
        "24/7: Candyman Collection",
        "24/7: Captain America Collection",
        "24/7: Carrie Collection",
        "24/7: Catherine Tate Show Collection",
        "24/7: Chappelle's Show Collection",
        "24/7: Charles in Charge Collection",
        "24/7: Charlies Angels Collection",
        "24/7: Charmed 2018 Collection",
        "24/7: Cheech and Chong Collection",
        "24/7: Cheers Collection",
        "24/7: Child's Play Collection",
        "24/7: Chuck Collection",
        "24/7: Classic 2000's Vol 1 Collection",
        "24/7: Classic 2000's Vol 2 Collection",
        "24/7: Classic 80's Vol 1 Collection",
        "24/7: Classic 80's Vol 2 Collection",
        "24/7: Classic 90's Vol 1 Collection",
        "24/7: Classic 90's Vol 2 Collection",
        "24/7: Claws Collection",
        "24/7: Clerks Collection",
        "24/7: Clint Eastwood Collection",
        "24/7: Cloudy with a Chance of Meatballs Collection",
        "24/7: Coach Collection",
        "24/7: Cobra Kai S01 Collection",
        "24/7: Cobra Kai S02 Collection",
        "24/7: Cocoon Collection",
        "24/7: Cold Case Collection",
        "24/7: Columbo 1-6 Collection",
        "24/7: Columbo 7-11 Collection",
        "24/7: Comedy Central Roasts Collection",
        "24/7: Conan Collection",
        "24/7: Cooking On High Collection",
        "24/7: Cop and a Half Collection",
        "24/7: Cops Collection",
        "24/7: Coral Reef Aquarium Collection",
        "24/7: Corn Wars Collection",
        "24/7: Cow and Chicken Collection",
        "24/7: Crank Yankers Collection",
        "24/7: Criminal Minds Collection",
        "24/7: Crocodile Dundee Collection",
        "24/7: CSI: Crime Scene Investigation Collection",
        "24/7: Curb Your Enthusiasm Collection",
        "24/7: Daddy's Home Collection",
        "24/7: Dancehall Mix",
        "24/7: Danger Mouse Collection",
        "24/7: Danny Phantom Collection",
        "24/7: Dark Side of the Ring Collection",
        "24/7: DCs Legends of Tomorrow Collection",
        "24/7: Death Row Stories Collection",
        "24/7: Deep Sea Collection",
        "24/7: Denzel Washington Collection",
        "24/7: Designated Survivor Collection",
        "24/7: Despicable Me Collection",
        "24/7: Destination Fear Collection",
        "24/7: Deuce Bigalow Collection",
        "24/7: Dexter Collection",
        "24/7: Dharma & Greg Collection",
        "24/7: Diary Of A Wimpy Kid Collection",
        "24/7: Die Hard Collection",
        "24/7: Die Hart Collection",
        "24/7: Different Strokes Collection",
        "24/7: Dinosaur Train Collection",
        "24/7: Dinosaurs Collection",
        "24/7: Dirty Harry Collection",
        "24/7: Dirty John Collection",
        "24/7: Discovery Mighty Trains Collection",
        "24/7: Disney Shorts Collection",
        "24/7: Disney's Christmas Fireplace Collection",
        "24/7: DMX - Woodstock 99",
        "24/7: Donald Duck Collection",
        "24/7: Doom Collection",
        "24/7: Dora the Explorer Collection",
        "24/7: Dora the Explorer Season 6-8 Collection",
        "24/7: Downton Abbey Collection",
        "24/7: Ducktales( 80's) Collection",
        "24/7: Dumb and Dumber Collection",
        "24/7: Dummy Collection",
        "24/7: Dynasty (1980) S01 Collection",
        "24/7: Dynasty (1980) S02 Collection",
        "24/7: Dynasty (1980) S03 Collection",
        "24/7: Dynasty (1980) S04 Collection",
        "24/7: Dynasty (1980) S05 Collection",
        "24/7: Dynasty (1980) S07 Collection",
        "24/7: Dynasty (1980) S08 Collection",
        "24/7: Dynasty (1980) S09 Collection",
        "24/7: Dynasty (2017) SO1-S02 Collection",
        "24/7: Eastbound and Down Collection",
        "24/7: Ed Edd n Eddy Collection",
        "24/7: Eight is Enough Collection",
        "24/7: Elvis Presley Collection",
        "24/7: Empire Collection",
        "24/7: Enter The Ninja Collection",
        "24/7: Ernest Collection",
        "24/7: Eureka Collection",
        "24/7: Everybody Hates Chris",
        "24/7: Everybody Loves Raymond Collection",
        "24/7: EVIL Season 1 Collection",
        "24/7: F*ck That's Delicious Collection",
        "24/7: F-Troop Season 1 Collection",
        "24/7: F-Troop Season 2 Collection",
        "24/7: Family Guy Season 1-3 Collection",
        "24/7: Family Guy Season 10-12 Collection",
        "24/7: Family Guy Season 4-6 Collection",
        "24/7: Family Guy Season 7-9 Collection",
        "24/7: Family Matters S01-S03 Collection",
        "24/7: Family Matters S04-S06 Collection",
        "24/7: Family Matters S07-S09 Collection",
        "24/7: Fantastic Beasts Collection",
        "24/7: Fantastic Four Collection",
        "24/7: Fast & Furious Collection",
        "24/7: FBI S01-S02 Collection",
        "24/7: Felix The Cat Collection",
        "24/7: Fifty Shades of Grey Collection",
        "24/7: Fire Masters Collection",
        "24/7: Flip or Flop Collection",
        "24/7: Flipper Collection",
        "24/7: Flipping 101 With Tarek El Moussa",
        "24/7: Footloose Collection",
        "24/7: For All Mankind Collection",
        "24/7: For Life S01 Collection",
        "24/7: Forest Rainstorm Collection",
        "24/7: Forever Marilyn Collection",
        "24/7: Fraggle Rock Collection",
        "24/7: Free Willy Collection",
        "24/7: Friday The 13th Prt 1-6 Collection",
        "24/7: Friday The 13th Prt 7-12 Collection",
        "24/7: Friends S01-S02 Collection",
        "24/7: Friends S03-S04 Collection",
        "24/7: Friends S05-S06 Collection",
        "24/7: Friends S07-S08 Collection",
        "24/7: Friends S09-S10 Collection",
        "24/7: Frozen Collection",
        "24/7: Full House Collection",
        "24/7: Fuller House Collection",
        "24/7: Futurama Collection",
        "24/7: G.I. Joe Collection",
        "24/7: Game of Thrones S01-S03 Collection",
        "24/7: Game of Thrones S04-S06 Collection",
        "24/7: Game of Thrones S07-S08 Collection",
        "24/7: Get Smart (1965 - 1970) Collection",
        "24/7: Ghost Adventures Collection",
        "24/7: Ghostbusters Collections",
        "24/7: GI Joe Collection",
        "24/7: Gigantosaurus Collection",
        "24/7: Gilligan's Island Collection",
        "24/7: Girl Meets World Collection",
        "24/7: GLOW Collection",
        "24/7: Godfather of Harlem Collection",
        "24/7: Godzilla Collection Vol 1 Collection",
        "24/7: Godzilla Collection Vol 2 Collection",
        "24/7: Good Girls Collection",
        "24/7: Good Morning Miss Bliss Collection",
        "24/7: Good Times Collection",
        "24/7: Goofy Movies Collection",
        "24/7: Gravity Falls Collection",
        "24/7: Grease Collection",
        "24/7: Greatness Code Collection 4K",
        "24/7: Green Acres Collection",
        "24/7: Gremlins Collection",
        "24/7: Grey's Anatomy Collection",
        "24/7: Growing Pains 24-7",
        "24/7: Grumpy Old Men Collection",
        "24/7: Guardians of the Galaxy Collection",
        "24/7: Gumby Collection",
        "24/7: Gummi Bears Collections",
        "24/7: Gunsmoke Season 1 Collection",
        "24/7: Gunsmoke Season 2 Collection",
        "24/7: Gunsmoke Season 3 Collection",
        "24/7: Halloween Collection",
        "24/7: Hangin' with Mr. Cooper Collection",
        "24/7: Hanna Collection",
        "24/7: Hannibal Collection",
        "24/7: Happy Days Collection",
        "24/7: Hardcore Pawn Collection",
        "24/7: Harley Quinn Collection",
        "24/7: Harry Potter Collection",
        "24/7: Hart to Hart Collection",
        "24/7: Haunting In the Heartland Collection",
        "24/7: He-Man and the Masters of the Universe Collection",
        "24/7: Heavy Thunderstorm Collection",
        "24/7: Hellboy Collection",
        "24/7: Henry Danger Collection",
        "24/7: Herbie Collection",
        "24/7: Highlander Collection",
        "24/7: Hip Hop Video Mix",
        "24/7: Hitman Collection",
        "24/7: Hoarders Pt. 1 Collection",
        "24/7: Hoarders Pt. 2 Collection",
        "24/7: Hoarders Pt. 3 Collection",
        "24/7: Home Alone Collection",
        "24/7: Home Before Dark S01 Collection",
        "24/7: Home Improvement Collection",
        "24/7: Hostel Collection",
        "24/7: Hotel Transylvania Collection",
        "24/7: House Collection",
        "24/7: House Party Collection",
        "24/7: Hulk Collection",
        "24/7: Hunters Collection",
        "24/7: I Love Lucy S01-S02 Collection",
        "24/7: Ice Road Truckers Collection",
        "24/7: Impractical Jokers Collection",
        "24/7: In Living Color Collection",
        "24/7: Independence Day Collection",
        "24/7: Indiana Jones Collection",
        "24/7: Ink Master Collection",
        "24/7: Insecure Collection",
        "24/7: Inspector Gadget",
        "24/7: Ip Man Collection",
        "24/7: Iron Maiden Hammersmith 1982",
        "24/7: Iron Man Collection",
        "24/7: It Collection",
        "24/7: IZombie S01-S02 Collection",
        "24/7: IZombie S03-S04 Collection",
        "24/7: Jackie Chan Adventures Collection",
        "24/7: Jake and the Neverland Pirates Collection",
        "24/7: Janet Jackson Up Close!",
        "24/7: Jaws Collection",
        "24/7: Jay and Silent Bob Collection",
        "24/7: Jeepers Creepers Collection",
        "24/7: Jeff Dunham Collection",
        "24/7: Jersey Shore - Family Vacation Collection",
        "24/7: Jesse Collection",
        "24/7: John Wick Collection",
        "24/7: Johnny Bravo Collection",
        "24/7: Johnny Carson Collection",
        "24/7: Johnny Test Collection",
        "24/7: Jordan vs Lebron - The Best GOAT Comparison",
        "24/7: Judge Judy Collection",
        "24/7: Judge Mathis Collection",
        "24/7: Jumanji Collection",
        "24/7: Keeping Up Appearances Collection",
        "24/7: Kenan & Kel Collection",
        "24/7: Kevin Costner Collection",
        "24/7: Kick Boxer Collection",
        "24/7: KIDZ BOP",
        "24/7: Kill Bill Collection",
        "24/7: Kimba the White Lion Collection",
        "24/7: King of the Hill Season 1-3 Collection",
        "24/7: Kobe Bryant - The Black Mamba",
        "24/7: Kolchak The Night Stalker Collection",
        "24/7: Krazy Kat Collection",
        "24/7: Larry Bird's 50 Greatest Moments Collection",
        "24/7: Last Comic Standing Season 4 Collection",
        "24/7: Last man Standing Collection",
        "24/7: Laverne & Shirley Collection",
        "24/7: Law & Order SVU 1 Collection",
        "24/7: Law & Order SVU 2 Collection",
        "24/7: Law & Order SVU 3 Collection",
        "24/7: Law and Order Criminal Intent Collection",
        "24/7: Legally Blonde Collection",
        "24/7: LEGO City Mini Movies Collection",
        "24/7: Lego Masters US Collection",
        "24/7: Lego Movie Collection",
        "24/7: Lego Ninjago Collection",
        "24/7: Leprechaun Collection",
        "24/7: Leslie Nielsen Collection",
        "24/7: Lethal Weapon Collection",
        "24/7: Letter Kenny Collection",
        "24/7: Liberty's Kids Collection",
        "24/7: Lil Wayne Live at Firefly Music Festival",
        "24/7: Lincoln Rhyme: Hunt for the Bone Collector Collection",
        "24/7: Little Bear Collection",
        "24/7: Little House on the Prairie Collection",
        "24/7: Living Single Collection",
        "24/7: Locke & Key Collection",
        "24/7: Look Who's Talking Collection",
        "24/7: Looney Tunes Collection",
        "24/7: Lost Collection",
        "24/7: Lost in Space Collection",
        "24/7: Love & Hip Hop New York Collection",
        "24/7: Love Death Robots Collection",
        "24/7: Lucifer Collection",
        "24/7: M*A*S*H Collection",
        "24/7: Mad Max Collection",
        "24/7: Making A Murderer S01-S02 Collection",
        "24/7: Malcolm in the Middle Collection",
        "24/7: Maleficent Collection",
        "24/7: Man Vs Food Collection",
        "24/7: Maniac Collection",
        "24/7: Mark Wahlberg Collection",
        "24/7: Marlon Collection",
        "24/7: Married with Children S01-S04 Collection",
        "24/7: Married With Children S05-S08 Collrction",
        "24/7: Married wth Children S09-S11 Collection",
        "24/7: Martin Collection",
        "24/7: Mary Poppins Collection",
        "24/7: Mater's Tall Tales Collection",
        "24/7: Mayans Collection",
        "24/7: Mega Man Collection",
        "24/7: Melrose Place Season 1 Collection",
        "24/7: Melrose Place Season 2 Collection",
        "24/7: Men In Black Collection",
        "24/7: Miami Vice S01-S02 Collection",
        "24/7: Miami Vice S03-S04 Collection",
        "24/7: Miami Vice S05 Collection",
        "24/7: Michael Jackson - Dangrous Tour",
        "24/7: Michael Jackson - Live at MSG",
        "24/7: Michael Jordan - Greatest Moments Collection",
        "24/7: Mickey and the Roadster Racers Collection",
        "24/7: Mickey Mouse Club House Collection",
        "24/7: Mickey Mouse Collection",
        "24/7: Mighty Ducks Cartoon Collection",
        "24/7: Mike Tyson - Greatest Moments Collection",
        "24/7: Miss Congeniality Collection",
        "24/7: Mission Impossible Collection",
        "24/7: Mister Ed Collection",
        "24/7: Mister Roger's Neighborhood Collection",
        "24/7: Mom Season 1-3 Collection",
        "24/7: Mom Season 4-5 Collection",
        "24/7: Monsters Inc / University Collection",
        "24/7: Moonlighting (1985) Collection",
        "24/7: Mork and Mindy Collection",
        "24/7: Most Dangerous Game Collection",
        "24/7: Mr Bean Cartoon Collection",
        "24/7: Mr. Robot Collection",
        "24/7: Muhammad Ali - Greatest Moments Collection",
        "24/7: Muppet Babies S01-S04 Collection",
        "24/7: Muppet Babies S05-S07 Collection",
        "24/7: Murder She Wrote Collection",
        "24/7: Murphy Brown Collection",
        "24/7: My 600-Lb. Life Collection",
        "24/7: My Name is Earl Collection",
        "24/7: MythBusters Collection",
        "24/7: Nancy Drew Collection",
        "24/7: Narcos Collection",
        "24/7: Narcos Mexico Collection",
        "24/7: Naruto Collection",
        "24/7: National Treasure Collection",
        "24/7: NBA Greatest Dunk Contests Collection",
        "24/7: NCIS L.A. Collection",
        "24/7: NCIS Season 1-2 Collection",
        "24/7: NCIS Season 3 Collection",
        "24/7: Neverending Story Collection",
        "24/7: Newlyweds: Nick and Jessica Collection",
        "24/7: NHL Top 100 Players of All-Time Collection",
        "24/7: Night at the Museum Collection",
        "24/7: Night Court Collection",
        "24/7: Nursery Rhymes Collection",
        "24/7: Ocean's Collections",
        "24/7: Old School Kung-Fu Movies Collection",
        "24/7: Old School R&B",
        "24/7: On The Run II - Beyonc",
        "24/7: One Tree Hill Collection",
        "24/7: Orange is the New Black S01-S02 Collection",
        "24/7: Orange is the New Black S03-S04 Collection",
        "24/7: Orange is the New Black S05-S06 Collection",
        "24/7: Orange is the New Black S07-S08 Collection",
        "24/7: OZ Collection",
        "24/7: Ozark Collection",
        "24/7: Ozzy Osbourne Chile 8 Mayo 2018",
        "24/7: Pacific Rim Collection",
        "24/7: Paranormal Collection",
        "24/7: Pauly Shore Collection",
        "24/7: Paw Patrol Collection",
        "24/7: Peaceful Waterfall Collection",
        "24/7: Pearl Jam Live 2018",
        "24/7: PeeWees Playhouse Collection",
        "24/7: Peppa Pig Collection",
        "24/7: Percy Jackson Collection",
        "24/7: Perfect Strangers Collection",
        "24/7: Perry Mason Collection",
        "24/7: Pinky and the Brain Collection",
        "24/7: Pirates of the Caribbean Collection",
        "24/7: PJ Masks Collection",
        "24/7: Planet of the Apes S01 Collection",
        "24/7: PokÃ©mon Collection",
        "24/7: Police Academy Collection",
        "24/7: Popeye The Sailor Man Collection",
        "24/7: Porky's Collection",
        "24/7: Power S01-S02 Collection",
        "24/7: Power S03-S04 Collection",
        "24/7: Power S05 Collection",
        "24/7: Power S06 Collection",
        "24/7: Predator Collection",
        "24/7: Press Your Luck Collection",
        "24/7: Prince Live - 20TEN Tour",
        "24/7: Prison Break Collection",
        "24/7: Problem Child Collection",
        "24/7: Project Blue Book Collection",
        "24/7: Property Brothers Collection",
        "24/7: Psycho Collection",
        "24/7: Punky Brewster Collection",
        "24/7: Puppy Dog Pals Collection",
        "24/7: Pure Country Collection",
        "24/7: Quantum Leap Collection",
        "24/7: Queer Eye (2018) Collection",
        "24/7: Quick Draw Collection",
        "24/7: Rambo Collection",
        "24/7: Ramy Collection",
        "24/7: Raven's Home collection",
        "24/7: Real Rob Collection",
        "24/7: Reba Collection",
        "24/7: Rel Collection",
        "24/7: Relaxing Birds Collection",
        "24/7: Reno 911 Collection",
        "24/7: Reno 911! Collection",
        "24/7: Revenge of the Nerds Collection",
        "24/7: Rick and Morty Collection",
        "24/7: Riddick Collection",
        "24/7: Ride Along Collection",
        "24/7: Ripley's Believe It or Not! Season 1 Collection",
        "24/7: Ripley's Believe It or Not! Season 2 Collection",
        "24/7: Robin Williams Collection",
        "24/7: Robo Cop Collection",
        "24/7: Robot Boy Collection",
        "24/7: Rocko's Modern Life Collection",
        "24/7: Rocky Collection",
        "24/7: Rome Collection",
        "24/7: Roseanne S01-S3 Collection",
        "24/7: Roseanne S04-S6 Collection",
        "24/7: Roseanne S07-S10 Collection",
        "24/7: Rugrats Collection",
        "24/7: Rugrats Movie Collection",
        "24/7: Rules of Engagement Collection",
        "24/7: Rush Hour Collection",
        "24/7: S$*! My Dad Says Collection",
        "24/7: Sabrina The Teenage Witch Collection",
        "24/7: Sanford And Son S01-S03 Collection",
        "24/7: Sanford and Son S04-S06 Collection",
        "24/7: Saved By The Bell - College Years Collection",
        "24/7: Saved By The Bell - The New Class Collection",
        "24/7: Saved By The Bell Collection",
        "24/7: Saved By The Bell TV Movie Collection",
        "24/7: Saw Collection",
        "24/7: Scandal S01-S02 Collection",
        "24/7: Scandal S03-S04A Collection",
        "24/7: Scandal S04B-S06A Collection",
        "24/7: Scandal S06B-S07 Collection",
        "24/7: Scary Movie Collection",
        "24/7: Scream Collection",
        "24/7: Scrubs 1-3 Collection",
        "24/7: Scrubs 4-6 Collection",
        "24/7: Scrubs 7-9 Collection",
        "24/7: See Collection",
        "24/7: Seinfeld Collection",
        "24/7: Sesame Street Collection",
        "24/7: Shameless Collection",
        "24/7: Shark Tank Collection",
        "24/7: Shaun the Sheep Collection",
        "24/7: She-ra and the Princesses of Power Collection",
        "24/7: Short Circuit Collection",
        "24/7: Shower Sounds Collection",
        "24/7: Shrek Collection",
        "24/7: Silent Hill Collection",
        "24/7: SilverHawks Collections",
        "24/7: Sister Act Collection",
        "24/7: Sliders Collection",
        "24/7: Small Wonder Collection",
        "24/7: Smokey and the Bandit Collection",
        "24/7: Sniper Collection",
        "24/7: Snoop Dogg - Live at the Avalon",
        "24/7: Snow Falling on Lake Collection",
        "24/7: Snowfall S01-S03 Collection",
        "24/7: Sons of Anarchy S01-S02 Collection",
        "24/7: Sons of Anarchy S03-S04 Collection",
        "24/7: Sons of Anarchy S05-S06A Collection",
        "24/7: South Park Collection",
        "24/7: Space Force Collection",
        "24/7: Spawn Cartoon Collection",
        "24/7: Speed Collection",
        "24/7: Spider-man (90's) Collection",
        "24/7: Spider-Man and His Amazing Friends Collection",
        "24/7: Spike Lee Collection",
        "24/7: Spongebob Movie Collection",
        "24/7: SpongeBob SquarePants Collection",
        "24/7: Star Trek Deep Space Nine Season 1-2 Collection",
        "24/7: Star Trek The Original Series Season 1 Collection",
        "24/7: Star Trek The Original Series Season 2 Collection",
        "24/7: Star Trek The Original Series Season 3 Collection",
        "24/7: Star Trek: Picard Collection",
        "24/7: Star Wars Clone Wars Collection",
        "24/7: Star Wars Rebels Collection",
        "24/7: Step Up Collection",
        "24/7: Steven Seagal Movie Collection",
        "24/7: Stranger Things S01 Collection",
        "24/7: Stranger Things S02 Collection",
        "24/7: Stranger Things S03 Collection",
        "24/7: Stuck in the Middle Collection",
        "24/7: Super Mario World Collection",
        "24/7: Super Troopers Collection",
        "24/7: SuperFriends (1980) Collection",
        "24/7: Supergirl Collection",
        "24/7: Superman Cartoon Collection",
        "24/7: Superman Collection",
        "24/7: Supernatural Season 02 Collection",
        "24/7: Supernatural Season 1 Collection",
        "24/7: Supernatural Season 14 Collection",
        "24/7: Supernatural Season 3-4 Collection",
        "24/7: Swamp People Collection",
        "24/7: Swamp Thing Collection",
        "24/7: Sweet Magnolias Collection",
        "24/7: T.J. Hooker Collection",
        "24/7: Taken Collection",
        "24/7: Tales From the Crypt Collection",
        "24/7: Tales From the Crypt-keeper Collection",
        "24/7: Talking Tom & Friends Collection",
        "24/7: Taxi Collection",
        "24/7: Teen Mom 2 Collection",
        "24/7: Teen Titans Go Collection",
        "24/7: Teen Wolf S01-S02 Collection",
        "24/7: Teen Wolf S03-S04 Collection",
        "24/7: Teenage Mutant Ninja Turtles Collection",
        "24/7: Teletubbies Collection",
        "24/7: Terminator: The sarah Connor Chronicles Collection",
        "24/7: Texas Chainsaw Massacre Collection",
        "24/7: That 70s Show Collection",
        "24/7: The A-Team Collection",
        "24/7: The Addams Family Collection",
        "24/7: The Alienist Collection",
        "24/7: The Amanda Show Collection",
        "24/7: The Amazing Spider-Man Collection",
        "24/7: The Andy Griffith Show Collection",
        "24/7: The Bernie Mac Show Collection",
        "24/7: The Beverly Hillbillies Season 1-2 Collection",
        "24/7: The Beverly Hillbillies Season 3-4 Collection",
        "24/7: The Big Bang Theory S01-S04 Collection",
        "24/7: The Big Bang Theory S05-S07 Collection",
        "24/7: The Big Show Show Collection",
        "24/7: The Bionic Woman S01 (2017) Collection",
        "24/7: The Boondock Saints Collection",
        "24/7: The Boondocks Collection",
        "24/7: The Boys Collection",
        "24/7: The Brady Bunch Collection",
        "24/7: The Butterfly Effect Collection",
        "24/7: The Chi Collection",
        "24/7: The Chilling Adventures of Sabrina Collection",
        "24/7: The Cleveland Show Collection",
        "24/7: The Conjuring / Annabelle Collection",
        "24/7: The Conners Collection",
        "24/7: The Cosby Show Collection",
        "24/7: The Dick Van Dyke Show Collection",
        "24/7: The Dukes Of Hazzard S01-S02 Collection",
        "24/7: The Dukes Of Hazzard S03-S04 Collection",
        "24/7: The Dukes Of Hazzard S05-S07 Collection",
        "24/7: The East Side Kids (1941) Collection",
        "24/7: The Evil Dead Collection",
        "24/7: The Expendables Collection",
        "24/7: The Facts Of Life Collection",
        "24/7: The Fairly Oddparents Collection",
        "24/7: The Final Destination Collection",
        "24/7: The First 48 Collection",
        "24/7: The Flintstone Kids Collection",
        "24/7: The Flintstones Collection",
        "24/7: The Following S01-S02 Collection",
        "24/7: The Fresh Prince of Bel-Air Collection",
        "24/7: The Get Down S01 Collection",
        "24/7: The Godfather Collection",
        "24/7: The Gods Must Be Crazy Collection",
        "24/7: The Golden Girls Collection",
        "24/7: The Good Doctor Collection",
        "24/7: The Green Hornet 24-7",
        "24/7: The Grudge Collection",
        "24/7: The Handmaid's Tale S01 Collection",
        "24/7: The Hangover Collection",
        "24/7: The Hills Have Eyes Collection",
        "24/7: The Honeymooners Collection",
        "24/7: The Hunger Games Collection",
        "24/7: The Incedible Hulk S01-S02 Collection",
        "24/7: The Incredible Hulk S03-S05 Collection",
        "24/7: The Jacksons - Victory Tour Toronto 1984",
        "24/7: The Jamie Foxx Show Collection",
        "24/7: The Jeffersons Collection",
        "24/7: The Jetsons Collection",
        "24/7: The Karate Kid Collection",
        "24/7: The King of Queens S01-S03 Collection",
        "24/7: The King of Queens S04-S06 Collection",
        "24/7: The King of Queens S07-S09 Collection",
        "24/7: The Last Dance Collection",
        "24/7: The Last O.G. Collection",
        "24/7: The Legacy of Shaquille O'Neal Collection",
        "24/7: The Life and Times of Grizzly Adams Collection",
        "24/7: The Little Mermaid Collection",
        "24/7: The Little Rascals Collection",
        "24/7: The Lord of the Rings Collection",
        "24/7: The Lost Boys Collection",
        "24/7: The Love Boat Collection",
        "24/7: The Mandalorian Collection",
        "24/7: The Matrix Collection",
        "24/7: The Maze Runner Collection",
        "24/7: The Middle Collection",
        "24/7: The Mighty Ducks Collection",
        "24/7: The Mummy Collection",
        "24/7: The Munsters Collection",
        "24/7: The Naked Gun Collection",
        "24/7: The Nanny Collection",
        "24/7: The New Adventures of Gilligan (1975) Collection",
        "24/7: The New Fat Albert Show (1979-1981) Collection",
        "24/7: The Octonauts Collection",
        "24/7: The Odd Couple S01-S02 Collection",
        "24/7: The Odd Couple S03-S05 Collection",
        "24/7: The Office Collection",
        "24/7: The Partridge Family Season 1-2 Collection",
        "24/7: The Pier S01 Collection",
        "24/7: The Princess Diaries Collection",
        "24/7: The Purge Collection",
        "24/7: The Purge Movie Collection",
        "24/7: The Rookie S01 Collection",
        "24/7: The Ropers Collection",
        "24/7: The Simpsons Collection",
        "24/7: The Six Million Dollar Man Collection",
        "24/7: The Smurfs Collection",
        "24/7: The Sopranos S01-S03 Collection",
        "24/7: The Sopranos S04 Collection",
        "24/7: The Sopranos S05 Collection",
        "24/7: The Sopranos S06 Collection",
        "24/7: The Spider-Man Collection",
        "24/7: The Super Mario Super Show! Collection",
        "24/7: The Three Stooges Collection",
        "24/7: The Twilight Saga Collection",
        "24/7: The Twilight Zone (1959) Collections",
        "24/7: The Umbrella Academy S01 Collection",
        "24/7: The Vampire Diaries Collection",
        "24/7: The Walking Dead S01-S03 Collection",
        "24/7: The Walking Dead S04-S05 Collection",
        "24/7: The Walking Dead S06-S07 Collection",
        "24/7: The Walking Dead S08-S09 Collection",
        "24/7: The Walking Dead S10 Collection",
        "24/7: The Waltons Season 1 Collection",
        "24/7: The Waltons Season 2 Collection",
        "24/7: The Waltons Season 3 Collection",
        "24/7: The Waltons Season 4 Collection",
        "24/7: The Wire S01-02 Collection",
        "24/7: The Wire S03-04 Collection",
        "24/7: The Wire S05 Collection",
        "24/7: The Witcher Collection",
        "24/7: The Wonder Years Collection",
        "24/7: The Wrestlers Collection",
        "24/7: The X-Files Season 1-2 Collection",
        "24/7: This is Us S01-S02 Collection",
        "24/7: This Is Us S03 Collection",
        "24/7: This Is Us S04 Collection",
        "24/7: Thomas & Friends Collection",
        "24/7: Thor Collection",
        "24/7: Three Stooges Cartoon Collection",
        "24/7: Three's Company Company",
        "24/7: Thunder Cats Collection",
        "24/7: Tiger King Murder Mayhem And Madness Collection",
        "24/7: Titus Collection",
        "24/7: Tom and Jerry Collection",
        "24/7: Tom Hanks Collection",
        "24/7: Tomb Raider Collection",
        "24/7: Top 100 Runs in NFL History Collection",
        "24/7: Transformers Collection*",
        "24/7: Trolls Movie Collection",
        "24/7: Tropical Island Beach Collection",
        "24/7: True Blood S01-02 Collection",
        "24/7: True Blood S03-04 Collection",
        "24/7: True Blood S05-07 Collection",
        "24/7: Truth Be Told Collection",
        "24/7: Twerk Party",
        "24/7: Two and a Half Men Collection",
        "24/7: UFC Fight Island: Declassified",
        "24/7: UFO Hunters Collection",
        "24/7: Ultimate Warrior's greatest moments Collection",
        "24/7: Under the Dome Collection",
        "24/7: Undercover Boss Collection",
        "24/7: Underworld Collection",
        "24/7: Unsolved Mysteries Collection",
        "24/7: Unsolved Mysteries Season 2-3 Collection",
        "24/7: Unsolved Mysteries Season 4-6 Collection",
        "24/7: Usher - OMG Tour (Live From London 2011)",
        "24/7: Vampirina Collection",
        "24/7: Veggietales Collection",
        "24/7: Vikings Collection",
        "24/7: Waiting Collection",
        "24/7: Warrior Nun Collection",
        "24/7: Warrior S01 Collection",
        "24/7: Watchmen Collection",
        "24/7: Waxworks Collection",
        "24/7: Waynes World Collection",
        "24/7: Weeds Collection",
        "24/7: Weekend at Bernies Collection",
        "24/7: Western Movie Collection",
        "24/7: What's Happenning!! Collection",
        "24/7: Whats New Scooby Doo Collection",
        "24/7: When The Streetlights Go On Collection",
        "24/7: Who's the Boss Collection",
        "24/7: Who's the Boss Season 1-2 Collection",
        "24/7: Who's the Boss Season 3-4 Collection",
        "24/7: Will Smith Collection",
        "24/7: Willy Wonka Collection",
        "24/7: Wonder Woman Season 1 Collection",
        "24/7: Wreck It Ralph Collection",
        "24/7: Wrong Turn Collection",
        "24/7: Wu-Tang: An American Saga",
        "24/7: WWE 20 Matches that Define the Intercontinental Title Collection",
        "24/7: X-Men Collection",
        "24/7: X-Men The Animated Series Collection",
        "24/7: You S01-02 Collection",
        "24/7: Young Sheldon Collection",
        "24/7: Zombieland Collection",
        "24/7: Knight Rider Collection",
        "24/7: 3rd Rock from the Sun Collection",
        "24/7: Alf Collection",
        "24/7: All Grown Up Collection",
        "24/7: American Dad Collection",
        "24/7: Bad Blood Collection",
        "24/7: Barney & Friends Collection",
        "24/7: Beavis and Butt-Head Collection",
        "24/7: Beetlejuice Collection",
        "24/7: Ben 10 Alien Force Collection",
        "24/7: Ben 10 Collection",
        "24/7: Ben and Holly's Little Kingdom Collection",
        "24/7: Benny Hill Show Collection",
        "24/7: Best of In Living Collor Music Videos",
        "24/7: Billions Collection",
        "24/7: Bionic Six Collection",
        "24/7: Blade Collection",
        "24/7: Blaze and the Monster Machines",
        "24/7: Bob Marley - The Legend Live",
        "24/7: Bob's Burgers Collection",
        "24/7: Bosko Collection",
        "24/7: Boyz II Men - The Total Package Tour",
        "24/7: Bubble Guppies Collection",
        "24/7: Bumfights Collection",
        "24/7: Caillou Collection",
        "24/7: Charmed 2018 Collection",
        "24/7: Columbo 1-6 Collection",
        "24/7: Columbo 7-11 Collection",
        "24/7: Comedy Central Roasts Collection",
        "24/7: Cooking On High Collection",
        "24/7: Corn Wars Collection",
        "24/7: Cow and Chicken Collection",
        "24/7: Danny Phantom Collection",
        "24/7: Despicable Me Collection",
        "24/7: Destination Fear Collection",
        "24/7: Diary Of A Wimpy Kid Collection",
        "24/7: Disney Shorts Collection",
        "24/7: Disney's Christmas Fireplace Collection",
        "24/7: DMX - Woodstock 99",
        "24/7: Donald Duck Collection",
        "24/7: Dora the Explorer Collection",
        "24/7: Ducktales( 80's) Collection",
        "24/7: Eight is Enough Collection",
        "24/7: Elvis Presley Collection",
        "24/7: Everybody Loves Raymond Collection",
        "24/7: Felix The Cat Collection",
        "24/7: Fire Masters Collection",
        "24/7: Flipper Collection",
        "24/7: Fraggle Rock Collection",
        "24/7: Friday Collection",
        "24/7: Frozen Collection",
        "24/7: Futurama Collection",
        "24/7: Ghost Adventures Collection",
        "24/7: Gilligan's Island Collection",
        "24/7: Godfather of Harlem Collection",
        "24/7: Goofy Movies Collection",
        "24/7: Gravity Falls Collection",
        "24/7: Green Acres Collection",
        "24/7: Gremlins Collection",
        "24/7: Gumby Collection",
        "24/7: Gummi Bears Collections",
        "24/7: Hangin' with Mr. Cooper Collection",
        "24/7: Harley Quinn Collection",
        "24/7: Harry Potter Collection",
        "24/7: Haunting In the Heartland Collection",
        "24/7: He-Man and the Masters of the Universe Collection",
        "24/7: I Dream of Jeannie Collection",
        "24/7: I Love Lucy Collection S01-S02",
        "24/7: I Love Lucy S03-S04 Collection",
        "24/7: I Love Lucy S05-S07 Collection",
        "24/7: Indiana Jones Collection",
        "24/7: Ip Man Collection",
        "24/7: Iron Maiden Hammersmith 1982",
        "24/7: Jake and the Neverland Pirates Collection",
        "24/7: Janet Jackson Up Close!",
        "24/7: Jaws Collection",
        "24/7: Johnny Bravo Collection",
        "24/7: Johnny Test Collection",
        "24/7: Jordan vs Lebron - The Best GOAT Comparison",
        "24/7: KIDZ BOP",
        "24/7: Kill Bill Collection",
        "24/7: Kobe Bryant - The Black Mamba",
        "24/7: Krazy Kat Collection",
        "24/7: Larry Bird's 50 Greatest Moments Collection",
        "24/7: Law and Order Criminal Intent Collection",
        "24/7: LEGO City Mini Movies Collection",
        "24/7: Lego Masters US Collection",
        "24/7: Lego Ninjago Collection",
        "24/7: Little Bear Collection",
        "24/7: Look Who's Talking Collection",
        "24/7: Looney Tunes Collection",
        "24/7: Marlon Collection",
        "24/7: Mater's Tall Tales Collection",
        "24/7: Mega Man Collection",
        "24/7: Michael Jackson - Dangrous Tour",
    ],
    [
        "24/7 : AUF WIEDERSEHEN, PET",
        "24/7 : VEVO CHRISTMAS MUSIC",
        "24/7 : VEVO CHRISTMAS HIP HOP",
        "24/7 : VEVO CHRISTMAS BEST HITS",
        "24/7 : THE CHRISTMAS CAROLER CHALLENGE",
        "24/7 : SUGAR RUSH CHRISTMAS",
        "24/7 : NAILED IT! HOLIDAY",
        "24/7 : HOLIDAY YULE LOG",
        "24/7 : HOLIDAY WARS",
        "24/7 : HOLIDAY BAKING CHAMPIONSHIP",
        "24/7 : GREAT CHRISTMAS LIGHT FIGHT",
        "24/7 : CHRISTMAS MOVIES",
        "24/7 : CHRISTMAS COOKIE CHALLENGE",
        "24/7 : CHRISTMAS CARTOONS",
        "24/7 : YELLOWSTONE",
        "24/7 : X-MEN CARTOONS",
        "24/7 : X-MEN",
        "24/7 : WRESTLEMANIA",
        "24/7 : WKRP",
        "24/7 : WILL FERRELL MOVIES",
        "24/7 : WILL AND GRACE",
        "24/7 : WICKED TUNA",
        "24/7 : WHOSE LINE IS IT ANYWAY",
        "24/7 : WHO'S THE BOSS",
        "24/7 : WELCOME BACK KOTTER",
        "24/7 : WAR MOVIES",
        "24/7 : VAMPIRE MOVIES",
        "24/7 : UFC",
        "24/7 : TYLER PERRY MOVIES",
        "24/7 : TWO AND A HALF MEN",
        "24/7 : TWILIGHT",
        "24/7 : TRANSPORTER",
        "24/7 : TRANSFORMERS CARTOONS",
        "24/7 : TRANSFORMERS",
        "24/7 : TRAILER PARK BOYS",
        "24/7 : TOY STORY",
        "24/7 : TOSH.0",
        "24/7 : TOP GEAR",
        "24/7 : TOM AND JERRY",
        "24/7 : TMNT",
        "24/7 : TIMBER KINGS",
        "24/7 : THUNDERCATS",
        "24/7 : THRILLER MOVIES",
        "24/7 : THREE'S COMPANY",
        "24/7 : THOMAS AND FRIENDS",
        "24/7 : THE WONDER YEARS",
        "24/7 : THE WALTONS",
        "24/7 : THE WALKING DEAD",
        "24/7 : THE TWILIGHT ZONE",
        "24/7 : THE SOPRANOS",
        "24/7 : THE SMURFS",
        "24/7 : THE RANCH",
        "24/7 : THE PJS",
        "24/7 : THE PINK PANTHER",
        "24/7 : THE OFFICE",
        "24/7 : THE NANNY",
        "24/7 : THE MUNSTERS",
        "24/7 : THE MOD SQUAD",
        "24/7 : THE LOVE BOAT",
        "24/7 : THE LAYOVER",
        "24/7 : THE JEFFERSONS",
        "24/7 : THE HONEYMOONERS",
        "24/7 : THE HANGOVER",
        "24/7 : THE GOLDEN GIRLS",
        "24/7 : THE GOLDBERGS",
        "24/7 : THE GARFIELD SHOW",
        "24/7 : THE FLINTSTONES",
        "24/7 : THE FIRST 48HRS",
        "24/7 : THE DREW CAREY SHOW",
        "24/7 : THE COSBY SHOW",
        "24/7 : THE CONNERS",
        "24/7 : THE CAROL BURNETT SHOW",
        "24/7 : THE BOB NEWHART SHOW",
        "24/7 : THE BLUE PLANET",
        "24/7 : THE BIONIC WOMAN",
        "24/7 : THE BIG BANG THEORY",
        "24/7 : THE BEVERLY HILLBILLIES",
        "24/7 : THE BATMAN",
        "24/7 : THE AVENGERS",
        "24/7 : THE ANDY GRIFFITH SHOW",
        "24/7 : THE ALFRED HITCHCOCK HOUR",
        "24/7 : THE ADDAMS FAMILY",
        "24/7 : THE A TEAM",
        "24/7 : THAT GIRL",
        "24/7 : TEXAS CHAINSAW MOVIES",
        "24/7 : TEEN TITANS GO!",
        "24/7 : TAXI",
        "24/7 : TALES FROM THE CRYPT",
        "24/7 : SUPERHERO MOVIES",
        "24/7 : SUPER WINGS",
        "24/7 : STREET OUTLAWS",
        "24/7 : STORAGE HUNTERS",
        "24/7 : STEVEN SEAGAL",
        "24/7 : STAR WARS THE CLONE WARS",
        "24/7 : STAR WARS REBELS",
        "24/7 : STAR WARS",
        "24/7 : STAR TREK: VOYAGER",
        "24/7 : STAR TREK: DISCOVERY",
        "24/7 : STAR TREK: DEEP SPACE NINE",
        "24/7 : STAR TREK MOVIES",
        "24/7 : STAR TREK",
        "24/7 : SPORTS MOVIES",
        "24/7 : SPONGEBOB SQUAREPANTS",
        "24/7 : SPIN CITY",
        "24/7 : SPIDERMAN",
        "24/7 : SOUTH PARK",
        "24/7 : SOUTH BEACH TOWING",
        "24/7 : SIX MILLION DOLLAR MAN",
        "24/7 : SIMPSONS TREEHOUSE OF HORROR",
        "24/7 : SIMPSONS",
        "24/7 : SHERLOCK",
        "24/7 : SHE-RA: PRINCESS OF POWER",
        "24/7 : SHARK TANK",
        "24/7 : SEX AND THE CITY",
        "24/7 : SESAME STREET",
        "24/7 : SEINFELD",
        "24/7 : SCOOBY-DOO CLASSIC",
        "24/7 : SCOOBY-DOO",
        "24/7 : SCI-FI MOVIES",
        "24/7 : SCAM CITY",
        "24/7 : SAW",
        "24/7 : SAVED BY THE BELL",
        "24/7 : SANFORD AND SON",
        "24/7 : SABRINA THE TEENAGE WITCH",
        "24/7 : RUSH HOUR",
        "24/7 : RUGRATS",
        "24/7 : ROSEANNE",
        "24/7 : ROMANCE MOVIES",
        "24/7 : ROCKY",
        "24/7 : ROBOT CHICKEN",
        "24/7 : ROBIN WILLIAMS",
        "24/7 : ROB ZOMBIE MOVIES",
        "24/7 : RIDICULOUSNESS",
        "24/7 : RICK AND MORTY",
        "24/7 : RED GREEN SHOW",
        "24/7 : REBA",
        "24/7 : RAMBO",
        "24/7 : QUENTIN TARANTINO MOVIES",
        "24/7 : QUANTUM LEAP",
        "24/7 : PUPPY DOG PALS",
        "24/7 : PRISON BREAK",
        "24/7 : PREDATOR/ALIEN MOVIES",
        "24/7 : POWER RANGERS",
        "24/7 : POWER",
        "24/7 : POPEYE",
        "24/7 : POLICE ACADEMY",
        "24/7 : POKEMON",
        "24/7 : PLANET OF THE APES",
        "24/7 : PLANET EARTH",
        "24/7 : PJ MASKS",
        "24/7 : PIRATES OF THE CARIBBEAN",
        "24/7 : PINKY AND THE BRAIN",
        "24/7 : PETTICOAT JUNCTION",
        "24/7 : PERRY MASON",
        "24/7 : PERFECT STRANGERS",
        "24/7 : PEPPA PIG",
        "24/7 : PEAKY BLINDERS",
        "24/7 : PAWN STARS",
        "24/7 : PAW PATROL",
        "24/7 : PARTS UNKNOWN",
        "24/7 : PARKS AND RECREATION",
        "24/7 : OVERHAULING",
        "24/7 : OUTER LIMITS",
        "24/7 : ONLY FOOLS AND HORSES",
        "24/7 : NO RESERVATIONS",
        "24/7 : NIGHT COURT",
        "24/7 : NEWHART",
        "24/7 : NEW ADVENTURES OF OLD CHRISTINE",
        "24/7 : NAKED AND AFRAID",
        "24/7 : MYTHBUSTERS",
        "24/7 : MY NAME IS EARL",
        "24/7 : MY LITTLE PONY",
        "24/7 : MURDOCH MYSTERIES",
        "24/7 : MURDER SHE WROTE",
        "24/7 : MUPPET BABIES SHOW",
        "24/7 : MR BEAN ANIMATED",
        "24/7 : MR BEAN",
        "24/7 : MOST HAUNTED",
        "24/7 : MOST EXTREME ELIMINATION",
        "24/7 : MOST EXPENSIVEST",
        "24/7 : MORTAL KOMBAT",
        "24/7 : MORK & MINDY",
        "24/7 : MOM",
        "24/7 : MODERN FAMILY",
        "24/7 : MIKE & MOLLY",
        "24/7 : MICKEY MOUSE CLUBHOUSE",
        "24/7 : MIAMI VICE",
        "24/7 : MEN IN BLACK",
        "24/7 : MELROSE PLACE",
        "24/7 : MCMILLAN AND WIFE",
        "24/7 : MAYDAY",
        "24/7 : MATRIX",
        "24/7 : MASTERCHEF",
        "24/7 : MARY TYLER MOORE SHOW",
        "24/7 : MARVEL AVENGERS ASSEMBLE",
        "24/7 : MARTIN",
        "24/7 : MARRIED WITH CHILDREN",
        "24/7 : MAN WITH A PLAN",
        "24/7 : MAN VS FOOD",
        "24/7 : MAMA'S FAMILY",
        "24/7 : MAGNUM P.I.",
        "24/7 : MAD ABOUT YOU",
        "24/7 : M*A*S*H*",
        "24/7 : LOST IN SPACE",
        "24/7 : LORD OF THE RINGS",
        "24/7 : LOONEY TUNES",
        "24/7 : LIZARD LICK TOWING",
        "24/7 : LIVING SINGLE",
        "24/7 : LITTLE HOUSE ON THE PRAIRIE",
        "24/7 : LITTLE EINSTEINS",
        "24/7 : LETTERKENNY",
        "24/7 : LETHAL WEAPON",
        "24/7 : LEGO STAR WARS",
        "24/7 : LAW & ORDER",
        "24/7 : LAVERNE & SHIRLEY",
        "24/7 : LAST MAN STANDING",
        "24/7 : KUNG FU PANDA",
        "24/7 : KNIGHT RIDER",
        "24/7 : KITCHEN NIGHTMARES",
        "24/7 : KING OF THE HILL",
        "24/7 : KING OF QUEENS",
        "24/7 : KEEPING UP WITH THE KARDASHIANS",
        "24/7 : JUST FOR LAUGHS",
        "24/7 : JURASSIC PARK",
        "24/7 : JOHNNY TEST",
        "24/7 : JOHN WAYNE",
        "24/7 : JIM CARREY",
        "24/7 : JET LI MOVIES",
        "24/7 : JCVD MOVIES",
        "24/7 : JASON BOURNE",
        "24/7 : JAMES BOND",
        "24/7 : JACKIE CHAN MOVIES",
        "24/7 : INTERVENTION",
        "24/7 : INSIDIOUS MOVIES",
        "24/7 : INK MASTERS",
        "24/7 : INDIANA JONES",
        "24/7 : INCREDIBLE HULK",
        "24/7 : IN LIVING COLOR",
        "24/7 : IMPRACTICAL JOKERS",
        "24/7 : I LOVE LUCY",
        "24/7 : I DREAM OF JEANNIE",
        "24/7 : HOW IT’S MADE",
        "24/7 : HOW I MET YOUR MOTHER",
        "24/7 : HORROR MOVIES",
        "24/7 : HOME IMPROVEMENT",
        "24/7 : HOGAN'S HEROES",
        "24/7 : HOARDERS",
        "24/7 : HIP HOP SQUARES",
        "24/7 : HENRY DANGER",
        "24/7 : HELLS KITCHEN",
        "24/7 : HE-MAN MASTERS OF UNIVERSE",
        "24/7 : HARRY POTTER",
        "24/7 : HARDCORE PAWN",
        "24/7 : HAPPY ENDINGS",
        "24/7 : HAPPY DAYS",
        "24/7 : HALLOWEEN WARS",
        "24/7 : HALLOWEEN CAKE-OFF",
        "24/7 : HALLOWEEN BAKING CHAMPIONSHIP",
        "24/7 : GUNSMOKE",
        "24/7 : GROWING PAINS",
        "24/7 : GREASE",
        "24/7 : GOOSEBUMPS",
        "24/7 : GOOD TIMES",
        "24/7 : GOMER PYLE USMC",
        "24/7 : GOLD RUSH",
        "24/7 : GODFATHER OF HARLEM",
        "24/7 : GILMORE GIRLS",
        "24/7 : GILLIGAN'S ISLAND",
        "24/7 : GHOST ADVENTURES",
        "24/7 : GET SMART",
        "24/7 : GANGLAND UNDERCOVER",
        "24/7 : GANGLAND",
        "24/7 : GAME OF THRONES",
        "24/7 : FULL HOUSE",
        "24/7 : FRIENDS",
        "24/7 : FRIDAY THE 13TH",
        "24/7 : FRESH PRINCE OF BEL-AIR",
        "24/7 : FRASIER",
        "24/7 : FRAGGLE ROCK",
        "24/7 : FORGED IN FIRE",
        "24/7 : FORENSIC FILES",
        "24/7 : FIREPLACE",
        "24/7 : FIFTY SHADES",
        "24/7 : FEAR FACTOR",
        "24/7 : FAST AND FURIOUS",
        "24/7 : FANTASY ISLAND",
        "24/7 : FAMILY TIES",
        "24/7 : FAMILY MOVIES",
        "24/7 : FAMILY MATTERS",
        "24/7 : FAMILY GUY",
        "24/7 : F-TROOP",
        "24/7 : EXPEDITION UNKNOWN",
        "24/7 : EVERYBODY LOVES RAYMOND",
        "24/7 : EVERYBODY HATES CHRIS",
        "24/7 : ENTOURAGE",
        "24/7 : ELLEN'S GAME OF GAMES",
        "24/7 : ELENA OF AVALOR",
        "24/7 : EDDY MURPHY",
        "24/7 : DUKES OF HAZARD",
        "24/7 : DUCK TALES",
        "24/7 : DUCK DYNASTY",
        "24/7 : DRUG WARS",
        "24/7 : DREAMWORKS ANIMATION",
        "24/7 : DRAGON BALL Z",
        "24/7 : DORA THE EXPLORER",
        "24/7 : DOG THE BOUNTY HUNTER",
        "24/7 : DISNEY SONGS",
        "24/7 : DISNEY MOVIES",
        "24/7 : DINOSAURS",
        "24/7 : DINERS, DRIVE-INS AND DIVES",
        "24/7 : DIFF'RENT STROKES",
        "24/7 : DIE HARD",
        "24/7 : DICK VAN DYKE SHOW",
        "24/7 : DEGRASSI JUNIOR HIGH",
        "24/7 : DEADLIEST CATCH",
        "24/7 : DALLAS",
        "24/7 : DA ALI G SHOW",
        "24/7 : CURB YOUR ENTHUSIASM",
        "24/7 : CRIMINAL MINDS",
        "24/7 : CRIME MOVIES",
        "24/7 : COMEDY MOVIES",
        "24/7 : COLUMBO",
        "24/7 : COBRA KAI",
        "24/7 : CLINT EASTWOOD",
        "24/7 : CHIPS",
        "24/7 : CHILDREN OF THE CORN",
        "24/7 : CHICAGO PD",
        "24/7 : CHICAGO MED",
        "24/7 : CHICAGO FIRE",
        "24/7 : CHEERS",
        "24/7 : CHARMED",
        "24/7 : CHAPPELLE'S SHOW",
        "24/7 : CAR 54, WHERE ARE YOU!",
        "24/7 : CAPTAIN PLANET AND THE PLANETEERS",
        "24/7 : BURN NOTICE",
        "24/7 : BUFFY THE VAMPIRE SLAYER",
        "24/7 : BRUCE LEE MOVIES",
        "24/7 : BROOKLYN NINE-NINE",
        "24/7 : BREAKING BAD",
        "24/7 : BRADY BUNCH",
        "24/7 : BOY MEETS WORLD",
        "24/7 : BOXING MOVIES",
        "24/7 : BORDER SECURITY",
        "24/7 : BONANZA",
        "24/7 : BOBS BURGERS",
        "24/7 : BLUEY",
        "24/7 : BLUE'S CLUES & YOU",
        "24/7 : BLUE'S CLUES",
        "24/7 : BLUE BLOODS",
        "24/7 : BLAZE AND THE MONSTER MACHINES",
        "24/7 : BLADE MOVIES",
        "24/7 : BILLY THE EXTERMINATOR",
        "24/7 : BEWITCHED",
        "24/7 : BEN 10",
        "24/7 : BECKER",
        "24/7 : BEAVIS AND BUTTHEAD",
        "24/7 : BAYWATCH",
        "24/7 : BATMAN THE ANIMATED SERIES",
        "24/7 : BATMAN MOVIES",
        "24/7 : BATMAN BRAVE AND THE BOLD",
        "24/7 : BATMAN 1966",
        "24/7 : BARNEY MILLER",
        "24/7 : BAR RESCUE",
        "24/7 : BACK TO THE FUTURE",
        "24/7 : AVATAR",
        "24/7 : ARRESTED DEVELOPMENT",
        "24/7 : ARNOLD SCHWARZENEGGER",
        "24/7 : ARE YOU AFRAID OF THE DARK",
        "24/7 : ARCHER",
        "24/7 : ANCIENT ALIENS",
        "24/7 : AMERICAN PICKERS",
        "24/7 : AMERICAN DAD",
        "24/7 : AMERICAN CHOPPER",
        "24/7 : ALL IN THE FAMILY",
        "24/7 : ALICE",
        "24/7 : ALF",
        "24/7 : AL PACINO",
        "24/7 : ADVENTURE TIME",
        "24/7 : ADAM SANDLER",
        "24/7 : ACTION MOVIES",
        "24/7 : ACCORDING TO JIM",
        "24/7 : A NIGHTMARE ON ELM STREET",
        "24/7 : A HAUNTING",
        "24/7 : A DIFFERENT WORLD",
        "24/7 : 90210",
        "24/7 : 60 DAYS IN",
        "24/7 : 3RD ROCK FROM THE SUN",
        "24/7 : 24 HOURS IN POLICE CUSTODY",
        "24/7 : 21 JUMP STREET",
    ],
    [
        "HULU: (US) Universal Kids",
        "HULU: (US) Olympic Channel",
        "HULU: (US) A&E",
        "HULU: (US) SEC Network",
        "HULU: (US) SHOxBET Pacific",
        "HULU: (US) HBO Zone Pacific",
        "HULU: (US) HBO Signature Pacific",
        "HULU: (US) HBO Latino Pacific",
        "HULU: (US) More MAX Pacific",
        "HULU: (US) Showtime Extreme Pacific",
        "HULU: (US) HBO East",
        "HULU: (US) HBO Pacific",
        "HULU: (US) HBO Comedy East",
        "HULU: (US) HBO Comedy Pacific",
        "HULU: (US) HBO Family East",
        "HULU: (US) HBO Family Pacific",
        "HULU: (US) HBO Latino East",
        "HULU: (US) HBO Latino West",
        "HULU: (US) HBO Signature East",
        "HULU: (US) HBO Zone East",
        "HULU: (US) HBO2 East",
        "HULU: (US) HBO2 West",
        "HULU: (US) SHOWTIME East",
        "HULU: (US) SHOWTIME West",
        "HULU: (US) SHO 2 East",
        "HULU: (US) SHO 2 West",
        "HULU: (US) SHOWTIME Extreme East",
        "HULU: (US) SHOWTIME Family Zone East",
        "HULU: (US) SHOWTIME Family Zone Pacific",
        "HULU: (US) SHOWTIME Next East",
        "HULU: (US) SHOWTIME Next West",
        "HULU: (US) SHOWTIME Showcase East",
        "HULU: (US) SHOWTIME Showcase Pacific",
        "HULU: (US) SHOWTIME Women East",
        "HULU: (US) SHOWTIME Women Pacific",
        "HULU: (US) STARZ East",
        "HULU: (US) STARZ Pacific",
        "HULU: (US) STARZ Cinema East",
        "HULU: (US) STARZ Cinema Pacific",
        "HULU: (US) STARZ Comedy East",
        "HULU: (US) STARZ Comedy Pacific",
        "HULU: (US) STARZ Edge East",
        "HULU: (US) STARZ Edge Pacific",
        "HULU: (US) STARZ Encore East",
        "HULU: (US) STARZ Encore West",
        "HULU: (US) STARZ Encore Action East",
        "HULU: (US) STARZ Encore Action West",
        "HULU: (US) STARZ Encore Black East",
        "HULU: (US) STARZ Encore Black Pacific",
        "HULU: (US) STARZ Encore Classic East",
        "HULU: (US) STARZ Encore Classic West",
        "HULU: (US) STARZ Encore Espanol East",
        "HULU: (US) STARZ Encore Espanol Pacific",
        "HULU: (US) STARZ Encore Family East",
        "HULU: (US) STARZ Encore Family West",
        "HULU: (US) STARZ Encore Suspense East",
        "HULU: (US) STARZ Encore Suspense West",
        "HULU: (US) STARZ Encore Westerns East",
        "HULU: (US) STARZ Encore Westerns West",
        "HULU: (US) STARZ InBlack East",
        "HULU: (US) STARZ InBlack Pacific",
        "HULU: (US) STARZ Encore Kids East",
        "HULU: (US) STARZ Kids pacific",
        "HULU: (US) 5StarMax",
        "HULU: (US) ActionMax East",
        "HULU: (US) ActionMax West",
        "HULU: (US) CineMax East",
        "HULU: (US) MoreMax East",
        "HULU: (US) MovieMax",
        "HULU: (US) OuterMax",
        "HULU: (US) ThrillerMax East",
        "HULU: (US) BigTen",
        "HULU: (US) Vice",
        "HULU: (US) MSNBC",
        "HULU: (US) MTV",
        "HULU: (US) Paramount Network",
        "HULU: (US) TCM West",
        "HULU: (US) TCM East",
        "HULU: (US) TNT West",
        "HULU: (US) TVLand",
        "HULU: (US) Travel Channel",
        "HULU: (US) USA",
        "HULU: (US) Discovery",
        "HULU: (US) Investigation Discovery",
        "HULU: (US) FYI",
        "HULU: (US) FreeForm",
        "HULU: (US) Golf Channel",
        "HULU: (US) HGTV",
        "HULU: (US) HLN",
        "HULU: (US) LMN",
        "HULU: (US) LifeTime",
        "HULU: (US) NBCLX",
        "HULU: (US) Movie Plex Pacific",
        "HULU: (US) Nat Geo Wild",
        "HULU: (US) Nat Geo",
        "HULU: (US) ESPN U",
        "HULU: (US) ABC News Live",
        "HULU: (US) ABC Localish",
        "HULU: (US) AdultSwim West",
        "HULU: (US) AdultSwim East",
        "HULU: (US) Animal Planet",
        "HULU: (US) CBS Sports Network",
        "HULU: (US) CBS News",
        "HULU: (US) Cozi Tv",
        "HULU: (US) Dabl",
        "HULU: (US) Espn News",
        "HULU: (US) Fox Business",
        "HULU: (US) Fox News",
        "HULU: (US) FXM",
        "HULU: (US) Nasa",
        "HULU: (US) News Nation",
        "HULU: (US) Nickelodeon",
        "HULU: (US) QVC",
        "HULU: (US) SHOBET East",
        "HULU: (US) SmithSonian Channel",
        "HULU: (US) IndiePlex",
        "HULU: (US) RetroPlex East",
        "HULU: (US) TLC",
        "HULU: (US) VH1",
        "HULU: (US) Bravo",
        "HULU: (US) MAX Latino",
        "HULU: (US) Motortrend",
    ],
    [
        "SL (PUN): Desi Channel",
        "SL (PUN): Sanjha TV",
        "SL (PUN): Only Music",
        "SL (PUN): News Only",
        "SL (PUN): JUST IN",
        "SL (PUN): PTC Punjabi Gold",
        "SL (PUN): PTC Simran",
        "SL (PUN): PTC Dhol TV",
        "SL (PUN): PTC Music",
        "SL (PUN): Gurbaani TV",
        "SL (PUN): Jus Punjabi",
        "SL (PUN): PTC Punjabi",
        "SL (PUN): JUS Now",
        "SL (ENG): Noursat",
        "SL (ENG): GAC Living",
        "SL (HIN): Colors Cineplex",
        "SL (HIN): Total TV",
        "SL (HIN): JK Channel",
        "SL (HIN): Sadhna Prime News",
        "SL (HIN): Gulistan News",
        "SL (HIN): Soham",
        "SL (HIN): Arihant",
        "SL (HIN): InSync",
        "SL (HIN): Hindi Khabar",
        "SL (HIN): Ishwar",
        "SL (HIN): Sadhna Plus News",
        "SL (HIN): NDTV 24x7",
        "SL (HIN): Vaani TV",
        "SL (HIN): Shubh TV",
        "SL (HIN): Satsang TV",
        "SL (HIN): Sony Max 2",
        "SL (HIN): Sony Pal",
        "SL (HIN): Sony Yay",
        "SL (HIN): R.Bharat",
        "SL (HIN): Republic TV",
        "SL (HIN): Eros Now Slate",
        "SL (HIN): MX ONE 2",
        "SL (HIN): Sony KAL",
        "SL (PT): Premiere 1",
        "SL (PT): Premiere 2",
        "SL (PT): Premiere 3",
        "SL (PT): Premiere 4",
        "SL (PT): Premiere 5",
        "SL (PT): Premiere 6",
        "SL (PT): Premiere 7",
        "SL (PT): Premiere 8",
        "SL (PT): Record TV Americas",
        "SL (PT): Band Internacional",
        "SL (PT): Band News",
        "SL (PT): BabyTV",
        "SL (PT): Globoplay",
        "SL (URD): ARY Digital",
        "SL (URD): ARY News",
        "SL (URD): Express News",
        "SL (URD): GEO News",
        "SL (URD): GEO TV",
        "SL (URD): QTV",
        "SL (URD): ARY Zauq",
        "SL (URD): Dunya TV",
        "SL (URD): GEO Kahani",
        "SL (URD): TV One",
        "SL (URD): HUM TV",
        "SL (URD): HUM Sitaray",
        "SL (URD): HUM Masala",
        "SL (URD): PTV Global",
        "SL (URD): HUM News",
        "SL (PL): PolSat 1",
        "SL (PL): BabyTV Polish",
        "SL (PL): Disco Polo Music",
        "SL (PL): iTVN",
        "SL (PL): iTVN Extra",
        "SL (PL): TVN 24",
        "SL (PL): PolSat News",
        "SL (PL): 4fun",
        "SL (PL): 4fun Hits",
        "SL (PL): 4fun Fit and Dance",
        "SL (PL): TV Republika",
        "SL (PL): Adventure TV",
        "SL (PL): Lubelska TV",
        "SL (PL): Stars TV",
        "SL (PL): Red Carpet",
        "SL (PL): Twoja TV",
        "SL (PL): Channel 13",
        "SL (PL): Love TV",
        "SL (PL): Top Kids",
        "SL (PL): Nuta TV",
        "SL (PL): Power TV",
        "SL (PL): Sportowa TV",
        "SL (PL): TVP Info",
        "SL (PL): TVP Polonia",
        "SL (PL): TVP Wilno",
        "SL (ES): CGTN-Español",
        "SL (ES): Cinelatino",
        "SL (ES): Pasiones",
        "SL (ES): ESPN Deportes",
        "SL (ES): BabyTV Español",
        "SL (ES): Sony Cine",
        "SL (ES): Unimas KTFD",
        "SL (ES): Unimas KTFQ",
        "SL (ES): Unimas WUTF",
        "SL (ES): AZ Corazón",
        "SL (ES): Azteca",
        "SL (ES): beIN SPORTS en Español",
        "SL (ES): AZ Cinema",
        "SL (ES): AZ Clic",
        "SL (ES): NTN24",
        "SL (ES): TeleFórmula",
        "SL (ES): CubaMax",
        "SL (ES): Telemicro Internacional",
        "SL (ES): Antena 3 Internacional",
        "SL (ES): TV Española Internacional",
        "SL (ES): RCN Novelas",
        "SL (ES): Nuestra Tele",
        "SL (ES): A3Cine",
        "SL (ES): Ecuavisa Internacional",
        "SL (ES): TyC Sports",
        "SL (ES): Caracol TV Internacional",
        "SL (ES): TV Quisqueya",
        "SL (ES): El Financiero Bloomberg TV",
        "SL (ES): Vme Kids",
        "SL (ES): Estrella TV",
        "SL (ES): Video Rola",
        "SL (ES): Canal Sur",
        "SL (ES): Estudio 5",
        "SL (ES): Discovery en Español",
        "SL (ES): Discovery Familia",
        "SL (ES): Zona Futbol",
        "SL (ES): A3 Series",
        "SL (ES): Novelas de Oro",
        "SL (ES): Novelas en Familia",
        "SL (ES): Novelas de Romance",
        "SL (ES): Nuestra Vision",
        "SL (ES): Tarima TV",
        "SL (ENG): Dove Channel HD",
        "SL (ENG): Pac-12 Los Angeles",
        "SL (ENG): DUCK TV",
        "SL (ENG): NFL RedZone",
        "SL (ENG): BABY TV",
        "SL (ENG): Willow Cricket HD",
        "SL (ENG): Red Bull Tv",
        "SL (ENG): STARZ ENCORE BLACK",
        "SL (ENG): Eurochannel HD",
        "SL (ENG): WTXF (FOX)",
        "SL (ENG): National Geographic",
        "SL (ENG): KNSD (NBC)",
        "SL (ENG): Starz Encore Suspense",
        "SL (ENG): Investigation Discovery",
        "SL (ENG): FOX Sports 1",
        "SL (ENG): IFC",
        "SL (ENG): Disney Channel",
        "SL (ENG): Starz Cinema",
        "SL (ENG): Showtime Women",
        "SL (ENG): KTBC (FOX)",
        "SL (ENG): Pac-12 Oregon",
        "SL (ENG): Starz Kids & Family",
        "SL (ENG): Starz Edge",
        "SL (ENG): EPIX Drive-In",
        "SL (ENG): WTVT (FOX)",
        "SL (ENG): Lifetime HD",
        "SL (ENG): KRIV (FOX)",
        "SL (ENG): BET",
        "SL (ENG): WJBK (FOX)",
        "SL (ENG): Fuse",
        "SL (ENG): WRC (NBC)",
        "SL (ENG): Starz",
        "SL (ENG): BabyTV",
        "SL (ENG): truTV",
        "SL (ENG): FOX Sports 2",
        "SL (ENG): Starz Encore Family",
        "SL (ENG): Starz Encore Classic",
        "SL (ENG): TNT",
        "SL (ENG): CNN",
        "SL (ENG): WVIT (NBC)",
        "SL (ENG): NickToons",
        "SL (ENG): WCAU (NBC)",
        "SL (ENG): WNBC (NBC)",
        "SL (ENG): ESPN2",
        "SL (ENG): Travel Channel",
        "SL (ENG): Revolt TV",
        "SL (ENG): FX",
        "SL (ENG): Showtime 2",
        "SL (ENG): Longhorn Network",
        "SL (ENG): USA",
        "SL (ENG): Comet",
        "SL (ENG): A&E HD",
        "SL (ENG): SYFY",
        "SL (ENG): NDTV 24x7",
        "SL (ENG): Starz Encore Español",
        "SL (ENG): Science",
        "SL (ENG): SEC Network",
        "SL (ENG): FXX",
        "SL (ENG): Euronews",
        "SL (ENG): EPIX",
        "SL (ENG): VICE",
        "SL (ENG): MTV",
        "SL (ENG): World Fishing Network",
        "SL (ENG): CGTN",
        "SL (ENG): Miami Heat",
        "SL (ENG): BBC America",
        "SL (ENG): WTVJ (NBC)",
        "SL (ENG): Hallmark Channel",
        "SL (ENG): Olympic Channel",
        "SL (ENG): The Cowboy Channel",
        "SL (ENG): ESPNEWS",
        "SL (ENG): WMAQ (NBC)",
        "SL (ENG): Oxygen",
        "SL (ENG): WOGX (FOX)",
        "SL (ENG): TeenNick",
        "SL (ENG): KNTV (NBC)",
        "SL (ENG): American Heroes",
        "SL (ENG): CMT",
        "SL (ENG): Grit",
        "SL (ENG): truTV",
        "SL (ENG): TBS",
        "SL (ENG): Discovery Channel",
        "SL (ENG): Starz Encore Westerns",
        "SL (ENG): WTTG (FOX)",
        "SL (ENG): WFLD (FOX)",
        "SL (ENG): Comedy Central",
        "SL (ENG): Hallmark Movies & Mysteries",
        "SL (ENG): Showtime Family Zone",
        "SL (ENG): WAGA (FOX)",
        "SL (ENG): Showtime",
        "SL (ENG): Destination America",
        "SL (ENG): EPIX Hits",
        "SL (ENG): NFL Network",
        "SL (ENG): Starz West",
        "SL (ENG): Starz Encore Action",
        "SL (ENG): AMC",
        "SL (ENG): HDNet Movies",
        "SL (ENG): Zoom",
        "SL (ENG): Hallmark Drama",
        "SL (ENG): Outdoor Channel",
        "SL (ENG): Nat Geo Wild",
        "SL (ENG): EPIX 2",
        "SL (ENG): Starz Encore West",
        "SL (ENG): Pac-12 Arizona",
        "SL (ENG): Sportsman Channel",
        "SL (ENG): HDNet Movies",
        "SL (ENG): Sundance",
        "SL (ENG): Showtime Showcase",
        "SL (ENG): Showtime Next",
        "SL (ENG): Disney Junior",
        "SL (ENG): DIY Network",
        "SL (ENG): History",
        "SL (ENG): MSNBC",
        "SL (ENG): ESPNU",
        "SL (ENG): Stadium",
        "SL (ENG): CNBC",
        "SL (ENG): HLN",
        "SL (ENG): KTVU (FOX)",
        "SL (ENG): Bloomberg Television",
        "SL (ENG): MTV2",
        "SL (ENG): MotorTrend",
        "SL (ENG): Pac-12 Washington",
        "SL (ENG): ESPN",
        "SL (ENG): Willow Extra HD",
        "SL (ENG): KTTV (FOX)",
        "SL (ENG): KNBC (NBC)",
        "SL (ENG): KXAS (NBC)",
        "SL (ENG): Cheddar News",
        "SL (ENG): Lifetime Movie Network",
        "SL (ENG): Starz Comedy",
        "SL (ENG): IFC",
        "SL (ENG): Pac-12 Mountain",
        "SL (ENG): MoviePlex",
        "SL (ENG): Nick Jr.",
        "SL (ENG): NHL Network",
        "SL (ENG): Pac-12 Bay Area",
        "SL (ENG): WOFL (FOX)",
        "SL (ENG): Freeform",
        "SL (ENG): AXS TV",
        "SL (ENG): DOGTV",
        "SL (ENG): Boomerang",
        "SL (ENG): Paramount Network",
        "SL (ENG): beIN SPORTS",
        "SL (ENG): Showtime Extreme",
        "SL (ENG): CNN",
        "SL (ENG): E!",
        "SL (ENG): Cartoon Network & Adult Swim",
        "SL (ENG): Food Network",
        "SL (ENG): Starz Encore",
        "SL (ENG): Disney XD",
        "SL (ENG): HGTV",
        "SL (ENG): KDFW (FOX)",
        "SL (ENG): Destination America",
        "SL (ENG): FYI",
        "SL (ENG): REELZ",
        "SL (ENG): TV Land",
        "SL (ENG): Cooking Channel",
        "SL (ENG): KMSP (FOX)",
        "SL (ENG): TCM",
        "SL (ENG): Showtime West",
        "SL (ENG): GSN",
        "SL (ENG): RT America",
        "SL (ENG): WE tv",
        "SL (ENG): Aapka Colors",
        "SL (ENG): Law & Crime",
        "SL (ENG): Bravo",
        "SL (ENG): NBC GOLF CHANNEL HD",
        "SL (ENG): TENNIS CHANNEL HD",
        "SL (ENG): A&E",
        "SL (ENG): ACC NETWORK",
        "SL (ENG): LAFF",
        "SL (ES): TUDN Extra 1 HD",
        "SL (ES): TUDN Extra 2 HD",
        "SL (ES): TUDN Extra 3 HD",
        "SL (ES): TUDN Extra 4 HD",
        "SL (ES): TUDN Extra 5 HD",
        "SL (ES): TUDN Extra 6 HD",
        "SL (ES): TUDN Extra 7 HD",
        "SL (ES): TUDN Extra 8 HD",
        "SL (ES): TUDN Extra 9 HD",
        "SL (ES): TUDN Extra 10 HD",
        "SL (ES): TUDN Extra 11 HD",
        "SL (ENG): PPV1",
        "SL (ENG): PPV2",
        "SL (ENG): PPV3",
        "SL (ENG): PPV4",
        "SL (ENG): PPV5",
        "SL (ES): PPV1",
        "SL (ES): PPV2",
        "SL (ES): PPV3",
        "SL (ES): PPV4",
        "SL (ES): PPV5",
    ],
    [
        "US: A&E",
        "US: ABC NY",
        "US: Adult Swim",
        "US: AMC",
        "US: AMERICAN HEROES CHANNEL",
        "US: ANIMAL PLANET",
        "US: AWE",
        "US: AXS TV",
        "US: BET",
        "US: BOUNCE TV",
        "US: BRAVO",
        "US: BUZZR",
        "US: CBN",
        "US: CBS East",
        "US: CMT",
        "US: Comedy Central",
        "US: COMET",
        "US: Cooking Channel",
        "US: COURT TV",
        "US: COZI",
        "US: CW 57",
        "US: Dabl",
        "US: Discovery",
        "US: Discovery Family",
        "US: DIY (MAGNOLIA NETWORK)",
        "US: Dog TV",
        "US: E! Entertainment",
        "US: FOOD NETWORK",
        "US: FOX 29",
        "US: FREEFORM",
        "US: FUSE",
        "US: FUSION",
        "US: FX",
        "US: FXX",
        "US: FYI Network",
        "US: Game Show Network",
        "US: GRIT",
        "US: Hallmark Channel",
        "US: Hallmark Drama",
        "US: Hallmark Movies & Mysteries",
        "US: HGTV",
        "US: History Channel",
        "US: IFC",
        "US: Investigation Discovery",
        "US: ION TV",
        "US: KION 5",
        "US: LAFF TV",
        "US: Logo",
        "US: ME TV (West)",
        "US: MTV",
        "US: MTV 2",
        "US: NASA TV",
        "US: National Geographic",
        "US: National Geographic Wild",
        "US: Outdoor TV",
        "US: OWN",
        "US: Oxygen",
        "US: Oxygen (West)",
        "US: Paramount Network",
        "US: PBS",
        "US: PIXL",
        "US: Pursuit Channel",
        "US: QVC",
        "US: REELZ",
        "US: REVOLT",
        "US: SMITHSONIAN CHANNEL",
        "US: SYFY",
        "US: TASTEMADE",
        "US: TBS",
        "US: TLC",
        "US: TNT",
        "US: TNT West",
        "US: TRAVEL CHANNEL",
        "US: TruTV",
        "US: TV LAND",
        "US: TV ONE",
        "US: USA Network",
        "US: USA Network (West)",
        "US: VH1",
        "US: Viceland",
        "US: WE TV",
        "US: WGN 9 Chicago",
        "US: WGN AMERICA",
        "US: Antenna TV",
        "US: BBC America",
        "US: BET HER",
        "US: BET Jams",
        "US: CLEO TV",
        "US: COWBOY CHANNEL",
        "US: DESTINATION AMERICA",
        "US: Discovery ID",
        "US: Discovery Life",
        "US: DISCOVERY SCIENCE",
        "US: EWTN Africa",
        "US: Get TV",
        "US: INFO WARS",
        "US: INSP TV",
        "US: Jewelry TV",
        "US: KCAL 9",
        "US: LAW & CRIME",
        "US: LIfetime Network",
        "US: Lifetime Real Women",
        "US: MTV Classic",
        "US: MTV Live",
        "US: Outside TV",
        "US: Ovation",
        "US: PEOPLE TV",
        "US: Red Bull TV",
        "US: RIDE TV",
        "US: START TV",
        "US: TBN TV (Trinity Broadcasting Network)",
        "US: The Film Detective",
        "US: TV LAND (West)",
    ],
    [
        "US: CINEMAX",
        "US: CINEMAX 5STARMAX",
        "US: CINEMAX ACTIONMAX",
        "US: CINEMAX MOREMAX",
        "US: CINEMAX MOVIEMAX",
        "US: CINEMAX OUTERMAX",
        "US: CINEMAX THRILLERMAX",
        "US: EPIX",
        "US: EPIX 2",
        "US: EPIX Drive-In",
        "US: EPIX Hits",
        "US: FXM",
        "US: HBO",
        "US: HBO 2",
        "US: HBO Comedy",
        "US: HBO Family",
        "US: HBO Signature",
        "US: HBO Zone",
        "US: HDnet Movies",
        "US: Showtime",
        "US: Showtime 2",
        "US: Showtime Extreme",
        "US: Showtime Family Zone",
        "US: Showtime Next",
        "US: Showtime Showcase",
        "US: Showtime Women",
        "US: SHOxBET",
        "US: Starz",
        "US: Starz Cinema",
        "US: Starz Comedy",
        "US: Starz Edge",
        "US: Starz Encore",
        "US: Starz Encore Action",
        "US: Starz Encore Black",
        "US: Starz Encore Classic",
        "US: Starz Encore Family",
        "US: Starz Encore Suspense",
        "US: Starz Encore Westerns",
        "US: Starz In Black",
        "US: Starz Kids & Family",
        "US: Starz West",
        "US: Sundance TV",
        "US: Turner Classic Movies",
        "USA: Lifetime Movie Network",
    ],
    [
        "USA: Nat Geo HD",
        "USA: Nat Geo West HD",
        "USA: Nat Geo Mundo HD",
        "USA: Nat Geo Wild HD",
        "USA: Animal Planet HD",
        "USA: Animal Planet West",
        "USA: Discovery Channel HD",
        "USA: Investigation Discovery HD",
        "USA: Crime & Investigation HD",
        "USA: Discovery Life HD",
        "USA: Discovery Family HD",
        "USA: Discovery Familia",
        "USA: Discovery Science HD",
        "USA: Discovery Velocity",
        "USA: History HD",
        "USA: Science HD",
        "USA: Travel Channel HD",
    ],
    [
        "US: Baby TV",
        "US: Cartoon Network",
        "US: Disney Channel",
        "US: Disney Junior",
        "US: Disney XD",
        "US: Nick Jr",
        "US: Nickelodeon",
        "US: Nicktoons",
        "US: Teen Nick",
        "US: Universal Kids",
        "US: Baby First",
        "US: Boomerang",
        "US: PBS Kids",
    ],
    [
        "US: ABC News Live",
        "US: Al Jazeera",
        "US: BBC World News",
        "US: CHEDDAR NEWS",
        "US: CHEDDAR NEWS",
        "US: CNBC",
        "US: CNBC World",
        "US: CNN",
        "US: FOX BUSINESS",
        "US: FOX NEWS",
        "US: HLN",
        "US: MSNBC",
        "US: NEWSMAX",
        "US: RFD-TV",
        "US: RT News",
        "US: The Weather Channel",
        "US: WEATHER NATION",
        "US: Bloomberg TV",
        "US: C-SPAN",
        "US: C-SPAN 2",
        "US: C-SPAN 3",
        "US: CNBC WORLD",
        "US: ONE AMERICA NEWS",
    ],
    [
        "US: NCAA ABC",
        "US: NCAA ACC Network",
        "US: NCAA Big Ten",
        "US: NCAA CBS",
        "US: NCAA CBS Sports",
        "US: NCAA ESPN",
        "US: NCAA ESPN 2",
        "US: NCAA ESPN News",
        "US: NCAA ESPN U",
        "US: NCAA FOX Sports",
        "US: NCAA Fox Sports 1",
        "US: NCAA Longhorn Network",
        "US: NCAA NBC",
        "US: NCAA Pac 12 Bay Area",
        "US: NCAA Pac 12 Los Angeles",
        "US: NCAA Pac 12 Mountain",
        "US: NCAA Pac 12 Network",
        "US: NCAA Pac 12 Oregon",
        "US: NCAA Pac 12 Washington",
        "US: NCAA Pac-12 Arizona",
        "US: NCAA SEC Network",
        "US: NCAA Stadium",
        "US: NCAA Yes Network",
        "-- USA SPORTS --",
        "US: ACC Network",
        "US: Altitude Sports",
        "US: AT&T Sportsnet Pittsburgh",
        "US: AT&T Sportsnet Rocky Mountain",
        "US: AT&T Sportsnet Southwest",
        "US: Bally Sports Arizona",
        "US: Bally Sports Carolinas",
        "US: Bally Sports Cincinnati",
        "US: Bally Sports Deportes",
        "US: Bally Sports Detroit",
        "US: Bally Sports Florida",
        "US: Bally Sports Midwest",
        "US: Bally Sports New Orleans",
        "US: Bally Sports North",
        "US: Bally Sports Ohio",
        "US: Bally Sports Ohio Plus",
        "US: Bally Sports Oklahoma",
        "US: Bally Sports San Diego",
        "US: Bally Sports SoCal",
        "US: Bally Sports Soccer Plus",
        "US: Bally Sports South",
        "US: Bally Sports Southeast Carolinas",
        "US: Bally Sports Southeast Georgia",
        "US: Bally Sports Southeast Tennessee",
        "US: Bally Sports Southwest Plus",
        "US: Bally Sports Sun",
        "US: Bally Sports West",
        "US: Bally Sports Wisconsin",
        "US: Bein Sports",
        "US: Big Ten Network",
        "US: CBS Sports Network",
        "US: Eleven Sports",
        "US: ESPN",
        "US: ESPN 2",
        "US: ESPN 3",
        "US: ESPN NEWS",
        "US: ESPN U",
        "US: Fox College Sports Pacific",
        "US: Fox Sports 1",
        "US: Fox Sports 2",
        "US: GINX eSPORTS",
        "US: Golf Channel",
        "US: Longhorn Network",
        "US: Marquee Sports Network",
        "US: MASN",
        "US: MASN 2",
        "US: MAVTV",
        "US: MLB NETWORK",
        "US: MOTOR TREND",
        "US: MSG",
        "US: MSG+",
        "US: NBA TV",
        "US: NBC Miami",
        "US: NBC NY",
        "US: NBC Sports Bay Area",
        "US: NBC Sports Boston",
        "US: NBC Sports California",
        "US: NBC Sports Chicago",
        "US: NBC Sports Philly",
        "US: NBC Sports Washington",
        "US: NESN",
        "US: NESN PLUS",
        "US: NFL Network",
        "US: NHL Network",
        "US: Olympic Channel",
        "US: PAC 12 Arizona",
        "US: PAC 12 Bay Area",
        "US: PAC 12 Los Angeles",
        "US: PAC 12 Mountain",
        "US: PAC 12 Network",
        "US: PAC 12 Oregon",
        "US: SEC NETWORK",
        "US: SNY (Sportsnet NY)",
        "US: SPECTRUM SPORTSNET (DODGERS)",
        "US: SPECTRUM SPORTSNET (LAKERS)",
        "US: Sportsman Channel",
        "US: STADIUM",
        "US: Tennis Channel",
        "US: TVG",
        "US: TVG2",
        "US: UFC Fight Pass 24/7",
        "US: Willow Cricket",
        "US: World Fishing Network",
        "US: WWE NETWORK",
        "US: Yes Network",
        "US: Bally Sports Indiana",
        "US: Bally Sports Kanas City",
        "US: Bally Sports Prime Ticket",
        "US: Billiard TV",
        "US: NFL Redzone",
    ],
    [
        "WI | MILWAUKEE | My 24",
        "VA | NORFOLK | MyTVZ",
        "TX | HOUSTON | My20",
        "TX | FORT WORTH | My27",
        "TN | NASHVILLE | My30",
        "SC | GREENVILLE | My 40",
        "NY | NEW YORK CITY | My 9",
        "NV | LAS VEGAS | My LVTV",
        "NC | RALEIGH | MyRDCTV",
        "MD | BALTIMORE | MyTV Baltimore",
        "MA | BOSTON | myTV 38",
        "IL | CHICAGO | My50 Chicago",
        "FL | WEST PALM BEACH | My 15",
        "FL | MIAMI | My TV 33",
        "DC | WASHINGTON | My20",
        "CA | LOS ANGELES | My13",
        "AZ | PHOENIX | My45",
        "AL | BIRMINGHAM | My68",
        "UH | SALT LAKE CITY | Univision KUTH",
        "TX | SAN ANTONIO | Univision KWEX",
        "TX | HOUSTON | Univision KXLN",
        "TX | FORT WORTH | Univision KUVN",
        "TX | AUSTIN | Univision KAKW",
        "PA | PHILADELPHIA | Univision WUVP",
        "NY | NEW YORK CITY | Univision WXTV",
        "NC | RALEIGH | Univision WUVC",
        "IL | CHICAGO | Univision WGBO",
        "GA | ATLANTA | Univision WUVG",
        "FL | MIAMI | Univision WLTV",
        "CA | SAN FRANCISCO | Univision KDTV",
        "CA | SACRAMENTO | Univision KUVS",
        "CA | LOS ANGELES | Univision KMEX",
        "CA | FRESNO | Univision KFTV",
        "UH | SALT LAKE CITY | Unimas KUTH",
        "UH | SALT LAKE CITY | My Utah TV",
        "TX | SAN ANTONIO | Unimas KNIC",
        "TX | HOUSTON | Unimas KFTH",
        "TX | FORT WORTH | unimas KSTR",
        "TX | AUSTIN | Unimas KTFO",
        "PA | PHILADELPHIA | Unimas WFPA",
        "NY | NEW YORK CITY | Unimas WFUT",
        "NC | RALEIGH | Unimas WTNC",
        "IL | CHICAGO | Unimas WXFT",
        "GA | ATLANTA | Unimas WUVG",
        "FL | MIAMI | Unimas WAMI",
        "CA | SAN FRANCISCO | Unimas KFSF",
        "CA | SACRAMENTO | Unimas KTFK",
        "CA | LOS ANGELES | Unimas KFTR",
        "CA | FRESNO | Unimas KTFF",
        "AZ | PHOENIX | Unimas KFPH",
        "IA | DAVENPORT | UniMas",
        "IA | DAVENPORT | Univision",
        "IA | DAVENPORT | Telemundo Chicago",
        "IA | DAVENPORT | Iowa PBS",
        "IA | DAVENPORT | Iowa PBS",
        "IA | DAVENPORT | WQAD",
        "IA | DAVENPORT | Local 4",
        "IA | DAVENPORT | KWQC-TV6",
        "IA | DAVENPORT | FOX 18",
        "SF CBSNEWS",
        "PIT CBSNEWS",
        "PHI CBSNEWS",
        "NY CBSNEWS",
        "MINN CBSNEWS",
        "LA CBSNEWS",
        "DEN CBSNEWS",
        "DAL CBSNEWS",
        "CHI CBSNEWS",
        "BOS CBSNEWS",
        "US: Yuma - KSWT",
        "US: Yakima - KAPP",
        "US: Wilmington - WECT - WSFX",
        "US: Wichita Falls - KAUZ",
        "US: Wichita - KWCH",
        "US: Wichita - KSCW",
        "US: West Palm Beach - WFLX",
        "US: Wausau - WZAW",
        "US: Wausau - WSAW",
        "US: Washington - Politics Uncut",
        "US: Waco - KWTX",
        "US: Victoria - KAVU VITX",
        "US: Tyler - KLTV",
        "US: Twin Falls - KMVT",
        "US: Tucson - KOLD",
        "US: Traverse City - WWTV - 9&10 News Plus",
        "US: Traverse City - WWTV - 9&10 News",
        "US: Traverse City - Northern Michigan From Above",
        "US: Topeka - WIBW",
        "US: Toledo - WTVG",
        "US: Tallahassee - WCTV",
        "US: St. Louis - KMOV",
        "US: Springfield - WSHM/WGGB",
        "US: Springfield - KYTV",
        "US: Springfield - KSPR",
        "US: Spokane - KXLY",
        "US: Spokane - KAYU",
        "US: Spokane - Air4Adventures",
        "US: South Bend - WNDU",
        "US: Sioux Falls - KSFY",
        "US: Shreveport - KSLA",
        "US: Sherman - KXII",
        "US: Scottsbluff - KNEP",
        "US: Savannah - WTOC",
        "US: San Francisco - KTSF",
        "US: SAGINAW - WNEM",
        "US: Rockford - WIFR",
        "US: Roanoke - WDBJ",
        "US: Richmond - WWBT",
        "US: Reno - KOLO",
        "US: Rapid City - KOTA",
        "US: Rapid City - KEVN",
        "US: Presque Isle - WAGM",
        "US: Port of Spain - OCTV One Caribbean Television",
        "US: Pocatello - KPVI",
        "US: Pittsburg - KOAM",
        "US: Pittsburg - KFJX",
        "US: PHOENIX - KTVK",
        "US: Phoenix - KPHO",
        "US: Pendleton - KFFX",
        "US: Parkersburg - WTAP",
        "US: Parkersburg - WOVA",
        "US: Panama City - WJHG",
        "US: Panama City - WECP",
        "US: Omaha - WOWT",
        "US: Odessa - KOSA",
        "US: North Platte - KNOP",
        "US: Newport - WXIX",
        "US: New York - VUit-LIVE",
        "US: New York - VUit ESports",
        "US: New York - James Altucher Show",
        "US: New York - Food Curated",
        "US: New Orleans - WVUE",
        "US: Nashville - WSMV",
        "US: Myrtle Beach - WMBF",
        "US: Montgomery - WSFA",
        "US: Monroe - KNOE",
        "US: Mobile - WALA",
        "US: Minot - KMOT",
        "US: Meridian - WTOK",
        "US: Memphis - WMC",
        "US: Medford - KMVU",
        "US: MARQUETTE - WLUC",
        "US: Marion - VUit Sports",
        "US: Marion - Prospect Meadows Sports Complex",
        "US: Madison - WMTV",
        "US: Madison - WISC Channel3000+",
        "US: Madison - WISC",
        "US: Madison - TVW WISC",
        "US: Lufkin - KTRE",
        "US: Lubbock - KCBD",
        "US: Louisville - WAVE",
        "US: Lincoln - KOLN",
        "US: Lexington - WKYT2",
        "US: Lexington - WKYT",
        "US: Lawton - KSWO",
        "US: Laredo - KGNS",
        "US: Lansing - WILX",
        "US: Lake Charles - KPLC",
        "US: La Crosse - WKBT",
        "US: Knoxville - WVLT",
        "US: Kennewick - KVEW",
        "US: Kansas City - KCTV",
        "US: Jonesboro - KAIT",
        "US: Jackson - WLBT",
        "US: Ithaca - WENY",
        "US: Ithaca - NY Local Ithaca",
        "US: Huntsville - WAFF",
        "US: Huntington - WSAZ",
        "US: Huntington - WQCW",
        "US: Honolulu - KITV ABC",
        "US: Honolulu - KGMB KHNL",
        "US: Honolulu - Hawaii TV KITV",
        "US: Henderson - KVVU",
        "US: Hazard - WYMT",
        "US: Hattiesburg - WDAM",
        "US: Hastings - KSNB",
        "US: Hartford - WFSB",
        "US: Harrisonburg - WHSV",
        "US: Greenville - WXVT",
        "US: Greenville - WNBD",
        "US: Greenville - WITN",
        "US: Greenville - WHNS",
        "US: Greenville - WABG",
        "US: Green Bay - WBAY",
        "US: Grand Junction - KKCO",
        "US: Grand Junction - KJCT",
        "US: Gainesville - WCJB",
        "US: Flint - WJRT",
        "US: Fargo - KVLY-NBC",
        "US: Fargo - KVLY-CBS",
        "US: Fairbanks - KXDF",
        "US: Fairbanks - KTVF",
        "US: Evansville - WFIE",
        "US: Eureka - KVIQ",
        "US: EUREKA - KIEM",
        "US: Erie - Erie News Now Plus ENN+",
        "US: Eau Claire - WEAU",
        "US: Dothan - WTVY",
        "US: Dothan - WRGX",
        "US: Davenport - KWQC",
        "US: Columbus - WTVM",
        "US: Columbia - WIS",
        "US: Colorado Springs - KKTV",
        "US: Cleveland - WOIO",
        "US: Cheyenne - KGWN",
        "US: Charlottesville - WVIR News 29",
        "US: Charlottesville - WCAV",
        "US: Charlotte - WBTV",
        "US: Charleston - WCSC",
        "US: Cedar Rapids - KCRG",
        "US: Casper - KCWY",
        "US: Cape Girardeau - KFVS",
        "US: Burlington - WCAX",
        "US: Bryan - KBTX",
        "US: Bridgeport - WDTV",
        "US: Bowling Green - WBKO",
        "US: Bismarck - KFYR",
        "US: Birmingham - WBRC",
        "US: Binghamton - WICZ",
        "US: Biloxi - WLOX",
        "US: Beaverton - KPTV",
        "US: Baton Rouge - WAFB",
        "US: Bangor - WABI",
        "US: Augusta - WRDW",
        "US: Augusta - WAGT",
        "US: Atlanta - WGCL",
        "US: Atlanta - Investigate TV",
        "US: Anchorage - KTUU",
        "US: Amarillo - KFDA",
        "US: Allentown - WFMZ Weather",
        "US: Allentown - WFMZ Edicion en Espanol",
        "US: Allentown - WFMZ",
        "US: Alexandria - KALB-CBS",
        "US: Alexandria - KALB",
        "US: Albany - WALB",
        "US: ABC 25 (Jacksonville) | HD",
        "WV | CHEYENNE | WyomingPBS",
        "WV | CHEYENNE | WyomingPBS",
        "WV | CHEYENNE | Telemundo Denver",
        "WV | CHEYENNE | News Channel 5",
        "WV | CHEYENNE | Neb Public Media",
        "WV | CHEYENNE | KKTQ",
        "WV | CHEYENNE | KCWY 13",
        "WV | CHEYENNE | Fox 27",
        "WV | CHARLESTON | WVPB",
        "WV | CHARLESTON | WVPB",
        "WV | CHARLESTON | WSAZ",
        "WV | CHARLESTON | WOWK 13",
        "WV | CHARLESTON | WCHS",
        "WV | CHARLESTON | FOX 11",
        "WV | CASPER | WyomingPBS",
        "WV | CASPER | WyomingPBS",
        "WV | CASPER | Telemundo Denver",
        "WV | CASPER | KFNB",
        "WV | CASPER | KCWY 13",
        "WV | CASPER | K2TV",
        "WV | CASPER | CBS 14",
        "WV | BLUEFIELD | WVVA",
        "WV | BLUEFIELD | WVPB",
        "WV | BLUEFIELD | WVPB",
        "WV | BLUEFIELD | WOAY",
        "WV | BLUEFIELD | Univision",
        "WV | BLUEFIELD | UniMas",
        "WV | BLUEFIELD | Telemundo",
        "WV | BLUEFIELD | FOX 59",
        "WV | BLUEFIELD | CW",
        "WV | BLUEFIELD | CBS 59",
        "WV | BLUEFIELD | BlueRidge",
        "WI | WAUSAU | WZAW",
        "WI | WAUSAU | WAOW 9",
        "WI | WAUSAU | UniMas",
        "WI | WAUSAU | Telemundo Chicago",
        "WI | WAUSAU | PBS Wisconsin",
        "WI | WAUSAU | NewsChannel 7",
        "WI | WAUSAU | NBC 12",
        "WI | WAUSAU | CW",
        "WI | MILWAUKEE | WISN 12",
        "WI | MILWAUKEE | TMJ4",
        "WI | MILWAUKEE | Super 18",
        "WI | MILWAUKEE | MilwPBS",
        "WI | MILWAUKEE | MilwPBS",
        "WI | MILWAUKEE | FOX 6",
        "WI | MILWAUKEE | CBS 58",
        "WI | MADISON | WKOW 27",
        "WI | MADISON | WISC TV3",
        "WI | MADISON | Telemundo Chicago",
        "WI | MADISON | PBS Wisconsin",
        "WI | MADISON | PBS Wisconsin",
        "WI | MADISON | NBC 15",
        "WI | MADISON | Fox 47",
        "WI | LA CROSSE | WXOW 19",
        "WI | LA CROSSE | WEAU 13",
        "WI | LA CROSSE | Univision",
        "WI | LA CROSSE | UniMas",
        "WI | LA CROSSE | Telemundo Chicago",
        "WI | LA CROSSE | PBS Wisconsin",
        "WI | LA CROSSE | News 8",
        "WI | LA CROSSE | Iowa PBS",
        "WI | LA CROSSE | FOX 25/48",
        "WI | LA CROSSE | CW",
        "WI | GREENBAY | WBAY-TV 2",
        "WI | GREENBAY | Telemundo",
        "WI | GREENBAY | PBS Wisconsin",
        "WI | GREENBAY | PBS Wisconsin",
        "WI | GREENBAY | NBC 26",
        "WI | GREENBAY | Local 5",
        "WI | GREENBAY | Fox 11",
        "WI | GREENBAY | CW14",
        "WI | GREENBAY | Comet TV",
        "WA | YAKIMA | Telemundo",
        "WA | YAKIMA | KVEW",
        "WA | YAKIMA | KNDO",
        "WA | YAKIMA | KIMA",
        "WA | YAKIMA | KCTS 9",
        "WA | YAKIMA | KCTS 9",
        "WA | YAKIMA | FOX 41",
        "WA | YAKIMA | CW 9 Yakima/Tri-Cities",
        "WA | SPOKANE | Telemundo 48",
        "WA | SPOKANE | KXLY 4",
        "WA | SPOKANE | KSPS",
        "WA | SPOKANE | KSPS",
        "WA | SPOKANE | KREM 2",
        "WA | SPOKANE | KHQ",
        "WA | SPOKANE | IDAHOPTV",
        "WA | SPOKANE | Fox 28",
        "WA | SPOKANE | Comet TV",
        "WA | SEATTLE | Telemundo 48",
        "WA | SEATTLE | Q13",
        "WA | SEATTLE | KOMO 4",
        "WA | SEATTLE | KIRO 7",
        "WA | SEATTLE | KING 5",
        "WA | SEATTLE | KCTS 9",
        "WA | SEATTLE | KCTS 9",
        "WA | SEATTLE | CW 11",
        "VA | ST PETERSBURG | WTVR-TV",
        "VA | ST PETERSBURG | WRLH",
        "VA | ST PETERSBURG | WHRO",
        "VA | ST PETERSBURG | VPM",
        "VA | ST PETERSBURG | VPM",
        "VA | ST PETERSBURG | PBS NC",
        "VA | ST PETERSBURG | NBC 12",
        "VA | ST PETERSBURG | ABC 8",
        "VA | ROANOKE | WSLS 10",
        "VA | ROANOKE | WFXR",
        "VA | ROANOKE | WDBJ",
        "VA | ROANOKE | BlueRidge",
        "VA | ROANOKE | BlueRidge",
        "VA | ROANOKE | ABC 13",
        "VA | NORFOLK | WHRO",
        "VA | NORFOLK | WHRO",
        "VA | NORFOLK | WGNT 27",
        "VA | NORFOLK | WAVY 10",
        "VA | NORFOLK | PBS NC",
        "VA | NORFOLK | News 3",
        "VA | NORFOLK | FOX 43",
        "VA | NORFOLK | ABC 13",
        "UT | SALT LAKE CITY | KUTV 2",
        "UT | SALT LAKE CITY | KUED",
        "UT | SALT LAKE CITY | KUED",
        "UT | SALT LAKE CITY | KSL 5",
        "UT | SALT LAKE CITY | FOX 13",
        "UT | SALT LAKE CITY | ABC 4",
        "TX | WACO | Telemundo Houston",
        "TX | WACO | News 3",
        "TX | WACO | KWTX",
        "TX | WACO | KCEN",
        "TX | WACO | KAMU",
        "TX | WACO | KAMU",
        "TX | WACO | Fox 44",
        "TX | WACO | 25 ABC",
        "TX | VICTORIA | Univision",
        "TX | VICTORIA | UniMas",
        "TX | VICTORIA | TV 8",
        "TX | VICTORIA | Telemundo 60 San Antonio",
        "TX | VICTORIA | News 4",
        "TX | VICTORIA | KLRN",
        "TX | VICTORIA | KLRN",
        "TX | VICTORIA | KEDT",
        "TX | VICTORIA | Fox 19",
        "TX | VICTORIA | CBS 41",
        "TX | VICTORIA | ABC 25",
        "TX | TYLER | Telemundo 39",
        "TX | TYLER | LPB",
        "TX | TYLER | LPB",
        "TX | TYLER | KLTV 7",
        "TX | TYLER | KETK NBC",
        "TX | TYLER | FOX 51",
        "TX | TYLER | CBS 19",
        "TX | SAN ANTONIO | WOAI",
        "TX | SAN ANTONIO | Telemundo 60 San Antonio",
        "TX | SAN ANTONIO | KSAT",
        "TX | SAN ANTONIO | KLRN",
        "TX | SAN ANTONIO | KLRN",
        "TX | SAN ANTONIO | KENS",
        "TX | SAN ANTONIO | KABB",
        "TX | SAN ANTONIO | CW 35",
        "TX | ODESSA | Telemundo 20",
        "TX | ODESSA | NewsWest 9",
        "TX | ODESSA | FOX 24",
        "TX | ODESSA | CBS 7",
        "TX | ODESSA | Big 2",
        "TX | ODESSA | BasinPBS",
        "TX | LUBBOCK | Telemundo Lubbock",
        "TX | LUBBOCK | KTTZ-TV",
        "TX | LUBBOCK | KTTZ-TV",
        "TX | LUBBOCK | KLBK",
        "TX | LUBBOCK | KCBD 11",
        "TX | LUBBOCK | KAMC",
        "TX | LUBBOCK | FOX 34",
        "TX | LAREDO | Telemundo Laredo",
        "TX | LAREDO | NGNS",
        "TX | LAREDO | KLRN",
        "TX | LAREDO | KLRN",
        "TX | LAREDO | KGNS 8",
        "TX | LAREDO | Fox Laredo",
        "TX | LAREDO | CBS 13",
        "TX | HOUSTON | TV 8",
        "TX | HOUSTON | Telemundo Houston",
        "TX | HOUSTON | KUHT TV 8",
        "TX | HOUSTON | KPRC 2",
        "TX | HOUSTON | KHOU 11",
        "TX | HOUSTON | FOX 26",
        "TX | HARLINGEN | KVEO",
        "TX | HARLINGEN | KRGV",
        "TX | HARLINGEN | KFXV-TV",
        "TX | HARLINGEN | KEDT",
        "TX | HARLINGEN | CBS 4",
        "TX | EL PASO | PBS El Paso",
        "TX | EL PASO | NewsChannel 9",
        "TX | EL PASO | KTDO",
        "TX | EL PASO | KFOX 14",
        "TX | EL PASO | El Paso-Las Cruces CW",
        "TX | EL PASO | Comet TV",
        "TX | EL PASO | CBS 4",
        "TX | EL PASO | ABC 7",
        "TX | DALLAS | WFAA 8",
        "TX | DALLAS | TXA 21",
        "TX | DALLAS | Telemundo 39",
        "TX | DALLAS | NBC 5",
        "TX | DALLAS | KERA",
        "TX | DALLAS | FOX 4",
        "TX | DALLAS | CBS 11",
        "TX | DALLAS | WFAA 8",
        "TX | CORPUS CHRISTI | KRIS 6 News",
        "TX | CORPUS CHRISTI | KIII-TV 3",
        "TX | CORPUS CHRISTI | KEDT",
        "TX | CORPUS CHRISTI | KAJA",
        "TX | CORPUS CHRISTI | FOX 38",
        "TX | CORPUS CHRISTI | CW South Texas",
        "TX | CORPUS CHRISTI | 10 News",
        "TX | BEAUMONT | Your NBC",
        "TX | BEAUMONT | TV 8",
        "TX | BEAUMONT | Telemundo Houston",
        "TX | BEAUMONT | Southeast Texas CW",
        "TX | BEAUMONT | KUHT TV 8",
        "TX | BEAUMONT | KFDM 6",
        "TX | BEAUMONT | KBMT",
        "TX | BEAUMONT | FOX 4",
        "TX | AUSTIN | Telemundo Austin",
        "TX | AUSTIN | KXAN",
        "TX | AUSTIN | KVUE",
        "TX | AUSTIN | KEYE",
        "TX | AUSTIN | FOX 7",
        "TX | AUSTIN | Austin PBS",
        "TX | AUSTIN | Austin PBS",
        "TX | AMARILLO | Telemundo 39",
        "TX | AMARILLO | PPBS",
        "TX | AMARILLO | PPBS",
        "TX | AMARILLO | Local 4",
        "TX | AMARILLO | KFDA",
        "TX | AMARILLO | FOX 14",
        "TX | AMARILLO | CW 11",
        "TX | AMARILLO | ABC 7",
        "TX | ABILENE | Telemundo 39",
        "TX | ABILENE | KTXS",
        "TX | ABILENE | KTAB",
        "TX | ABILENE | KRBC",
        "TX | ABILENE | KERA",
        "TX | ABILENE | KERA",
        "TX | ABILENE | FOX 15",
        "TN | TRI CITIES | WEMT",
        "TN | TRI CITIES | WCYB-TV",
        "TN | TRI CITIES | WCYB",
        "TN | TRI CITIES | PBS NC",
        "TN | TRI CITIES | etpbs",
        "TN | TRI CITIES | etpbs",
        "TN | TRI CITIES | CBS",
        "TN | TRI CITIES | ABC",
        "TN | NASHVILLE | WZTV",
        "TN | NASHVILLE | WTVF",
        "TN | NASHVILLE | WSMV 4",
        "TN | NASHVILLE | WKRN",
        "TN | NASHVILLE | Telemundo",
        "TN | NASHVILLE | NPT",
        "TN | NASHVILLE | NPT",
        "TN | MEMPHIS | WREG",
        "TN | MEMPHIS | WMC NBC 4",
        "TN | MEMPHIS | WKNO",
        "TN | MEMPHIS | WKNO",
        "TN | MEMPHIS | WHBQ",
        "TN | MEMPHIS | WATN",
        "TN | MEMPHIS | Telemundo Chicago",
        "TN | MEMPHIS | MPB",
        "TN | KNOXVILLE | WBIR",
        "TN | KNOXVILLE | WATE 6",
        "TN | KNOXVILLE | Local 8",
        "TN | KNOXVILLE | Fox 43",
        "TN | KNOXVILLE | etpbs",
        "TN | KNOXVILLE | etpbs",
        "TN | CHATTANOOGA | WTVC 9",
        "TN | CHATTANOOGA | WTVC 9",
        "TN | CHATTANOOGA | WTCI",
        "TN | CHATTANOOGA | WTCI",
        "TN | CHATTANOOGA | WFLI-TV",
        "TN | CHATTANOOGA | News 12",
        "TN | CHATTANOOGA | GPB",
        "TN | CHATTANOOGA | Comet TV",
        "TN | CHATTANOOGA | Channel 3",
        "SD | SIOUX FALLS | TelemundO",
        "SD | SIOUX FALLS | SDPB",
        "SD | SIOUX FALLS | SDPB",
        "SD | SIOUX FALLS | Pioneer",
        "SD | SIOUX FALLS | KSFY",
        "SD | SIOUX FALLS | KELO",
        "SD | SIOUX FALLS | KDLT",
        "SD | SIOUX FALLS | Iowa PBS",
        "SD | SIOUX FALLS | Fox 7",
        "SD | SIOUX CITY | Telemundo Nebraska",
        "SD | SIOUX CITY | Siouxland CW",
        "SD | SIOUX CITY | SDPB",
        "SD | SIOUX CITY | Neb Public Media",
        "SD | SIOUX CITY | KTIV 4",
        "SD | SIOUX CITY | KCAU 9",
        "SD | SIOUX CITY | Iowa PBS",
        "SD | SIOUX CITY | Iowa PBS",
        "SD | SIOUX CITY | FOX 44",
        "SD | SIOUX CITY | CBS 14",
        "SD | RAPID CITY | Telemundo",
        "SD | RAPID CITY | SDPB",
        "SD | RAPID CITY | SDPB",
        "SD | RAPID CITY | KOTA",
        "SD | RAPID CITY | KNBN",
        "SD | RAPID CITY | KEVN",
        "SD | RAPID CITY | KELO",
        "SC | GREENVILLE | WYFF 4",
        "SC | GREENVILLE | SCETV",
        "SC | GREENVILLE | SCETV",
        "SC | GREENVILLE | PBS NC",
        "SC | GREENVILLE | FOX",
        "SC | GREENVILLE | ABC 13",
        "SC | GREENVILLE | 7 News",
        "SC | FLORENCE | WMBF",
        "SC | FLORENCE | WBTW",
        "SC | FLORENCE | SCETV",
        "SC | FLORENCE | SCETV",
        "SC | FLORENCE | PBS NC",
        "SC | FLORENCE | FXB",
        "SC | FLORENCE | CW 15",
        "SC | FLORENCE | ABC 15",
        "SC | COLUMBIA | WOLO-TV",
        "SC | COLUMBIA | WLTX",
        "SC | COLUMBIA | WIS",
        "SC | COLUMBIA | WACH",
        "SC | COLUMBIA | SCETV",
        "SC | COLUMBIA | SCETV",
        "SC | CHARLESTON | WCBD",
        "SC | CHARLESTON | SCETV",
        "SC | CHARLESTON | SCETV",
        "SC | CHARLESTON | Live 5",
        "SC | CHARLESTON | Fox 24",
        "SC | CHARLESTON | ABC 4",
        "SC | AUGUSTA | WAGT",
        "SC | AUGUSTA | SCETV",
        "SC | AUGUSTA | SCETV",
        "SC | AUGUSTA | News 12",
        "SC | AUGUSTA | GPB",
        "SC | AUGUSTA | FOX 54",
        "SC | AUGUSTA | ABC 6",
        "RI | PROVIDENCE | WPRI 12",
        "RI | PROVIDENCE | WNAC",
        "RI | PROVIDENCE | Telemundo Boston",
        "RI | PROVIDENCE | New England Cable News",
        "RI | PROVIDENCE | NBC 10",
        "RI | PROVIDENCE | GBH",
        "RI | PROVIDENCE | CPTV",
        "RI | PROVIDENCE | ABC 6",
        "PA | WILKES BARRE | WYOU",
        "PA | WILKES BARRE | WVIA",
        "PA | WILKES BARRE | WVIA",
        "PA | WILKES BARRE | WNEP 16",
        "PA | WILKES BARRE | WBRE-TV",
        "PA | WILKES BARRE | Telemundo 62",
        "PA | WILKES BARRE | Fox 56",
        "PA | PITTSBURGH | WTAE 4",
        "PA | PITTSBURGH | WQED",
        "PA | PITTSBURGH | WQED",
        "PA | PITTSBURGH | Telemundo 62",
        "PA | PITTSBURGH | NBC 11",
        "PA | PITTSBURGH | KDKA-TV",
        "PA | PITTSBURGH | FOX 53",
        "PA | PITTSBURGH | CW Pittsburgh",
        "PA | PHILADELPHIA | WHYY",
        "PA | PHILADELPHIA | WHYY",
        "PA | PHILADELPHIA | The CW Philly 57",
        "PA | PHILADELPHIA | Telemundo 62",
        "PA | PHILADELPHIA | PBS39",
        "PA | PHILADELPHIA | NJ PBS",
        "PA | PHILADELPHIA | NBC 10",
        "PA | PHILADELPHIA | FOX 29",
        "PA | PHILADELPHIA | CBS 3",
        "PA | PHILADELPHIA | 6 ABC",
        "PA | JOHNSTOWN | WTAJ",
        "PA | JOHNSTOWN | WPSU",
        "PA | JOHNSTOWN | WPSU",
        "PA | JOHNSTOWN | WJAC-TV 6",
        "PA | JOHNSTOWN | Telemundo 62",
        "PA | JOHNSTOWN | FOX 8",
        "PA | JOHNSTOWN | ABC 23",
        "PA | ERIE | WSEE",
        "PA | ERIE | WQLN",
        "PA | ERIE | WQLN",
        "PA | ERIE | WICU 12",
        "PA | ERIE | The CW Erie",
        "PA | ERIE | Telemundo",
        "PA | ERIE | JET 24",
        "PA | ERIE | FOX 66",
        "OR | PORTLAND | Telemundo 48",
        "OR | PORTLAND | OPB",
        "OR | PORTLAND | OPB",
        "OR | PORTLAND | KOIN 6",
        "OR | PORTLAND | KGW 8",
        "OR | PORTLAND | KATU",
        "OR | PORTLAND | FOX 12",
        "OR | MEDFORD | Southern Oregon CW 11",
        "OR | MEDFORD | SO PBS",
        "OR | MEDFORD | SO PBS",
        "OR | MEDFORD | NewsWatch 12",
        "OR | MEDFORD | News 10",
        "OR | MEDFORD | NBC 5",
        "OR | MEDFORD | My 48",
        "OR | MEDFORD | FOX 26",
        "OR | EUGENE | Telemundo 48",
        "OR | EUGENE | Oregon's FOX",
        "OR | EUGENE | OPB",
        "OR | EUGENE | OPB",
        "OR | EUGENE | NBC 16",
        "OR | EUGENE | KEZI 9",
        "OR | EUGENE | CW Eugene",
        "OR | EUGENE | CBS 13",
        "OK | TULSA | OETA",
        "OK | TULSA | OETA",
        "OK | TULSA | KUTUCD2",
        "OK | TULSA | KTUL",
        "OK | TULSA | KQCW",
        "OK | TULSA | KOTV 6",
        "OK | TULSA | KJRH 2",
        "OK | TULSA | Fox 23",
        "OK | SHERMAN | Telemundo 39",
        "OK | SHERMAN | OETA",
        "OK | SHERMAN | OETA",
        "OK | SHERMAN | KTEN",
        "OK | SHERMAN | FOX 12",
        "OK | SHERMAN | CBS 12",
        "OK | SHERMAN | ABC 10",
        "OK | OKLAHOMA CITY | OETA",
        "OK | OKLAHOMA CITY | News 9",
        "OK | OKLAHOMA CITY | KOCO 5",
        "OK | OKLAHOMA CITY | KFOR",
        "OK | OKLAHOMA CITY | FOX 25",
        "OK | OKLAHOMA CITY | CW 34",
        "OH | ZANESVILLE | WOUB",
        "OH | ZANESVILLE | WOUB",
        "OH | ZANESVILLE | WOSU",
        "OH | ZANESVILLE | WHIZ-TV",
        "OH | ZANESVILLE | Telemundo NY",
        "OH | ZANESVILLE | FOX 28",
        "OH | ZANESVILLE | ABC",
        "OH | ZANESVILLE | 10TV",
        "OH | YOUNGSTOWN | WYFX",
        "OH | YOUNGSTOWN | WNEO",
        "OH | YOUNGSTOWN | WKBN 27",
        "OH | YOUNGSTOWN | WBCB, The Valley's CW",
        "OH | YOUNGSTOWN | Telemundo NY",
        "OH | YOUNGSTOWN | Ideastream",
        "OH | YOUNGSTOWN | 33 WYTV",
        "OH | YOUNGSTOWN | 21 WFMJ",
        "OH | WHEELING | WVPB",
        "OH | WHEELING | WVPB",
        "OH | WHEELING | WTOV 9",
        "OH | WHEELING | WQED",
        "OH | WHEELING | WOUB",
        "OH | WHEELING | Telemundo",
        "OH | WHEELING | FOX 9",
        "OH | WHEELING | ABC Ohio Valley",
        "OH | WHEELING | 7 News",
        "OH | TOLEDO | WTOL 11",
        "OH | TOLEDO | WGTE",
        "OH | TOLEDO | WGTE",
        "OH | TOLEDO | WBGU",
        "OH | TOLEDO | Telemundo NY",
        "OH | TOLEDO | NBC 24",
        "OH | TOLEDO | Fox 36",
        "OH | TOLEDO | DPTV",
        "OH | TOLEDO | 13 ABC",
        "OH | LIMA | WBGU",
        "OH | LIMA | ThinkTV-16",
        "OH | LIMA | Telemundo NY",
        "OH | LIMA | NBC Lima",
        "OH | LIMA | Fox Lima",
        "OH | LIMA | CBS Lima",
        "OH | LIMA | ABC Lima",
        "OH | DAYTON | WHIO",
        "OH | DAYTON | WDTN",
        "OH | DAYTON | ThinkTV-16",
        "OH | DAYTON | ThinkTV-16",
        "OH | DAYTON | Telemundo NY",
        "OH | DAYTON | Fox 45",
        "OH | DAYTON | CET",
        "OH | DAYTON | ABC 22",
        "OH | COLUMBUS | WOSU",
        "OH | COLUMBUS | WOSU",
        "OH | COLUMBUS | The CW Columbus",
        "OH | COLUMBUS | Telemundo Chicago",
        "OH | COLUMBUS | NBC 4",
        "OH | COLUMBUS | FOX 28",
        "OH | COLUMBUS | ABC 6",
        "OH | COLUMBUS | 10TV",
        "OH | CLEVELAND | WNEO",
        "OH | CLEVELAND | WKYC 3",
        "OH | CLEVELAND | Telemundo NY",
        "OH | CLEVELAND | News 5",
        "OH | CLEVELAND | Ideastream",
        "OH | CLEVELAND | Ideastream",
        "OH | CLEVELAND | FOX 8",
        "OH | CLEVELAND | Cleveland 19",
        "OH | CINCINATI | WLWT",
        "OH | CINCINATI | WKRC",
        "OH | CINCINATI | WCPO",
        "OH | CINCINATI | ThinkTV-14",
        "OH | CINCINATI | ThinkTV-14",
        "OH | CINCINATI | The CW Cincinnati",
        "OH | CINCINATI | Telemundo NY",
        "OH | CINCINATI | KET",
        "OH | CINCINATI | Fox 19",
        "OH | CINCINATI | CET",
        "NY | WATERTOWN | WWNY",
        "NY | WATERTOWN | WVNC",
        "NY | WATERTOWN | WPBS",
        "NY | WATERTOWN | WPBS",
        "NY | WATERTOWN | Telemundo",
        "NY | WATERTOWN | Fox 28",
        "NY | WATERTOWN | ABC 50",
        "NY | UTICA | WUTR",
        "NY | UTICA | WFXV 33",
        "NY | UTICA | WCNY",
        "NY | UTICA | WCNY",
        "NY | UTICA | Telemundo",
        "NY | UTICA | NewsChannel 2",
        "NY | UTICA | CBS Utica",
        "NY | SYRACUSE | WCNY",
        "NY | SYRACUSE | Telemundo NY",
        "NY | SYRACUSE | NBC 3",
        "NY | SYRACUSE | Fox 68",
        "NY | SYRACUSE | CBS 5",
        "NY | SYRACUSE | ABC 9",
        "NY | ROCHESTER | WXXI",
        "NY | ROCHESTER | WUHF",
        "NY | ROCHESTER | Telemundo NY",
        "NY | ROCHESTER | News 8",
        "NY | ROCHESTER | NBC 10",
        "NY | ROCHESTER | CW Rochester",
        "NY | ROCHESTER | Comet TV",
        "NY | ROCHESTER | ABC 13",
        "NY | NEW YORK CITY | WLNY TV",
        "NY | NEW YORK CITY | WLIW",
        "NY | NEW YORK CITY | THIRTEEN",
        "NY | NEW YORK CITY | Telemundo NY",
        "NY | NEW YORK CITY | PIX 11",
        "NY | NEW YORK CITY | NBC 4",
        "NY | NEW YORK CITY | FOX 5",
        "NY | NEW YORK CITY | CPTV",
        "NY | NEW YORK CITY | CBS 2",
        "NY | NEW YORK CITY | ABC 7",
        "NY | ELMIRA | WVIA",
        "NY | ELMIRA | WETM 18",
        "NY | ELMIRA | WENY",
        "NY | ELMIRA | WENY",
        "NY | ELMIRA | Telemundo NY",
        "NY | ELMIRA | Big Fox",
        "NY | BUFFALO | WNED",
        "NY | BUFFALO | WNED",
        "NY | BUFFALO | WIVB 4",
        "NY | BUFFALO | WGRZ",
        "NY | BUFFALO | Telemundo NY",
        "NY | BUFFALO | Fox 29",
        "NY | BUFFALO | 7 ABC",
        "NY | BINGHAMTON | WVIA",
        "NY | BINGHAMTON | WCNY",
        "NY | BINGHAMTON | WBNG 12",
        "NY | BINGHAMTON | Telemundo NY",
        "NY | BINGHAMTON | NBC 5",
        "NY | BINGHAMTON | FOX 40",
        "NY | BINGHAMTON | Channel 34",
        "NY | BINGHAMTON | Binghamton CW 11",
        "NY | ALBANY | WNYT 13",
        "NY | ALBANY | WMHT",
        "NY | ALBANY | WMHT",
        "NY | ALBANY | Telemundo NY",
        "NY | ALBANY | Fox 23",
        "NY | ALBANY | CW 15",
        "NY | ALBANY | Comet TV",
        "NY | ALBANY | CBS 6",
        "NY | ALBANY | 10 ABC",
        "NV | RENO | Telemundo Las Vegas",
        "NV | RENO | PBS Reno",
        "NV | RENO | PBS Reno",
        "NV | RENO | NBC 4",
        "NV | RENO | KOLO 8",
        "NV | RENO | FOX 11",
        "NV | RENO | Channel 2",
        "NV | LAS VEGAS | Vegas PBS",
        "NV | LAS VEGAS | Vegas PBS",
        "NV | LAS VEGAS | The CW Las Vegas",
        "NV | LAS VEGAS | Telemundo Las Vegas",
        "NV | LAS VEGAS | KVVU",
        "NV | LAS VEGAS | KTNV",
        "NV | LAS VEGAS | KSNV",
        "NV | LAS VEGAS | KLAS",
        "NM | ALBUQUERQUE | Telemundo 2 KASA",
        "NM | ALBUQUERQUE | NM PBS",
        "NM | ALBUQUERQUE | NM PBS",
        "NM | ALBUQUERQUE | KRQE",
        "NM | ALBUQUERQUE | KOB 4",
        "NM | ALBUQUERQUE | KOAT 7",
        "NM | ALBUQUERQUE | FOX NM",
        "NE | OMAHA | WOWT",
        "NE | OMAHA | Neb Public Media",
        "NE | OMAHA | KMTV 3",
        "NE | OMAHA | KETV 7",
        "NE | OMAHA | Iowa PBS",
        "NE | OMAHA | Fox 42",
        "NE | LINCOLN | Univision",
        "NE | LINCOLN | UniMas",
        "NE | LINCOLN | Telemundo Nebraska",
        "NE | LINCOLN | NTV",
        "NE | LINCOLN | Neb Public Media",
        "NE | LINCOLN | Neb Public Media",
        "NE | LINCOLN | Local 4",
        "NE | LINCOLN | KLKN",
        "NE | LINCOLN | Iowa PBS",
        "NE | LINCOLN | FOX Nebraska",
        "NE | LINCOLN | 10/11",
        "NE | HASTINGS | Telemundo Nebraska",
        "NE | HASTINGS | NTV",
        "NE | HASTINGS | Neb Public Media",
        "NE | HASTINGS | Neb Public Media",
        "NE | HASTINGS | Local 4",
        "NE | HASTINGS | KLKN",
        "NE | HASTINGS | FOX Nebraska",
        "NE | HASTINGS | 10/11",
        "ND | MINOT | Telemundo",
        "ND | MINOT | Prairie Public",
        "ND | MINOT | Prairie Public",
        "ND | MINOT | KMOT-TV",
        "ND | MINOT | KFYR-TV",
        "ND | MINOT | KBMY",
        "ND | MINOT | CBS 13",
        "ND | FARGO | WDAY 6",
        "ND | FARGO | Telemundo",
        "ND | FARGO | Prairie Public",
        "ND | FARGO | Prairie Public",
        "ND | FARGO | KX4",
        "ND | FARGO | KVRR",
        "NC | WILMINGTON | WWAY 3",
        "NC | WILMINGTON | WWAY",
        "NC | WILMINGTON | WECT",
        "NC | WILMINGTON | PBS NC",
        "NC | WILMINGTON | PBS NC",
        "NC | WILMINGTON | FOX Wilmington",
        "NC | WILMINGTON | Cape Fear CW",
        "NC | RALEIGH | WRAL",
        "NC | RALEIGH | PBS NC",
        "NC | RALEIGH | PBS NC",
        "NC | RALEIGH | Fox 50",
        "NC | RALEIGH | CW 22",
        "NC | RALEIGH | CBS NC",
        "NC | RALEIGH | ABC 11",
        "NC | GREENVILLE | WYDO",
        "NC | GREENVILLE | WNCT 9",
        "NC | GREENVILLE | WITN",
        "NC | GREENVILLE | PBS NC",
        "NC | GREENVILLE | PBS NC",
        "NC | GREENVILLE | ABC 12",
        "NC | GHREENSBORO | WXII 12",
        "NC | GHREENSBORO | WFMY 2",
        "NC | GHREENSBORO | PBS NC",
        "NC | GHREENSBORO | PBS NC",
        "NC | GHREENSBORO | FOX 8",
        "NC | GHREENSBORO | CW 20",
        "NC | GHREENSBORO | ABC 45",
        "NC | CHARLOTTE | WSOC 9",
        "NC | CHARLOTTE | WCNC NBC",
        "NC | CHARLOTTE | WBTV",
        "NC | CHARLOTTE | SCETV",
        "NC | CHARLOTTE | PBS NC",
        "NC | CHARLOTTE | PBS NC",
        "NC | CHARLOTTE | PBS CLT",
        "NC | CHARLOTTE | FOX 46",
        "MT | MISSOULA | Telemundo Denver",
        "MT | MISSOULA | NBC Montana",
        "MT | MISSOULA | MontanaPBS",
        "MT | MISSOULA | MontanaPBS",
        "MT | MISSOULA | KSPS",
        "MT | MISSOULA | KPAX 8",
        "MT | MISSOULA | FOX Montana",
        "MT | MISSOULA | CW Montana",
        "MT | MISSOULA | ABC Montana",
        "MT | HELENA | Telemundo",
        "MT | HELENA | MontanaPBS",
        "MT | HELENA | MontanaPBS",
        "MT | HELENA | KXLH 9",
        "MT | HELENA | KTVH",
        "MT | HELENA | Helena's CW 10",
        "MT | HELENA | Fox Montana",
        "MT | HELENA | ABC Montana",
        "MT | GREAT FALLS | Telemundo",
        "MT | GREAT FALLS | MontanaPBS",
        "MT | GREAT FALLS | MontanaPBS",
        "MT | GREAT FALLS | KTVH",
        "MT | GREAT FALLS | KRTV 3",
        "MT | GREAT FALLS | Fox Montana",
        "MT | GREAT FALLS | ABC Montana",
        "MT | GLENDIVE | Telemundo Denver",
        "MT | GLENDIVE | Prairie Public",
        "MT | GLENDIVE | MontanaPBS",
        "MT | GLENDIVE | MontanaPBS",
        "MT | GLENDIVE | KXGN 2",
        "MT | GLENDIVE | KX5",
        "MT | GLENDIVE | FOX 4",
        "MT | GLENDIVE | ABC",
        "MT | BUTTE | Telemundo",
        "MT | BUTTE | NBC Montana",
        "MT | BUTTE | MontanaPBS",
        "MT | BUTTE | MontanaPBS",
        "MT | BUTTE | KXLF 4",
        "MT | BUTTE | Fox Montana",
        "MT | BUTTE | CW Montana",
        "MT | BUTTE | ABC Montana",
        "MT | BILLINGS | Telemundo Denver",
        "MT | BILLINGS | Q2",
        "MT | BILLINGS | MontanaPBS",
        "MT | BILLINGS | MontanaPBS",
        "MT | BILLINGS | KULR 8",
        "MT | BILLINGS | FOX 4",
        "MT | BILLINGS | CW Montana",
        "MT | BILLINGS | ABC 6",
        "MS | MERIDIAN | Telemundo Houston",
        "MS | MERIDIAN | NBC 30",
        "MS | MERIDIAN | MPB",
        "MS | MERIDIAN | MPB",
        "MS | MERIDIAN | Fox 30",
        "MS | MERIDIAN | Channel 11",
        "MS | MERIDIAN | CBS 24",
        "MS | MERIDIAN | APT PBS",
        "MS | JACKSON | WLBT 3",
        "MS | JACKSON | WJTV 12",
        "MS | JACKSON | WAPT 16",
        "MS | JACKSON | Telemundo Houston",
        "MS | JACKSON | MPB",
        "MS | JACKSON | Fox 40",
        "MS | HATTIESBURG | WDAM 7",
        "MS | HATTIESBURG | WDAM",
        "MS | HATTIESBURG | Telemundo Houston",
        "MS | HATTIESBURG | MPB",
        "MS | HATTIESBURG | MPB",
        "MS | HATTIESBURG | FOX 23",
        "MS | HATTIESBURG | CBS 22",
        "MS | GREENWOOD | Telemundo",
        "MS | GREENWOOD | NBC 33",
        "MS | GREENWOOD | MPB",
        "MS | GREENWOOD | MPB",
        "MS | GREENWOOD | Fox 10",
        "MS | GREENWOOD | Delta CBS",
        "MS | GREENWOOD | ABC 6",
        "MS | BILOXI | WYES",
        "MS | BILOXI | WXXV",
        "MS | BILOXI | WXXV",
        "MS | BILOXI | WLOX",
        "MS | BILOXI | WLOX",
        "MS | BILOXI | Telemundo",
        "MS | BILOXI | MPB",
        "MS | BILOXI | MPB",
        "MO | ST LOUIS | Telemundo Chicago",
        "MO | ST LOUIS | Nine PBS",
        "MO | ST LOUIS | Nine PBS",
        "MO | ST LOUIS | KSDK 5",
        "MO | ST LOUIS | KMOV 4",
        "MO | ST LOUIS | FOX 2",
        "MO | ST LOUIS | ABC 30",
        "MO | ST JOSEPH | St. Joseph’s CW 6",
        "MO | ST JOSEPH | NBC 21",
        "MO | ST JOSEPH | KQ2",
        "MO | ST JOSEPH | Kansas City PBS",
        "MO | ST JOSEPH | Kansas City PBS",
        "MO | ST JOSEPH | Fox 26",
        "MO | ST JOSEPH | CBS 30",
        "MO | SPRINGFIELD | Telemundo",
        "MO | SPRINGFIELD | OPTV",
        "MO | SPRINGFIELD | OPTV",
        "MO | SPRINGFIELD | KY3",
        "MO | SPRINGFIELD | KSPR",
        "MO | SPRINGFIELD | KOLR 10",
        "MO | SPRINGFIELD | FOX 5",
        "MO | JOPLIN | Telemundo 39",
        "MO | JOPLIN | OPTV",
        "MO | JOPLIN | KSN",
        "MO | JOPLIN | KODE 12",
        "MO | JOPLIN | KOAM-TV",
        "MO | JOPLIN | FOX 14",
        "MO | COLUMBIA | Telemundo",
        "MO | COLUMBIA | Mid-Missouri's CW",
        "MO | COLUMBIA | KRCG 13",
        "MO | COLUMBIA | KOMU 8",
        "MO | COLUMBIA | KMOS",
        "MO | COLUMBIA | KMOS",
        "MO | COLUMBIA | FOX 22",
        "MO | COLUMBIA | ABC 17",
        "MN | ROCHESTER | Telemundo",
        "MN | ROCHESTER | Rochester CW",
        "MN | ROCHESTER | KTTC",
        "MN | ROCHESTER | KIMT 3",
        "MN | ROCHESTER | Iowa PBS",
        "MN | ROCHESTER | Iowa PBS",
        "MN | ROCHESTER | FOX 47",
        "MN | ROCHESTER | ABC 6",
        "MN | MINNEAPOLIS | WCCO 4",
        "MN | MINNEAPOLIS | TPT 2",
        "MN | MINNEAPOLIS | TPT",
        "MN | MINNEAPOLIS | Telemundo",
        "MN | MINNEAPOLIS | KSTP",
        "MN | MINNEAPOLIS | KARE 11",
        "MN | MINNEAPOLIS | FOX 9",
        "MN | MINNEAPOLIS | CW 23",
        "MN | MANKATO | TPT 2",
        "MN | MANKATO | TPT",
        "MN | MANKATO | Telemundo",
        "MN | MANKATO | KEYC NBC",
        "MN | MANKATO | KEYC 12",
        "MN | MANKATO | Fox 12",
        "MN | MANKATO | ABC",
        "MN | GRAND RAPIDS | WDSE",
        "MN | GRAND RAPIDS | WDSE",
        "MN | GRAND RAPIDS | WDIO",
        "MN | GRAND RAPIDS | Telemundo Chicago",
        "MN | GRAND RAPIDS | LPTV",
        "MN | GRAND RAPIDS | KBJR 6",
        "MN | GRAND RAPIDS | KBJR 6",
        "MN | GRAND RAPIDS | FOX 21",
        "MN | GRAND RAPIDS | CW 2",
        "MN | DULUTH | WDSE",
        "MN | DULUTH | WDSE",
        "MN | DULUTH | WDIO",
        "MN | DULUTH | Telemundo Chicago",
        "MN | DULUTH | KBJR 6",
        "MN | DULUTH | KBJR 6",
        "MN | DULUTH | FOX 21",
        "MN | DULUTH | CW 2",
        "MI | TRAVERSE CITY | WWTV",
        "MI | TRAVERSE CITY | WCMU",
        "MI | TRAVERSE CITY | WCMU",
        "MI | TRAVERSE CITY | TV 7",
        "MI | TRAVERSE CITY | Telemundo NY",
        "MI | TRAVERSE CITY | Local 32",
        "MI | TRAVERSE CITY | ABC 29",
        "MI | MARQUETTE | WNMU",
        "MI | MARQUETTE | WNMU",
        "MI | MARQUETTE | TV 6",
        "MI | MARQUETTE | Telemundo Chicago",
        "MI | MARQUETTE | Local 3",
        "MI | MARQUETTE | Fox UP",
        "MI | MARQUETTE | ABC 10",
        "MI | JACKSON | WLNS 6",
        "MI | JACKSON | WKAR",
        "MI | JACKSON | WKAR",
        "MI | JACKSON | WILX 10",
        "MI | JACKSON | WGTE",
        "MI | JACKSON | Telemundo NY",
        "MI | JACKSON | My ABC 53",
        "MI | JACKSON | FOX 47",
        "MI | JACKSON | DPTV",
        "MI | FLINT | WNEM",
        "MI | FLINT | WKAR",
        "MI | FLINT | Telemundo NY",
        "MI | FLINT | NBC 25",
        "MI | FLINT | Fox 66",
        "MI | FLINT | DPTV",
        "MI | FLINT | ABC 12",
        "MI | DETROIT | Telemundo Chicago",
        "MI | DETROIT | Local 4",
        "MI | DETROIT | FOX 2",
        "MI | DETROIT | DPTV",
        "MI | DETROIT | DPTV",
        "MI | DETROIT | CW 50",
        "MI | DETROIT | CBS 62",
        "MI | DETROIT | ABC 7",
        "MI | ALPENA | WCMU",
        "MI | ALPENA | WCMU",
        "MI | ALPENA | WBKB Fox",
        "MI | ALPENA | WBKB 11",
        "MI | ALPENA | WBKB",
        "MI | ALPENA | NBC 5",
        "ME | PRESQUE ISLE | WWPI",
        "ME | PRESQUE ISLE | New England Cable News",
        "ME | PRESQUE ISLE | Maine PBS",
        "ME | PRESQUE ISLE | Maine PBS",
        "ME | PRESQUE ISLE | Fox 8",
        "ME | PRESQUE ISLE | CBS 8",
        "ME | PRESQUE ISLE | ABC",
        "ME | PORTLAND | WCSH 6",
        "ME | PORTLAND | NHPBS",
        "ME | PORTLAND | New England Cable News",
        "ME | PORTLAND | Maine PBS",
        "ME | PORTLAND | Maine PBS",
        "ME | PORTLAND | Fox 23",
        "ME | PORTLAND | Channel 8",
        "ME | PORTLAND | CBS 13",
        "ME | BANGORE | WLBZ 2",
        "ME | BANGORE | WABI 5",
        "ME | BANGORE | New England Cable News",
        "ME | BANGORE | Maine PBS",
        "ME | BANGORE | Maine PBS",
        "ME | BANGORE | FOX 22",
        "ME | BANGORE | ABC 7",
        "MD | SALISBURY | WRDE TV",
        "MD | SALISBURY | WHYY",
        "MD | SALISBURY | WBOC",
        "MD | SALISBURY | MPT",
        "MD | SALISBURY | MPT",
        "MD | SALISBURY | FOX 21",
        "MD | SALISBURY | Delmarva CW 3",
        "MD | SALISBURY | 47 ABC",
        "MD | BALTIMORE | WJZ 13",
        "MD | BALTIMORE | WHUT",
        "MD | BALTIMORE | WETA",
        "MD | BALTIMORE | WBAL 11",
        "MD | BALTIMORE | The CW Baltimore",
        "MD | BALTIMORE | MPT",
        "MD | BALTIMORE | MPT",
        "MD | BALTIMORE | FOX 45",
        "MD | BALTIMORE | ABC 2",
        "MA | SPRINGFIELD | Univision",
        "MA | SPRINGFIELD | UniMas",
        "MA | BOSTON | WMUR",
        "MA | BOSTON | WCVB 5",
        "MA | BOSTON | WBZ 4",
        "MA | BOSTON | Telemundo Boston",
        "MA | BOSTON | NHPBS",
        "MA | BOSTON | New England Cable News",
        "MA | BOSTON | NBC Boston",
        "MA | BOSTON | GBH",
        "MA | BOSTON | FOX 25",
        "MA | BOSTON | CW56",
        "LA | SHREVEPORT | Telemundo Houston",
        "LA | SHREVEPORT | LPB",
        "LA | SHREVEPORT | LPB",
        "LA | SHREVEPORT | KTBS 3",
        "LA | SHREVEPORT | KTAL-TV",
        "LA | SHREVEPORT | KSLA 12",
        "LA | SHREVEPORT | Fox 33",
        "LA | SHREVEPORT | CW 21",
        "LA | NEW ORLEANS | WYES",
        "LA | NEW ORLEANS | WYES",
        "LA | NEW ORLEANS | WWL-TV",
        "LA | NEW ORLEANS | WGNO",
        "LA | NEW ORLEANS | WDSU",
        "LA | NEW ORLEANS | Fox 8",
        "LA | MONROE | Telemundo Houston",
        "LA | MONROE | NBC 10",
        "LA | MONROE | LPB",
        "LA | MONROE | LPB",
        "LA | MONROE | KNOE 8",
        "LA | MONROE | KAQY ABC",
        "LA | MONROE | FOX 14",
        "LA | MONROE | Arkansas PBS",
        "LA | LAKE CHARLES | Telemundo Houston",
        "LA | LAKE CHARLES | SWLA",
        "LA | LAKE CHARLES | LPB",
        "LA | LAKE CHARLES | LPB",
        "LA | LAKE CHARLES | KSWL",
        "LA | LAKE CHARLES | KPLC 7",
        "LA | LAKE CHARLES | FOX 29",
        "LA | LAFAYETTE | Telemundo Houston",
        "LA | LAFAYETTE | NBC 15.2",
        "LA | LAFAYETTE | LPB",
        "LA | LAFAYETTE | LPB",
        "LA | LAFAYETTE | KLFY 10",
        "LA | LAFAYETTE | KATC 3",
        "LA | LAFAYETTE | FOX 15",
        "LA | LAFAYETTE | Acadiana CW",
        "LA | BATON ROUGE | WVLA",
        "LA | BATON ROUGE | WBRZ 2",
        "LA | BATON ROUGE | WAFB 9",
        "LA | BATON ROUGE | Telemundo Houston",
        "LA | BATON ROUGE | LPB",
        "LA | BATON ROUGE | LPB",
        "LA | BATON ROUGE | Fox 44",
        "LA | ALEXANDRIA | Telemundo Houston",
        "LA | ALEXANDRIA | LPB",
        "LA | ALEXANDRIA | KALB",
        "LA | ALEXANDRIA | Fox 48",
        "LA | ALEXANDRIA | CBS 2",
        "LA | ALEXANDRIA | ABC 31",
        "KY | PADUCAH | WSIL-TV",
        "KY | PADUCAH | Telemundo Chicago",
        "KY | PADUCAH | Local 6",
        "KY | PADUCAH | KFVS 12",
        "KY | PADUCAH | KET",
        "KY | PADUCAH | KET",
        "KY | PADUCAH | Fox 23",
        "KY | LOUISVILLE | WLKY",
        "KY | LOUISVILLE | WHAS",
        "KY | LOUISVILLE | WDRB",
        "KY | LOUISVILLE | WBKI TV",
        "KY | LOUISVILLE | WAVE",
        "KY | LOUISVILLE | KET",
        "KY | LOUISVILLE | KET",
        "KY | LEXINGTON | WYMT-TV",
        "KY | LEXINGTON | WKYT",
        "KY | LEXINGTON | LEX 18",
        "KY | LEXINGTON | KET",
        "KY | LEXINGTON | KET",
        "KY | LEXINGTON | Fox 56",
        "KY | LEXINGTON | ABC 36",
        "KY | BOWLING GREEN | WBKO 13",
        "KY | BOWLING GREEN | WBKO",
        "KY | BOWLING GREEN | Telemundo Chicago",
        "KY | BOWLING GREEN | NPT",
        "KY | BOWLING GREEN | NBC 40",
        "KY | BOWLING GREEN | KET",
        "KY | BOWLING GREEN | KET",
        "KY | BOWLING GREEN | CBS 40",
        "KVLY 11",
        "KS | WICHITA | Telemundo Chicago",
        "KS | WICHITA | PBS Kansas PTV",
        "KS | WICHITA | PBS Kansas PTV",
        "KS | WICHITA | KWCH 12",
        "KS | WICHITA | KSN",
        "KS | WICHITA | KSAS-TV",
        "KS | WICHITA | KAKE",
        "KS | KANSAS CITY | KTWU",
        "KS | KANSAS CITY | KSHB",
        "KS | KANSAS CITY | KMBC",
        "KS | KANSAS CITY | KCWE 29",
        "KS | KANSAS CITY | KCTV 5",
        "KS | KANSAS CITY | Kansas City PBS",
        "KS | KANSAS CITY | Kansas City PBS",
        "KS | KANSAS CITY | FOX 4",
        "IN | SOUTH BEND | WSBT 22 CBS",
        "IN | SOUTH BEND | WNDU 16",
        "IN | SOUTH BEND | WGVU",
        "IN | SOUTH BEND | WCWW-LD",
        "IN | SOUTH BEND | WSBT 22 FOX",
        "IN | SOUTH BEND | PBS Michiana",
        "IN | SOUTH BEND | PBS Michiana",
        "IN | SOUTH BEND | ABC 57",
        "IN | INDIANAPOLIS | WTIU",
        "IN | INDIANAPOLIS | WTHR 13",
        "IN | INDIANAPOLIS | WFYI",
        "IN | INDIANAPOLIS | WFYI",
        "IN | INDIANAPOLIS | RTV 6",
        "IN | INDIANAPOLIS | FOX 59",
        "IN | INDIANAPOLIS | CBS 4",
        "IN | FORT WAYNE | WANE 15",
        "IN | FORT WAYNE | UniMas",
        "IN | FORT WAYNE | Telemundo Chicago",
        "IN | FORT WAYNE | PBS Fort Wayne",
        "IN | FORT WAYNE | PBS Fort Wayne",
        "IN | FORT WAYNE | NBC",
        "IN | FORT WAYNE | FOX 55",
        "IN | FORT WAYNE | Fort Wayne CW",
        "IN | FORT WAYNE | ABC 21",
        "IN | EVANSVILLE | WNIN",
        "IN | EVANSVILLE | WEHT Local",
        "IN | EVANSVILLE | Telemundo Chicago",
        "IN | EVANSVILLE | KET",
        "IN | EVANSVILLE | FOX 44",
        "IN | EVANSVILLE | CBS 44",
        "IN | EVANSVILLE | 14 WFIE",
        "IL | ROCKFORD | Univision",
        "IL | ROCKFORD | UniMas",
        "IL | ROCKFORD | Telemundo Chicago",
        "IL | ROCKFORD | PBS Wisconsin",
        "IL | ROCKFORD | PBS Wisconsin",
        "IL | ROCKFORD | FOX 39",
        "IL | ROCKFORD | CW",
        "IL | ROCKFORD | Channel 17",
        "IL | ROCKFORD | 23 WIFR",
        "IL | ROCKFORD | 13 WREX",
        "IL | PEORIA | WYZZ",
        "IL | PEORIA | WTVP",
        "IL | PEORIA | WTVP",
        "IL | PEORIA | WMBD",
        "IL | PEORIA | WEEK 25",
        "IL | PEORIA | Univision",
        "IL | PEORIA | UniMas",
        "IL | PEORIA | Telemundo Chicago",
        "IL | PEORIA | Peoria-Bloomington CW",
        "IL | PEORIA | ABC",
        "IL | CHICAGO | WTTW",
        "IL | CHICAGO | WTTW",
        "IL | CHICAGO | The U",
        "IL | CHICAGO | The CW26",
        "IL | CHICAGO | Telemundo Chicago",
        "IL | CHICAGO | NBC 5",
        "IL | CHICAGO | My50 Chicago",
        "IL | CHICAGO | FOX 32",
        "IL | CHICAGO | Comet TV",
        "IL | CHICAGO | CBS 2",
        "IL | CHICAGO | ABC 7",
        "IL | CHAMPAIGN | WRSP",
        "IL | CHAMPAIGN | WILLDT",
        "IL | CHAMPAIGN | WILL-KN",
        "IL | CHAMPAIGN | WICS",
        "IL | CHAMPAIGN | WCIA 3",
        "IL | CHAMPAIGN | WBUI",
        "IL | CHAMPAIGN | WAND",
        "IL | CHAMPAIGN | Telemundo Chicago",
        "ID | IDAHO FALLS | Univision",
        "ID | IDAHO FALLS | UniMas",
        "ID | IDAHO FALLS | Telemundo East Idaho",
        "ID | IDAHO FALLS | News 3",
        "ID | IDAHO FALLS | Local News 8",
        "ID | IDAHO FALLS | KPVI",
        "ID | IDAHO FALLS | IDAHOPTV",
        "ID | IDAHO FALLS | IDAHOPTV",
        "ID | IDAHO FALLS | FOX 5",
        "ID | IDAHO FALLS | CW East Idaho",
        "ID | BOISE | Univision",
        "ID | BOISE | UniMas",
        "ID | BOISE | Treasure Valley CW",
        "ID | BOISE | Telemundo Denver",
        "ID | BOISE | NewsChannel 7",
        "ID | BOISE | KBOI 2",
        "ID | BOISE | IDAHOPTV",
        "ID | BOISE | IDAHOPTV",
        "ID | BOISE | FOX 9",
        "ID | BOISE | 6 On Your Side",
        "IA | TERRE HAUTE | WTWO",
        "IA | TERRE HAUTE | WTHI 10",
        "IA | TERRE HAUTE | WAWV",
        "IA | TERRE HAUTE | Univision",
        "IA | TERRE HAUTE | UniMas",
        "IA | TERRE HAUTE | Telemundo NY",
        "IA | TERRE HAUTE | MyFOX10",
        "IA | OTTUMWA | Telemundo Chicago",
        "IA | OTTUMWA | KYOU 15",
        "IA | OTTUMWA | KTVO 3",
        "IA | OTTUMWA | KTVO 3",
        "IA | OTTUMWA | Iowa PBS",
        "IA | OTTUMWA | Iowa PBS",
        "IA | DEMOINES | Telemundo Chicago",
        "IA | DEMOINES | Local 5",
        "IA | DEMOINES | KCCI 8",
        "IA | DEMOINES | Iowa PBS",
        "IA | DEMOINES | Iowa PBS",
        "IA | DEMOINES | Fox 17",
        "IA | DEMOINES | Channel 13",
        "IA | CEDAR RAPIDS | Telemundo Chicago",
        "IA | CEDAR RAPIDS | KCRG-TV",
        "IA | CEDAR RAPIDS | Iowa PBS",
        "IA | CEDAR RAPIDS | Iowa PBS",
        "IA | CEDAR RAPIDS | Fox 28",
        "IA | CEDAR RAPIDS | CW 9.3",
        "IA | CEDAR RAPIDS | CBS 2",
        "IA | CEDAR RAPIDS | 7 KWWL",
        "HI | HONOLULU | Telemundo 52",
        "HI | HONOLULU | KITV 4",
        "HI | HONOLULU | KHON 2",
        "HI | HONOLULU | KHNL",
        "HI | HONOLULU | KGMB",
        "GA | WACON | WMAZDT2",
        "GA | WACON | Univision",
        "GA | WACON | UniMas",
        "GA | WACON | Telemundo Atlanta",
        "GA | WACON | GPB",
        "GA | WACON | GPB",
        "GA | WACON | FOX 24",
        "GA | WACON | FOX 24",
        "GA | WACON | 41 NBC",
        "GA | WACON | 13 WMAZ",
        "GA | SAVANNAH | WTOC 11",
        "GA | SAVANNAH | WSAV-TV",
        "GA | SAVANNAH | WJCL 22",
        "GA | SAVANNAH | SCETV",
        "GA | SAVANNAH | GPB",
        "GA | SAVANNAH | GPB",
        "GA | SAVANNAH | Fox 28",
        "GA | COLUMBUS | WTVM 9",
        "GA | COLUMBUS | WRBL 3",
        "GA | COLUMBUS | WLTZ",
        "GA | COLUMBUS | Univision",
        "GA | COLUMBUS | UniMas",
        "GA | COLUMBUS | Telemundo Atlanta",
        "GA | COLUMBUS | GPB",
        "GA | COLUMBUS | GPB",
        "GA | COLUMBUS | FOX 54",
        "GA | AUGUSTA | WAGT",
        "GA | AUGUSTA | Univision",
        "GA | AUGUSTA | UniMas",
        "GA | AUGUSTA | SCETV",
        "GA | AUGUSTA | News 12",
        "GA | AUGUSTA | GPB",
        "GA | AUGUSTA | GPB",
        "GA | AUGUSTA | FOX 54",
        "GA | AUGUSTA | ABC 6",
        "GA | ATLANTA | WSB 2",
        "GA | ATLANTA | Telemundo Atlanta",
        "GA | ATLANTA | GPB",
        "GA | ATLANTA | GPB",
        "GA | ATLANTA | FOX 5",
        "GA | ATLANTA | CW 69",
        "GA | ATLANTA | CBS 46",
        "GA | ATLANTA | ATL PBA",
        "GA | ATLANTA | 11 Alive",
        "GA | ALBANY | WSWG",
        "GA | ALBANY | WALB NBC",
        "GA | ALBANY | WALB ABC",
        "GA | ALBANY | Univision",
        "GA | ALBANY | UniMas",
        "GA | ALBANY | GPB",
        "GA | ALBANY | GPB",
        "GA | ALBANY | FOX 31",
        "FL | WEST PALM BEACH | WPTV",
        "FL | WEST PALM BEACH | WPEC",
        "FL | WEST PALM BEACH | WPBT",
        "FL | WEST PALM BEACH | WPBF",
        "FL | WEST PALM BEACH | WLRN",
        "FL | WEST PALM BEACH | Telemundo 51",
        "FL | WEST PALM BEACH | South Florida PBS",
        "FL | WEST PALM BEACH | Fox 29",
        "FL | WEST PALM BEACH | CW 34",
        "FL | TAMPA | WEDU PBS",
        "FL | TAMPA | NBC 8",
        "FL | TAMPA | FOX 13",
        "FL | TAMPA | CW 44",
        "FL | TAMPA | ABC 7",
        "FL | TAMPA | ABC 28",
        "FL | TAMPA | 10 News",
        "FL | TALLASSEE | WFSU-KN",
        "FL | TALLAHASSEE | WFSU",
        "FL | TALLAHASSEE | WCTV",
        "FL | TALLAHASSEE | The CW Tallahassee",
        "FL | TALLASSEE | Telemundo 51",
        "FL | TALLASSEE | NBC 40",
        "FL | TALLAHASSEE | GPB",
        "FL | TALLAHASSEE | FOX 49",
        "FL | TALLAHASSEE | ABC 27",
        "FL | PANAMA CITY | WMBB 13",
        "FL | PANAMA CITY | WFSU-KN",
        "FL | PANAMA CITY | WFSU",
        "FL | PANAMA CITY | WECP-TV",
        "FL | PANAMA CITY | Univision",
        "FL | PANAMA CITY | UniMas",
        "FL | PANAMA CITY | Telemundo Houston",
        "FL | PANAMA CITY | NewsChannel 7",
        "FL | PANAMA CITY | FOX 28",
        "FL | ORLANDO | WUCF",
        "FL | ORLANDO | WFTV 9",
        "FL | ORLANDO | WESH 2",
        "FL | ORLANDO | TV 27",
        "FL | ORLANDO | Telemundo Orlando",
        "FL | ORLANDO | News 6",
        "FL | ORLANDO | FOX 35",
        "FL | ORLANDO | CW 18",
        "FL | ORLANDO | Comet TV",
        "FL | MIAMI | WSVN 7",
        "FL | MIAMI | WPBT",
        "FL | MIAMI | WLRN",
        "FL | MIAMI | Telemundo 51",
        "FL | MIAMI | NBC 6",
        "FL | MIAMI | Local 10",
        "FL | MIAMI | CBS 4",
        "FL | JACKSONVILLE | Telemundo 51",
        "FL | JACKSONVILLE | NBC 12",
        "FL | JACKSONVILLE | JAX PBS",
        "FL | JACKSONVILLE | JAX PBS",
        "FL | JACKSONVILLE | FOX 30",
        "FL | JACKSONVILLE | CW 17",
        "FL | JACKSONVILLE | Channel 4 News4Jax",
        "FL | JACKSONVILLE | CBS 47",
        "FL | JACKSONVILLE | ABC 25",
        "FL | GAINSVILLE | WUFT",
        "FL | GAINSVILLE | WOGX",
        "FL | GAINSVILLE | WCJB",
        "FL | GAINSVILLE | Univision",
        "FL | GAINSVILLE | UniMas",
        "FL | GAINSVILLE | Telemundo 51",
        "FL | GAINSVILLE | NBC 9",
        "FL | GAINSVILLE | CBS 4",
        "FL | FT MYERS | WXCW",
        "FL | FT MYERS | WINK-TV",
        "FL | FT MYERS | WGCU",
        "FL | FT MYERS | WCGU",
        "FL | FT MYERS | Telemundo 51",
        "FL | FT MYERS | NBC 2",
        "FL | FT MYERS | Fox 4",
        "FL | FT MYERS | ABC 7",
        "DC | WASHINGTON | WUSA 9",
        "DC | WASHINGTON | WHUT",
        "DC | WASHINGTON | WETA",
        "DC | WASHINGTON | Telemundo 44",
        "DC | WASHINGTON | NBC 4",
        "DC | WASHINGTON | MPT",
        "DC | WASHINGTON | FOX 5",
        "DC | WASHINGTON | ABC 7",
        "CT | HARTFORD | WFSB 3",
        "CT | HARTFORD | WCCT-TV",
        "CT | HARTFORD | Telemundo",
        "CT | HARTFORD | New England Cable News",
        "CT | HARTFORD | NEPM",
        "CT | HARTFORD | NBC CT",
        "CT | HARTFORD | FOX 61",
        "CT | HARTFORD | CPTV",
        "CT | HARTFORD | CPTV",
        "CT | HARTFORD | ABC 8",
        "CO | GRAND JUNCTION | Telemundo Denver",
        "CO | GRAND JUNCTION | RMPBS",
        "CO | GRAND JUNCTION | KREX 5",
        "CO | GRAND JUNCTION | KKCO 11",
        "CO | GRAND JUNCTION | KJCT 8",
        "CO | GRAND JUNCTION | Fox 4",
        "CO | DENVER | Telemundo Denver",
        "CO | DENVER | RMPBS",
        "CO | DENVER | RMPBS",
        "CO | DENVER | FOX 31",
        "CO | DENVER | Denver 7",
        "CO | DENVER | CBS 4",
        "CO | DENVER | 9 News",
        "CO | COLORADO SPRINGS | RMPBS",
        "CO | COLORADO SPRINGS | RMPBS",
        "CO | COLORADO SPRINGS | KRDO 13",
        "CO | COLORADO SPRINGS | KOAA 5",
        "CO | COLORADO SPRINGS | KKTV 11",
        "CO | COLORADO SPRINGS | Fox 21",
        "CA | YUMA | News 11",
        "CA | YUMA | KYMA",
        "CA | YUMA | KPBS",
        "CA | YUMA | KPBS",
        "CA | YUMA | FOX 9",
        "CA | YUMA | Desert CW6",
        "CA | YUMA | ABC 5",
        "CA | SANTA BARBARA | NewsChannel 3",
        "CA | SANTA BARBARA | PBS SoCal Kids",
        "CA | SANTA BARBARA | KCET",
        "CA | SANTA BARBARA | Univision",
        "CA | SANTA BARBARA | Central Coast CW",
        "CA | SANTA BARBARA | NewsChannel 12",
        "CA | SANTA BARBARA | KSBY 6",
        "CA | SANTA BARBARA | FOX 11",
        "CA | SANTA BARBARA | NewsChannel 3 ABC",
        "CA | SAN FRANCISCO | Telemundo 48",
        "CA | SAN FRANCISCO | NBC 11",
        "CA | SAN FRANCISCO | KVIE",
        "CA | SAN FRANCISCO | KTVU Plus",
        "CA | SAN FRANCISCO | KQED",
        "CA | SAN FRANCISCO | KQED",
        "CA | SAN FRANCISCO | KPIX 5",
        "CA | SAN FRANCISCO | KBCW 44",
        "CA | SAN FRANCISCO | FOX 2",
        "CA | SAN FRANCISCO | ABC 7",
        "CA | SAN DIEGO | NBC 7",
        "CA | SAN DIEGO | KUANLD",
        "CA | SAN DIEGO | KPBS",
        "CA | SAN DIEGO | KPBS",
        "CA | SAN DIEGO | FOX 5",
        "CA | SAN DIEGO | CW San Diego",
        "CA | SAN DIEGO | CBS 8",
        "CA | SAN DIEGO | ABC 10",
        "CA | SACRAMENTO | KVIE",
        "CA | SACRAMENTO | KVIE",
        "CA | SACRAMENTO | KCRA 3",
        "CA | SACRAMENTO | FOX 40",
        "CA | SACRAMENTO | CW 31",
        "CA | SACRAMENTO | CBS 13",
        "CA | SACRAMENTO | ABC 10",
        "CA | PALM SPRINGS | PBS SoCal Kids",
        "CA | PALM SPRINGS | Palm Springs CW 5",
        "CA | PALM SPRINGS | KMIR",
        "CA | PALM SPRINGS | KESQ",
        "CA | PALM SPRINGS | KCET",
        "CA | PALM SPRINGS | FOX 11",
        "CA | PALM SPRINGS | CBS 2",
        "CA | MONTGOMERY | Telemundo 23",
        "CA | MONTGOMERY | KSBW",
        "CA | MONTGOMERY | KQED",
        "CA | MONTGOMERY | KQED",
        "CA | MONTGOMERY | KION",
        "CA | MONTGOMERY | FOX 35",
        "CA | MONTGOMERY | Central Coast CW",
        "CA | MONTGOMERY | Central Coast ABC",
        "CA | LOS ANGELES | Telemundo 52",
        "CA | LOS ANGELES | PBS SoCal Kids",
        "CA | LOS ANGELES | NBC 4",
        "CA | LOS ANGELES | KLCS",
        "CA | LOS ANGELES | KCET",
        "CA | LOS ANGELES | FOX 11",
        "CA | LOS ANGELES | CBS 2",
        "CA | LOS ANGELES | ABC 7",
        "CA | FRESNO | ValleyPBS",
        "CA | FRESNO | ValleyPBS",
        "CA | FRESNO | KSEE 24",
        "CA | FRESNO | KNSO",
        "CA | FRESNO | Fox 26",
        "CA | FRESNO | CBS 47",
        "CA | FRESNO | ABC 30",
        "CA | EUREKA | Telemundo 48",
        "CA | EUREKA | News Channel 3",
        "CA | EUREKA | KEET",
        "CA | EUREKA | KEET",
        "CA | EUREKA | Fox 28",
        "CA | EUREKA | CW",
        "CA | EUREKA | CBS 17",
        "CA | EUREKA | ABC 23",
        "CA | CHICO | NBC 24",
        "CA | CHICO | KIXE",
        "CA | CHICO | FOX 20",
        "CA | CHICO | Chico-Redding CW",
        "CA | CHICO | CBS 12",
        "CA | CHICO | ABC 7",
        "CA | BAKERSFIELD | ValleyPBS",
        "CA | BAKERSFIELD | ValleyPBS",
        "CA | BAKERSFIELD | Telemundo 52",
        "CA | BAKERSFIELD | KGET 17",
        "CA | BAKERSFIELD | KCET",
        "CA | BAKERSFIELD | KBAK",
        "CA | BAKERSFIELD | FOX 58",
        "CA | BAKERSFIELD | 23 ABC",
        "AZ | TUSCON | News 4",
        "AZ | TUSCON | News 13",
        "AZ | TUSCON | KGUN 9",
        "AZ | TUSCON | Fox 11",
        "AZ | TUSCON | CW Tucson",
        "AZ | TUSCON | AZPM",
        "AZ | TUSCON | AZPM",
        "AZ | PHOENIX | Telemundo Phoenix",
        "AZ | PHOENIX | NBC 12",
        "AZ | PHOENIX | FOX 10",
        "AZ | PHOENIX | CBS 5",
        "AZ | PHOENIX | Arizona PBS",
        "AZ | PHOENIX | ABC 15",
        "AR | LITTLE ROCK | THV 11",
        "AR | LITTLE ROCK | Telemundo 39",
        "AR | LITTLE ROCK | KATV 7",
        "AR | LITTLE ROCK | KARK 4",
        "AR | LITTLE ROCK | Fox 16",
        "AR | LITTLE ROCK | Arkansas PBS",
        "AR | FORT SMITH | Telemundo 39",
        "AR | FORT SMITH | OETA",
        "AR | FORT SMITH | KNWA",
        "AR | FORT SMITH | Fox 24",
        "AR | FORT SMITH | Channel 5",
        "AR | FORT SMITH | Arkansas PBS",
        "AR | FORT SMITH | Arkansas PBS",
        "AR | FORT SMITH | ABC 40/29",
        "AL | MONTGOMERY | WSFA 12",
        "AL | MONTGOMERY | Telemundo Houston",
        "AL | MONTGOMERY | Montgomery's CW",
        "AL | MONTGOMERY | FOX 20",
        "AL | MONTGOMERY | CBS 8",
        "AL | MONTGOMERY | APT PBS",
        "AL | MONTGOMERY | ABC 32",
        "AL | MOBILE | WKRG-TV",
        "AL | MOBILE | Telemundo Houston",
        "AL | MOBILE | NBC 15",
        "AL | MOBILE | Fox10",
        "AL | MOBILE | APT PBS",
        "AL | MOBILE | ABC 3",
        "AL | HUNTSVILLE | WZDX",
        "AL | HUNTSVILLE | WHNT 19",
        "AL | HUNTSVILLE | WAFF 48",
        "AL | HUNTSVILLE | WAAY 31",
        "AL | HUNTSVILLE | Telemundo Chicago",
        "AL | HUNTSVILLE | APT PBS",
        "AL | DOTHAN | WTVY 4",
        "AL | DOTHAN | WRGX",
        "AL | DOTHAN | WDHN",
        "AL | DOTHAN | Telemundo Houston",
        "AL | DOTHAN | FOX 34",
        "AL | DOTHAN | APT PBS",
        "AL | DOTHAN | APT PBS",
        "AL | BIRMINGHAM | WVTM 13",
        "AL | BIRMINGHAM | Telemundo Houston",
        "AL | BIRMINGHAM | FOX 6",
        "AL | BIRMINGHAM | CW 21",
        "AL | BIRMINGHAM | CBS 42",
        "AL | BIRMINGHAM | APT PBS",
        "AL | BIRMINGHAM | APT PBS",
        "AL | BIRMINGHAM | ABC 33/40",
        "AK | JUNEAU | Telemundo 48",
        "AK | JUNEAU | NBC Southeast",
        "AK | JUNEAU | KYEX",
        "AK | JUNEAU | KAKM",
        "AK | JUNEAU | KAKM",
        "AK | JUNEAU | Fox Juneau",
        "AK | JUNEAU | CW Alaska",
        "AK | JUNEAU | Comet TV",
        "AK | JUNEAU | ABC 8",
        "AK | FAIRBANKS | Telemundo 48",
        "AK | FAIRBANKS | KUAC",
        "AK | FAIRBANKS | KTVF Channel 11",
        "AK | FAIRBANKS | FOX Fairbanks",
        "AK | FAIRBANKS | CBS 13",
        "AK | FAIRBANKS | ABC Alaska",
        "AK | ANCHORAGE | Telemundo 52",
        "AK | ANCHORAGE | KAKM",
        "AK | ANCHORAGE | KAKM",
        "AK | ANCHORAGE | FOX 4",
        "AK | ANCHORAGE | CW Alaska",
        "AK | ANCHORAGE | Channel 2",
        "AK | ANCHORAGE | CBS 5",
        "AK | ANCHORAGE | ABC 13",
    ],
    [
        "AYV NEWS - Sierra Leone",
        "|AF| RTS1",
        "|AF| RTS2",
        "|AF| 2STV",
        "|AF| WALF TV",
        "|AF| TFM SENEGAL",
        "|AF| AFRICA 1 TV",
        "|AF| AFRICA 2 TV",
        "|AF| AFRICA 3 TV",
        "|AF| TFM RELIGIONS",
        "|AF| SEN TV",
        "|AF| LAMP FALL TV (Senegal)",
        "|AF| MUSIC TV (Senegal)",
        "|AF| DTV SENEGAL",
        "|AF| A2i TV (Senegal)",
        "|AF| A2i MUSIC",
        "|AF| A2i NAJA",
        "|AF| A2i RELIGION",
        "|AF| DELICE TV",
        "|AF| MOURIDE TV",
        "|AF| TMS",
        "|AF| AFRO CINEMA",
        "|AF| RTI1 (Cote D'ivoire)",
        "|AF| RTI2 (Cote D'ivoire)",
        "|AF| RTG (Guiniea)",
        "|AF| NOLLYWOOD MOVIES",
        "|AF| NOLLYWOOD FRENCH",
        "|AF| TELE CONGO",
        "|AF| AFRO MUSIC",
        "|AF| ORTM AFRICA",
        "|AF| AFRIQUE MEDIA TV",
        "|AF| RTB",
        "|AF| SIKKA TV",
        "|AF| AFRICA SPORTS",
        "|AF| CRTV (CAMEROUN)",
        "|AF| TELE TCHAD",
        "|AF| EQUINOXE TV",
        "|AF| STV (Cameroun)",
        "|AF| AIT INT'L",
        "|AF| AFRICA 24",
        "|AF| ABN TV",
        "|AF| SOUMALIAN TV (SOMALI)",
        "|AF| KALSAN TV (SOMALI)",
        "|AF| SBC TV (SOMALI)",
        "|AF| SLNTV (SOMALI)",
        "|AF| PUNTLAND TV (SOMALI)",
        "|AF| BULSHO TV (SOMALI)",
        "|AF| SNTV (SOMALI)",
        "|AF| SOMALI CABLE",
        "|AF| SAAB TV (SOMALI)",
        "|AF| DACWA TV (SOMALI)",
        "|AF| HORN CABLE (SOMALI)",
        "|AF| RTN TV (SOMALI)",
        "|AF| NTAI (NIGERIA)",
        "|AF| ROCK TV (NIGERIA)",
        "|AF| TV CONTINENTAL (NIGERIA)",
        "|AF| ITV (NIGERIA)",
        "|AF| HI-IMPACT TV (NIGERIA)",
        "|AF| LAGOS TV (NIGERIA)",
        "|AF| RTNC (CONGO)",
        "|AF| BE-ONE TV (CONGO)",
        "|AF| DIASPORA24 TV (GHANA)",
        "|AF| TV AFRICA",
        "|AF| RTV (GHANA)",
        "|AF| ADOM TV (GHANA)",
        "|AF| GTV SPORTS (GHANA)",
        "|AF| GH ONE TV (GHANA)",
        "|AF| JOY NEWS (GHANA)",
        "|AF| AFRICA NEWS",
        "|AF| CHANNELS 24 TV",
        "|AF| KASS TV (KENYA)",
        "|AF| KTN NEWS (KENYA)",
        "|AF| NTV (KENYA)",
        "|AF| K24 (KENYA)",
        "|AF| KBC (KENYA)",
        "|AF| UTV (KENYA)",
        "|AF| EBRU TV (KENYA)",
        "|AF| KAMEME TV (KENYA)",
        "|AF| INOORO TV (KENYA)",
        "|AF| GBS TV (KENYA)",
        "|AF| ERI TV (ERITREA)",
        "|AF| IMPACT TV (BURKINA FASO)",
        "|AF| TVM HD (MOZAMBIQUE)",
        "|AF| FRANCOPHONIE24",
        "|AF| KBC1",
        "|AF| ORTB TV",
        "|AF| NBS TV (UGANDA)",
        "|AF| SALT (UGANDA)",
        "|AF| RTV (RWANDA)",
        "|AF| RTV NEWS",
        "|AF| TELESUD",
        "|AF| TM2 (MALI)",
        "|AF| TPA (ANGOLA)",
        "|AF| VOX AFRICA",
        "|AF| God TV",
        "|AF| TV1 (Zambia)",
        "|AF| TV2 (Zambia)",
        "|AF| TV4 (Zambia)",
        "|AF| TBC (TANZANIA)",
        "|AF| AFROBEATS",
        "|AF| A12 (COTE D'IVOIRE)",
        "|AF| NOVELA CHANNEL",
        "|AF| AFROCULTURE TV",
        "|AF| VISION 4 TV",
        "|AF| DASH TV NEWS",
        "|AF| DASH TV",
        "|AF| AFRICA 7",
        "|AF| LOUGA TV",
        "|AF| AFOREVO",
        "|AF| KALAC TV",
        "|AF| CONGO PLANETE TV",
        "|AF| RTJ",
        "SLBC TV - Sierra Leone",
        "STAR TV - Sierra Leone",
        "MERCY TV - Sierra Leone",
        "Amaze TV - Sierra Leone",
    ],
    [
        "|AR| AL AOULA MAROC HD (1080P)",
        "|AR| AL AOULA MAROC",
        "|AR| AL AOULA LAAYOUNE TV HD (1080P)",
        "|AR| AL AOULA LAAYOUNE TV",
        "|AR| 2M MAROC",
        "|AR| 2M MONDE",
        "|AR| AL MAGHRIBIA MAROC HD (1080P)",
        "|AR| AL MAGHRIBIA MAROC",
        "|AR| ARRIADIA MAROC HD (1080P)",
        "|AR| ARRIADIA MAROC",
        "|AR| ARRIADIA HD (TNT)",
        "|AR| ARRABIAA MAROC HD (1080P)",
        "|AR| ARRABIAA MAROC",
        "|AR| MAROC 8 TAMAZIGHT HD (1080P)",
        "|AR| MAROC 8 TAMAZIGHT",
        "|AR| ASSADISSA MAROC HD (1080P)",
        "|AR| ASSADISSA MAROC",
        "|AR| AFLAM HD (1080P)",
        "|AR| AFLAM 7 TV",
        "|AR| MEDI 1 TV HD (1080P)",
        "|AR| MEDI 1 TV",
        "|AR| MEDI 1 TV Maghreb",
        "|AR| MEDI 1 TV Afrique",
        "|AR| M24 HD",
        "|AR| TELE MAROC",
        "|AR| CHADA TV",
        "|AR| AL ONS TV",
        "|AR| ENTV",
        "|AR| CANAL ALGERIE 2",
        "|AR| ALGERIE 3",
        "|AR| ALGERIE 4 AMAZIGH",
        "|AR| ALGERIE 5",
        "|AR| ALGERIE 6",
        "|AR| ALGERIE 7",
        "|AR| ALGERIE 8",
        "|AR| TV7 ELMAARIFA",
        "|AR| TV8 EDHAKIRA",
        "|AR| ECHOUROUK TV",
        "|AR| ECHOUROUK NEWS",
        "|AR| ENNAHAR ALGERIE",
        "|AR| EL HADDAF",
        "|AR| AL DJAZAIRIA",
        "|AR| DZAIR TV",
        "|AR| EL DJAZAIR N1",
        "|AR| SAMIRA TV",
        "|AR| AL MAGHARIBIA",
        "|AR| EL BILAD TV",
        "|AR| BEUR TV",
        "|AR| BAHIA TV",
        "|AR| EL HAYAT TV ALGERIE",
        "|AR| AL BADIL TV",
        "|AR| AL ANIS TV",
        "|AR| EL FADJR TV",
        "|AR| LINA TV",
        "|AR| TUNISIA 1",
        "|AR| TUNISIA 2",
        "|AR| M TUNISIA",
        "|AR| ATTASSI3A TV",
        "|AR| NESSMA TV",
        "|AR| HANNIBAL",
        "|AR| El HIWAR ETTOUNSSI",
        "|AR| TELEVZA TV",
        "|AR| ZAYTOUNA",
        "|AR| ALJANOUBIA",
        "|AR| SAHEL TV",
        "|AR| ALJAZEERA HD (1080P)",
        "|AR| ALJAZEERA HD",
        "|AR| ALJAZEERA MOUBASHER HD",
        "|AR| ALJAZEERA DOCUMENTARY",
        "|AR| RT ARABIC",
        "|AR| SKY NEWS ARABIC",
        "|AR| AL ARABIYA HD (1080P)",
        "|AR| DW ARABIC",
        "|AR| BBC ARABIC",
        "|AR| RUSSIA TODAY",
        "|AR| CNBC ARABIA",
        "|AR| AL ALAM",
        "|AR| AL HADATH HD (1080P)",
        "|AR| AL HADATH",
        "|AR| TRT ARABIC",
        "|AR| FRANCE 24",
        "|AR| NUMIDIA NEWS TV",
        "|AR| ORIENT NEWS",
        "|AR| ANN",
        "|AR| BATOOT KIDS",
        "|AR| SPACETOON",
        "|AR| CARTOON NETWORK",
        "|AR| AJYAL TV HD",
        "|AR| TOYOR JANAH BABY",
        "|AR| TOYOR JANAH",
        "|AR| KSA AJYAL HD",
        "|AR| MAJID",
        "|AR| MODY KIDS",
        "|AR| SEMSEM",
        "|AR| TAHA TV",
        "|AR| ART AFLAM 1",
        "|AR| ART AFLAM 2",
        "|AR| ART CINEMA",
        "|AR| ART HEKAYAT",
        "|AR| ART HEKAYAT 2",
        "|AR| MBC1",
        "|AR| MBC 2",
        "|AR| MBC 3",
        "|AR| MBC 4",
        "|AR| MBC 5 FHD",
        "|AR| MBC 5",
        "|AR| MBC MAX",
        "|AR| MBC ACTION",
        "|AR| MBC DRAMA",
        "|AR| MBC MASR 1",
        "|AR| MBC MASR 2",
        "|AR| MBC BOLLYWOOD",
        "|AR| MBC IRAQ",
        "|AR| ROTANA CINEMA",
        "|AR| ROTANA COMEDY",
        "|AR| ROTANA DRAMA",
        "|AR| ROTANA KHALIJI",
        "|AR| ROTANA CLIP",
        "|AR| ROTANA CLASSIC",
        "|AR| ROTANA MUSIC",
        "|AR| ROTANA KIDS",
        "|AR| ROTANA+ HD",
        "|AR| NBN LEBANON",
        "|AR| MTV LEBANON",
        "|AR| LBC INTERNATIONAL HD",
        "|AR| LBC LEBANON",
        "|AR| FUTURE TV",
        "|AR| AL MAYADEEN",
        "|AR| AL MANAR TV",
        "|AR| TL LIBANON",
        "|AR| LANA TV",
        "|AR| LANA PLUS",
        "|AR| LDC",
        "|AR| AL JADEED",
        "|AR| OTV",
        "|AR| ARABICA MUSIC",
        "|AR| AL SUMMARIA",
        "|AR| AL TAGHAYER",
        "|AR| ALITTIHAD",
        "|AR| ABU DHABI HD",
        "|AR| ABU DHABI DRAMA",
        "|AR| NATIONAL GEO ABU DHABI HD",
        "|AR| ABU DHABI SPORTS 1",
        "|AR| ABU DHABI SPORTS 2",
        "|AR| YAS SPORTS",
        "|AR| DUBAI TV",
        "|AR| DUBAI ONE",
        "|AR| SAMA DUBAI",
        "|AR| DUBAI ZAMAN",
        "|AR| NOOR DUBAI",
        "|AR| DUBAI SPORT 1 HD",
        "|AR| DUBAI SPORT 3 HD",
        "|AR| DUBAI SPORT 2 HD",
        "|AR| DUBAI RACING HD",
        "|AR| DUBAI RACING 2 HD",
        "|AR| DUBAI RACING 3",
        "|AR| ALSHARJAH TV HD",
        "|AR| AL SHARJAH SPORT HD",
        "|AR| AL SHARQIYA KALBA",
        "|AR| AL DAFRAH TV",
        "|AR| AL Karma TV",
        "|AR| AL EMARAT",
        "|AR| AL AAN TV HD",
        "|AR| ALWOUSTA TV HD",
        "|AR| AL QAMAR TV",
        "|AR| AJMAN TV HD",
        "|AR| HAWAS TV",
        "|AR| AL MASRIYAH",
        "|AR| AL MASRAWIA AFLAM",
        "|AR| WATAN HD (1080P)",
        "|AR| CBC",
        "|AR| CBC SOFRA",
        "|AR| CBC DRAMA",
        "|AR| CBC EXTRA",
        "|AR| NILE DRAMA",
        "|AR| NILE COMEDY",
        "|AR| NILE FAMILY",
        "|AR| NILE CINEMA",
        "|AR| NILE CULTURE",
        "|AR| NILE LIFE",
        "|AR| NILE NEWS",
        "|AR| NILE SPORT",
        "|AR| NILE TV INTERNATIONAL",
        "|AR| PANORAMA DRAMA",
        "|AR| PANORAMA DRAMA 2",
        "|AR| PANORAMA FILM",
        "|AR| PANORAMA FOOD",
        "|AR| ALHAYAT 1",
        "|AR| ALHAYAT 2",
        "|AR| ALHAYAT MUSALSALAT",
        "|AR| AL NAHAR TV",
        "|AR| AL NAHAR YOUM",
        "|AR| AL NAHAR CINEMA",
        "|AR| AL NAHAR DRAMA",
        "|AR| MEKAMELEEN HD (1080P)",
        "|AR| MEKAMELEEN HD",
        "|AR| MELODY CLASSIC",
        "|AR| AL KAHERA WAL NAS",
        "|AR| AL KAHERA WAL NAS 2",
        "|AR| CINEMA PRO",
        "|AR| TEN TV",
        "|AR| SADA EL BALAD",
        "|AR| SADA EL BALAD 2",
        "|AR| SADA EL BALAD DRAMA",
        "|AR| ZEE ALWAN",
        "|AR| ZEE AFLAM",
        "|AR| DMC HD",
        "|AR| DMC SPORT",
        "|AR| DMC DRAMA",
        "|AR| ON E HD",
        "|AR| ON TIME SPORTS 2 HD",
        "|AR| ON Time Sports HD",
        "|AR| ON Time Sports",
        "|AR| ONTIME SPORTS",
        "|AR| AL AHLY HD",
        "|AR| EL SHARQ TV",
        "|AR| AL SHARQ EL AWSAT TV",
        "|AR| B4U AFLAM",
        "|AR| CAIRO DRAMA",
        "|AR| CAIRO CINEMA",
        "|AR| TIME COMEDY",
        "|AR| SAWT EL MADA",
        "|AR| TOKTOK CINEMA",
        "|AR| STAR CINEMA 1",
        "|AR| STAR CINEMA 2",
        "|AR| TOKTOK AFLAM",
        "|AR| QUEST",
        "|AR| AL MEHWAR",
        "|AR| AL KAWTAR",
        "|AR| AL HAYAT",
        "|AR| HAWACOM TV",
        "|AR| AGHANI TV",
        "|AR| ALFATH TV",
        "|AR| ALARABY TV HD (1080P)",
        "|AR| ALHIWAR TV",
        "|AR| WEYYAK DRAMA",
        "|AR| WANASAH TV",
        "|AR| eXtra News",
        "|AR| IQRAA",
        "|AR| AL RESALAH TV",
        "|AR| AYAAT TV",
        "|AR| AL RAHMAH",
        "|AR| AL NADA TV",
        "|AR| AL MAJD QURAN",
        "|AR| AL MAJD HADITH",
        "|AR| MAKKAH TV",
        "|AR| AHLU AL QURAN TV",
        "|AR| HUDA TV",
        "|AR| WESAL LHAQ",
        "|AR| ALIMAN TV",
        "|AR| AL ISTIQAMA",
        "|AR| AL ITEJAH TV",
        "|AR| DAAWAH TV",
        "|AR| WESAL TV",
        "|AR| AL MAAREF",
        "|AR| DOHAT AL QURAAN",
        "|AR| ALKASS SPORT 1 HD",
        "|AR| ALKASS SPORT 2 HD",
        "|AR| ALKASS SPORT 3 HD",
        "|AR| ALKASS SPORT 4 HD",
        "|AR| ALKASS SPORT 5 HD",
        "|AR| QATAR HD TV",
        "|AR| QATAR TV 2 HD",
        "|AR| QATAR TODAY",
        "|AR| AL RAYYAN HD",
        "|AR| AL RAYYAN AL QADEEM HD",
        "|AR| BARAEEM",
        "|AR| AL FAJER TV HD",
        "|AR| ALFAJER 2 HD",
        "|AR| KSA SPORTS 1 HD",
        "|AR| KSA SPORTS 2 HD",
        "|AR| SAUDI 1",
        "|AR| SBC TV FHD",
        "|AR| SAUDI SUNAH HD",
        "|AR| SAUDI QUARAN HD",
        "|AR| SAUDI AL EKHBARIA FHD",
        "|AR| AL MAJD",
        "|AR| AL KHALIJ",
        "|AR| AFAQ TV",
        "|AR| NABAA TV",
        "|AR| CITRUSS TV",
        "|AR| ASHARQ",
        "|AR| ZIKRAYAT TV HD",
        "|AR| ZAD TV",
        "|AR| AL FALLUJAH TV HD",
        "|AR| AL RAFIDAIN TV HD",
        "|AR| ALQANAT 9 HD",
        "|AR| HONA BAGHDAD TV",
        "|AR| ALSHARQIYA TV",
        "|AR| ALSHARQIYA NEWS TV HD",
        "|AR| ALRASHEED TV HD",
        "|AR| ALMASAR TV",
        "|AR| AL GHADEER",
        "|AR| AL FORAT TV",
        "|AR| ALHURRA TV",
        "|AR| DIJLAH TV",
        "|AR| DIJLAH TARAB",
        "|AR| AL IRAQYA",
        "|AR| AL IRAQIYA NEWS",
        "|AR| IRAQIA SPORT HD",
        "|AR| AL ANWAR",
        "|AR| AL ANWAR 2",
        "|AR| Al NAEEM TV",
        "|AR| SAMA TV",
        "|AR| ANB TV",
        "|AR| IMAN HUSSEIN TV 1",
        "|AR| KARBALA TV",
        "|AR| ZAGROS TV",
        "|AR| JORDAN TV",
        "|AR| JORDAN TODAY",
        "|AR| JORDAN SPORT",
        "|AR| ROYA TV",
        "|AR| KAIFA TV",
        "|AR| AMMAN TV",
        "|AR| NOJOUM TV",
        "|AR| KUWAIT 1 HD (1080P)",
        "|AR| KUWAIT 2",
        "|AR| KUWAIT SPORT",
        "|AR| KUWAIT SPORT PLUS",
        "|AR| ASSIRAT",
        "|AR| KTV ARABE",
        "|AR| KTV ETHRAA",
        "|AR| KTV AL MAJLIS",
        "|AR| KTV KHALLIK BILBAIT",
        "|AR| KUWAIT AL RAI",
        "|AR| ALRAI",
        "|AR| FUNOON TV",
        "|AR| AL BAWADI",
        "|AR| AL SABAH",
        "|AR| BELQEES TV",
        "|AR| ATV",
        "|AR| MARINA TV",
        "|AR| PALESTINE TODAY",
        "|AR| PALESTINE LIVE",
        "|AR| PALESTINE",
        "|AR| PALESTINIAN TV",
        "|AR| SADA DRAMA",
        "|AR| AL MAALY",
        "|AR| ALAQSA TV",
        "|AR| AL NAS",
        "|AR| MUSAWA",
        "|AR| AL SALAM TV",
        "|AR| AWDEH",
        "|AR| AN NAJAH NBC",
        "|AR| MAWAL",
        "|AR| HEKAYA TV",
        "|AR| NABLUS TV",
        "|AR| RAJEEN TV",
        "|AR| SYRIA TV",
        "|AR| SYRIA DRAMA",
        "|AR| SYRIA NEWS",
        "|AR| SYRIA EDUCATION TV",
        "|AR| HALAB TODAY TV",
        "|AR| AL YAUM",
        "|AR| NOOR AL-SHAM",
        "|AR| AL ALAM SYRIA",
        "|AR| LIBYA TV",
        "|AR| LIBYA ALWATAN",
        "|AR| LIBYA 218 TV",
        "|AR| LIBYA ALAHRAR TV",
        "|AR| LIBYA ALHADATH",
        "|AR| LIBYA 218 NEWS",
        "|AR| BAHRAIN INTERNATIONAL",
        "|AR| BAHRAIN TV",
        "|AR| BAHRAIN QUARAN",
        "|AR| BAHRAIN SPORT 1",
        "|AR| BAHRAIN SPORT 2",
        "|AR| YEMEN TV",
        "|AR| YEMEN SHABAB",
        "|AR| ALHAWYAH TV",
        "|AR| AL ERTH AL NABAWI",
        "|AR| SUHAIL TV",
        "|AR| ALGHAD TV",
        "|AR| AL MAHRAH TV",
        "|AR| OMAN TV",
        "|AR| OMAN SPORT",
        "|AR| OMAN TV CULTURAL",
        "|AR| OMAN TV MOBASHIR",
        "|AR| SUDAN TV",
        "|AR| TAYBA TV",
    ],
    [
        "AR: OSN Ya Hala Al Oula HD",
        "AR: OSN Ya Hala HD",
        "AR: OSN Ya Hala Cinema HD",
        "AR: OSN Alfa Cinema 1",
        "AR: OSN Alfa Cinema 2",
        "AR: OSN Al Safwa",
        "AR: OSN Al Yawm",
        "AR: OSN Fann",
        "AR: OSN Movies First HD",
        "AR: OSN Movies First HD+2",
        "AR: OSN Movies Action HD",
        "AR: OSN Movies HD",
        "AR: OSN Enigma HD",
        "AR: OSN Family HD",
        "AR: OSN POP UP HD",
        "AR: OSN Kids HD",
        "AR: OSN AD Drama HD",
        "AR: OSN Musalsalat HD",
        "AR: OSN Musalsalat 2 HD",
        "AR: OSN Star Movies HD",
        "AR: OSN Star World HD",
        "AR: OSN Mezze HD",
        "AR: OSN Mix HD",
        "AR: OSN Women HD",
        "AR: OSN Comdey HD",
        "AR: OSN Series HD",
        "AR: OSN Series First HD",
        "AR: OSN E! Entertainment HD",
        "AR: OSN TLC HD",
        "AR: OSN Living HD",
        "AR: OSN Nat Geo HD",
        "AR: OSN Nat Geo People HD",
        "AR: OSN Nat Geo Wild HD",
        "AR: OSN History HD",
        "AR: OSN H2 HD",
        "AR: OSN Discovery HD",
        "AR: OSN Discovery ID HD",
        "AR: OSN Discovery Science HD",
        "AR: OSN Crime Investigation+ HD",
        "AR: OSN Box Office",
        "AR: OSN On Demand",
        "AR: OSN Disney Channel",
        "AR: OSN Disney Junior HD",
        "AR: OSN Nickelodeon HD",
        "AR: OSN Nick Jr. HD",
        "AR: OSN Nicktoons",
        "AR: OSN Kids Zone TV",
        "AR: OSN Baby TV",
        "AR: OSN Sport Yas",
        "AR: OSN Pehla Sports Action",
        "AR: OSN WWE Network HD",
        "AR: OSN MTV HD",
        "AR: OSN Music Now",
        "AR: OSN News",
        "AR: OSN TFC",
        "AR: OSN Pehla GMA News Pinoy",
        "AR: OSN Pehla GMA Pinoy HD",
        "AR: OSN Pehla GMA Life Pinoy",
        "AR: OSN Pehla ABP News HD",
        "AR: OSN ANC",
        "AR: OSN CinemaOne",
        "AR: OSN Pehla Colors HD",
        "AR: OSN CINEMO",
        "AR: MBC 1 FHD",
        "AR: MBC 1",
        "AR: MBC 2 FHD",
        "AR: MBC 2",
        "AR: MBC 3 FHD",
        "AR: MBC 3",
        "AR: MBC 4 FHD",
        "AR: MBC 4",
        "AR: MBC 5 FHD",
        "AR: MBC 5",
        "AR: MBC Iraq HD",
        "AR: MBC Action HD",
        "AR: MBC Action",
        "AR: MBC Drama HD",
        "AR: MBC Drama FHD (USA)",
        "AR: MBC Drama",
        "AR: MBC Max HD",
        "AR: MBC Max",
        "AR: MBC Bollywood HD",
        "AR: MBC Bollywood",
        "AR: MBC Variety HD",
        "AR: MBC Persia",
        "AR: AD Sports 1 FHD",
        "AR: AD Sports 2 FHD",
        "AR: AD Sports 3 FHD",
        "AR: AD Sports 4 FHD",
    ],
    [
        "ALB: Top Channel HD",
        "ALB: Top Channel",
        "ALB: Klan HD",
        "ALB: Klan HD EST",
        "ALB: Klan plus HD",
        "ALB: Vizion Plus HD",
        "ALB: RTSH 1 HD",
        "ALB: RTSH 2 HD",
        "ALB: RTSH 3 HD",
        "ALB: RTSH 24",
        "ALB: RTSH Shqip",
        "ALB: RTSH Sport",
        "ALB: RTSH Film",
        "ALB: RTSH Plus",
        "ALB: Alsat M (2)",
        "ALB: TV Kopliku HD",
        "ALB: TV Koha",
        "ALB: Fax News",
        "ALB: Supersonic TV",
        "ALB: TV Dukagjini HD",
        "ALB: Syri Blue HD",
        "ALB: Cameria TV",
        "ALB: Tema",
        "ALB: RTK 1 HD",
        "ALB: RTK 2",
        "ALB: RTK 3",
        "ALB: RTK 4",
        "ALB: Klan Kosova HD",
        "ALB: KTV HD",
        "ALB: KTV Toksor",
        "ALB: RTV 21 HD",
        "ALB: RTV 21 Maqedonia",
        "ALB: RTV 21 Junior",
        "ALB: RTV 21 Plus HD",
        "ALB: RTV 21 Popullore",
        "ALB: RTV 21 Mix HD",
        "ALB: Shenja TV",
        "ALB: RRokum TV",
        "ALB: Syri Vizion",
        "ALB: Dukagjini",
        "ALB: Era TV",
        "ALB: TV Dukagjini",
        "ALB: Stupcat HD",
        "ALB: EXP Histori",
        "ALB: EXP Natyra",
        "ALB: EXP Shkence",
        "ALB: NatGeo HD",
        "ALB: NatGeo Wild",
        "ALB: Travel Channel",
        "ALB: Fine Living",
        "ALB: Cufo TV",
        "ALB: Bang Bang",
        "ALB: Junior TV",
        "ALB: Baby TV",
        "ALB: Boomerang",
        "ALB: Cartoon Network",
        "ALB: Tring Tring",
        "ALB: Tip TV",
        "ALB: Tring Kids",
        "ALB: Top news",
        "ALB: Ora News HD",
        "ALB: News 24 HD",
        "ALB: Report TV",
        "ALB: Kanali 7 News",
        "ALB: ABC News",
        "ALB: Digi Gold 1",
        "ALB: Digi Gold 2",
        "ALB: Digi Gold 3",
        "ALB: Digi Gold 4",
        "ALB: Jolly HD",
        "ALB: T HD",
        "ALB: Tring Shqip",
        "ALB: Film Box Extra HD",
        "ALB: Family HD",
        "ALB: Stinet",
        "ALB: Tring 3 Plus",
        "ALB: Living HD",
        "ALB: Film Nje HD",
        "ALB: Film Dy HD",
        "ALB: Film Aksion",
        "ALB: Film Autor",
        "ALB: Film Drame",
        "ALB: Film Hits",
        "ALB: Film Komedi",
        "ALB: Film Thriller",
        "ALB: Tring Super HD",
        "ALB: Tring Life",
        "ALB: Tring Intern",
        "ALB: Tring Action",
        "ALB: Tring Comedy",
        "ALB: Tring Fantasy",
        "ALB: Tring History",
        "ALB: Tring Planet",
        "ALB: Tring Smile",
        "ALB: Tring World",
        "ALB: Tring Muse",
        "ALB: Fox",
        "ALB: Fox Life",
        "ALB: Fox Crime",
        "ALB: Netflix Aksion",
        "ALB: Netflix Fantazi",
        "ALB: Netflix Romance",
        "ALB: Netflix Femije",
        "ALB: Netflix Epik",
        "ALB: Netflix Relaks FHD",
        "ALB: Netflix Spider-Man",
        "ALB: Netflix Jackie Chan",
        "ALB: Netflix Van Damme",
        "ALB: Netflix Dwayne Johnson",
        "ALB: Netflix Arnold Schwarzenegger",
        "ALB: Netflix Sylvester Stallone",
        "ALB: Netflix Bud Spencer & Terence Hill",
        "ALB: Netflix Komedi",
        "ALB: Netflix Aventure",
        "ALB: X Action",
        "ALB: X Drama",
        "ALB: X Family",
        "ALB: X Histori",
        "ALB: ALB UK HD",
        "ALB: ShqipTV Film HD",
        "ALB: Alb Thriller",
        "ALB: Alb Action",
        "ALB: Alb Folk HD",
        "ALB: Sky Aksion",
        "ALB: Sky Komedi",
        "ALB: Muse",
        "ALB: IN TV",
        "ALB: Channel ONE",
        "ALB: SuperSport News HD",
        "ALB: SuperSport 2 HD",
        "ALB: SuperSport 2 SD",
        "ALB: SuperSport 3 HD",
        "ALB: SuperSport 4 HD",
        "ALB: SuperSport 5 HD",
        "ALB: SuperSport 6 HD",
        "ALB: SuperSport 7 HD",
        "ALB: Tring Sport 1 News HD",
        "ALB: Tring Sport 1 HD",
        "ALB: Tring Sport 2 HD",
        "ALB: Tring Sport 3 HD",
        "ALB: Tring Sport 4 HD",
        "ALB: Art Sport 1 HD",
        "ALB: Art Sport 2 HD",
        "ALB: Art Sport 3 HD",
        "ALB: K Sport 1",
        "ALB: K Sport 2",
        "ALB: K Sport 3",
        "ALB: K Sport 4",
        "ALB: K Sport 5",
        "ALB: Folk +",
        "ALB: RTSH Muzike",
        "ALB: AlbMUSIC",
        "ALB: RTV Puls HD",
        "ALB: Balkanika",
        "ALB: BBF Music",
        "ALB: Elrodi TV",
        "ALB: Folklorit",
        "ALB: MTV",
        "ALB: Dasma TV",
        "ALB: First",
        "ALB: Turbo Muzik",
        "ALB: TV OPOJA",
        "ALB: CLUB TV",
    ],
    [
        "AU: 7 Flix HD",
        "AU: 7 Mate HD",
        "AU: 7 Two HD",
        "AU: 7 Perth",
        "AU: 9Gem HD",
        "AU: 9Go HD",
        "AU: 9Go! Perth",
        "AU: 9 Perth",
        "AU: 9Life Sydney HD",
        "AU: ABC Perth",
        "AU: ABC Darwin",
        "AU: ABC Hobart",
        "AU: ABC Comedy HD",
        "AU: ABC Kids HD",
        "AU: ABC News Australia HD",
        "AU: ABC One HD",
        "AU: ABC ME National",
        "AU: Channel One HD",
        "AU: Channel 7 HD",
        "AU: Channel 9 HD",
        "AU: Channel 10 HD",
        "AU: Channel 10 Peach HD",
        "AU: Expo Channel HD",
        "AU: Food Network HD",
        "AU: NITV HD",
        "AU: SBS HD",
        "AU: SBS Viceland HD",
        "AU: Racing.com",
        "AU: Sky 1 Racing HD",
        "AU: Sky 2 Racing HD",
        "AU: Sky Thoroughbred Central",
        "AU: Race Central TV",
        "AU: SBS World Movies HD",
        "AU: Bloomberg Australia HD",
        "AU: Outdoor Channel HD",
        "AU: Acc TV",
        "AU: Ten Bold HD",
        "AU: Sky News Australia HD",
        "AU: Sky News Extra",
        "AU: Sky News Extra 2",
        "AU: Sky News Extra 3",
        "AU: SMTV",
        "AU: TVSN",
        "AU: Channel 44",
        "AU: Fox Sports News HD",
    ],
    [
        "|BD| ATN BANGLA UK",
        "|BD| ATN NEWS",
        "|BD| BOISHAKHI TV",
        "|BD| BTV WORLD",
        "|BD| COLORS BANGLA",
        "|BD| ZEE BANGLA",
        "|BD| GAZI TV",
        "|BD| INDEPENDENT TV",
        "|BD| RTV",
        "|BD| RUPOSHI BANGLA",
        "|BD| SOMOY NEWS TV",
        "|BD| TIME TELEVISION",
        "BD: Colosal TV",
        "BD: ATN Bangla",
        "BD: Nokshi TV",
        "BD: NTV Europe",
        "BD: News 24 HD",
        "BD: Maasranga TV",
        "BD: ATN Music",
        "BD: Asian TV",
        "BD: Ruposhi",
        "BD: Boishakhi TV",
        "BD: Channel I",
        "BD: DBC News",
        "BD: BTV",
        "BD: T Sports HD",
        "BD: Deepto TV",
        "BD: SA TV HD",
        "BD: NTV",
        "BD: Nagorik TV",
        "BD: RTV",
        "BD: Colors Bangla HD",
        "BD: My TV HD",
        "BD: BTV World",
        "BD: Ananda TV",
        "BD: Sangeet Bangla",
        "BD: Channel T1",
        "BD: Channel 24",
        "BD: Nexus TV",
        "BD: Sangsad TV",
        "BD: Zee Bangla HD",
        "BD: Bangla TV",
        "BD: ITV Bangla",
        "BD: Mohona TV",
        "BD: Aakaash Bangla",
        "BD: Dhoom Music",
        "BD: Ekushey TV",
        "BD: Bijoy TV",
        "BD: CNN Bangla",
    ],
    [
        "BR: Evento Star+ FHD",
        "BR: Evento Star+ HD",
        "BR: Star+ 01 HD",
        "BR: Evento Star+ 2 FHD",
        "BR: Evento Star+ 2 HD",
        "BR: Star+ 02 HD",
        "BR: SporTV FHD",
        "BR: SporTV HD",
        "BR: SporTV",
        "BR: SporTV 2 HD",
        "BR: SporTV 2 FHD",
        "BR: SporTV 2",
        "BR: SporTV 3 FHD",
        "BR: SporTV 3 HD",
        "BR: SporTV 3",
        "BR: ESPN Brasil FHD",
        "BR: ESPN Brasil HD",
        "BR: ESPN Brasil SD",
        "BR: ESPN FHD",
        "BR: ESPN HD",
        "BR: ESPN",
        "BR: ESPN 2 FHD",
        "BR: ESPN 2 HD",
        "BR: ESPN Extra FHD",
        "BR: ESPN Extra HD",
        "BR: ESPN Extra SD",
        "BR: ESPN 4 FHD",
        "BR: ESPN 4 HD",
        "BR: ESPN 4",
        "BR: AMAZON PRIME 01",
        "BR: FURACÃO PLAY 01 HD",
        "BR: FURACÃO PLAY 02 HD",
        "BR: Premiere Clubes FHD",
        "BR: Premiere Clubes HD",
        "BR: Premiere 1 FHD",
        "BR: Premiere 1 HD",
        "BR: Premiere 2 FHD",
        "BR: Premiere 2 HD",
        "BR: Premiere 2 SD",
        "BR: Premiere 3 FHD",
        "BR: Premiere 3 HD",
        "BR: Premiere 3 SD",
        "BR: Premiere 4 FHD",
        "BR: Premiere 4 HD",
        "BR: Premiere 4 SD",
        "BR: Premiere 5 FHD",
        "BR: Premiere 5 HD",
        "BR: Premiere 6 FHD",
        "BR: Premiere 6 HD",
        "BR: Premiere 7 FHD",
        "BR: Premiere 7 HD",
        "BR: Dazn 1 HD",
        "BR: Dazn 2 HD",
        "BR: Dazn 3 HD",
        "BR: CONMEBOL 01 FHD",
        "BR: CONMEBOL 01 HD",
        "BR: CONMEBOL 01",
        "BR: CONMEBOL 02 FHD",
        "BR: CONMEBOL 02 HD",
        "BR: CONMEBOL 02",
        "BR: CONMEBOL 03 FHD",
        "BR: CONMEBOL 03 HD",
        "BR: CONMEBOL 03",
        "BR: CONMEBOL 04 FHD",
        "BR: CONMEBOL 04 HD",
        "BR: CONMEBOL 04",
        "BR: Eleven Sports 1 HD",
        "BR: Eleven Sports 2 HD",
        "BR: Eleven Sports 3 HD",
        "BR: Eleven Sports 4 HD",
        "BR: Eleven Sports 5 HD",
        "BR: Eleven Sports 6 HD",
        "BR: Eleven Sports 7 HD",
        "BR: Eleven Sports 8 HD",
        "BR: Eleven Sports 9 HD",
        "BR: Eleven Sports 10 HD",
        "BR: TNT SPORTS 1 HD",
        "BR: TNT SPORTS 3 HD",
        "BR: TNT SPORTS 2 HD",
        "BR: TNT SPORTS 4 SD",
        "BR: TNT SPORTS 5 SD",
        "BR: TNT SPORTS 6 SD",
        "BR: TNT SPORTS 7 HD",
        "BR: TNT SPORTS 7 SD",
        "BR: TNT SPORTS 8 HD",
        "BR: TNT SPORTS 8 SD",
        "BR: Combate FHD",
        "BR: Combate HD",
        "BR: Combate",
        "BR: BAND SPORTS FHD",
        "BR: BAND SPORTS",
        "BR: Esporte Interativo 1 HD (Somente quando há transmissão)",
        "BR: Esporte Interativo 2 HD (Somente quando há transmissão)",
        "BR: Esporte Interativo 3 HD (Somente quando há transmissão)",
        "BR: Esporte Interativo 4 HD (Somente quando há transmissão)",
        "BR: Esporte Interativo 5 HD (Somente quando há transmissão)",
        "BR: Esporte Interativo 6 HD (Somente quando há transmissão)",
        "BR: Globo 4K",
        "BR: Globo TV Bahia",
        "BR: Globo SD",
        "BR: Globo News FHD",
        "BR: Globo News",
        "BR: TV Globo Premium",
        "BR: Gloobinho HD",
        "BR: Gloob",
        "BR: Gloobinho SD",
        "BR: TV Brasil HD",
        "BR: TV Brasil 2 FHD",
        "BR: Canal Brasil",
        "BR: Record HD",
        "BR: Record SD",
        "BR: Record News",
        "BR: Rede Brasil",
        "BR: Rede TV HD",
        "BR: Rede TV SD",
        "BR: SBT HD",
        "BR: TBS FHD",
        "BR: TBS HD",
        "BR: TBS SD",
        "BR: Mais Globosat HD",
        "BR: Mais Globosat",
        "BR: Futura FHD",
        "BR: OFF HD",
        "BR: OFF SD",
        "BR: Telecine Premium FHD (Dual Audio)",
        "BR: Telecine Premium FHD",
        "BR: Telecine Premium HD (Dual Audio)",
        "BR: Telecine Premium HD",
        "BR: Telecine Premium SD",
        "BR: Telecine Action FHD (Dual Audio)",
        "BR: Telecine Action FHD",
        "BR: Telecine Action HD (Dual Audio)",
        "BR: Telecine Action HD",
        "BR: Telecine Action",
        "BR: Telecine Fun FHD (Dual Audio)",
        "BR: Telecine Fun FHD",
        "BR: Telecine Fun HD (Dual Audio)",
        "BR: Telecine Fun HD",
        "BR: Telecine Fun",
        "BR: Telecine Pipoca FHD (Dual Audio)",
        "BR: Telecine Pipoca FHD",
        "BR: Telecine Pipoca HD (Dual Audio)",
        "BR: Telecine Pipoca HD",
        "BR: Telecine Pipoca",
        "BR: Telecine Touch FHD (Dual Audio)",
        "BR: Telecine Touch HD (Dual Audio)",
        "BR: Telecine Touch HD",
        "BR: Telecine Touch HD",
        "BR: Telecine Touch",
        "BR: Telecine Cult",
        "BR: Telecine Cult FHD",
        "BR: Telecine Cult HD (Dual Audio)",
        "BR: Telecine Cult HD",
        "BR: HBO FHD",
        "BR: HBO HD",
        "BR: HBO 2 HD",
        "BR: HBO 2",
        "BR: HBO Plus HD",
        "BR: HBO Plus",
        "BR: HBO Mundi HD",
        "BR: HBO Mundi",
        "BR: HBO Pop HD",
        "BR: HBO Pop",
        "BR: HBO Xtreme FHD",
        "BR: HBO Xtreme",
        "BR: HBO Family HD",
        "BR: HBO Signature HD",
        "BR: Cinemax HD",
        "BR: Cinemax FHD",
        "BR: Fox FHD",
        "BR: FOX HD",
        "BR: FOX",
        "BR: FX FHD",
        "BR: FX HD",
        "BR: FX",
        "BR: Fox Life FHD",
        "BR: FOX Life HD",
        "BR: Fox Life",
        "BR: TNT FHD",
        "BR: TNT HD",
        "BR: TNT",
        "BR: TNT HD (Dual Audio)",
        "BR: TNT Series HD (Dual Audio)",
        "BR: TNT Series FHD",
        "BR: TNT Series SD",
        "BR: TNT Séries HD",
        "BR: Universal TV FHD (Dual Audio)",
        "BR: Universal Channel HD",
        "BR: Universal Channel",
        "BR: Warner Channel FHD",
        "BR: Warner Channel HD",
        "BR: Warner Channel",
        "BR: Paramount Channel FHD",
        "BR: Paramount Channel",
        "BR: Studio Universal FHD (Dual Audio)",
        "BR: Studio Universal FHD",
        "BR: Studio Universal HD",
        "BR: Studio Universal",
        "BR: AXN HD",
        "BR: AXN",
        "BR: Prime Box Brasil HD",
        "BR: Megapix HD",
        "BR: Megapix",
        "BR: TLC FHD",
        "BR: TLC HD",
        "BR: TLC",
        "BR: HGTV FHD",
        "BR: HGTV HD",
        "BR: Tru TV FHD",
        "BR: Tru TV HD",
        "BR: Tru TV SD",
        "BR: SYFY FHD",
        "BR: Syfy HD",
        "BR: SYFY SD",
        "BR: TCM FHD",
        "BR: TCM HD",
        "BR: TCM",
        "BR: Lifetime FHD",
        "BR: Lifetime HD",
        "BR: Lifetime",
        "BR: Band HD",
        "BR: BAND NEWS",
        "BR: A&E",
        "BR: E!",
        "BR: Multishow FHD",
        "BR: Multishow",
        "BR: Sony FHD",
        "BR: Sony Channel HD",
        "BR: Sony SD",
        "BR: Comedy Central",
        "BR: Animal Planet HD",
        "BR: Animal Planet",
        "BR: Nat Geo HD",
        "BR: Discovery Channel FHD",
        "BR: Discovery Home & Health FHD",
        "BR: Discovery Home & Health",
        "BR: Discovery Turbo FHD",
        "BR: Discovery Turbo HD",
        "BR: Discovery Turbo",
        "BR: Discovery Science FHD",
        "BR: Discovery Science",
        "BR: Discovery World FHD",
        "BR: Discovery World HD",
        "BR: Discovery Theather FHD",
        "BR: History Channel FHD",
        "BR: History Channel HD",
        "BR: H2 FHD",
        "BR: H2 HD",
        "BR: Investigação Discovery FHD",
        "BR: Investigação Discovery HD",
        "BR: Investigação Discovery SD",
        "BR: Cartoon Network HD",
        "BR: Cartoon Network",
        "BR: Baby TV",
        "BR: Discovery Kids FHD",
        "BR: Discovery Kids HD",
        "BR: Discovery Kids",
        "BR: Boomerang",
        "BR: Disney Channel HD",
        "BR: Nick Jr. FHD",
        "BR: Nick JR HD",
        "BR: Nickelodeon FHD",
        "BR: Nickelodeon HD",
        "BR: Nickelodeon",
        "BR: Sabor e Arte FHD",
        "BR: Tooncast FHD",
        "BR: Tooncast HD",
        "BR: Tooncast",
        "BR: MTV HD",
        "BR: Arte 1 HD",
        "BR: Arte 1",
        "BR: GNT FHD",
        "BR: GNT HD",
        "BR: Space FHD",
        "BR: Space HD",
        "BR: Space SD",
        "BR: Yeeaah HD",
        "BR: ZooMoo HD",
        "BR: NovoTempo",
        "BR: Woohoo",
        "BR: i SAT FHD",
        "BR: i SAT HD",
        "BR: I Sat SD",
        "BR: Master TV HD",
        "BR: Music Box Brasil",
        "BR: Music Box Brasil HD",
        "BR: Music Box Brasil SD",
        "BR: Music Top HD",
        "BR: Play TV SD",
        "BR: Viva HD",
        "BR: Viva SD",
        "BR: TVT HD",
        "BR: Globo Centro America FHD",
        "BR: Globo Centro America HD",
        "BR: Globo Centro America SD",
        "BR: Globo EPTV Campinas HD",
        "BR: Globo EPTV Campinas SD",
        "BR: Globo Integra Juiz De Fora",
        "BR: Globo EPTV São Carlos",
        "BR: Globo Inter PT FHD",
        "BR: Globo RPC Paranavai HD",
        "BR: Globo Minas FHD",
        "BR: Globo Minas HD",
        "BR: Globo Minas SD",
        "BR: Globo Nordeste SD",
        "BR: Globo NOW",
        "BR: Globo NSC TV Blumenau SD",
        "BR: Globo RBS TV Caxias do Sul FHD",
        "BR: Globo RBS TV Pelotas FHD",
        "BR: Globo RBS TV POA FHD",
        "BR: Globo RBS TV POA SD",
        "BR: Globo NSC Joinville FHD",
        "BR: Globo Rede Amazonica FHD",
        "BR: Globo RJ FHD",
        "BR: Globo RJ HD",
        "BR: Globo RJ SD",
        "BR: Globo RPC Curitiba HD",
        "BR: Globo RPC Curitiba SD",
        "BR: GLOBO SP FHD",
        "BR: Globo SP HD",
        "BR: Globo SP SD",
        "BR: Globo TV Belem FHD",
        "BR: Globo TV Belem SD",
        "BR: Globo TV Brasilia FHD",
        "BR: Globo TV Brasilia SD",
        "BR: Globo RPC Maringa FHD",
        "BR: Globo RPC Maringa HD",
        "BR: Globo TV Morena FHD",
        "BR: Globo TV Morena HD",
        "BR: Globo Amazonas HD",
        "BR: Globo TV Tem Sao José Do Rio Preto FHD",
        "BR: Globo TV TEM Sorocaba HD",
        "BR: Globo TV Verdes Mares FHD",
        "BR: Globo TV Verdes Mares HD",
        "BR: Globo TV Verdes Mares SD",
        "BR: GloboTV Anhanguera FHD",
        "BR: GloboTV NSC Floripa FHD",
        "BR: Globo TV Florianopolis",
        "BR: Mais na Tela FHD",
        "BR: Mais na Tela HD",
        "BR: Primer TV HD",
        "BR: RBI TV FHD",
        "BR: RBTV HD",
        "BR: Record BA HD",
        "BR: Record Itapoan HD",
        "BR: Record Brasília HD",
        "BR: Record Goias HD",
        "BR: Record Goiás HD",
        "BR: Record MG HD",
        "BR: Record Rio RJ HD",
        "BR: Record Campinas HD",
        "BR: Rede Vida HD",
        "BR: Rede Genesis SD",
        "BR: Rede Minas FHD",
        "BR: Rede Super SD",
        "BR: Retro Music",
        "BR: Sabor&Arte HD",
        "BR: SBT AM HD",
        "BR: SBT MG HD",
        "BR: SBT SP HD",
        "BR: SBT RJ HD",
        "BR: SBT GO Serra Dourada",
        "BR: SBT HD",
        "BR: SBT",
        "BR: Taormina TV HD",
        "BR: Terra Viva FHD",
        "BR: Terra Viva HD",
        "BR: TV Aparecida FHD",
        "BR: TV MaisNews|Cultura FHD",
        "BR: TV Mon HD",
        "BR: TV Pampa HD",
        "BR: TV Camara FHD",
        "BR: TV das Artes",
        "BR: Tv Diario FHD",
        "BR: TV Envangelizar",
        "BR: TV Gazeta Alagoas FHD",
        "BR: TV Gazeta FHD",
        "BR: TV Gazeta Sul SD",
        "BR: TV Gideoes HD",
        "BR: TV Imaculada HD",
        "BR: TV Justiça FHD",
        "BR: TV Novo Tempo FHD",
        "BR: TV Pai Eterno FHD",
        "BR: TV Pampa HD",
        "BR: TV Ra Tim Bum FHD",
        "BR: TV Rio Sul SD",
        "BR: TV Senado HD",
        "BR: TV Tem Bauru FHD",
    ],
    [
        "BG: Max Sport 1 FHD",
        "BG: Max Sport 2 FHD",
        "BG: Max Sport 3 FHD",
        "BG: Max Sport 4 FHD",
        "BG: Diema Sport HD",
        "BG: Diema Sport",
        "BG: Diema Sports 1 FHD",
        "BG: Diema Sports 2 HD",
        "BG: Nova Sport FHD",
        "BG: Sport+ FHD",
        "BG: Eurosport 1 FHD",
        "BG: Eurosport 1",
        "BG: Eurosport 2 FHD",
        "BG: Edge Sport FHD",
        "BG: Edge Sport",
        "BG: Auto Motor Sport FHD",
        "BG: Trace Sport Stars FHD",
        "BG: Trace Sport Stars",
        "BG: Fishing & Hunting Channel",
        "BG: BNT 1 FHD",
        "BG: BNT 1",
        "BG: BNT 2 FHD",
        "BG: BNT 2",
        "BG: BNT 3 FHD",
        "BG: BNT 3",
        "BG: BNT 4 FHD",
        "BG: BNT 4",
        "BG: bTV FHD",
        "BG: bTV",
        "BG: bTV Comedy",
        "BG: bTV Cinema",
        "BG: BTV Lady",
        "BG: bTV Action",
        "BG: bTV Lady",
        "BG: Nova TV FHD",
        "BG: Nova TV",
        "BG: Diema TV",
        "BG: Diema Family",
        "BG: FOX FHD",
        "BG: Fox",
        "BG: Fox Crime FHD",
        "BG: Fox Crime",
        "BG: Fox Life FHD",
        "BG: Fox Life",
        "BG: Cinemax 1",
        "BG: Cinemax 1 FHD",
        "BG: Cinemax 2",
        "BG: Cinemax 2 FHD",
        "BG: Epic Drama FHD",
        "BG: FightBox FHD",
        "BG: FilmBox Extra HD",
        "BG: HBO",
        "BG: HBO FHD",
        "BG: HBO 2 FHD",
        "BG: HBO 2 HD",
        "BG: HBO 3 FHD",
        "BG: HBO 3 HD",
        "BG: Movie Star HD",
        "BG: Movie Star",
        "BG: Kino Nova",
        "BG: TV 1000",
        "BG: TV Europa",
        "BG: TV Plus",
        "BG: AMC",
        "BG: AXN",
        "BG: Action Plus HD",
        "BG: Comedy Plus",
        "BG: TLC",
        "BG: 24 Kitchen FHD",
        "BG: Food Network FHD",
        "BG: Nat Geo FHD",
        "BG: Nat Geo",
        "BG: Nat Geo Wild FHD",
        "BG: Nat Geo Wild",
        "BG: History Channel FHD",
        "BG: History",
        "BG: Discovery Channel FHD",
        "BG: Discovery Channel",
        "BG: Discovery Science FHD",
        "BG: Discovery Science",
        "BG: ID Xtra FHD",
        "BG: Animal Planet FHD",
        "BG: Travel Channel FHD",
        "BG: Travel Channel TV",
        "BG: Viasat Explore",
        "BG: Viasat History",
        "BG: Wild TV FHD",
        "BG: Planeta HD",
        "BG: Cartoon Network",
        "BG: Nickelodeon",
        "BG: Disney Channel HD",
        "BG: Baby TV HD",
        "BG: Duck TV",
        "BG: Jim Jam",
        "BG: Nova News",
        "BG: Bloomberg FHD",
        "BG: Bloomberg",
        "BG: Bulgaria 24",
        "BG: Box TV",
        "BG: City TV",
        "BG: Fen TV",
        "BG: TV1 FHD",
        "BG: BG-DNES FHD",
        "BG: 7/8 TV",
        "BG: 7/8 TV FHD",
        "BG: Alfa TV",
        "BG: BG-DNES FHD",
        "BG: BSTV",
        "BG: DSTV",
        "BG: Evrokom",
        "BG: Hobby TV FHD",
        "BG: MagicTV",
        "BG: Planeta Folk TV",
        "BG: Skat",
        "BG: Tiankov Folk",
        "BG: VTK",
        "BG: WnessTV",
        "BG: Hit Mix Channel",
        "BG: BG Music",
        "BG: BN Music",
        "BG: Ring TV FHD",
        "BG: Ring TV",
        "BG: The Voice",
        "BG: Balkanika Music TV HD",
        "BG: Djazz FHD",
        "BG: Folklor TV",
        "BG: iConcerts HD",
        "BG: Retro Music TV",
    ],
    [
        "CA: Bein Sports HD",
        "CA: TSN 5 HD",
        "CA: TSN 4 HD",
        "CA: TSN 3 HD",
        "CA: TSN 2 HD",
        "CA: TSN 1 HD",
        "CA: Sportsnet 360 HD",
        "CA: Sportsnet World HD",
        "CA: Sportsnet One HD",
        "CA: Sportsnet Pacific HD",
        "CA: Sportsnet West HD",
        "CA: Sportsnet Ontario HD",
        "CA: TVA Sports HD",
        "CA: TVA Sports",
        "CA: Flow Sport 1",
        "CA: RDS 2 (FR)",
        "CA: RDS (FR)",
        "CA: RDS Info (FR)",
        "CA: Golf Channel HD",
        "CA: ABC Spark HD",
        "CA: Bravo HD",
        "CA: Cooking Channel",
        "CA: Bet HD",
        "CA: CHCH HD",
        "CA: Comedy Gold HD",
        "CA: E! Entertainment",
        "CA: Slice",
        "CA: TLC",
        "CA: HLN HD",
        "CA: OWN",
        "CA: Showcase East",
        "CA: CMT HD",
        "CA: HGTV HD",
        "CA: GameShow Network TV HD",
        "CA: Lifetime",
        "CA: Womens Network",
        "CA: DIY Network HD",
        "CA: GSN",
        "CA: Family West",
        "CA: OLN HD",
        "CA: YTV (East)",
        "CA: YTV HD",
        "CA: HBO 1 HD",
        "CA: HBO 2 HD",
        "CA: AMC HD",
        "CA: Fox HD",
        "CA: FXX HD",
        "CA: Silver Screen Classic",
        "CA: Paramount Network",
        "CA: Action",
        "CA: CBC Montreal HD (FR)",
        "CA: CBC Montreal HD",
        "CA: CBC Toronto HD",
        "CA: CBC Calgary HD",
        "CA: CBC Fredericton HD",
        "CA: CBC Vancouver HD",
        "CA: CBC Ottawa HD",
        "CA: CBC Winnipeg HD",
        "CA: CBC Regina HD",
        "CA: CBS HD (West)",
        "CA: CBS East",
        "CA: OMNI.1",
        "CA: OMNI.2",
        "CA: ICI Vancouver FR",
        "CA: Global News BC",
        "CA: Global Toronto HD",
        "CA: Global News Vancouver HD",
        "CA: Global News Halifax",
        "CA: Global News Kingston",
        "CA: Global News Montreal",
        "CA: Global News National",
        "CA: Global News Peterborough",
        "CA: Global News Regina",
        "CA: Global News Saskatoon",
        "CA: Global News Winnipeg",
        "CA: Global News Calgary",
        "CA: Global News Edmonton",
        "CA: Global News Lethbridge",
        "CA: Global News Okanagan",
        "CA: TFO (FR)",
        "CA: TVO HD",
        "CA: PeachTree HD",
        "CA: CityTV HD",
        "CA: City Toronto HD",
        "CA: City Vancouver",
        "CA: City Winnipeg",
        "CA: City Saskatchewan",
        "CA: CTV Halifax",
        "CA: CTV Toronto",
        "CA: CTV Vancouver",
        "CA: CTV 2 Vancouver",
        "CA: CTV2 Ottawa",
        "CA: YES TV HD (Alberta)",
        "CA: YES TV HD (Ontario)",
        "CA: Miracle",
        "CA: Joy TV",
        "CA: ONNtv Ontario",
        "CA: The Shopping Channel",
        "CA: Weather Network HD (Toronto)",
        "CA: ICI RDI (FR)",
        "CA: ICI Montreal (FR)",
        "CA: ICI Tele",
        "CA: ICI ARTV (FR)",
        "CA: TVA Riviere-du-Loup (CIMT)",
        "CA: TVA Montreal (FR)",
        "CA: TV5 Quebec (FR)",
        "CA: Addik TV (FR)",
        "CA: Canal D (FR)",
        "CA: Canal Savoir (FR)",
        "CA: Tele Quebec (FR)",
        "CA: Casa (FR)",
        "CA: Super Ecran 1",
        "CA: Super Ecran 2",
        "CA: Super Ecran 3",
        "CA: Super Ecran 4",
        "CA: MOI et CIE (FR)",
        "CA: Series+",
        "CA: Historia (FR)",
        "CA: LCN (FR)",
        "CA: Unis TV (FR)",
        "CA: V Montreal (FR)",
        "CA: Vie (FR)",
        "CA: VRAK (FR)",
        "CA: Z tele (FR)",
        "CA: ZESTE (FR)",
        "CA: Discovery Channel HD",
        "CA: Animal Planet",
        "CA: Nat Geo HD",
        "CA: History",
        "CA: Discovery Science",
        "CA: Discovery Velocity",
        "CA: World Fishing Network HD",
        "CA: CP24 HD",
        "CA: BNN HD",
        "CA: BBC Wold News",
        "CA: CTV News HD",
        "CA: CTV News Live 2",
        "CA: CTV News Live 3",
        "CA: CTV News Live 4",
        "CA: CBC News Toronto HD",
        "CA: CBC News",
        "CA: CPAC HD",
        "CA: CNN HD",
        "CA: CNBC World",
        "CA: Cheknews HD",
        "CA: BBC Toronto Gaunda Punjab",
        "CA: Assemblée Nationale Québec (FR)",
        "CA: Ontario Parliamentary Network (FR)",
        "CA: Family JR",
        "CA: Teletoon HD",
        "CA: Treehouse HD",
        "CA: Cartoon Network",
        "CA: Disney Channel (FR)",
        "CA: Disney Channel HD",
        "CA: Disney JR",
        "CA: Knowledge Kids",
        "CA: Much Music",
        "CA: Stingray Classica",
        "CA: Stingray djazz",
        "CA: Stingray Everything 80s",
        "CA: Stingray Flashback 70s",
        "CA: Stingray Hit List",
        "CA: Stingray Holiday Hits",
        "CA: Stingray Hot Country",
        "CA: Stingray Naturescape",
    ],
    [
        "DE: RTL HD (1080P)",
        "DE: RTL HD (720P)",
        "DE: RTL SD",
        "DE: RTL Nitro HD (1080P)",
        "DE: RTL Nitro HD (720P)",
        "DE: RTL Nitro SD",
        "DE: Pro 7 HD (1080P)",
        "DE: Pro 7 HD (720P)",
        "DE: Pro 7 SD",
        "DE: Pro 7 Maxx HD (1080P)",
        "DE: Pro 7 Maxx HD (720P)",
        "DE: Pro 7 Maxx SD",
        "DE: Pro 7 Fun HD (720P)",
        "DE: Sixx HD (1080P)",
        "DE: Sixx HD (720P)",
        "DE: Sixx SD",
        "DE: VOX HD (1080P)",
        "DE: VOX HD (720P)",
        "DE: VOX SD",
        "DE: VOXup",
        "DE: SAT.1 HD (1080P)",
        "DE: SAT.1 HD (720P)",
        "DE: SAT.1 SD",
        "DE: SAT 1 GOLD HD (1080P)",
        "DE: SAT 1 GOLD HD (720P)",
        "DE: SAT 1 GOLD SD",
        "DE: Kabel eins HD (1080P)",
        "DE: Kabel eins HD (720P)",
        "DE: Kabel eins SD",
        "DE: Tele 5 HD (1080P)",
        "DE: Tele 5 HD (720P)",
        "DE: Sky Cinema Premieren HD (1080P)",
        "DE: Sky Cinema Premieren HD (720P)",
        "DE: Sky Cinema Premieren 24 HD (1080P)",
        "DE: Sky Cinema Premieren 24 SD",
        "DE: Sky Cinema Best Of HD (1080P)",
        "DE: Sky Cinema Best Of HD (720P)",
        "DE: Sky Cinema Best Of SD",
        "DE: Sky Cinema Action HD (1080P)",
        "DE: Sky Cinema Action HD (720P)",
        "DE: Sky Cinema Action SD",
        "DE: Sky Cinema Family HD (1080P)",
        "DE: Sky Cinema Family HD (720P)",
        "DE: Sky Cinema Family SD",
        "DE: Sky Cinema Special HD (1080P)",
        "DE: Sky Cinema Special HD (720P)",
        "DE: Sky Cinema Thriller HD (1080P)",
        "DE: Sky Cinema Special SD",
        "DE: Sky Cinema Fun",
        "DE: Sky Cinema Classic",
        "DE: TNT Film HD (1080P)",
        "DE: TNT Film HD (720P)",
        "DE: TNT Film SD",
        "DE: Kinowelt",
        "DE: Sky 1 HD (1080P)",
        "DE: Sky 1 HD (720P)",
        "DE: Sky 1 SD",
        "DE: Sky Krimi HD (1080P)",
        "DE: Sky Krimi HD (720P)",
        "DE: Sky Krimi SD",
        "DE: Sky Atlantic HD (1080P)",
        "DE: Sky Atlantic HD (720P)",
        "DE: Sky Atlantic SD",
        "DE: FOX HD (1080P)",
        "DE: FOX HD (720P)",
        "DE: FOX SD",
        "DE: TNT Serie HD (1080P)",
        "DE: TNT Serie HD (720P)",
        "DE: TNT Serie SD",
        "DE: Syfy HD (1080P)",
        "DE: Syfy HD (720P)",
        "DE: Syfy SD",
        "DE: 13th Street HD (1080P)",
        "DE: 13th Street HD (720P)",
        "DE: 13th Street SD",
        "DE: Universal HD (1080P)",
        "DE: Universal HD (720P)",
        "DE: TNT Comedy HD (1080P)",
        "DE: TNT Comedy HD (720P)",
        "DE: TLC HD (1080P)",
        "DE: TLC HD (720P)",
        "DE: TLC SD",
        "DE: Comedy Central HD (1080P)",
        "DE: Comedy Central HD (720P)",
        "DE: Anixe Serie HD (1080P)",
        "DE: Anixe Serie HD (720P)",
        "DE: Anixe Serie SD",
        "DE: Das Erste HD (1080P)",
        "DE: Das Erste HD (720P)",
        "DE: Das Erste SD",
        "DE: ZDF HD (1080P)",
        "DE: ZDF HD (720P)",
        "DE: ZDF NEO HD (1080P)",
        "DE: ZDF NEO HD (720P)",
        "DE: ZDF NEO SD",
        "DE: ZDF Info HD",
        "DE: ZDF Info",
        "DE: Kabel eins Classic (1080P)",
        "DE: Kabel eins Classic (720P)",
        "DE: Kabel eins Classic SD",
        "DE: RTL Zwei HD (1080P)",
        "DE: RTL Zwei HD (720P)",
        "DE: RTL Zwei SD",
        "DE: RTL Passion HD (1080P)",
        "DE: RTL Passion HD (720P)",
        "DE: RTL Crime HD (1080P)",
        "DE: RTL Crime SD",
        "DE: RTL Living HD",
        "DE: RTL Plus",
        "DE: Servus TV HD (1080P)",
        "DE: Servus TV HD (720P)",
        "DE: E! Entertainment HD (1080P)",
        "DE: E! Entertainment HD (720P)",
        "DE: Sony Channel",
        "DE: Sony AXN (1080P)",
        "DE: Sony AXN (720P)",
        "DE: Heimat Kanal",
        "DE: Anixe HD (1080P)",
        "DE: WDR HD",
        "DE: SWR HD",
        "DE: N-TV HD (1080P)",
        "DE: N-TV HD (720P)",
        "DE: N-TV SD",
        "AT: ORF 1 HD",
        "AT: ORF 2 HD",
        "AT: ORF 3 HD",
        "DE: Romance TV HD",
        "DE: Romance TV",
        "DE: Silverline",
        "DE: Sat 1 Emotions",
        "DE: One HD (1080P)",
        "DE: One HD (720P)",
        "DE: One SD",
        "DE: ATV",
        "DE: ATV 2",
        "DE: Bon Gusto HD",
        "DE: Franken TV HD",
        "DE: Kronehit TV",
        "DE: Euronews",
        "DE: DW",
        "DE: Tagesschau24 HD",
        "DE: Sky Sports News HD (1080P)",
        "DE: Sky Sports News HD (720P)",
        "DE: Sky Sports News SD",
        "DE: Sky Sport 1 HD (1080P)",
        "DE: Sky Sport 1 HD (720P)",
        "DE: Sky Sport 1 SD",
        "DE: Sky Sport 2 HD (1080P)",
        "DE: Sky Sport 2 HD (720P)",
        "DE: Sky Sport 2 SD",
        "DE: Sky Sport 3 HD (1080P) (Match Time)",
        "DE: Sky Sport 3 HD (720P) (Match Time)",
        "DE: Sky Sport 3 SD",
        "DE: Sky Sport 4 HD (1080P) (Match Time)",
        "DE: Sky Sport 4 HD (720P) (Match Time)",
        "DE: Sky Sport 4 SD",
        "DE: Sky Sport 5 HD (1080P) (Match Time)",
        "DE: Sky Sport 5 HD (720P) (Match Time)",
        "DE: Sky Sport 5 SD",
        "DE: Sky Sport 6 HD (1080P) (Match Time)",
        "DE: Sky Sport 6 HD (720P) (Match Time)",
        "DE: Sky Sport 6 SD",
        "DE: Sky Sport 7 HD (1080P) (Match Time)",
        "DE: Sky Sport 7 HD (720P) (Match Time)",
        "DE: Sky Sport 7 SD",
        "DE: Sky Sport 8 HD (1080P) (Match Time)",
        "DE: Sky Sport 8 HD (720P) (Match Time)",
        "DE: Sky Sport 8 SD",
        "DE: Sky Sport 9 HD (1080P) (Match Time)",
        "DE: Sky Sport 9 HD (720P) (Match Time)",
        "DE: Sky Sport 9 SD",
        "DE: Sky Sport 10 HD (1080P) (Match Time)",
        "DE: Sky Sport 10 HD (720P) (Match Time)",
        "DE: Sky Sport 10 SD",
        "DE: Sky Sport Austria 1 HD (1080P)",
        "DE: Sky Sport Austria 1 HD (720P)",
        "DE: Sky Sport Austria 1 SD",
        "DE: Sky Sport Austria 2 HD (1080P)",
        "DE: Sky Sport Austria 2 HD (720P)",
        "DE: Sky Sport Austria 2 SD",
        "DE: Sky Sport Austria 3 HD (1080P)",
        "DE: Sky Sport Austria 3 HD (720P)",
        "DE: Sky Sport F1 HD (1080P)",
        "DE: Sky Sport F1 HD (720P)",
        "DE: Dazn 1 Bar HD (1080P)",
        "DE: Dazn 1 Bar HD (720P)",
        "DE: Dazn 2 Bar HD (1080P)",
        "DE: Dazn 2 Bar HD (720P)",
        "DE: Eurosport 1 HD (1080P)",
        "DE: Eurosport 1 HD (720P)",
        "DE: Eurosport 1 SD",
        "DE: Eurosport 2 HD (1080P)",
        "DE: Eurosport 2 HD (720P)",
        "DE: Eurosport 2 SD",
        "DE: Eurosport 2 Xtra HD (1080P)",
        "DE: Eurosport 2 Xtra HD (720P)",
        "DE: Eurosport 2 Xtra SD",
        "DE: Eurosport360 1 FHD",
        "DE: Eurosport360 2 FHD",
        "DE: Eurosport360 3 FHD",
        "DE: Eurosport360 4 FHD",
        "DE: Eurosport360 5 FHD",
        "DE: Eurosport360 6 FHD",
        "DE: Eurosport360 7 FHD",
        "DE: Eurosport360 8 FHD",
        "DE: Eurosport360 9 FHD",
        "DE: eSport HD (1080P)",
        "DE: Sport1 HD (1080P)",
        "DE: Sport1 HD (720P)",
        "DE: Sport1 SD",
        "DE: Sport1+ HD (720P)",
        "DE: Sportdigital HD (1080P)",
        "DE: Auto Motor Sport HD",
        "AT: ORF Sport +",
        "DE: PDCTV 1 HD",
        "DE: PDCTV 2 HD",
        "DE: PDCTV 3 HD",
        "DE: Bayern FC TV (1080P)",
        "DE: Sky Sport Bundesliga 1 HD (1080P)",
        "DE: Sky Sport Bundesliga 1 HD (720P)",
        "DE: Sky Sport Bundesliga 1 SD",
        "DE: Sky Sport Bundesliga 2 HD (1080P) (Match Time)",
        "DE: Sky Sport Bundesliga 2 HD (720P) (Match Time)",
        "DE: Sky Sport Bundesliga 2 SD (Match Time)",
        "DE: Sky Sport Bundesliga 3 HD (1080P) (Match Time)",
        "DE: Sky Sport Bundesliga 3 HD (720P) (Match Time)",
        "DE: Sky Sport Bundesliga 3 SD (Match Time)",
        "DE: Sky Sport Bundesliga 4 HD (1080P) (Match Time)",
        "DE: Sky Sport Bundesliga 4 HD (720P) (Match Time)",
        "DE: Sky Sport Bundesliga 4 SD (Match Time)",
        "DE: Sky Sport Bundesliga 5 HD (1080P) (Match Time)",
        "DE: Sky Sport Bundesliga 5 HD (720P) (Match Time)",
        "DE: Sky Sport Bundesliga 5 SD (Match Time)",
        "DE: Sky Sport Bundesliga 6 HD (1080P) (Match Time)",
        "DE: Sky Sport Bundesliga 6 HD (720P) (Match Time)",
        "DE: Sky Sport Bundesliga 6 SD (Match Time)",
        "DE: Sky Sport Bundesliga 7 HD (1080P) (Match Time)",
        "DE: Sky Sport Bundesliga 7 HD (720P) (Match Time)",
        "DE: Sky Sport Bundesliga 7 SD (Match Time)",
        "DE: Sky Sport Bundesliga 8 HD (1080P) (Match Time)",
        "DE: Sky Sport Bundesliga 8 HD (720P) (Match Time)",
        "DE: Sky Sport Bundesliga 8 SD (Match Time)",
        "DE: Sky Sport Bundesliga 9 HD (1080P) (Match Time)",
        "DE: Sky Sport Bundesliga 9 SD (Match Time)",
        "DE: Sky Sport Bundesliga 10 HD (1080P) (Match Time)",
        "DE: Sky Sport Bundesliga 10 SD (Match Time)",
        "DE: Telekom Fußball 1 HD",
        "DE: Telekom Fußball 2 HD",
        "DE: Telekom Fußball 3 HD",
        "DE: Telekom Fußball 4 HD",
        "DE: Telekom Fußball 5 HD",
        "DE: Telekom Fußball 6 HD",
        "DE: Telekom Fußball 7 HD",
        "DE: Telekom Basketball 1 HD",
        "DE: Telekom Basketball 2 HD",
        "DE: Telekom Basketball 3 HD",
        "DE: Telekom Basketball 4 HD",
        "DE: Telekom Basketball 5 HD",
        "DE: Telekom Basketball 6 HD",
        "DE: Telekom Basketball 7 HD",
        "DE: Telekom Basketball 8 HD",
        "DE: Telekom Basketball 9 HD",
        "DE: Telekom Eishockey 1 HD",
        "DE: Telekom Eishockey 2 HD",
        "DE: Telekom Eishockey 3 HD",
        "DE: Telekom Eishockey 4 HD",
        "DE: Telekom Eishockey 5 HD",
        "DE: Telekom Eishockey 6 HD",
        "DE: Telekom Eishockey 7 HD",
        "DE: Telekom Eishockey 8 HD",
        "DE: FLYERALARM Frauen-Bundesliga 1 HD",
        "DE: FLYERALARM Frauen-Bundesliga 2 HD",
        "DE: FLYERALARM Frauen-Bundesliga 3 HD",
        "DE: FLYERALARM Frauen-Bundesliga 4 HD",
        "DE: FLYERALARM Frauen-Bundesliga 5 HD",
        "DE: FLYERALARM Frauen-Bundesliga 6 HD",
        "DE: FLYERALARM Frauen-Bundesliga 7 HD",
        "DE: FLYERALARM Frauen-Bundesliga 8 HD",
        "DE: Nat Geo HD (1080P)",
        "DE: Nat Geo HD (720P)",
        "DE: Nat Geo Wild HD (1080P)",
        "DE: Nat Geo Wild HD (720P)",
        "DE: Animal Planet HD (1080P)",
        "DE: Animal Planet HD (720P)",
        "DE: History HD (1080P)",
        "DE: Doxx HD (1080P)",
        "DE: Doxx HD (720P)",
        "DE: History HD (720P)",
        "DE: Discovery HD (1080P)",
        "DE: Discovery HD (720P)",
        "DE: Crime+ Investigation HD",
        "DE: Crime+ Investigation",
        "DE: Dmax HD (1080P)",
        "DE: Dmax HD (720P)",
        "DE: Kabel eins Doku HD (1080P)",
        "DE: Kabel eins Doku HD (720P)",
        "DE: Kabel eins Doku SD",
        "DE: N24 Doku HD (1080P)",
        "DE: N24 Doku HD (720P)",
        "DE: N24 Doku",
        "DE: SPIEGEL Geschichte HD (1080P)",
        "DE: SPIEGEL TV Wissen HD (720P)",
        "DE: SPIEGEL TV Wissen",
        "DE: Welt HD (1080P)",
        "DE: Welt HD (720P)",
        "DE: Welt der Wunder TV",
        "DE: HGTV",
        "DE: Arte HD (1080P)",
        "DE: Arte HD (720P)",
        "DE: Phoenix FHD (1080P)",
        "DE: Phoenix HD (720P)",
        "DE: Phoenix",
        "DE: Motorvision TV HD (1080P)",
        "DE: Motorvision TV HD (720P)",
        "DE: Motorvision TV",
        "DE: 3Sat HD",
        "DE: 3Sat",
        "DE: ARD Alpha HD",
        "DE: Disney Channel HD (1080P)",
        "DE: Disney Channel HD (720P)",
        "DE: Disney Channel SD",
        "DE: Disney Junior HD (1080P)",
        "DE: Disney Junior HD (720P)",
        "DE: Disney Junior SD",
        "DE: Cartoon Network",
        "DE: Nickelodeon HD",
        "DE: Nick JR",
        "DE: Boomerang",
        "DE: Nickelodeon (AT)",
        "DE: KIKA HD (1080P)",
        "DE: KIKA",
        "DE: Super RTL HD (1080P)",
        "DE: Super RTL SD",
        "DE: Toggo Plus",
        "DE: Sky Select Premiere 1 FHD",
        "DE: Sky Select Premiere 2 FHD",
        "DE: Sky Select Premiere 3 FHD",
        "DE: Sky Select Premiere 4 FHD",
        "DE: Sky Select Premiere 5 FHD",
        "DE: Sky Select Premiere 6 FHD",
        "DE: Eagle Cinema 007",
        "DE: Eagle Cinema Aktion HD",
        "DE: Eagle Cinema Drama HD",
        "DE: Eagle Cinema Geschichte HD",
        "DE: Eagle Cinema Romantisch HD",
        "DE: Eagle Cinema Sci-Fi HD",
        "DE: Eagle Cinema Premiere HD",
        "DE: Eagle Cinema Thriller HD",
        "DE: Eagle Cinema Horror HD",
        "DE: Sky Select 1 HD",
        "DE: Sky Select 2 HD",
        "DE: Sky Select 3 HD",
        "DE: Sky Select 4 HD",
        "DE: Sky Select 5 HD",
        "DE: Sky Select 6 HD",
        "DE: MDR HD",
        "DE: MDR Sachsen HD",
        "DE: NDR Niedersachen",
        "DE: NDR Fernsehen HD",
        "DE: NDR Hamburg HD",
        "DE: HR Fernsehen HD (1080P)",
        "DE: HR Fernsehen HD (720P)",
        "DE: BR Fernsehen Nord",
        "DE: BR Fernsehen Sued",
        "DE: NOA 4 Hamburg",
        "DE: NOA 4 Norderstedt",
        "DE: Baden TV",
        "DE: Rbb HD",
        "DE: Munchen TV",
        "DE: NRWision HD",
        "DE: Niederbayern TV",
        "DE: RNF",
        "DE: MTV HD (1080P)",
        "DE: Deluxe Music HD (1080P)",
        "DE: JukeBox TV",
        "DE: Classica",
        "DE: Bibel TV Musik",
        "DE: 1.2.3 TV",
        "DE: Sonnenklar HD",
        "DE: Bibel TV",
        "DE: Bibel TV Impuls",
        "DE: HSE24",
        "DE: HSE24 Extra",
    ],
    [
        "ES: LA 1  HD (1080P)",
        "ES: LA 1 HD (720P)",
        "ES: LA 1 SD",
        "ES: La 1 Catalunya",
        "ES: LA 2 HD (1080P)",
        "ES: LA 2 HD (720P)",
        "ES: LA 2",
        "ES: Antena 3 FHD (Multi-Audio)",
        "ES: Antena 3 HD (1080P)",
        "ES: Antena 3 HD (720P)",
        "ES: Antena 3 SD",
        "ES: Antena 3 Internacional",
        "ES: Cuatro FHD (Multi-Audio)",
        "ES: Cuatro HD (1080P)",
        "ES: Cuatro HD (720P)",
        "ES: Cuatro SD",
        "ES: Telecinco FHD (Multi-Audio)",
        "ES: Telecinco HD (1080P)",
        "ES: Telecinco HD (720P)",
        "ES: Telecinco SD",
        "ES: La Sexta FHD (Multi-Audio)",
        "ES: La Sexta HD (1080P)",
        "ES: La Sexta HD (720P)",
        "ES: La Sexta SD",
        "ES: Mega HD (1080P)",
        "ES: Mega HD (720P)",
        "ES: Mega SD",
        "ES: Nova HD (1080P)",
        "ES: Nova (720P)",
        "ES: Nova",
        "ES: Neox HD (1080P)",
        "ES: Neox HD (720P)",
        "ES: Neox",
        "ES: Energy",
        "ES: Divinity",
        "ES: Star TVE HD (1080P)",
        "ES: Star TVE",
        "ES: Clan HD (1080P)",
        "ES: Clan HD (720P)",
        "ES: Clan",
        "ES: TVE Internacional",
        "ES: BeMad TV HD (1080P)",
        "ES: BeMad TV HD (720P)",
        "ES: BeMad TV",
        "ES: Gol HD (1080P)",
        "ES: Gol HD (720P)",
        "ES: Gol",
        "ES: Teledeporte HD (1080P)",
        "ES: Teledeporte HD (720P)",
        "ES: Teledeporte SD",
        "ES: Paramount Channel",
        "ES: Boing HD",
        "ES: A3series HD (1080P)",
        "ES: A3series HD (720P)",
        "ES: A3series SD",
        "ES: FDF HD",
        "ES: Somos HD (1080P)",
        "ES: Somos",
        "ES: TEN",
        "ES: Televisa HD",
        "ES: intereconomia",
        "ES: Dmax HD",
        "ES: Dmax",
        "ES: 24h HD",
        "ES: 24h",
        "ES: Fibracat TV FHD",
        "ES: 24h Catalunya",
        "ES: Mitele Eventos",
        "ES: Telesur HD",
        "ES: Trece",
        "ES: Canal Costa TV",
        "ES: 7 TV Murcia HD (1080P)",
        "ES: 7 TV Murcia",
        "ES: Condavisión HD",
        "ES: Canal Parlamento",
        "ES: mtmad 24h",
        "ES: La Voz 24h",
        "ES: À Punt HD",
        "ES: 7 TeleValencia",
        "ES: Canal Málaga",
        "ES: TV Benavente HD",
        "ES: Onda Algeciras TV",
        "ES: Onda Cádiz",
        "ES: Hamaika TV",
        "ES: TeleBilbao",
        "ES: TVG HD",
        "ES: TVG 2 HD",
        "ES: TVG Infantil HD",
        "ES: TVG Musigal HD",
        "ES: TVG Cultura HD",
        "ES: TVG Momentos G HD",
        "ES: TVG América HD",
        "ES: TVG Eventos 1 HD",
        "ES: TVG Eventos 2 HD",
        "ES: TVG Eventos 3 HD",
        "ES: Tenerife TV HD",
        "ES: PTV Sevilla",
        "ES: PTV Málaga",
        "ES: PTV Granada",
        "ES: InterAlmería HD",
        "ES: 8TV Cádiz",
        "ES: Alcarria TV",
        "ES: Canal Diocesano Toledo",
        "ES: Cancionero TV",
        "ES: Bon Dia TV",
        "ES: Imás TV HD",
        "ES: Teleonuba TV",
        "ES: CCMA Exclusiu 1",
        "ES: CCMA Exclusiu 2",
        "ES: Visión 6",
        "ES: La 7 León",
        "ES: La 8 Zamora",
        "ES: La 8 Valladolid",
        "ES: La 8 Bierzo",
        "ES: La 8 Burgos",
        "ES: La 8 Ávila",
        "ES: M. Sagas",
        "ES: M. Accion FHD (Multi-Audio)",
        "ES: M. Accion HD (1080P)",
        "ES: M. Accion HD (720P)",
        "ES: M. Accion SD",
        "ES: M. Comedia FHD (Multi-Audio)",
        "ES: M. Comedia HD (1080P)",
        "ES: M. Comedia HD (720P)",
        "ES: M. Comedia SD",
        "ES: M. Estrenos FHD (Multi-Audio)",
        "ES: M. Estrenos HD (1080P)",
        "ES: M. Estrenos HD (720P)",
        "ES: M. Estrenos SD",
        "ES: M. Estrenos 2 HD (1080P)",
        "ES: M. Estrenos 2 HD (720P)",
        "ES: M. Estrenos 2 SD",
        "ES: M. Drama FHD (Multi-Audio)",
        "ES: M. Drama HD (1080P)",
        "ES: M. Drama HD (720P)",
        "ES: M. Drama SD",
        "ES: M. Cláscios FHD (Multi-Audio)",
        "ES: M. Cláscios HD (1080P)",
        "ES: M. Cláscios HD (720P)",
        "ES: M. Cláscios SD",
        "ES: M. Cine N",
        "ES: TCM FHD (Multi-Audio)",
        "ES: TCM HD (1080P)",
        "ES: TCM HD (720P)",
        "ES: TCM SD",
        "ES: Hollywood FHD (Multi-Audio)",
        "ES: Hollywood HD (1080P)",
        "ES: Hollywood HD (720P)",
        "ES: Hollywood SD",
        "ES: Sundance TV HD (1080P)",
        "ES: Sundance TV HD (720P)",
        "ES: Sundance TV",
        "ES: Canal Dark HD (1080P)",
        "ES: Dark Red HD",
        "ES: Dark Red SD",
        "ES: XTRM HD (1080P)",
        "ES: XTRM",
        "ES: BOM Cine",
        "ES: MyTime movie network FHD",
        "ES: Runtime HD",
        "ES: Sofy TV",
        "ES: Comedy Dynamics FHD",
        "ES: Rakuten 1",
        "ES: Rakuten 2",
        "ES: Rakuten 3",
        "ES: La Resistencia HD (1080P)",
        "ES: La Resistencia HD (720P)",
        "ES: La Resistencia SD",
        "ES: M. Series FHD (Multi-Audio)",
        "ES: M. Series HD (1080P)",
        "ES: M. Series HD (720P)",
        "ES: M. Series SD",
        "ES: M. Series 2 FHD (Multi-Audio)",
        "ES: M. Series 2 HD (1080P)",
        "ES: M. Series 2 HD (720P)",
        "ES: M. Series 2 SD",
        "ES: M. Crimen SD",
        "ES: #0 FHD (Multi-Audio)",
        "ES: #0 HD (1080P)",
        "ES: #0 HD (720P)",
        "ES: #0 SD",
        "ES: Calle 13 FHD (Multi-Audio)",
        "ES: Calle 13 HD (1080P)",
        "ES: Calle 13 HD (720P)",
        "ES: Calle 13",
        "ES: Cosmo TV FHD (Multi-Audio)",
        "ES: Cosmo TV HD (1080P)",
        "ES: Cosmo TV HD (720P)",
        "ES: Cosmo TV SD",
        "ES: AMC FHD (Multi-Audio)",
        "ES: AMC HD (1080P)",
        "ES: AMC HD (720P)",
        "ES: AMC SD",
        "ES: Fox FHD (Multi-Audio)",
        "ES: Fox HD (1080P)",
        "ES: Fox HD (720P)",
        "ES: Fox SD",
        "ES: AXN FHD (Multi-Audio)",
        "ES: AXN HD (1080P)",
        "ES: AXN HD (720P)",
        "ES: AXN SD",
        "ES: AXN White FHD (Multi-Audio)",
        "ES: AXN White HD (1080P)",
        "ES: AXN White HD (720P)",
        "ES: AXN White SD",
        "ES: TNT FHD (Multi-Audio)",
        "ES: TNT HD (1080P)",
        "ES: TNT HD (720P)",
        "ES: TNT SD",
        "ES: SyFy FHD (Multi-Audio)",
        "ES: SyFy HD (1080P)",
        "ES: SYFY HD (720P)",
        "ES: SyFy SD",
        "ES: Comedy Central FHD (Multi-Audio)",
        "ES: Comedy Central HD (1080P)",
        "ES: Comedy Central HD (720P)",
        "ES: Comedy Central SD",
        "ES: MTV Espana",
        "ES: National Geoghraphic FHD (Multi-Audio)",
        "ES: National Geoghraphic HD (1080P)",
        "ES: National Geoghraphic HD (720P)",
        "ES: National Geoghraphic SD",
        "ES: Nat Geo Wild FHD (Multi-Audio)",
        "ES: Nat Geo Wild HD (1080P)",
        "ES: Nat Geo Wild HD (720P)",
        "ES: Nat Geo Wild SD",
        "ES: Discovery Channel HD (1080P)",
        "ES: Discovery Channel HD (720P)",
        "ES: Discovery Channel SD",
        "ES: Odisea 4K",
        "ES: Odisea HD (1080P)",
        "ES: Odisea HD (720P)",
        "ES: Odisea SD",
        "ES: Historia HD (1080P)",
        "ES: Historia HD (720P)",
        "ES: Historia SD",
        "ES: Cazavisión HD (720P)",
        "ES: Cazavisión",
        "ES: Blaze HD",
        "ES: Blaze",
        "ES: Crime & Investigation",
        "ES: Canal Cocina 4K",
        "ES: Canal Cocina HD (1080P)",
        "ES: Canal Cocina HD (720P)",
        "ES: Canal Cocina",
        "ES: Decasa HD (1080P)",
        "ES: Decasa",
        "ES: Dkiss",
        "ES: El Garaje TV",
        "ES: Nickelodeon FHD (Multi-Audio)",
        "ES: Nickelodeon HD (1080P)",
        "ES: Nickelodeon HD (720P)",
        "ES: Nickelodeon SD",
        "ES: Nick JR",
        "ES: Disney Junior",
        "ES: Disney Channel FHD (Multi-Audio)",
        "ES: Disney Channel HD (1080P)",
        "ES: Disney Channel HD (720P)",
        "ES: Disney Channel SD",
        "ES: DreamWorks HD (1080P)",
        "ES: DreamWorks HD (720P)",
        "ES: DreamWorks SD",
        "ES: Canal Panda HD (1080P)",
        "ES: Canal Panda",
        "ES: Baby TV",
        "ES: Super3",
        "ES: Pequeradio TV",
        "ES: TV3 HD (1080P)",
        "ES: TV3 HD (720P)",
        "ES: TV3",
        "ES: TV3. CAT",
        "ES: Betevé CAT",
        "ES: 3/24 CAT FHD",
        "ES: 33 CAT",
        "ES: teve.cat HD",
        "ES: Canal Sur HD",
        "ES: Canal Sur Andalucía HD (1080P)",
        "ES: Canal Sur 2 HD",
        "ES: TeleMadrid HD (1080P)",
        "ES: TeleMadrid HD (720P)",
        "ES: TeleMadrid",
        "ES: Euronews HD",
        "ES: DW en Español",
        "ES: Hispan TV",
        "ES: ETB 1 HD",
        "ES: ETB 2 HD",
        "ES: ETB Sat",
        "ES: Levante TV",
        "ES: Galicia TV",
        "ES: Extremadura TV",
        "ES: Aragon TV",
        "ES: Castilla La Mancha Media",
        "ES: Lancelot TV",
        "ES: La 8 Mediterráneo HD",
        "ES: La Otra",
        "ES: NORTEvisión",
        "ES: M. Fest HD (1080P)",
        "ES: M. Fest HD (720P)",
        "ES: M. Fest SD",
        "ES: Sol Music",
        "ES: VH1",
        "ES: VH1 Classic",
        "ES: Cadena Elite",
        "ES: HIT TV",
        "ES: Mezzo Live HD (1080P)",
        "ES: Mezzo Live HD (720P)",
        "ES: Mezzo",
        "ES: Classica",
        "ES: Caza y Pesca FHD (Multi-Audio)",
        "ES: Caza y Pesca HD (1080P)",
        "ES: Caza y Pesca HD (720P)",
        "ES: Caza y Pesca SD",
        "ES: Toros FHD (Multi-Audio)",
        "ES: Toros HD (1080P)",
        "ES: Toros HD (720P)",
        "ES: Toros",
        "ES: Iberalia FHD",
        "ES: Iberalia",
        "ES: Iberalia 100%Caza HD (1080P)",
        "ES: Iberalia 100%Caza HD (720P)",
        "ES: Iberalia 100%Caza",
        "ES: Iberalia 100%Pesca HD (1080P)",
        "ES: Iberalia 100%Pesca HD (720P)",
        "ES: Iberalia 100%Pesca",
        "ES: Secret Story 24h",
        "ES: Movistar Alquiler 1",
        "ES: Movistar Alquiler 2",
        "ES: Movistar Alquiler 3",
        "ES: Movistar Alquiler 4",
        "ES: Movistar Alquiler 5",
        "ES: Movistar Alquiler 6",
        "ES: Movistar Alquiler 7",
        "ES: Movistar Alquiler 8",
        "ES: Movistar Alquiler 9",
        "ES: Movistar Alquiler 10",
        "ES: Movistar Alquiler 11",
        "ES: Movistar Alquiler 12",
    ],
    [
        "ES: Movistar+ Portada",
        "ES: Multideporte",
        "ES: M Liga de Campeones FHD (H.265)",
        "ES: M Liga de Campeones FHD (Multi-Audio)",
        "ES: M Liga de Campeones HD (1080P)",
        "ES: M Liga de Campeones HD (1080P) OP1",
        "ES: M Liga de Campeones HD (1080P) OP2",
        "ES: M Liga de Campeones HD (1080P) OP3",
        "ES: M Liga de Campeones HD (720P)",
        "ES: M Liga de Campeones HD (720P) OP1",
        "ES: M Liga de Campeones HD (720P) OP2",
        "ES: M Liga de Campeones SD",
        "ES: M Liga de Campeones SD (OP1)",
        "ES: M Liga de Campeones 1 FHD (H.265)",
        "ES: M Liga de Campeones 1 FHD (H.265) OP1",
        "ES: M Liga de Campeones 1 FHD (Multi-Audio)",
        "ES: M Liga de Campeones 1 FHD (Solo Eventos)",
        "ES: M Liga de Campeones 1 FHD (Solo Eventos) OP1",
        "ES: M Liga de Campeones 1 FHD (Solo Eventos) OP2",
        "ES: M Liga de Campeones 1 HD (Solo Eventos)",
        "ES: M Liga de Campeones 1 SD (Solo Eventos)",
        "ES: M Liga de Campeones 2 FHD (Multi-Audio)",
        "ES: M Liga de Campeones 2 FHD (Solo Eventos)",
        "ES: M Liga de Campeones 2 FHD (Solo Eventos) OP1",
        "ES: M Liga de Campeones 2 HD (Solo Eventos)",
        "ES: M Liga de Campeones 2 SD (Solo Eventos)",
        "ES: M Liga de Campeones 3 HD (Solo Eventos)",
        "ES: M Liga de Campeones 3 SD (Solo Eventos)",
        "ES: M Liga de Campeones 4 HD (Solo Eventos)",
        "ES: M Liga de Campeones 4 SD (Solo Eventos)",
        "ES: M Liga de Campeones 5 HD (Solo Eventos)",
        "ES: M Liga de Campeones 6 HD (Solo Eventos)",
        "ES: M Liga de Campeones 7 HD (Solo Eventos)",
        "ES: M Liga de Campeones 8 HD (Solo Eventos)",
        "ES: M Liga de Campeones 9 (Solo Eventos)",
        "ES: M Liga de Campeones 10 (Solo Eventos)",
        "ES: M Liga de Campeones 11 (Solo Eventos)",
        "ES: M Liga de Campeones 12 (Solo Eventos)",
        "ES: M Liga de Campeones 13 (Solo Eventos)",
        "ES: M Liga de Campeones 14 (Solo Eventos)",
        "ES: M Liga de Campeones 15 (Solo Eventos)",
        "ES: M Liga de Campeones 16 (Solo Eventos)",
        "ES: M. LaLiga FHD (H.265)",
        "ES: M. LaLiga FHD (Multi-Audio)",
        "ES: M. LaLiga HD (1080P)",
        "ES: M. LaLiga HD (1080P) OP1",
        "ES: M. LaLiga HD (1080P) OP2",
        "ES: M. LaLiga HD (1080P) OP3",
        "ES: M. LaLiga HD (720P)",
        "ES: M. LaLiga HD (720P) OP1",
        "ES: M. LaLiga SD",
        "ES: M. LaLiga SD (OP1)",
        "ES: M. LaLiga 1 FHD (H.265)",
        "ES: M. LaLiga 1 FHD (Multi-Audio)",
        "ES: M. LaLiga 1 FHD (Solo Eventos)",
        "ES: M. LaLiga 1 FHD (Solo Eventos) OP1",
        "ES: M. LaLiga 1 HD (720P) (Solo Eventos)",
        "ES: M. LaLiga 1 SD (Solo Eventos)",
        "ES: M. LaLiga 2 FHD (H.265)",
        "ES: M. LaLiga 2 FHD (Multi-Audio)",
        "ES: M. LaLiga 2 FHD (Solo Eventos)",
        "ES: M. LaLiga 2 HD (720P) (Solo Eventos)",
        "ES: M. LaLiga 2 (Solo Eventos)",
        "ES: M. LaLiga 3 FHD (H.265)",
        "ES: M. LaLiga 3 FHD (Multi-Audio)",
        "ES: M. LaLiga 3 FHD (Solo Eventos)",
        "ES: M. LaLiga 3 HD (720P) (Solo Eventos)",
        "ES: M. LaLiga 3 (Solo Eventos)",
        "ES: M. LaLiga 4 FHD (Solo Eventos)",
        "ES: M. LaLiga 4 HD (720P) (Solo Eventos)",
        "ES: M. LaLiga 4 (Solo Eventos)",
        "ES: M. LaLiga 5 FHD (Solo Eventos)",
        "ES: M. LaLiga 5 HD (720P) (Solo Eventos)",
        "ES: M. LaLiga 5 (Solo Eventos)",
        "ES: M. LaLiga 6 (Solo Eventos)",
        "ES: M. LaLiga 7 (Solo Eventos)",
        "ES: M. LaLiga 8 (Solo Eventos)",
        "ES: M. LaLiga 9 (Solo Eventos)",
        "ES: M. LaLiga 10 (Solo Eventos)",
        "ES: LaLiga TV Bar FHD (H.265)",
        "ES: LaLiga TV Bar FHD (Multi-Audio)",
        "ES: LaLiga TV Bar HD (1080P)",
        "ES: LaLiga TV Bar HD (720P)",
        "ES: LaLiga TV Bar SD",
        "ES: Real Madrid HD (1080P)",
        "ES: Real Madrid SD",
        "ES: Barca TV HD (1080P)",
        "ES: Barca TV",
        "ES: Sevilla FC",
        "ES: Betis TV",
        "ES: Esport 3 CAT",
    ],
    [
        "ES: M. Dazn 1 FHD (H.265)",
        "ES: M. Dazn 1 HD (1080P)",
        "ES: M. Dazn 1 HD (720P)",
        "ES: M. Dazn 1 SD",
        "ES: M. Dazn 2 FHD (H.265)",
        "ES: M. Dazn 2 HD (1080P)",
        "ES: M. Dazn 2 HD (720P)",
        "ES: M. Dazn 2 SD",
        "ES: M. Dazn 3 FHD (H.265)",
        "ES: M. Dazn 3 FHD (1080P)",
        "ES: M. Dazn 3 HD (720P)",
        "ES: M. Dazn 3 SD",
        "ES: M. Dazn 4 FHD (H.265)",
        "ES: M. Dazn 4 FHD (1080P)",
        "ES: M. Dazn 4 HD (720P)",
        "ES: M. Dazn 4 SD",
        "ES: M. Dazn F1 FHD (H.265)",
        "ES: M. Dazn F1 FHD (Multi-Audio)",
        "ES: M. Dazn F1 HD (1080P)",
        "ES: M. Dazn F1 HD (720P)",
        "ES: M. Dazn F1 SD",
        "ES: M. Golf FHD (H.265)",
        "ES: M. Golf FHD (Multi-Audio)",
        "ES: M. Golf HD (1080P)",
        "ES: M. Golf HD (720P)",
        "ES: M. Golf SD",
        "ES: #Vamos FHD (H.265)",
        "ES: #Vamos FHD (Multi-Audio)",
        "ES: #Vamos HD (1080P)",
        "ES: #Vamos HD (720P)",
        "ES: #Vamos SD",
        "ES: M. Deportes FHD (H.265)",
        "ES: M. Deportes FHD (Multi-Audio)",
        "ES: M. Deportes FHD (1080P)",
        "ES: M. Deportes HD (720P)",
        "ES: M. Deportes SD",
        "ES: M. Deportes 1 FHD (H.265)",
        "ES: M. Deportes 1 FHD (Multi-Audio)",
        "ES: M. Deportes 1 FHD (Solo Eventos)",
        "ES: M. Deportes 1 HD (Solo Eventos)",
        "ES: M. Deportes 1 (Solo Eventos)",
        "ES: M. Deportes 2 (Solo Eventos)",
        "ES: M. Deportes 3 (Solo Eventos)",
        "ES: M. Deportes 4 (Solo Eventos)",
        "ES: M. Deportes 5 (Solo Eventos)",
        "ES: M. Deportes 6 (Solo Eventos)",
        "ES: M. Deportes 7 (Solo Eventos)",
        "ES: M. Deportes 8 (Solo Eventos)",
        "ES: Eurosport 1 FHD (Multi-Audio)",
        "ES: Eurosport 1 FHD (H.265)",
        "ES: Eurosport 1 HD (1080P)",
        "ES: Eurosport 1 HD (720P)",
        "ES: Eurosport 1 SD",
        "ES: Eurosport 2 FHD (H.265)",
        "ES: Eurosport 2 HD (1080P)",
        "ES: Eurosport 2 HD (720P)",
        "ES: Eurosport 2 SD",
        "ES: Eurosport 3 HD (720P)",
        "ES: Eurosport 3 HD (720P)",
        "ES: Eurosport 4 HD (720P)",
        "ES: Eurosport 5 HD (720P)",
        "ES: Eurosport 6 HD (720P)",
        "ES: Eurosport 7 HD (720P)",
        "ES: Eurosport 8 HD (720P)",
        "ES: U-Beat FHD",
        "ES: Dazn Evento 1",
        "ES: Dazn Evento 2",
        "ES: Dazn Evento 3",
    ],
    [
        "FR: TF1 FHD (H.265)",
        "FR: TF1 HD (1080P)",
        "FR: TF1 HD",
        "FR: TF1 SD",
        "FR: TF1 +1 HD",
        "FR: M6 FHD (H.265)",
        "FR: M6 HD (1080P)",
        "FR: M6 HD",
        "FR: M6 SD",
        "FR: France 2 FHD (H.265)",
        "FR: France 2 HD (1080P)",
        "FR: France 2 HD",
        "FR: France 2 SD",
        "FR: France 3 FHD (H.265)",
        "FR: France 3 HD (1080P)",
        "FR: France 3 HD",
        "FR: France 3 SD",
        "FR: France 3 NOA HD (1080P)",
        "FR: France 4 FHD (H.265)",
        "FR: France 4 HD (1080P)",
        "FR: France 4 HD",
        "FR: France 4 SD",
        "FR: France 5 FHD (H.265)",
        "FR: France 5 HD (1080P)",
        "FR: France 5 HD",
        "FR: France 5 SD",
        "FR: TV5 Monde Europe HD (1080P)",
        "FR: TV5 Monde Europe",
        "FR: TV5 Monde Style",
        "FR: Paris Premiere HD (1080P)",
        "FR: Paris Premiere HD",
        "FR: Paris Premiere SD",
        "FR: 6Ter FHD (H.265)",
        "FR: 6ter HD (1080P)",
        "FR: 6ter HD",
        "FR: 6ter SD",
        "FR: Arte FHD (H.265)",
        "FR: Arte HD (1080P)",
        "FR: Arte HD",
        "FR: ARTE SD",
        "FR: NRJ12 FHD (H.265)",
        "FR: NRJ 12 HD (1080P)",
        "FR: NRJ 12 HD",
        "FR: NRJ 12 SD",
        "FR: TFX FHD (H.265)",
        "FR: TFX HD (1080P)",
        "FR: TFX HD",
        "FR: TFX SD",
        "FR: KTO",
        "FR: TV Breizh FHD (H.265)",
        "FR: TV Breizh HD (1080P)",
        "FR: TV Breizh HD",
        "FR: TV Breizh SD",
        "FR: Canal Alpha JU HD (1080P)",
        "FR: TV8 Mont Blanc HD (1080P)",
        "FR: Clique TV HD (1080P)",
        "FR: Weo TV HD (1080P)",
        "FR: 01 NET TV",
        "FR: A+ HD",
        "FR: ES1",
        "FR: La Chaine Normande",
        "FR: Mirabelle TV",
        "FR: NoTele",
        "FR: Francophonie 24",
        "FR: Grand Lille TV",
        "FR: Télé Grenoble",
        "FR: Bip TV",
        "FR: MATélé",
        "FR: IL TV",
        "FR: TV Vendée",
        "FR: TL7",
        "FR: Tébéo",
        "FR: Télé Nantes",
        "FR: TV7",
        "FR: Amazon Prime 1 FHD",
        "FR: Amazon Prime 2 FHD",
        "FR: Amazon Prime 3 FHD",
        "FR: Amazon Prime 4 FHD",
        "FR: Amazon Prime 5 FHD",
        "FR: Amazon Prime 6 FHD",
        "FR: Amazon Prime 7 FHD",
        "FR: Amazon Prime 8 FHD",
        "FR: Amazon Prime 9 FHD",
        "FR: Amazon Prime 10 FHD",
        "FR: Canal+ Ligue 1 HD (1080P)",
        "FR: Canal+ Premier League HD (1080P)",
        "FR: Canal+ Top 14 Rugby HD (1080P)",
        "FR: Canal+ Formula1 HD (1080P)",
        "FR: Canal+ MotoGP HD (1080P)",
        "FR: BeIN Sports 1 FHD (H.265)",
        "FR: BeIN Sports 1 HD (1080P)",
        "FR: BeIN Sports 1 HD",
        "FR: BeIN Sports 1 SD",
        "FR: BeIN Sports 2 FHD (H.265)",
        "FR: BeIN Sports 2 HD (1080P)",
        "FR: BeIN Sports 2 HD",
        "FR: BeIN Sports 2 SD",
        "FR: BeIN Sports 3 FHD (H.265)",
        "FR: BeIN Sports 3 HD (1080P)",
        "FR: BeIN Sports 3 HD",
        "FR: BeIN Sports 3 SD",
        "FR: BeIN Sports Max 4 HD (1080P)",
        "FR: BeIN Sports Max 4 HD",
        "FR: BeIN Sports Max 5 HD (1080P)",
        "FR: BeIN Sports Max 5 HD",
        "FR: BeIN Sports Max 6 HD (1080P)",
        "FR: BeIN Sports Max 6 HD",
        "FR: BeIN Sports Max 7 HD (1080P)",
        "FR: BeIN Sports Max 7 HD",
        "FR: BeIN Sports Max 8 HD (1080P)",
        "FR: BeIN Sports Max 8 HD",
        "FR: BeIN Sports Max 9 HD (1080P)",
        "FR: BeIN Sports Max 9 HD",
        "FR: BeIN Sports Max 10 HD (1080P)",
        "FR: BeIN Sports Max 10 HD",
        "FR: RMC Sport 1 FHD (H.265)",
        "FR: RMC Sport 1 HD (1080P)",
        "FR: RMC Sport 1 HD",
        "FR: RMC Sport 1 SD",
        "FR: RMC Sport 2 FHD (H.265)",
        "FR: RMC Sport 2 HD (1080P)",
        "FR: RMC Sport 2 HD",
        "FR: RMC Sport 2 SD",
        "FR: RMC Sport Live 5 (1080P)",
        "FR: RMC Sport Live 6 (1080P)",
        "FR: RMC Sport Live 7 (1080P)",
        "FR: RMC Sport Live 8 (1080P)",
        "FR: RMC Sport Live 9 (1080P)",
        "FR: RMC Sport Live 10 (1080P)",
        "FR: RMC Sport Live 11 (1080P)",
        "FR: RMC Sport Live 12 (1080P)",
        "FR: RMC Sport Live 13 (1080P)",
        "FR: RMC Sport Live 14 (1080P)",
        "FR: RMC Sport Live 15 (1080P)",
        "FR: RMC Sport Live 16 (1080P)",
        "FR: Canal+ Sport FHD (H.265)",
        "FR: Canal+ Sport HD (1080P)",
        "FR: Canal+ Sport HD",
        "FR: Eurosport 1 FHD (H.265)",
        "FR: Eurosport 1 HD (1080P)",
        "FR: Eurosport 1 HD",
        "FR: Eurosport 2 FHD (H.265)",
        "FR: Eurosport 2 HD (1080P)",
        "FR: Eurosport 2 HD",
        "FR: Eurosport 360° 3 HD",
        "FR: Eurosport 360° 4 HD",
        "FR: Eurosport 360° 5 HD",
        "FR: Eurosport 360° 6 HD",
        "FR: Eurosport 360° 7 HD",
        "FR: Eurosport 360° 8 HD",
        "FR: Eurosport 360° 9 HD",
        "FR: Eurosport 360° 10 HD",
        "FR: MultiSports 1 HD (1080P)",
        "FR: MultiSports 2 HD (1080P)",
        "FR: MultiSports 3 HD (1080P)",
        "FR: MultiSports 4 HD (1080P)",
        "FR: MultiSports 5 HD (1080P)",
        "FR: MultiSports 6 HD (1080P)",
        "FR: Foot+ 24/24 FHD (H.265)",
        "FR: Foot+ 24/24",
        "FR: InfoSport+ FHD (H.265)",
        "FR: InfoSport+ HD (1080P)",
        "FR: InfoSport+",
        "FR: L'Equipe 21 FHD (H.265)",
        "FR: L'Equipe 21 HD (1080P)",
        "FR: L'Equipe 21 HD",
        "FR: L'Equipe 21 SD",
        "FR: OL TV HD",
        "FR: Sport en France HD",
        "FR: AUTOMOTO LA CHAINE FHD (H.265)",
        "FR: AUTOMOTO LA CHAINE HD (1080P)",
        "FR: AUTOMOTO LA CHAINE HD",
        "FR: AUTOMOTO LA CHAINE",
        "FR: Extreme Sports",
        "FR: Golf Channel France (1080P)",
        "FR: Golf Channel SD",
        "FR: Golf+ HD (1080P)",
        "FR: Golf+ HD",
        "FR: Trace Sport Stars HD",
        "FR: Equidia FHD (H.265)",
        "FR: Equidia Live HD (1080P)",
        "FR: Equidia Live HD",
        "FR: Nautical HD (1080P)",
        "FR: Canal+ FHD (H.265)",
        "FR: Canal+ HD (1080P)",
        "FR: Canal+ HD",
        "FR: Canal+ SD",
        "FR: Canal+ Cinema FHD (H.265)",
        "FR: Canal+ Cinema HD (1080P)",
        "FR: Canal+ Cinema HD",
        "FR: Canal+ Cinema SD",
        "FR: Canal+ Decale FHD (H.265)",
        "FR: Canal+ Decale HD (1080P)",
        "FR: Canal+ Decale HD",
        "FR: Canal+ Family FHD (H.265)",
        "FR: Canal+ Family HD (1080P)",
        "FR: Canal+ Family HD",
        "FR: Canal+ Family SD",
        "FR: Canal+ Series FHD (H.265)",
        "FR: Canal+ Series HD (1080P)",
        "FR: Canal+ Series HD",
        "FR: Canal+ Series SD",
        "FR: Cine+ Premier FHD (H.265)",
        "FR: Cine+ Premier HD (1080P)",
        "FR: Cine+ Premier HD",
        "FR: Cine+ Premier SD",
        "FR: Cine+ Classic FHD (H.265)",
        "FR: Cine+ Classic HD (1080P)",
        "FR: Cine+ Classic HD",
        "FR: Cine+ Classic SD",
        "FR: Cine+ Emotion FHD (H.265)",
        "FR: Cine+ Emotion HD (1080P)",
        "FR: Cine+ Emotion HD",
        "FR: Cine+ Emotion SD",
        "FR: Cine+ Famiz FHD (H.265)",
        "FR: Cine+ Famiz HD (1080P)",
        "FR: Cine+ Famiz HD",
        "FR: Cine+ Famiz SD",
        "FR: Cine+ Frisson FHD (H.265)",
        "FR: Cine+ Frisson HD (1080P)",
        "FR: Cine+ Frisson HD",
        "FR: Cine+ Frisson SD",
        "FR: Cine+ Club FHD (H.265)",
        "FR: Cine+ Club HD (1080P)",
        "FR: Cine+ Club SD",
        "FR: Cine Polar HD (1080P)",
        "FR: Cine Polar",
        "FR: OCS Choc FHD (H.265)",
        "FR: OCS Choc HD (1080P)",
        "FR: OCS Choc HD",
        "FR: OCS City FHD (H.265)",
        "FR: OCS City HD (1080P)",
        "FR: OCS City HD",
        "FR: OCS Geant FHD (H.265)",
        "FR: OCS Geant HD (1080P)",
        "FR: OCS Geant HD",
        "FR: OCS Max FHD (H.265)",
        "FR: OCS Max HD (1080P)",
        "FR: OCS Max HD",
        "FR: Viceland HD (1080P)",
        "FR: TCM Cinema FHD (H.265)",
        "FR: TCM Cinema HD (1080P)",
        "FR: TCM Cinema HD",
        "FR: TCM Cinema SD",
        "FR: Paramount Channel Decale HD (1080P)",
        "FR: Paramount Channel HD (1080P)",
        "FR: Paramount Channel",
        "FR: Paramount Channel SD",
        "FR: Polar+ FHD (H.265)",
        "FR: Polar+ HD (1080P)",
        "FR: Nollywood TV HD (1080P)",
        "FR: National Geographic FHD (H.265)",
        "FR: National Geographic HD (1080P)",
        "FR: National Geographic HD",
        "FR: National Geographic SD",
        "FR: National Geo Wild FHD (H.265)",
        "FR: National Geo Wild HD (1080P)",
        "FR: National Geo Wild HD",
        "FR: National Geo Wild SD",
        "FR: Discovery FHD (H.265)",
        "FR: Discovery HD (1080P)",
        "FR: Discovery Channel HD",
        "FR: Discovery SD",
        "FR: Discovery Science FHD (H.265)",
        "FR: Discovery Science HD (1080P)",
        "FR: Discovery Science HD",
        "FR: Discovery Science SD",
        "FR: Discovery Family FHD (H.265)",
        "FR: Discovery Family HD (1080P)",
        "FR: Discovery Family HD",
        "FR: Discovery Family SD",
        "FR: Discovery Investigation FHD (H.265)",
        "FR: Discovery Investigation HD (1080P)",
        "FR: Discovery Investigation",
        "FR: Discovery Investigation SD",
        "FR: Planete+ FHD (H.265)",
        "FR: Planete+ HD (1080P)",
        "FR: Planete+ HD",
        "FR: Planete+ SD",
        "FR: Planete A&E FHD (H.265)",
        "FR: Planete+ A&E HD (1080P)",
        "FR: Planete+ AE HD",
        "FR: Planete+ AE SD",
        "FR: Planete+ CI FHD (H.265)",
        "FR: Planete+ CI HD (1080P)",
        "FR: Planete+ CI HD",
        "FR: Ushuaia TV FHD (H.265)",
        "FR: Ushuaia TV (1080P)",
        "FR: Ushuaia TV",
        "FR: Ushuaia TV SD",
        "FR: Seasons FHD (H.265)",
        "FR: Seasons HD (1080P)",
        "FR: Seasons HD",
        "FR: Trek HD (1080P)",
        "FR: Trek HD",
        "FR: Trek SD",
        "FR: Histoire FHD (H.265)",
        "FR: Histoire HD (1080P)",
        "FR: Histoire HD",
        "FR: Toute l'histoire FHD (H.265)",
        "FR: Toute l'histoire HD (1080P)",
        "FR: Toute l'histoire",
        "FR: Science et Vie FHD (H.265)",
        "FR: Science et Vie HD (1080P)",
        "FR: Science et Vie HD",
        "FR: Science et Vie SD",
        "FR: RMC Decouverte FHD (H.265)",
        "FR: RMC Decouverte HD (1080P)",
        "FR: RMC Decouverte HD",
        "FR: RMC Decouverte SD",
        "FR: MUSEUM HD (1080P)",
        "FR: Animaux FHD (H.265)",
        "FR: Animaux HD (1080P)",
        "FR: Animaux",
        "FR: Chasse et Peche FHD (H.265)",
        "FR: Chasse et Peche HD (1080P)",
        "FR: Chasse et Peche HD",
        "FR: Chasse et Peche SD",
        "FR: 13ème Rue FHD (H.265)",
        "FR: 13ème Rue HD (1080P)",
        "FR: 13ème RUE HD",
        "FR: 13ème RUE SD",
        "FR: C8 FHD (H.265)",
        "FR: C8 HD (1080P)",
        "FR: C8 HD",
        "FR: C8 SD",
        "FR: CStar FHD (H.265)",
        "FR: CStar HD (1080P)",
        "FR: CStar HD",
        "FR: CStar SD",
        "FR: Warner TV FHD (H.265)",
        "FR: Warner TV (1080P)",
        "FR: Comedie+ FHD (H.265)",
        "FR: Comedie+ HD (1080P)",
        "FR: Comedie+ HD",
        "FR: Comedy Central FHD (H.265)",
        "FR: Comedy Central HD (1080P)",
        "FR: My Comedy TV HD",
        "FR: Serie Club FHD (H.265)",
        "FR: Serie Club HD (1080P)",
        "FR: Serie Club HD",
        "FR: Serie Club SD",
        "FR: W9 FHD (H.265)",
        "FR: W9 HD (1080P)",
        "FR: W9 HD",
        "FR: W9 SD",
        "FR: AB1 FHD (H.265)",
        "FR: AB1 HD (1080P)",
        "FR: AB1 HD",
        "FR: AB1 SD",
        "FR: AB3 FHD (H.265)",
        "FR: AB3 HD (1080P)",
        "FR: Cherie 25 FHD (H.265)",
        "FR: Cherie 25 HD (1080P)",
        "FR: Cherie 25 HD",
        "FR: Cherie 25 SD",
        "FR: TF1 Series Films FHD (H.265)",
        "FR: TF1 Series Films HD (1080P)",
        "FR: TF1 Series Films HD",
        "FR: TF1 Series Films SD",
        "FR: RTL 9 FHD (H.265)",
        "FR: RTL9 HD (1080P)",
        "FR: RTL 9 HD",
        "FR: RTL 9 SD",
        "FR: Syfy Universal FHD (H.265)",
        "FR: Syfy Universal HD (1080P)",
        "FR: Syfy Universal HD",
        "FR: Syfy Universal SD",
        "FR: E!",
        "FR: E! SD",
        "FR: Teva FHD (H.265)",
        "FR: Teva HD (1080P)",
        "FR: Teva HD",
        "FR: TMC FHD (H.265)",
        "FR: TMC HD (1080P)",
        "FR: TMC HD",
        "FR: TMC SD",
        "FR: TMC+1 HD (1080P)",
        "FR: RMC Story FHD (H.265)",
        "FR: RMC Story HD (1080P)",
        "FR: RMC Story HD",
        "FR: RMC Story SD",
        "FR: Game One FHD (H.265)",
        "FR: Game One HD (1080P)",
        "FR: Game One SD",
        "FR: Action FHD (H.265)",
        "FR: Action HD (1080P)",
        "FR: Action HD",
        "FR: Action SD",
        "FR: Bet FHD (H.265)",
        "FR: Bet HD (1080P)",
        "FR: Bet HD",
        "FR: J-One FHD (H.265)",
        "FR: J-One HD (1080P)",
        "FR: NON STOP PEOPLE FHD (H.265)",
        "FR: NON STOP PEOPLE HD (1080P)",
        "FR: Altice Studio FHD (H.265)",
        "FR: Altice Studio HD (1080P)",
        "FR: My Cuisine HD (1080P)",
        "FR: M6 Boutique HD",
        "FR: Novelas TV (1080P)",
        "FR: Novelas TV",
        "FR: Crime District HD (1080P)",
        "FR: Crime District HD",
        "FR: Azur TV",
        "FR: Var Azur TV",
        "FR: IDF 1 HD/SD",
        "FR: Viàvosges TV",
        "FR: Luxe TV HD (1080P)",
        "FR: MDL TV",
        "FR: MOUV’ TV",
        "FR: CNEWS FHD (H.265)",
        "FR: CNEWS HD (1080P)",
        "FR: CNEWS HD",
        "FR: CNEWS SD",
        "FR: BFM TV FHD (H.265)",
        "FR: BFM TV HD (1080P)",
        "FR: BFM TV HD",
        "FR: BFM Business HD (1080P)",
        "FR: BFM Business HD",
        "FR: BFM Paris",
        "FR: BFM Lyon HD",
        "FR: BFM Grand Littoral",
        "FR: BFM Lille",
        "FR: LCI FHD (H.265)",
        "FR: LCI HD (1080P)",
        "FR: LCI HD",
        "FR: LCP HD (1080P)",
        "FR: LCP HD",
        "FR: LCP SD",
        "FR: Euronews HD",
        "FR: TV5 Monde Info",
        "FR: Monaco Info",
        "FR: France 24",
        "FR: France Info",
        "FR: I24 News HD",
        "FR: RT France",
        "FR: Africanews",
        "FR: CGTN",
        "FR: RT HD",
        "FR: Piwi+ FHD (H.265)",
        "FR: Piwi+ HD (1080P)",
        "FR: Piwi+ HD",
        "FR: Piwi+ SD",
        "FR: Teletoon+ FHD (H.265)",
        "FR: Teletoon+ HD (1080P)",
        "FR: Teletoon+ HD",
        "FR: Teletoon+1 HD (1080P)",
        "FR: Cartoon Network FHD (H.265)",
        "FR: Cartoon Network HD (1080P)",
        "FR: Cartoon Network HD",
        "FR: Cartoon Network SD",
        "FR: Boomerang FHD (H.265)",
        "FR: Boomerang HD (1080P)",
        "FR: Boomerang HD",
        "FR: Boomerang SD",
        "FR: Nickelodeon FHD (H.265)",
        "FR: Nickelodeon HD (1080P)",
        "FR: Nickelodeon HD",
        "FR: Nickelodeon +1 HD (H.265)",
        "FR: Nickelodeon +1 HD (1080P)",
        "FR: Nickelodeon Junior FHD (H.265)",
        "FR: Nickelodeon Junior HD (1080P)",
        "FR: Nickelodeon Junior HD",
        "FR: Nickelodeon 4teen FHD (H.265)",
        "FR: Nickelodeon 4teen HD (1080P)",
        "FR: Nickelodeon 4teen SD",
        "FR: Gulli FHD (H.265)",
        "FR: Gulli HD (1080P)",
        "FR: Gulli SD",
        "FR: Mangas FHD (H.265)",
        "FR: Mangas HD (1080P)",
        "FR: Mangas HD",
        "FR: Mangas SD",
        "FR: Disney+ HD (1080P)",
        "FR: Disney Channel FHD (H.265)",
        "FR: Disney Channel HD (1080P)",
        "FR: Disney Channel",
        "FR: Disney Channel SD",
        "FR: Disney Channel +1 HD (1080P)",
        "FR: Disney Junior FHD (H.265)",
        "FR: Disney Junior HD (1080P)",
        "FR: Disney Junior HD",
        "FR: Canal J FHD (H.265)",
        "FR: Canal J HD (1080P)",
        "FR: Canal J HD",
        "FR: Canal J SD",
        "FR: TOONAMI FHD (H.265)",
        "FR: TOONAMI HD (1080P)",
        "FR: TOONAMI SD",
        "FR: Baby HD",
        "FR: TIJI FHD (H.265)",
        "FR: TIJI HD (1080P)",
        "FR: TIJI HD",
        "FR: TIJI SD",
        "FR: Boing FHD (H.265)",
        "FR: Boing HD (1080P)",
        "FR: Boing HD",
        "FR: Boing SD",
        "FR: Game One +1 HD (1080P)",
        "FR: MTV FHD (H.265)",
        "FR: MTV HD (1080P)",
        "FR: MTV France",
        "FR: MTV Dance",
        "FR: MTV Hits",
        "FR: OKLM TV (1080P)",
        "FR: M6 Music FHD (H.265)",
        "FR: M6 Music Hits HD",
        "FR: MCM HD (1080P)",
        "FR: MCM HD",
        "FR: MCM SD",
        "FR: MCM TOP FHD (H.265)",
        "FR: MCM TOP HD (1080P)",
        "FR: MCM TOP HD",
        "FR: MCM TOP SD",
        "FR: Djazz TV HD (1080P)",
        "FR: Brava HD (1080P)",
        "FR: Mezzo",
        "FR: NRJ Hits HD (1080P)",
        "FR: NRJ Hits",
        "FR: Mezzo Live HD (1080P)",
        "FR: RFM TV HD",
        "FR: Stingray Classica HD (1080P)",
        "FR: Trace Urban HD (1080P)",
        "FR: Trace Latina HD (1080P)",
        "FR: Trace Urban HD",
        "FR: Trace Tropical HD",
        "FR: Trace Africa HD (EN)",
        "FR: Trace Africa HD (FR)",
        "FR: Trace Gospel HD",
        "FR: Trace Kitoko HD",
        "FR: Trace Mizki HD",
        "FR: Trace Naija HD",
        "FR: Trace Toca HD",
        "FR: Trace Ayiti HD",
        "FR: TVM3",
        "FR: DBM",
        "FR: D5TV",
        "FR: Melody",
        "FR: CanalPlay 1 FHD",
        "FR: CanalPlay 2 FHD",
        "FR: CanalPlay 3 FHD",
        "FR: CanalPlay 4 FHD",
        "FR: CanalPlay 5 FHD",
        "FR: CanalPlay 6 FHD",
        "FR: CanalPlay 7 FHD",
        "FR: CanalPlay 8 FHD",
        "FR: CanalPlay 9 FHD",
        "FR: CanalPlay 10 FHD",
        "FR: CanalPlay 11 FHD",
        "FR: CanalPlay 12 FHD",
    ],
    [
        "|GR| VOULI",
        "|GR| STAR",
        "|GR| SKAI",
        "|GR| MEGA HD",
        "|GR| KONTRA",
        "|GR| ERT1",
        "|GR| ERT2",
        "|GR| ERT 3 HD",
        "|GR| ERT WORLD",
        "|GR| ERT SPORTS",
        "|GR| Epsilon",
        "|GR| ANT 1",
        "|GR| Alpha TV",
        "|GR| NICKELODEON",
        "|GR| NEA TV",
        "|GR| EOE TV",
        "|GR| 4E",
        "|GR| ZOUGLA",
        "|GR| SIGMA TV",
        "|GR| TV100",
        "|GR| MAD TV",
        "|GR| KRHTH TV",
        "|GR| BOYAH TV",
        "|GR| ATTICA TV",
        "|GR| ACTION24",
        "|GR| EXTRA CHANNEL",
        "|GR| OTE Sport 1 FHD",
        "|GR| OTE Sport 2 FHD",
        "|GR| OTE Sport 3 FHD",
        "|GR| OTE Sport 4 FHD",
        "|GR| OTE Sport 5 FHD",
        "|GR| OTE Sport 6 FHD",
        "|GR| OTE Sport 7 FHD",
        "|GR| OTE Sport 8 FHD",
    ],
    [
        "HU: M1 FHD",
        "HU: M1 HD",
        "HU: M2",
        "HU: M2 FHD",
        "HU: M3",
        "HU: M5 FHD",
        "HU: M5",
        "HU: Duna TV FHD",
        "HU: Cool TV HD",
        "HU: Fix TV HD",
        "HU: RTL+",
        "HU: RTL II HD",
        "HU: RTL Gold",
        "HU: Galaxy 4",
        "HU: Story4",
        "HU: Ozone Network",
        "HU: Fem 3",
        "HU: ATV",
        "HU: Da Vinci Learning",
        "HU: Debrecen TV",
        "HU: Food Network",
        "HU: HirTV",
        "HU: Mozi HD",
        "HU: Viasat3",
        "HU: Viasat 6",
        "HU: TV 2 HD",
        "HU: Super TV 2",
        "HU: TV2 Comedy",
        "HU: Budapest",
        "HU: heti TV",
        "HU: Izaura TV",
        "HU: Jocky TV",
        "HU: Life",
        "HU: Moziverzum",
        "HU: TV Paprika",
        "HU: Slager TV",
        "HU: Sorozat+",
        "HU: Digi Sport 1 HD",
        "HU: Digi Sport 2 HD",
        "HU: Digi Sport 3 HD",
        "HU: Sport 1 FHD",
        "HU: Sport 2 FHD",
        "HU: EuroSport 1 FHD",
        "HU: Eurosport 2",
        "HU: M4 Sport",
        "HU: Spiler 1 TV FHD",
        "HU: Spiler 2 TV FHD",
        "HU: Sport 5",
        "HU: Extreme Sports Channel",
        "HU: Auto Motor Sport",
        "HU: HBO 1 HD",
        "HU: HBO 2",
        "HU: HBO CZ/HU HD",
        "HU: AXN",
        "HU: AXN Black",
        "HU: AXN White",
        "HU: Cinemax",
        "HU: Cinemax 2",
        "HU: AMC",
        "HU: Digi Film",
        "HU: FilmBox Premium",
        "HU: FilmBox",
        "HU: FilmBox Extra HD",
        "HU: Film+ FHD",
        "HU: Film+",
        "HU: Film Mania",
        "HU: Film Now",
        "HU: Film 4",
        "HU: Film Cafe",
        "HU: Spektrum FHD",
        "HU: Spektrum Home",
        "HU: Epic Drama FHD",
        "HU: Paramount Channel",
        "HU: Sony Movies Channel",
        "HU: Comedy Central Family",
        "HU: RTL Klub FHD",
        "HU: TLC",
        "HU: Animal Planet",
        "HU: National Geographic FHD",
        "HU: National Geographic Wild FHD",
        "HU: Discovery FHD",
        "HU: Discovery Science HD",
        "HU: Investigation Discovery",
        "HU: History HD",
        "HU: Duna World FHD",
        "HU: Digi World FHD",
        "HU: Travel Channel FHD",
        "HU: Viasat Explore",
        "HU: Viasat History",
        "HU: CBS Reality",
        "HU: Cartoon Network",
        "HU: Disney Channel",
        "HU: Nick Junior",
        "HU: Nickelodeon",
        "HU: Boomerang",
        "HU: Duck TV",
        "HU: JimJam",
        "HU: Minimax",
        "HU: Minimax / C8",
        "HU: TV2 Kids",
        "HU: 1 Music",
        "HU: Dikh TV",
    ],
    [
        "|IN| SONY TEN 1 HD",
        "|IN| SONY TEN 2 HD",
        "|IN| SONY TEN 3 HD",
        "|IN| TEN CRICKET",
        "|IN| STAR SPORTS 1",
        "|IN| STAR SPORTS 2",
        "|IN| STAR SPORTS 3",
        "|IN| WILLOW HD",
        "|IN| ZING",
        "|IN| ZINDAGI TV",
        "|IN| AAJ TAK",
        "|IN| LIFE PUNJABI",
        "|IN| ZEE PUNJABI",
        "|IN| DD PUNJABI",
        "|IN| JUS PUNJABI",
        "|IN| ZEE TV",
        "|IN| ZEE TV HD",
        "|IN| ZEE NEWS",
        "|IN| ZEE BANGLA",
        "|IN| ZEE CLASSIC",
        "|IN| ZEE CINEMA ASIA",
        "|IN| ZEE CINEMA HD",
        "|IN| ZEE BUSINESS",
        "|IN| ZEE BOLLYWOOD",
        "|IN| FOOD FOOD",
        "|IN| ZEE MARATHI",
        "|IN| ZEE ACTION",
        "|IN| ZEE TAMIL",
        "|IN| ZEE TELUGU",
        "|IN| ZEE TALKIES",
        "|IN| ZEE ANMOL CINEMA",
        "|IN| ZEE ETC",
        "|IN| ASIANET TV",
        "|IN| ASIANET PLUS",
        "|IN| ASIANET NEWS",
        "|IN| ASIANET MOVIES",
        "|IN| ZOOM",
        "|IN| UTV MOVIES INTERNATIONAL",
        "|IN| UTV BINDASS",
        "|IN| STAR PLUS HD",
        "|IN| STAR GOLD",
        "|IN| STAR BHARAT",
        "|IN| STAR JALSHA",
        "|IN| STAR VIJAY",
        "|IN| SONY TV UK",
        "|IN| SONY TV HD",
        "|IN| SONY SIX",
        "|IN| SONY MAX 2",
        "|IN| SONY SAB",
        "|IN| SONY ESPN",
        "|IN| SONY PAL",
        "|IN| & PICTURE HD",
        "|IN| RISHTEY TV",
        "|IN| MUSIC INDIA",
        "|IN| MTV INDIA",
        "|IN| INDIA TV",
        "|IN| COLORS HD",
        "|IN| COLORS GUJARATI",
        "|IN| COLORS KANNADA HD",
        "|IN| BINDASS PLAY",
        "|IN| B4U MOVIE",
        "|IN| B4U PLUS",
        "|IN| B4U MUSIC",
        "|IN| & TV",
        "|IN| SURYA TV",
        "|IN| SUN TV HD",
        "|IN| SUN MUSIC",
        "|IN| HUM EUROPE",
        "|IN| 9X MUSIC",
        "|IN| 9X TASHAN",
        "|IN| 9X JHAKAAS",
        "|IN| 9X JALWA",
        "|IN| DD NEWS",
        "|IN| Desi Channel",
        "|IN| E 24",
        "|IN| HOUSEFULL MOVIES",
        "|IN| PLAY TV",
        "|IN| ZEE HINDUSTAN",
        "|IN| INDIA TODAY",
        "|IN| DD MALAYALAM",
        "|IN| NOOR TV",
        "|IN| BOL NEWS HD",
        "|IN| ABP NEWS",
        "|IN| CNBC BAJAR",
        "|IN| FIRST INDIA NEWS",
        "|IN| LAHORE NEWS",
        "|IN| NDTV",
        "|IN| NDTV PROFIT",
        "|IN| PUNJABI 5AAB TV",
        "|NP| IMAGE CHANNEL",
        "|NP| NTV NATIONAL FHD",
        "|NP| NTV KOHALPUR FHD",
        "|NP| NTV PLUS FHD",
        "|NP| NEPAL 1 TV",
        "|NP| CHANNEL 4 NEPAL",
        "|NP| KANTIPUR TV",
        "|NP| AP1 HD",
        "|NP| HIMALAYA TV",
        "|NP| NTV NEWS FHD",
        "|NP| JANATA TV",
        "|NP| ABC NEWS",
        "|NP| NEWS 24",
        "|NP| AVENUES TV",
        "|NP| MOUNTAIN TV",
        "|NP| VISION NEPAL",
        "|NP| NTV ITHARI",
        "|NP| BUSINESS TV",
        "|BD| AAMAR BANGLA",
        "|BD| ATN BANGLA",
        "|BD| ATN NEWS",
        "|BD| BANGLA VISION",
        "|BD| BOISHAKHI TV",
        "|BD| BTV WORLD",
        "|BD| CHANNEL 9",
        "|BD| CHANNEL S",
        "|BD| COLORS BANGLA",
        "|BD| ZEE BANGLA",
        "|BD| EKATTOR TV",
        "|BD| GAZI TV",
        "|BD| GAAN BANGLA",
        "|BD| INDEPENDENT TV",
        "|BD| JAMUNA TV",
        "|BD| MASRANGA TV",
        "|BD| MOHONA TV",
        "|BD| MOVIE BANGLA",
        "|BD| MY TV",
        "|BD| NRB TV",
        "|BD| RTV",
        "|BD| RUPOSHI BANGLA",
        "|BD| SOMOY NEWS TV",
        "|BD| TIME TELEVISION",
        "|IN| CNBC Awaaz",
        "IN: Star Sports 1 FHD",
        "IN: Star Sports 2 FHD",
        "IN: Star Sports Select 1 HD",
        "IN: Star Sports Select 2 HD",
        "IN: Star Sports Hindi 1 HD",
        "IN: Star Sports Tamil 1",
        "IN: Sony Ten 1 HD",
        "IN: Sony Ten 2 HD",
        "IN: Sony Ten 3 HD",
        "IN: Sony Espn HD",
        "IN: Eurosport HD",
        "IN: Sony TV HD",
        "IN: Sony Max HD",
        "IN: Sony SAB HD",
        "IN: Sony Six HD",
        "IN: Sony Pix HD",
        "IN: Sony Marathi",
        "IN: Sony Wah",
        "IN: Sony YAY",
        "IN: Colors HD",
        "IN: Colors Cineplex HD",
        "IN: Colors Marathi HD",
        "IN: Colors Tamil HD",
        "IN: Colors Kannada HD",
        "IN: Colors Infinity HD",
        "IN: Colors Super Kannada",
        "IN: Colors Gujarati HD",
        "IN: Colors Gujarati Cinema",
        "IN: Colors Kannada Cinema HD",
        "IN: Colors Rishtey",
        "IN: & TV HD",
        "IN: &privé HD",
        "IN: &Flix HD",
        "IN: & Picture HD",
        "IN: &Xplor HD",
        "IN: CNBC Bajar Gujarati",
        "IN: Guj Sanskar TV",
        "IN: Guj Sadvidya TV",
        "IN: Pitaara TV",
        "IN: News 18 Gujarati",
        "IN: News 18 Kerala",
        "IN: News 18 Jharkhand",
        "IN: News 18 Odia",
        "IN: News18 Lokmat",
        "IN: TV9 Gujarati",
        "IN: Guj DD Girnar",
        "IN: Guj Lakshya TV",
        "IN: Guj Sandesh News",
        "IN: Gujarat First",
        "IN: VTV Gujarati",
        "IN: Mantavya News Gujrat",
        "IN: Discovery HD",
        "IN: India News Gujrati",
        "IN: Star Plus HD",
        "IN: Star Gold HD",
        "IN: Star Movies Select HD",
        "IN: Star Gold Select HD",
        "IN: Star Bharat HD",
        "IN: Star Jalsha HD",
        "IN: Star Jalsha Movies HD",
        "IN: Star World Premiere HD",
        "IN: Star Pravah HD",
        "IN: Star Vijay HD",
        "IN: Star World HD",
        "IN: Zee TV HD",
        "IN: Zee Cinema HD",
        "IN: Sony TV (Marathi)",
        "IN: Zee Marathi HD",
        "IN: Zee bangla HD",
        "IN: Zee Talkies HD",
        "IN: Zee Telugu",
        "IN: Zee Mundo HD",
        "IN: Zee Cafe HD",
        "IN: Zee Tamil HD",
        "IN: Zee Salaam",
        "IN: Zee TV canada HD",
        "IN: Zee Living",
        "IN: Zee Keralam",
        "IN: Zee 24 Kalak",
        "IN: Zee News",
        "IN: Zee Punjabi",
        "IN: Zee Action",
        "IN: Zee Kannada",
        "PK: Raavi TV",
        "IN: Zee News MPCG",
        "IN: Zee Magic",
        "IN: Zee Rajasthan",
        "IN: Star Maa HD",
        "IN: Star Maa Movies HD",
        "IN: Star Maa Gold",
        "IN: Animal Planet HD",
        "IN: HBO HD",
        "IN: Miniplex HD",
        "IN: Aaj Tak HD",
        "IN: Movies Now HD",
        "IN: Fox Life HD",
        "IN: TLC HD",
        "IN: Asianet HD",
        "IN: Asianet News Bangla",
        "IN: Sony BBC Earth HD",
        "IN: Discovery Science",
        "IN: History TV18 HD",
        "IN: Nat Geo HD",
        "IN: Nat Geo Wild HD",
        "IN: Sun TV HD",
        "IN: Sun News",
        "IN: Sun Udaya HD",
        "IN: Sun Music HD",
        "IN: Sun Life",
        "IN: Etv HD",
        "IN: Gemini HD",
        "IN: Gemini Movies HD",
        "IN: Gemini Comedy HD",
        "IN: Gemini Life",
        "IN: Gemini Music HD",
        "IN: KTV HD",
        "IN: Romedy Now HD",
        "IN: Gaunda Punjab TV",
        "IN: Punjabi TV",
        "IN: Global Punjab HD",
        "IN: PTC Punjab Gold HD",
        "IN: Pogo",
        "IN: Disney Channel",
        "IN: Baby TV HD",
        "IN: Channel Y",
        "IN: CNBC TV18 Prime News HD",
        "IN: Raj Digital Plus",
        "IN: DishTV Bhakti Active",
        "IN: DishTV Comedy Active",
        "IN: DishTV Evergreen Classics Active",
        "IN: DishTV Ibadat Active",
        "IN: UTV Action HD",
        "IN: UTV Movies HD",
        "IN: Maha Movie",
        "IN: MTV Beats HD",
        "IN: MTV India HD",
        "IN: MTV HD+",
        "IN: Jus Hindi HD",
        "IN: 5aabTV Gurbani HD",
        "IN: Food Food",
        "IN: Mazhavil HD",
        "IN: NDTV Good Times",
        "IN: ABP News HD",
        "IN: ABP Ananda HD",
        "IN: Republic Bangla",
        "IN: Divine TV",
        "IN: Harvest Arabia",
        "IN: Harvest USA TV",
        "IN: Harvest TV",
        "IN: Discovery Kids",
        "IN: Acv News",
        "IN: News 24",
        "IN: Bflix Movies",
        "IN: Epic HD",
        "IN: MH One",
        "IN: Cartoon Network",
        "IN: Nick HD+",
        "IN: Raj News (telegu)",
        "IN: M4 Malayalam TV",
        "IN: Pulari TV",
        "IN: Media One",
        "IN: Kairali News",
        "IN: Kairali TV",
        "IN: Kairali We",
        "IN: Udaya HD",
        "IN: Mathrubhumi News",
        "IN: Manorama News",
        "IN: 24News",
        "IN: DD National",
        "IN: D Sports",
        "IN: Mazhavil Manorama HD",
        "IN: Asianet Middle East",
        "IN: Amrita TV",
        "IN: Kappa TV",
        "IN: Jaya TV HD",
        "IN: Jeevan TV",
        "IN: I Love Movies",
        "IN: BBC World News",
        "IN: ET Now",
        "IN: Flowers TV",
        "IN: kalaignar TV",
        "IN: Mirror Now",
        "IN: Suvarna News 24x7",
        "IN: Tamil Vision International",
        "IN: TV9 Telugu",
        "IN: Ary Family",
        "IN: 9XM",
        "IN: KANAK NEWS",
        "IN: Kalinga TV",
        "IN: NaxatraNews",
        "IN: Odisha TV",
        "IN: PrameyaNews7",
        "IN: Prarthana TV HD",
        "IN: The Argus TV",
        "IN: Sony Ten 4 HD",
    ],
    [
        "IT: Sky Sport 24 FHD",
        "IT: Sky Sport 24",
        "IT: Sky Sport Uno FHD",
        "IT: Sky Sport Uno HD",
        "IT: Sky Sport Arena FHD",
        "IT: Sky Sport Arena HD",
        "IT: Sky Sport Football FHD",
        "IT: Sky Sport Football HD",
        "IT: Sky Sport Collection FHD",
        "IT: Sky Sport F1 FHD",
        "IT: Sky Sport F1 HD",
        "IT: Sky Sport MotoGP FHD",
        "IT: Sky Sport MotoGP HD",
        "IT: Sky Sport NBA FHD",
        "IT: Sky Sport NBA HD",
        "IT: Super Tennis FHD",
        "IT: SuperTennis HD",
        "IT: Eurosport 1 FHD",
        "IT: Eurosport 1 HD",
        "IT: Eurosport 2 FHD",
        "IT: Eurosport 2 HD",
        "IT: Sport Italia Live24",
        "IT: Rai Sport + FHD",
        "IT: Rai Sport + HD",
        "IT: Auto Moto TV",
        "IT: Juventus Channel",
        "IT: Inter Channel",
        "IT: Roma Channel",
        "IT: Torino Channel",
        "IT: Milan TV HD",
        "IT: Lazio Style Channel",
        "IT: Bike Channel HD",
        "IT: Discovery Motortrend",
        "IT: TopCalcio24",
        "IT: Sky Sport Serie A FHD",
        "IT: Sky Sport 251 HD",
        "IT: Sky Sport 252 HD",
        "IT: Sky Sport 253 HD",
        "IT: Sky Sport 254 HD",
        "IT: Sky Sport 255 HD",
        "IT: Sky Sport 256 HD",
        "IT: Sky Sport 257 HD",
        "IT: Dazn 1 FHD (SAT)",
        "IT: Dazn Eventi 1",
        "IT: Sky Cinema Uno FHD",
        "IT: Sky Cinema Uno HD",
        "IT: Sky Cinema Uno +24 FHD",
        "IT: Sky Cinema Uno +24 HD",
        "IT: Sky Cinema Due FHD",
        "IT: Sky Cinema Due HD",
        "IT: Sky Cinema Due +24 FHD",
        "IT: Sky Cinema Due +24 HD",
        "IT: Sky Cinema Action FHD",
        "IT: Sky Cinema Action HD",
        "IT: Sky Cinema Drama FHD",
        "IT: Sky Cinema Drama HD",
        "IT: Sky Cinema Family FHD",
        "IT: Sky Cinema Family HD",
        "IT: Sky Cinema Suspense FHD",
        "IT: Sky Cinema Suspense HD",
        "IT: Sky Cinema Comedy FHD",
        "IT: Sky Cinema Comedy HD",
        "IT: Sky Cinema Collection FHD",
        "IT: Sky Cinema Collection HD",
        "IT: Sky Cinema Romance FHD",
        "IT: Sky Cinema Romance HD",
        "IT: Premium Cinema HD",
        "IT: Premium Cinema Comedy HD",
        "IT: Premium Cinema Emotion HD",
        "IT: Premium Cinema Energy HD",
        "IT: Premium Action HD",
        "IT: Premium Crime HD",
        "IT: Premium Stories HD",
        "IT: Sky Uno FHD",
        "IT: Sky Uno HD",
        "IT: Sky Atlantic FHD",
        "IT: Sky Atlantic HD",
        "IT: Fox FHD",
        "IT: Fox HD",
        "IT: Fox Crime FHD",
        "IT: Fox Crime HD",
        "IT: Fox Life HD",
        "IT: Comedy Central",
        "IT: Cielo HD",
        "IT: Real Time FHD",
        "IT: Real Time HD",
        "IT: Sky Arte HD",
        "IT: Top Crime",
        "IT: Paramount Channel",
        "IT: HGTV",
        "IT: Canale 5 FHD",
        "IT: Blaze FHD",
        "IT: Italia 1 FHD",
        "IT: Italia 2",
        "IT: Mediaset 4 FHD",
        "IT: Mediaset 4 HD",
        "IT: Discovery Channel FHD",
        "IT: Discovery Channel HD",
        "IT: Discovery Science FHD",
        "IT: Discovery Science HD",
        "IT: Nat Geo FHD",
        "IT: Nat Geo wild FHD",
        "IT: History FHD",
        "IT: CI Crime+ Investigation HD",
        "IT: Caccia & Pesca HD",
        "IT: Pesca & Caccia HD",
        "IT: Food Network",
        "IT: Dmax HD",
        "IT: Italian Fishing TV",
        "IT: Disney Channel HD",
        "IT: Disney Junior FHD",
        "IT: Disney Junior HD",
        "IT: Cartoon Network FHD",
        "IT: Nickelodeon",
        "IT: Cartonitoo",
        "IT: Man-Ga",
        "IT: Dea Kids",
        "IT: Nick JR",
        "IT: Boing",
        "IT: K2",
        "IT: Frisbee",
        "IT: Rai 1 FHD",
        "IT: Rai 1 HD",
        "IT: Rai 2 FHD",
        "IT: Rai 2 HD",
        "IT: Rai 3 FHD",
        "IT: Rai 3 HD",
        "IT: Rai 4 FHD",
        "IT: Rai 4 HD",
        "IT: Rai 5 FHD",
        "IT: Rai 5 HD",
        "IT: Rai Premium FHD",
        "IT: Rai Premium HD",
        "IT: Rai Movie HD",
        "IT: Rai News 24 FHD",
        "IT: Rai News 24 HD",
        "IT: Rai Storia FHD",
        "IT: Rai Yoyo HD",
        "IT: Rai Glup FHD",
        "IT: Sky TG 24 HD",
        "IT: TgCom 24",
        "IT: Euronews",
        "IT: Mediaset Extra",
        "IT: Mediaset 20 HD",
        "IT: RSI La 1 HD",
        "IT: RSI La 2 HD",
        "IT: Laeffe",
        "IT: Focus",
        "IT: La 5",
        "IT: TV8",
        "IT: Nove",
        "IT: La 7d FHD",
        "IT: La 7d",
        "IT: La 7 HD",
        "IT: Sky Primafila 1 FHD",
        "IT: Sky Primafila 2 FHD",
        "IT: Sky Primafila 3 FHD",
        "IT: Sky Primafila 4 FHD",
        "IT: Sky Primafila 5 FHD",
        "IT: Sky Primafila 6 FHD",
        "IT: Sky Primafila 7 FHD",
        "IT: Sky Primafila 8 FHD",
        "IT: Sky Primafila 9 FHD",
        "IT: Sky Primafila 10 FHD",
        "IT: Alpa Uno TV",
        "IT: Antenna 3 TV",
        "IT: Brescia Tv",
        "IT: Canale 21 Campania",
        "IT: Canale 21 Lazio",
        "IT: Canale 33",
        "IT: Canale 46",
        "IT: Canale 7 Ita",
        "IT: Canale Dieci TV",
        "IT: Canale10",
        "IT: Canale8",
        "IT: CNBC Class",
        "IT: CTS TV",
        "IT: Di TV 1",
        "IT: Di TV 2",
        "IT: Di TV 3",
        "IT: Eden TV",
        "IT: ETV Marche",
        "IT: Fano TV",
        "IT: History Lab",
        "IT: Lucania TV",
        "IT: Napoli Canale 21 TV",
        "IT: Napoli TV 21",
        "IT: Onda TV",
        "IT: Orler TV",
        "IT: Primocanale TV",
        "IT: Radio Radicale TV",
        "IT: Rete 55 Live",
        "IT: Rete Biella TV",
        "IT: Rete Versilia",
        "IT: ReteSole Lazio",
        "IT: ReteSole Umbria",
        "IT: Rtp TV",
        "IT: Studio 100",
        "IT: Tele Arena Siena",
        "IT: Tele Boario",
        "IT: Tele Nord",
        "IT: Tele Padova Telecitt",
        "IT: Tele Studio Modena",
        "IT: Tele8",
        "IT: TeleColor",
        "IT: TeleMIa",
        "IT: TeleMonte Neve",
        "IT: TeleNorba TV",
        "IT: TelePace TV",
        "IT: TeleRadioSciacca",
        "IT: TeleSondrio News",
        "IT: TRM TV",
        "IT: TV Avicenza",
        "IT: TV2000",
        "IT: TVRS",
        "IT: Venezia Ponte Rialto Dal Vivo",
        "IT: Vera TV",
        "IT: Video Brescia",
        "IT: Video Tolento",
        "IT: Videolina",
        "IT: VisualRadio TV (musica)",
        "IT: Vitalia1",
        "IT: Vitalia2",
        "IT: Vitalia3",
    ],
    [
        "NL: NPO 1 FHD",
        "NL: NPO 1 HD",
        "NL: NPO 2 FHD",
        "NL: NPO 2 HD",
        "NL: NPO 3 FHD",
        "NL: NPO 3 HD",
        "NL: NPO 1 Extra",
        "NL: NPO 2 Extra FHD",
        "NL: NPO 2 Extra",
        "NL: NPO Nieuws",
        "NL: NPO Zappelin Xtra",
        "NL: NPO Politiek",
        "NL: Net 5 FHD",
        "NL: Net 5 HD",
        "NL: SBS 6 FHD",
        "NL: SBS 6 HD",
        "NL: SBS 9 FHD",
        "NL: SBS 9 HD",
        "NL: RTL 4 FHD",
        "NL: RTL 4 HD",
        "NL: RTL 5 FHD",
        "NL: RTL 5 HD",
        "NL: RTL 7 FHD",
        "NL: RTL 7 HD",
        "NL: RTL 8 FHD",
        "NL: RTL 8 HD",
        "NL: AT5 FHD",
        "NL: Omroep Gelderland",
        "NL: Omroep Tilburg",
        "NL: Omroep Zeeland",
        "NL: Omroep Ede TV",
        "NL: Omroep Flevoland",
        "NL: Omroep Fryslan",
        "NL: Omroep Brabant",
        "NL: Omroep P&M",
        "NL: TV West",
        "NL: RTV Utrect",
        "NL: RTV Slingeland",
        "NL: RTV Rijnmond",
        "NL: RTV OOST",
        "NL: RTV Noord",
        "NL: RTV Dordrecht",
        "NL: RTV Arnhem",
        "NL: Family7",
        "NL: ONS",
        "NL: BVN",
        "NL: Den Haag TV",
        "NL: Out TV",
        "NL: Podium.TV 1 SD",
        "NL: Podium.TV 3 SD",
        "NL: ESPN 1 FHD",
        "NL: ESPN 1 HD",
        "NL: ESPN 2 FHD",
        "NL: ESPN 2 HD",
        "NL: ESPN 3 FHD",
        "NL: ESPN 3 HD",
        "NL: ESPN 4 FHD",
        "NL: ESPN 4 HD",
        "NL: ESPN 5 FHD",
        "NL: ESPN 5 HD",
        "NL: ESPN 6 FHD",
        "NL: ESPN 6 HD",
        "NL: Ziggo Sport FHD",
        "NL: Ziggo Sport Select FHD",
        "NL: Ziggo Sport Select HD",
        "NL: Ziggo Sport Racing FHD",
        "NL: Ziggo Sport Racing HD",
        "NL: Ziggo Sport Golf FHD",
        "NL: Ziggo Sport Golf HD",
        "NL: Ziggo Sport Voetbal FHD",
        "NL: Ziggo Sport Voetbal HD",
        "NL: Ziggo Sport Docu FHD",
        "NL: Ziggo Sport Docu HD",
        "NL: Ziggo Sport Tennis FHD",
        "NL: Ziggo Sport Tennis HD",
        "NL: Eurosport 1 FHD",
        "NL: Eurosport 1 HD",
        "NL: Eurosport 2 FHD",
        "NL: Eurosport 2 HD",
        "NL: Film1 Action FHD",
        "NL: Film1 Action HD",
        "NL: Film1 Drama FHD",
        "NL: Film1 Drama HD",
        "NL: Film1 Premiere FHD",
        "NL: Film1 Premiere HD",
        "NL: Film1 Family FHD",
        "NL: Film1 Family HD",
        "NL: Fox FHD",
        "NL: Fox",
        "NL: RTL Crime HD",
        "NL: RTL Lounge HD",
        "NL: RTL Z FHD",
        "NL: RTL Z HD",
        "NL: Comedy Central FHD",
        "NL: Comedy Central",
        "NL: Comedy Central Extra",
        "NL: Comedy Central Family",
        "NL: CBS Reality",
        "NL: Viceland FHD",
        "NL: Animal Planet FHD",
        "NL: Animal Planet HD",
        "NL: Nat Geo Wild FHD",
        "NL: Nat Geo Wild HD",
        "NL: National Geographic FHD",
        "NL: National Geographic HD",
        "NL: Discovery FHD",
        "NL: Discovery HD",
        "NL: History Channel FHD",
        "NL: History Channel HD",
        "NL: Discovery ID FHD",
        "NL: Crime Investigation",
        "NL: Travel Channel",
        "NL: Love Nature FHD",
        "NL: Love Nature HD",
        "NL: Spike FHD",
        "NL: Spike HD",
        "NL: TLC FHD",
        "NL: TLC HD",
        "NL: 24 Kitchen FHD",
        "NL: 24 Kitchen HD",
        "NL: E! Entertainment HD",
        "NL: BBC First FHD",
        "NL: BBC First HD",
        "NL: Horse & Country HD",
        "NL: Insight TV HD",
        "NL: Disney Channel FHD",
        "NL: Disney Channel",
        "NL: Veronica / Disney XD FHD",
        "NL: Veronica / Disney XD HD",
        "NL: RTL Telekids HD",
        "NL: Nickelodeon FHD",
        "NL: Nickelodeon",
        "NL: Nicktoons",
        "NL: Nick Jr",
        "NL: Cartoon Network FHD",
        "NL: Cartoon Network",
        "NL: Boomerang",
        "NL: JimJam",
        "NL: Pebble TV",
        "NL: Duck TV",
        "NL: Baby TV",
        "NL: Nick Music",
        "NL: MTV FHD",
        "NL: MTV HD",
        "NL: MTV Music 24",
        "NL: TV Orange",
        "NL: Salto 1",
        "NL: Salto 2",
        "NL: Slam! TV",
        "NL: Xite HD",
        "NL: 192 TV",
        "NL: DanceTrippin",
        "NL: TV Ellef",
        "NL: Malive",
        "NL: REDBOX ACTIE 1",
        "NL: REDBOX ACTIE 2",
        "NL: REDBOX ACTIE 3",
        "NL: REDBOX ANIMATIE 1",
        "NL: REDBOX ANIMATIE 2",
        "NL: REDBOX ANIMATIE 3",
        "NL: REDBOX BLACK HD",
        "NL: REDBOX CONCERT",
        "NL: REDBOX DRAMA 1",
        "NL: REDBOX DRAMA 2",
        "NL: REDBOX HORROR 1",
        "NL: REDBOX HORROR 2",
        "NL: REDBOX KOMEDIE 1",
        "NL: REDBOX KOMEDIE 2",
        "NL: REDBOX NIEUWE FILMS",
        "NL: REDBOX RED HD",
        "NL: REDBOX SCI FI 1",
        "NL: REDBOX SCI FI 2",
        "NL: REDBOX TEKEN FILM",
        "NL: REDBOX THRILLER 1",
        "NL: REDBOX THRILLER 2",
        "NL: REDBOX THRILLER 3",
    ],
    [
        "PL: Novelas+ FHD",
        "PL: NOVELA TV",
        "PL: CANAL+ SPORT 4 FHD",
        "PL: CANAL+ SPORT 3 FHD",
        "PL: CANAL+ FILM FHD",
        "PL: 13 Ulica FHD",
        "PL: 4Fun Dance",
        "PL: 4Fun Gold Hits",
        "PL: 4Fun.TV",
        "PL: AXN FHD",
        "PL: AXN",
        "PL: AXN Black",
        "PL: AXN SPIN FHD",
        "PL: AXN White",
        "PL: Active Family",
        "PL: Adventure FHD",
        "PL: Animal Planet FHD",
        "PL: AMC FHD",
        "PL: Ale kino+ FHD",
        "PL: BBC Earth FHD",
        "PL: BBC FIRST FHD",
        "PL: BBC Brit FHD",
        "PL: BBC Cbeebies",
        "PL: Boomerang FHD",
        "PL: CANAL+ DOKUMENT FHD",
        "PL: CANAL+ FAMILY FHD",
        "PL: CANAL+1 FHD",
        "PL: CANAL+ NOW FHD",
        "PL: CANAL+ PREMIUM FHD",
        "PL: CANAL+ SPORT 1 FHD",
        "PL: CANAL+ SPORT 2 FHD",
        "PL: CANAL+ SERIALE FHD",
        "PL: CBS Europa FHD",
        "PL: CBS Reality FHD",
        "PL: CI Polsat FHD",
        "PL: Cartoon Network FHD",
        "PL: Cinemax FHD",
        "PL: Cinemax 2 FHD",
        "PL: Comedy Central FHD",
        "PL: DOMO+ FHD",
        "PL: DTX FHD",
        "PL: Disco Polo Music",
        "PL: Discovery FHD",
        "PL: Discovery Science FHD",
        "PL: Discovery Historia",
        "PL: Discovery Life FHD",
        "PL: Disney Channel FHD",
        "PL: Disney Junior",
        "PL: Disney XD",
        "PL: E! Entertainment FHD",
        "PL: Eleven Sports 1 FHD",
        "PL: Eleven Sports 1 HD",
        "PL: Eleven Sports 2 FHD",
        "PL: Eleven Sports 3 FHD",
        "PL: Eleven Sports 3 HD",
        "PL: Eleven Sports 4 FHD",
        "PL: Eleven Sports 4 HD",
        "PL: Epic Drama FHD",
        "PL: Eska Rock",
        "PL: Eska TV",
        "PL: Eska TV Extra",
        "PL: Eurosport 1 FHD",
        "PL: Eurosport 1 HD",
        "PL: Eurosport 2 FHD",
        "PL: Eurosport 2 HD",
        "PL: Extreme Sports FHD",
        "PL: Extreme Sports HD",
        "PL: Fightklub FHD",
        "PL: Fightbox FHD",
        "PL: FilmBox Action",
        "PL: FilmBox Extra FHD",
        "PL: FilmBox Family",
        "PL: Filmbox Arthouse",
        "PL: Filmbox Premium FHD",
        "PL: FOX",
        "PL: FOX FHD",
        "PL: Fox Comedy",
        "PL: Fox Comedy FHD",
        "PL: Fokus TV",
        "PL: Food Network FHD",
        "PL: Golf Channel",
        "PL: HBO1 FHD",
        "PL: HBO2 FHD",
        "PL: HBO3 FHD",
        "PL: History FHD",
        "PL: History 2 FHD",
        "PL: HGTV FHD",
        "PL: ID FHD",
        "PL: KINO POLSKA FHD",
        "PL: Kino Polska Muzyka",
        "PL: Kino TV FHD",
        "PL: Kuchnia+ FHD",
        "PL: Lifetime FHD",
        "PL: Metro",
        "PL: MiniMini+ FHD",
        "PL: MTV Live FHD",
        "PL: MTV Music 24",
        "PL: MTV Polska FHD",
        "PL: MTV Rocks",
        "PL: Motowizja FHD",
        "PL: NATIONAL GEO FHD",
        "PL: National Geographic Wild FHD",
        "PL: Nat Geo People FHD",
        "PL: Nowa TV",
        "PL: Nick Jr",
        "PL: Nickelodeon",
        "PL: Nicktoons FHD",
        "PL: Paramount Channel FHD",
        "PL: Planete+ FHD",
        "PL: Polo TV",
        "PL: Polsat FHD",
        "PL: Polsat 2 FHD",
        "PL: Polsat Cafe FHD",
        "PL: Polsat Film FHD",
        "PL: Polsat Games",
        "PL: Polsat Music FHD",
        "PL: Polsat News 2",
        "PL: Polsat News FHD",
        "PL: Polsat Play FHD",
        "PL: Polsat Play HD",
        "PL: Polsat Comedy Central Extra",
        "PL: Polsat Doku FHD",
        "PL: Polsat Jim Jam",
        "PL: Polsat Rodzina",
        "PL: Polsat Romans",
        "PL: Polsat Sport Extra FHD",
        "PL: Polsat Sport Extra HD",
        "PL: Polsat Sport Fight FHD",
        "PL: Polsat Sport Fight HD",
        "PL: Polsat Sport FHD",
        "PL: Polsat Sport HD",
        "PL: Polsat Sport News FHD",
        "PL: Polsat Sport News HD",
        "PL: Polsat Viasat Explore FHD",
        "PL: Polsat Viasat History FHD",
        "PL: Polsat Viasat Nature FHD",
        "PL: Puls 2",
        "PL: Red Carpet TV",
        "PL: Romance TV",
        "PL: STARS.TV",
        "PL: SciFi FHD",
        "PL: Sportklub FHD",
        "PL: Stopklatka",
        "PL: Sundance FHD",
        "PL: Super Polsat",
        "PL: Superstacja",
        "PL: TLC FHD",
        "PL: TNT FHD",
        "PL: TTV FHD",
        "PL: TV Okazje",
        "PL: TVN 24 FHD",
        "PL: TVN 7 FHD",
        "PL: TVN Fabula",
        "PL: TVN FHD",
        "PL: TVN Style FHD",
        "PL: TVN Turbo FHD",
        "PL: TVN24 BiS FHD",
        "PL: TVP 1 FHD",
        "PL: TVP 2 FHD",
        "PL: TVP 3",
        "PL: TV PULS FHD",
        "PL: TV Republika",
        "PL: TV4 FHD",
        "PL: TV6",
        "PL: TVP ABC",
        "PL: TVP FHD",
        "PL: TVP Historia",
        "PL: TVP INFO FHD",
        "PL: TVP Kultura",
        "PL: TVP Rozrywka",
        "PL: TVP SERIALE",
        "PL: TVP Sport FHD",
        "PL: TVP Sport HD",
        "PL: TVS",
        "PL: Travel Channel FHD",
        "PL: Da Vinci",
        "PL: VOD 205",
        "PL: VOD 206",
        "PL: VOD 207",
        "PL: VOD 208",
        "PL: VH1",
        "PL: VOX Music TV",
        "PL: WP",
        "PL: Zoom TV",
        "PL: wPolsce.pl",
        "PL: nSport+",
        "PL: teleTOON+ FHD",
        "PL: Polsat Sport Premium 1 FHD",
        "PL: Polsat Sport Premium 2 FHD",
        "PL: Polsat Sport Premium PPV 3",
        "PL: Polsat Sport Premium PPV 4",
        "PL: Polsat Sport Premium PPV 5",
        "PL: Polsat Sport Premium PPV 6",
        "PL: KSW PPV FHD [Live During Events Only]",
        "PL: Nuta. TV",
        "PL: TELE 5",
    ],
    [
        "PT: SPORT TV+ HD (1080P)",
        "PT: SPORT TV+",
        "PT: SPORT TV1 HD (1080P)",
        "PT: SPORT TV1",
        "PT: SPORT TV2 HD (1080P)",
        "PT: SPORT TV2",
        "PT: SPORT TV3 HD (1080P)",
        "PT: SPORT TV3",
        "PT: SPORT TV4 HD (1080P)",
        "PT: SPORT TV4",
        "PT: SPORT TV5 HD (1080P)",
        "PT: SPORT TV5",
        "PT: SPORT TV6 HD (1080P)",
        "PT: SPORT TV6",
        "PT: SPORT TV NBA HD (1080P)",
        "PT: SportTV UHD 4K",
        "PT: Eurosport 1",
        "PT: Eurosport 1 HD (1080P)",
        "PT: Eurosport 2 HD (1080P)",
        "PT: Eurosport 2",
        "PT: Benefica TV 1 HD (1080P)",
        "PT: Benefica TV 1",
        "PT: Fuel TV HD (1080P)",
        "PT: Fuel TV",
        "PT: MotorVision HD (1080P)",
        "PT: MotorVision",
        "PT: A Bola TV HD (1080P)",
        "PT: A Bola TV",
        "PT: Toros TV HD (1080P)",
        "PT: Toros TV",
        "PT: Sporting TV HD",
        "PT: PFC",
        "PT: Kombat Sport",
        "PT: Canal 11 HD (1080P)",
        "PT: Canal 11",
        "PT: RTP Internacional",
        "PT: RTP1 HD (1080P)",
        "PT: RTP1 HD (7200P)",
        "PT: RTP1",
        "PT: RTP2 HD (1080P)",
        "PT: RTP2 HD (7200P)",
        "PT: RTP2",
        "PT: RTP3 HD (1080P)",
        "PT: RTP3",
        "PT: RTP Acores",
        "PT: RTP Africa",
        "PT: RTP Madeira",
        "PT: RTP Memoria",
        "PT: RTP Arena HD (1080P)",
        "PT: SIC HD (1080P)",
        "PT: SIC",
        "PT: TVI HD (1080P)",
        "PT: TVI HD (720P)",
        "PT: TVI",
        "PT: TVI Ficção HD (1080P)",
        "PT: TVI Ficção",
        "PT: TVI Reality HD (1080P)",
        "PT: TVI Reality Mosaico",
        "PT: TVI Reality CAM 1",
        "PT: TVI Reality CAM 2",
        "PT: TVI Reality CAM 3",
        "PT: TVI Reality CAM 4",
        "PT: TV Record",
        "PT: Nos Studio HD (1080P)",
        "PT: Nos Studios",
        "PT: S+ HD (1080P)",
        "PT: S+",
        "PT: Canção Nova",
        "PT: Porto Canal HD (1080P)",
        "PT: Porto Canal",
        "PT: Canal NOS",
        "PT: Local Visão HD",
        "PT: Canal 180",
        "PT: Dog TV",
        "PT: Kuriakos TV HD (1080P)",
        "PT: Kuriakos TV",
        "PT: TVCine Top HD (1080P)",
        "PT: TVCine Top",
        "PT: TVCine Edition HD (1080P)",
        "PT: TVCine Edition",
        "PT: TVCine Emotion FHD (1080P)",
        "PT: TVCine Emotion",
        "PT: TVCine Emotion HD (1080P)",
        "PT: TVCine Action HD (1080P)",
        "PT: TVCine Action",
        "PT: Fox HD (1080P)",
        "PT: Fox Movies",
        "PT: Fox Life HD (1080P)",
        "PT: Fox Crime HD (1080P)",
        "PT: Fox Comedy HD (1080P)",
        "PT: Fox Comedy",
        "PT: Axn HD (1080P)",
        "PT: Axn Movies HD (1080P)",
        "PT: Axn White HD (1080P)",
        "PT: AMC HD (1080P)",
        "PT: AMC",
        "PT: CineMundo",
        "PT: HOLLYWOOD HD (1080P)",
        "PT: HOLLYWOOD",
        "PT: SyFy",
        "PT: 24 Kitchen HD (1080P)",
        "PT: Casa Cozinha HD (1080P)",
        "PT: Food Network HD (1080P)",
        "PT: TV Globo HD (1080P)",
        "PT: TV Globo",
        "PT: Globo Now",
        "PT: SIC RADICAL HD (1080P)",
        "PT: SIC RADICAL",
        "PT: SIC Caras HD (1080P)",
        "PT: SIC Caras",
        "PT: SIC Mulher HD (1080P)",
        "PT: SIC Mulher",
        "PT: SIC K HD (1080P)",
        "PT: SIC K",
        "PT: BBC Entertainment",
        "PT: TVI Reality",
        "PT: Canal Q",
        "PT: TLC",
        "PT: MTV Portugal HD (1080P)",
        "PT: MTV Portugal",
        "PT: Zap Viva HD (1080P)",
        "PT: Blaze HD (1080P)",
        "PT: Blaze",
        "PT: Discovery Channel",
        "PT: National Geoghraphic HD (1080P)",
        "PT: National Geographic",
        "PT: Nat Geo Wild HD (1080P)",
        "PT: Nat Geo Wild",
        "PT: Historia HD (1080P)",
        "PT: Historia",
        "PT: Odisseia HD (1080P)",
        "PT: Odisseia",
        "PT: Travel Channel HD (1080P)",
        "PT: Travel Channel",
        "PT: E! Entertainment HD (1080P)",
        "PT: E! Entertainment",
        "PT: Investigation Discovery HD (1080P)",
        "PT: Investigation Discovery",
        "PT: Crime Investigation HD (1080P)",
        "PT: Crime Investigation",
        "PT: Caça e Pesca HD (1080P)",
        "PT: Caça e Pesca",
        "PT: Cazavisión HD",
        "PT: Caçavision",
        "PT: CBS Reality",
        "PT: Fashion TV",
        "PT: Disney Channel",
        "PT: Disney Junior",
        "PT: Cartoon Network",
        "PT: Nickelodeon",
        "PT: Nick Jr",
        "PT: Canal Panda HD (1080P)",
        "PT: Canal Panda",
        "PT: Panda Biggs",
        "PT: Jim Jam",
        "PT: Biggs",
        "PT: Baby TV",
        "PT: SIC Noticias HD (1080P)",
        "PT: SIC Noticias",
        "PT: CMTV HD (1080P)",
        "PT: CMTV",
        "PT: TCV Cabo Verde",
        "PT: Euronews",
        "PT: TVI 24 HD (1080P)",
        "PT: TVI 24",
        "PT: ARTV",
        "PT: SPORT TV 1 UHD (H.265)",
        "PT: SPORT TV 1 FHD (H.265)",
        "PT: SPORT TV 1 HD (H.265)",
        "PT: SPORT TV 2 UHD (H.265)",
        "PT: SPORT TV 2 FHD (H.265)",
        "PT: SPORT TV 2 HD (H.265)",
        "PT: SPORT TV 3 HD (H.265)",
        "PT: Sport TV 4 HD (H.265)",
        "PT: Sport TV 5 HD (H.265)",
        "PT: Sport TV 6 HD (H.265)",
        "PT: SPORT TV NBA HD (H.265)",
        "PT: ELEVEN SPORTS 1 UHD (H.265)",
        "PT: ELEVEN SPORTS 1 FHD (H.265)",
        "PT: ELEVEN SPORTS 1 HD (H.265)",
        "PT: ELEVEN SPORTS 2 UHD (H.265)",
        "PT: ELEVEN SPORTS 2 FHD (H.265)",
        "PT: ELEVEN SPORTS 2 HD (H.265)",
        "PT: ELEVEN SPORTS 3 UHD (H.265)",
        "PT: ELEVEN SPORTS 3 HD (H.265)",
        "PT: BTV UHD (H.265)",
        "PT: BTV FHD (H.265)",
        "PT: BTV HD (H.265)",
        "PT: Eurosport 1 HD (H.265)",
        "PT: Eurosport 2 HD (H.265)",
        "PT: Canal 11 HD (H.265)",
        "PT: Sporting TV HD (H.265)",
        "PT: RTP 1 FHD (H.265)",
        "PT: RTP 1 HD (H.265)",
        "PT: RTP2 HD (H.265)",
        "PT: RTP3 HD (H.265)",
        "PT: SIC HD (H.265)",
        "PT: SIC FHD (H.265)",
        "PT: Sic Caras HD (H.265)",
        "PT: Sic Radical HD (H.265)",
        "PT: Sic Mulher HD (H.265)",
        "PT: SIC K HD (H.265)",
        "PT: SIC NOTICIAS FHD (H.265)",
        "PT: TVI FHD (H.265)",
        "PT: TVI HD (H.265)",
        "PT: TVI 24 HD (H.265)",
        "PT: TVI Ficcao HD (H.265)",
        "PT: TV Record HD (H.265)",
        "PT: TVI Reality HD (H.265)",
        "PT: CMTV FHD (H.265)",
        "PT: CMTV HD (H.265)",
        "PT: A BOLA HD (H.265)",
        "PT: TVCine Top HD (H.265)",
        "PT: TVCine Action FHD (H.265)",
        "PT: TVCine Edition FHD (H.265)",
        "PT: TVCine Edition HD (H.265)",
        "PT: TVCine Emotion HD (H.265)",
        "PT: AXN FHD (H.265)",
        "PT: AXN HD (H.265)",
        "PT: AXN White HD (H.265)",
        "PT: AXN Movies HD (H.265)",
        "PT: FOX FHD (H.265)",
        "PT: FOX HD (H.265)",
        "PT: FOX Movies HD (H.265)",
        "PT: Fox Crime HD (H.265)",
        "PT: Fox Comedy HD (H.265)",
        "PT: Fox Life HD (H.265)",
        "PT: NOS Studios HD (H.265)",
        "PT: Hollywood HD (H.265)",
        "PT: CineMundo HD (H.265)",
        "PT: Casa Cozinha HD (H.265)",
        "PT: AMC HD (H.265)",
        "PT: Syfy HD (H.265)",
        "PT: GLOBO HD (H.265)",
        "PT: Globo Now HD (H.265)",
        "PT: E! Entertainment HD (H.265)",
        "PT: Fuel TV HD (H.265)",
        "PT: MTV Portugal HD (H.265)",
        "PT: Porto Canal HD (H.265)",
        "PT: Zap Viva HD (H.265)",
        "PT: Odisseia HD (H.265)",
        "PT: Blaze HD (H.265)",
        "PT: 24 Kitchen HD (H.265)",
        "PT: National Geographic HD (H.265)",
        "PT: National Geographic Wild HD (H.265)",
        "PT: Investigation Discovery HD (H.265)",
        "PT: Discovery Channel HD (H.265)",
        "PT: Historia HD (H.265)",
        "PT: Travel Channel HD (H.265)",
    ],
    [
        "RO: TVR 1 FHD",
        "RO: TVR 1",
        "RO: TVR 2 FHD",
        "RO: TVR 2",
        "RO: TVR 3",
        "RO: TVR International",
        "RO: TVR Timisoara",
        "RO: TVR Tg Mures",
        "RO: TVR Cluj",
        "RO: TVR Craiova",
        "RO: TVR Iasi",
        "RO: Pro TV 4K",
        "RO: Pro TV FHD",
        "RO: Pro TV",
        "RO: Pro International",
        "RO: Pro 2 FHD",
        "RO: Pro 2",
        "RO: Pro Gold",
        "RO: ProTV Chisinau FHD",
        "RO: ProTV Chisinau",
        "RO: Pro TV News",
        "RO: Profit.ro",
        "RO: Prima TV",
        "RO: Kanal D 4K",
        "RO: Kanal D FHD",
        "RO: Kanal D HD",
        "RO: Kanal D",
        "RO: Antena International",
        "RO: Antena 1 4K",
        "RO: Antena 1 FHD",
        "RO: Antena 1 HD",
        "RO: Antena 1",
        "RO: Aleph News",
        "RO: Happy Channel HD (1080P)",
        "RO: Happy Channel",
        "RO: Antena Stars FHD",
        "RO: Antena Stars",
        "RO: Antena Comedy",
        "RO: Antena Monden",
        "RO: Canal 33 HD",
        "RO: National 24 Plus",
        "RO: Arcadia World FHD",
        "RO: Nasul TV",
        "RO: Antena 3 FHD",
        "RO: Antena 3",
        "RO: National TV",
        "RO: Digi 24 FHD",
        "RO: Digi 24 HD",
        "RO: DIGI 24",
        "RO: B1",
        "RO: Realitatea Plus TV",
        "RO: Romania TV",
        "RO: Digi Sport 4K",
        "RO: Digi Sport 1 HD (1080P)",
        "RO: DIGI Sport 1 HD",
        "RO: DIGI Sport 1",
        "RO: DIGI Sport 2 HD (1080P)",
        "RO: DIGI Sport 2",
        "RO: DIGI Sport 3 HD (1080P)",
        "RO: DIGI Sport 3",
        "RO: Digi Sport 4 HD (1080P)",
        "RO: DIGI Sport 4",
        "RO: Pro X FHD",
        "RO: Pro X",
        "RO: Telekom Sport 1 HD (1080P)",
        "RO: Telekom Sport 1 HD",
        "RO: Telekom Sport 1",
        "RO: Telekom Sport 2 HD (1080P)",
        "RO: Telekom Sport 2",
        "RO: Telekom Sport 3 HD (1080P)",
        "RO: Telekom Sport 3",
        "RO: Telekom Sport 4 HD (1080P)",
        "RO: Telekom Sport 4",
        "RO: Sport Extra",
        "RO: Look Sport FHD",
        "RO: Look Sport",
        "RO: Look Sport Plus FHD",
        "RO: Look Sport Plus",
        "RO: Look Sport 2 FHD",
        "RO: Look Sport 2",
        "RO: Look Sport 3 FHD",
        "RO: Look Sport 3",
        "RO: Eurosport 1 FHD",
        "RO: Eurosport 1",
        "RO: Eurosport 2 FHD",
        "RO: Eurosport 2",
        "RO: Auto Moto Sport",
        "RO: TV Paprika",
        "RO: E! Entertainment FHD",
        "RO: E! Entertainment",
        "RO: Digi Life FHD",
        "RO: Digi Life",
        "RO: TLC",
        "RO: Food Network",
        "RO: Fine Living",
        "RO: HGTV HD",
        "RO: Fishing and Hunting",
        "RO: DIGI World FHD",
        "RO: DIGI World",
        "RO: DIGI Animal World FHD",
        "RO: DIGI Animal World",
        "RO: Discovery FHD",
        "RO: Discovery",
        "RO: Discovery ID",
        "RO: Discovery Showcase FHD",
        "RO: Discovery Turbo Xtra FHD",
        "RO: Discovery Science FHD",
        "RO: Discovery Science",
        "RO: DocuBox HD",
        "RO: Crime Investigation",
        "RO: History FHD",
        "RO: History",
        "RO: Nat Geo FHD",
        "RO: Nat Geo",
        "RO: Nat Geo Wild FHD",
        "RO: Nat Geo Wild",
        "RO: Animal Planet FHD",
        "RO: Animal Planet",
        "RO: Travel Channel FHD",
        "RO: Travel Channel",
        "RO: Travel Mix",
        "RO: Viasat Explore FHD",
        "RO: Viasat Explore",
        "RO: Viasat History FHD",
        "RO: Viasat History",
        "RO: Viasat Nature FHD",
        "RO: Viasat Nature",
        "RO: DaVinci Learning",
        "RO: BBC Earth FHD",
        "RO: BBC Earth",
        "RO: Pescuit&Vanat FHD",
        "RO: DIGI 4K",
        "RO: Timeless Drama Channel",
        "RO: Pro Cinema",
        "RO: Cinemaration FHD",
        "RO: Cinemaration",
        "RO: Diva TV",
        "RO: TV 1000",
        "RO: Comedy Central",
        "RO: Epic Drama HD",
        "RO: AXN Romania FHD",
        "RO: AXN Romania",
        "RO: AXN White",
        "RO: AXN Black",
        "RO: AXN Spin",
        "RO: AMC FHD",
        "RO: AMC",
        "RO: Paramount Channel",
        "RO: Film Cafe",
        "RO: HBO 1 HD (1080P)",
        "RO: HBO 1",
        "RO: HBO 2 HD (1080P)",
        "RO: HBO 2",
        "RO: HBO 3 HD (1080P)",
        "RO: HBO 3",
        "RO: TNT HD",
        "RO: Film BoxExtra FHD",
        "RO: Film Box",
        "RO: FilmBox Family",
        "RO: FilmBox Premium",
        "RO: Film Now FHD",
        "RO: Film Now",
        "RO: Cinemax FHD",
        "RO: Cinemax",
        "RO: Cinemax 2 FHD",
        "RO: Bollywood TV HD",
        "RO: Bollywood TV",
        "RO: Disney Jr",
        "RO: Disney Channel",
        "RO: Nickelodeon",
        "RO: Nicktoons",
        "RO: Nick Jr",
        "RO: Cartoon Network",
        "RO: Minimax",
        "RO: Boomerang",
        "RO: Duck TV",
        "RO: Jim Jam",
        "RO: U TV FHD",
        "RO: U TV",
        "RO: KISS TV",
        "RO: 1 Music Channel",
        "RO: VH1 Classic",
        "RO: Favorit TV",
        "RO: Etno TV HD",
        "RO: Hora TV",
        "RO: Taraf TV HD",
        "RO: MTV Dance",
        "RO: Mooz Dance HD",
        "RO: Alfa & Omega TV",
        "RO: Credo TV",
        "RO: Magic TV",
        "RO: Neptun TV",
        "RO: Zu TV FHD",
        "RO: Bucuresti TV",
        "RO: Trinitas FHD",
        "RO: Speranta",
        "RO: Skynet XMASS",
        "RO: Skynet DRAMA",
        "RO: Skynet-Mafia",
        "RO: Star Action",
        "RO: Two and a Half Men",
        "RO: Colectie Romania",
        "RO: Skynet Colectie",
        "RO: 4K Star",
        "RO: Skynet Legendary",
        "RO: Skynet Game Of Thrones",
        "RO: Skynet Romantic",
        "RO: Sacrificiul",
        "RO: CineMovies Manele",
        "RO: Skynet Desene Animate",
        "RO: Best",
        "RO: Skynet Actiune",
        "RO: Skynet Aventura",
        "RO: Skynet Arte Martiale",
        "RO: Skynet Jean-Claude Van Damme",
        "RO: Romani au Talent",
        "RO: Skyline Romani au Talent 2020",
        "RO: Skynet Bollywood",
        "RO: Skynet Las Fierbinti",
        "RO: Las Fierbinti S01-S04",
        "RO: X Factor",
        "RO: Skynet Copii",
        "RO: Skynet iUmor",
        "RO: Skynet History",
        "RO: Skynet Movies Box Office",
        "RO: Skynet Western",
        "RO: Skynet Filme Religioase",
        "RO: Skynet Asia Express 2020",
        "RO: Skynet Vlad S 03",
        "RO: Merry Christmas",
        "RO: Flash 2020",
        "RO: Flash X Factor",
        "RO: Flash X Factor",
        "RO: Flash Documentare",
        "RO: Flash Premiere",
        "RO: Flash Chefi La Cutite",
        "RO: Flash Culinar",
        "RO: Prime Comedie",
        "RO: Prime Animatie",
        "RO: Prime Actiune",
        "RO: Prime Latino",
        "RO: Prime Horror",
        "RO: Prime Kids",
        "RO: Prime Sci-fi",
        "RO: Prime Bollywod",
        "RO: Prime History",
        "RO: Prime Cinema",
        "RO: Prime Aventura",
        "RO: Prime Survivor Romania",
        "RO: Prime Gasca Zurli",
        "RO: Prime Asia Express",
        "RO: Prime The Mentalist",
        "RO: Prime Kids Germana",
        "RO: Prime Culinar",
        "RO: Prime Comedie 1",
        "RO: Prime Western",
        "RO: Prime Thriller",
        "RO: Prime Booba",
        "RO: Prime De Craciun",
        "RO: 1M COMEDIE",
        "RO: 1M Aventura Fantezie",
        "RO: 1M Premiera",
        "RO: 1M BalkanOrient",
        "RO: 1M SCI-Fi",
        "RO: 1M Cinema Romania",
        "RO: 1M Actiune",
        "RO: 1M Horror",
        "RO: 1M Comando",
        "RO: 1M Las Fierbinti",
        "RO: 1M Clasicii Comediei",
        "RO: 1M Chefi La Cutite",
        "RO: 1M Mistere Nerezolvate",
        "RO: Simply Net Animatie",
        "RO: Simply Net Booba",
        "RO: Simply Net Las Firebinti",
        "RO: Simply Net Actiune",
        "RO: Simply Net Cinema",
        "RO: Simply Net Western",
        "RO: Simply Net History",
        "RO: Simply Net Bollywod",
        "RO: Simply Net 4k",
        "RO: Simply Net Kung Fu",
        "RO: Simply Net Comedie",
        "RO: Simply Net Van Damme",
        "RO: Simply Net Iumor",
        "RO: Simply Net Fiction",
        "RO: Simply Net Horror",
        "RO: Simply Net Filme Romanesti",
        "RO: Simply Net Music Mix",
        "RO: Simply Net Muzica De Ascultare",
        "RO: Simply Net Jet Li & Bruce Lee",
        "RO: Simply Net Thriler",
        "RO: Simply Net Jackie Chan & Donnie Yen",
        "RO: EVO Comedy 1 Full HD",
        "RO: EVO Comedy 3 Full HD",
        "RO: EVO Comedy 2 Full HD",
        "RO: EVO Action 1 Full HD",
        "RO: EVO Action 2 Full HD",
        "RO: EVO Action 3 Full HD",
        "RO: EVO Horror 1 Full HD",
        "RO: EVO Horror 3 Full HD",
        "RO: EVO Horror 2 Full HD",
        "RO: EVO iUmor Sezon 8",
        "RO: EVO Basme pt Copii",
        "RO: EVO Cinema 1 4K",
        "RO: EVO Cinema 2 4K",
        "RO: EVO Cinema 3 4K",
        "RO: EVO Cinema 4 4K",
        "RO: EVO Cinema 5 4K",
        "RO: EVO Cinema 6 4K",
        "RO: EVO Cinema 7 4K",
        "RO: EVO Cinema 8 4K",
        "RO: EVO Masha si Ursul",
        "RO: EVO Peppa Pig",
        "RO: EVO Tra La La",
        "RO: EVO Booba Kids",
        "RO: EVO Looney Tunes",
        "RO: HBO Sci-Fi",
        "RO: HBO Aventura",
        "RO: HBO Horror",
        "RO: HBO Comedie",
        "RO: HBO Actiune",
        "RO: HBO Premiere 2020",
        "RO: Cinema Kids-Dublat",
        "RO: Cinema Crime",
        "RO: Cinema Drama",
        "RO: Cinema Fiction",
        "RO: Cinema Comedie",
        "RO: Cinema Actiune",
        "RO: Cinema Aventura",
        "RO: Cinema Thirller",
        "RO: TRUE 4K CINEMA(fibra)",
        "RO: Cinema Premiere 2020",
    ],
    [
        "RU: Match! Futbol 1 FHD",
        "RU: Match! Futbol 1",
        "RU: Match! Futbol 2 FHD",
        "RU: Match! Futbol 2",
        "RU: Match! Futbol 3 FHD",
        "RU: Match! Futbol 3",
        "RU: Match! Nash Sport",
        "RU: Match!",
        "RU: Match! Premiere",
        "RU: Match! Igra FHD",
        "RU: Match! Igra",
        "RU: Match! Arena FHD",
        "RU: Match! Arena",
        "RU: Match! Planeta",
        "RU: Match! Box",
        "RU: KHL TV FHD",
        "RU: KHL TV",
        "RU: Viasat Sport FHD",
        "RU: Viasat Sport East",
        "RU: Eurosport 1 FHD",
        "RU: Eurosport 1",
        "RU: Eurosport 2 FHD",
        "RU: Eurosport 2",
        "RU: Extreme Sports",
        "RU: Астрахань.Ru Sport",
        "RU: Московский образовательный",
        "RU: 2x2",
        "RU: Липецкое время",
        "RU: Тюменское время",
        "RU: Russia 1 FHD",
        "RU: Russia К",
        "RU: Russia 24",
        "RU: Мир FHD",
        "RU: Мир",
        "RU: 24 Techno FHD",
        "RU: 24 Docu",
        "RU: Moscow 24",
        "RU: РОДНОЕ КИНО",
        "RU: Amedia 1 HD (1080P)",
        "RU: Amedia 2",
        "RU: Amedia Home Of HBO",
        "RU: Amedia Hit",
        "RU: VIP Comedy FHD",
        "RU: VIP Megahit FHD",
        "RU: VIP Premium FHD",
        "RU: VIP Serial FHD",
        "RU: Kinopokaz 1 FHD",
        "RU: Kinopokaz 2 FHD",
        "RU: Bollywood HD",
        "RU: Kino TV HD",
        "RU: Kino Hit",
        "RU: Kino Plus",
        "RU: Kino Klub",
        "RU: Kino Mix HD",
        "RU: Kino Svidanie",
        "RU: Kinosemya",
        "RU: KinoKomedija TV",
        "RU: Paramount Channel",
        "RU: Paramount Comedy",
        "RU: Sony Channel",
        "RU: Sony Sci-Fi",
        "RU: Sony Turbo",
        "RU: TV 1000",
        "RU: TV 1000 Action",
        "RU: TV 1000 Ruskoe Kino",
        "RU: Dom Kino Premium FHD",
        "RU: Крым 24",
        "RU: Serial",
        "RU: Detskiy Mir",
        "RU: Dom Kino",
        "RU: Domashniye Zhivotnyye",
        "RU: Мир белгородья",
        "RU: Nashe Noveo Kino",
        "RU: Russia Illusion",
        "RU: Russia Roman",
        "RU: ТВР 24 (Сергиев Посад)",
        "RU: India TV",
        "RU: ТНТ FHD",
        "RU: ТНТ 4",
        "RU: ТНТ",
        "RU: ТНТ Music",
        "RU: Domashniy",
        "RU: Че",
        "RU: HTB FHD",
        "RU: HTB",
        "RU: Usadba TV",
        "RU: GlobalStar TV",
        "RU: Fox HD",
        "RU: Fox",
        "RU: Fox Life",
        "RU: Perviy Kanal FHD",
        "RU: Perviy Kanal",
        "RU: Perviy Meteo",
        "RU: Perviy Obrazovatelniy",
        "RU: Perviy Vegetarianskiy",
        "RU: Fine Living",
        "RU: Волгоград 1 HD",
        "RU: TLC",
        "RU: Food Network",
        "RU: Смайлик ТВ",
        "RU: Animal Planet FHD",
        "RU: Animal Planet",
        "RU: Discovery FHD",
        "RU: Discovery Science",
        "RU: investigation discovery FHD",
        "RU: Nat Geo FHD",
        "RU: National Geographic",
        "RU: Nat Geo wild FHD",
        "RU: DTX FHD",
        "RU: History",
        "RU: Travel Channel FHD",
        "RU: Viasat Explorer",
        "RU: Viasat History FHD",
        "RU: Viasat History",
        "RU: Viasat Nature",
        "RU: ЛДПР ТВ HD",
        "RU: HD life FHD",
        "RU: Style",
        "RU: CBS Reality",
        "RU: Da Vinci Learning",
        "RU: Disney Channel",
        "RU: Boomerang",
        "RU: Cartoon Network",
        "RU: Nickelodeon FHD",
        "RU: Nickelodeon",
        "RU: NickJ R",
        "RU: Gulli Girl",
        "RU: JimJam",
        "RU: TiJi",
        "RU: Ocean TV",
        "RU: LNK FHD",
        "RU: Hunting and fishing",
        "RU: Friday HD",
        "RU: РБК-ТВ",
        "RU: Ren TV",
        "RU: Sarafan",
        "RU: CTC Love",
        "RU: Kids",
        "RU: Kids World",
        "RU: Super Kids",
        "RU: Auto Plus",
        "RU: ТВ3",
        "RU: ТВ Центр",
        "RU: Telecafe HD",
        "RU: Три Ангела HD",
        "RU: Шансон ТВ",
        "RU: Home Cinema",
        "RU: Kuchnia TV",
        "RU: La-Minor HD",
        "RU: МОЯ ПЛАНЕТА",
        "RU: Eureka FHD",
        "RU: Надежда",
        "RU: Muzhskoe kino",
        "RU: Muz TV",
        "RU: HAWE HOBEW",
        "RU: Mult",
        "RU: Multimania",
        "RU: Nauka 2.0",
        "RU: Nostalgija",
        "RU: 8 КАНАЛ",
        "RU: Music First",
        "RU: Bridge TV",
        "RU: Bridge Hits",
        "RU: Bridge TV Dance",
        "RU: RTG TV",
        "RU: Detektiv.TV",
        "RU: Drive ТВ",
        "RU: Belarus 24",
        "RU: EuroNews",
        "RU: Bloomberg TV",
        "RU: Kanal 5",
        "RU: Zvezda FHD",
        "RU: MTV Россия",
        "RU: Спас",
        "RU: СТС HD",
        "RU: RTR Planeta",
        "RU: Saint Petersbourg",
        "RU: TV XXI (TV21)",
        "RU: NANO HD",
        "RU: Туган Тел",
        "RU: Zee TV",
        "RU: Noviy Mir",
        "RU: Boxe TB",
        "RU: Известия FHD",
        "RU: Food HD",
        "RU: Tochka Otriva",
        "RU: Home",
        "RU: Illusion+",
        "RU: Europa Plus",
        "RU: Pravo",
        "RU: Horse World HD",
        "RU: 365 Days",
        "RU: Тольятти 24",
        "RU: Shant TV",
        "RU: Russia Xtream HD",
        "RU: LEN 24",
        "RU: AMC",
        "RU: 365 Days",
        "RU: ANI",
        "RU: Univer TV",
        "RU: Music Box",
        "RU: МТВ (Волгоград)",
        "RU: Brodilo",
        "RU: 360°",
        "RU: ZOO Park",
        "RU: BMECTE FM",
    ],
    [
        "|SRB| RTS 1",
        "|SRB| RTS 2",
        "|SRB| RTRS",
        "|SRB| PRVA TV",
        "|SRB| PRVA PLUS",
        "|SRB| PRVA WORLD",
        "|SRB| PRVA MAX",
        "|SRB| PRVA KICK",
        "|SRB| NOVA S",
        "|SRB| PINK",
        "|SRB| PINK 2",
        "|SRB| PINK 3 INFO",
        "|SRB| PINK SOAP",
        "|SRB| PINK KIDS",
        "|SRB| PINK ZABAVA",
        "|SRB| PINK WOLRD",
        "|SRB| PINK WESTERN",
        "|SRB| PINK THRILLER",
        "|SRB| PINK MOVIES",
        "|SRB| PINK ROMANCE",
        "|SRB| PINK SERIJE",
        "|SRB| PINK ACTION",
        "|SRB| PINK REALITY",
        "|SRB| PINK PREMIUM",
        "|SRB| PINK PLUS",
        "|SRB| PINK PEDIA",
        "|SRB| PINK MUSIC",
        "|SRB| PINK HORROR",
        "|SRB| PINK HITS 2",
        "|SRB| PINK FOLK 2",
        "|SRB| PINK LOL",
        "|SRB| PINK FILM",
        "|SRB| PINK SCI-FI & FANTASY",
        "|SRB| PINK EXTRA",
        "|SRB| PINK CRIME & MYSTERY",
        "|SRB| PINK COMEDY",
        "|SRB| PINK BIH",
        "|SRB| PINK HAHA",
        "|SRB| NATIONAL GEOGRAPHIC",
        "|SRB| HISTORY CHANNEL",
        "|SRB| ANIMAL PLANET",
        "|SRB| NICKELODEON",
        "|SRB| 24 KITCHEN",
        "|SRB| FOX MOVIES",
        "|SRB| FOX LIFE",
        "|SRB| FOX CRIME",
        "|SRB| EUROSPORT 2",
        "|SRB| EUROSPORT",
        "|SRB| DISCOVERY",
        "|SRB| CINESTAR PREMIERE 2",
        "|SRB| CINESTAR PREMIERE 1",
        "|SRB| CINEMAX",
        "|SRB| CINEMAX 2",
        "|HRT| CINESTAR TV ACTION",
        "|HRT| RTL TELEVIZIJA",
        "|HRT| RTL 2",
        "|HRT| RTL LIVING",
        "|HRT| NOVA TV",
        "|HRT| KLASIK TV",
        "|HRT| HRT 1",
        "|HRT| HRT 2",
        "|HRT| HRT 3",
        "|HRT| HRT 4",
        "|HRT| HBO ADRIA",
        "|HRT| HAYAT HD",
        "|HRT| BHT 1",
        "|HRT| HAYAT FLOK",
        "|HRT| HAYAT PLUS",
        "|HRT| HAYAT BH",
        "|HRT| HAPPY",
        "|HRT| DOMA TV",
        "|HRT| BHT 1",
        "|HRT| AXN ADRIA",
    ],
    [
        "|SE| VIASAT FILM PREMIERE [Multi-Sub]",
        "|SE| VIASAT FILM ACTION",
        "|SE| VIASAT FILM HITS",
        "|SE| VIASAT SERIES",
        "|SE| VIASAT NATURE",
        "|SE| VIASAT HISTORY",
        "|SE| VIASAT EXPLORE",
        "|SE| VIASAT SPORT",
        "|SE| VIASAT SPORT PREMIUM",
        "|SE| VIASAT FOTBOLL HD",
        "|SE| VIASAT HOCKEY HD",
        "|SE| VIASAT MOTOR",
        "|SE| VIASAT GOLF HD",
        "|SE| C MORE LIVE 1",
        "|SE| C MORE LIVE 3",
        "|SE| C MORE LIVE 2",
        "|SE| C MORE LIVE 4",
        "|SE| C MORE SPORT HD",
        "|SE| C MORE HITS HD",
        "|SE| C MORE STARS",
        "|SE| C MORE SERIES",
        "|SE| C MORE GOLF HD",
        "|SE| C MORE FOOTBALL HD",
        "|SE| C MORE HOCKEY HD",
        "|SE| C MORE FIRST HD",
        "|SE| SVT 1 HD",
        "|SE| SVT 2 HD",
        "|SE| SVT24/B",
        "|SE| SVT Kunskapskanalen HD",
        "|SE| TV3 HD",
        "|SE| TV4 HD",
        "|SE| TV4 GULD",
        "|SE| TV4 FILM",
        "|SE| BOOMERANG",
        "|SE| TV 4 SPORT",
        "|SE| TV4 FAKTA",
        "|SE| TV6",
        "|SE| TV8",
        "|SE| TV10",
        "|SE| TV 12 FHD",
        "|SE| SJUAN",
        "|SE| SF-KANALEN",
        "|SE| KANAL 5",
        "|SE| KANAL 9",
        "|SE| KANAL 11 FHD",
        "|SE| ANIMAL PLANET",
        "|SE| NATIONAL GEOGRAPHIC",
        "|SE| NAT GEO WILD",
        "|SE| DISCOVERY WORLD",
        "|SE| DISCOVERY CHANNEL",
        "|SE| INVESTIGATION DISCOVERY",
        "|DK| MTV",
        "|SE| EUROSPORT 1 HD",
        "|SE| EUROSPORT 2 HD",
        "|SE| SPORTKANALEN",
        "|SE| DR RAMASJANG",
        "|SE| N4 ICELAND",
        "|SE| TLC HD",
        "|SE| TNT",
        "|SE| NICKELODEON",
        "|SE| DISNEY JR (Multi Audio)",
        "|SE| CARTOON NETWORK SE",
        "|SE| DISNEY CHANNEL",
        "|SE| DISNEY XD (Multi Audio)",
        "|DK| TV2 SPORT",
        "|DK| TV3 SPORT HD",
        "|DK| TV3 MAX",
        "|DK| TV2",
        "|DK| TV2 FYN",
        "|DK| TV2 ZULU",
        "|DK| TV2 CHARLIE",
        "|NO| TV2 LIVSSTIL",
        "|DK| TV2 BORNHOLM",
        "|DK| TV2 Ost",
        "|DK| TV2 LORRY",
        "|DK| TV2 Ostjylland",
        "|DK| TV2 NEWS",
        "|DK| TV3",
        "|DK| TV3 PLUS",
        "|DK| KANAL 4",
        "|DK| KANAL 5",
        "|DK| DISCOVERY ID",
        "|DK| DR 1",
        "|DK| DR 2",
        "|DK| DK4",
        "|SE| BBC EARTH HD",
        "|DK| 6 EREN HD",
        "|DK| VIASAT FILM FAMILY",
        "|NO| VIASAT 4",
        "|NO| TV NORGE",
        "|NO| TV2 NORGE",
        "|NO| TV2 ZEBRA",
        "|NO| TV2 NYHETSKANALEN",
        "|NO| TV2 SPORTKANALEN",
        "|NO| TV3",
        "|NO| TV6",
        "|NO| NRK1",
        "|NO| NRK2",
        "|NO| NRK3/SUPER",
        "|NO| VOX",
        "|NO| MAX",
        "|NO| EUROSPORT NORGE",
        "|NO| MTV",
        "|FI| C MORE LIVE 1",
        "|FI| C MORE LIVE 2",
        "|FI| YLE 1 HD",
        "|FI| YLE 2 HD",
        "|FI| NELONEN",
        "|FI| NELONEN4 MAAILMA",
        "|FI| NELONEN4 PRIME",
        "|FI| DISCOVERY CHANNEL",
        "|FI| MTV3",
        "|FI| LIV",
        "|FI| TV7",
    ],
    [
        "|TR| BEIN SPORTS HABER",
        "|TR| BEIN SPORTS HD 1",
        "|TR| BEIN SPORTS HD 2",
        "|TR| BEIN SPORTS HD 3",
        "|TR| BEIN SPORTS HD 4",
        "|TR| SMART SPOR HD",
        "|TR| BEIN SPORTS 1 MAX HD",
        "|TR| TIVIBU SPOR 1 HD",
        "|TR| TIVIBU SPOR 2 HD",
        "|TR| TIVIBU SPOR 3 HD",
        "|TR| TIVIBU SPOR 4 HD",
        "|TR| S SPORTS TV HD",
        "|TR| TRT SPOR HD",
        "|TR| TRT SPOR 2 HD",
        "|TR| NTV HABER HD (1080P)",
        "|TR| MOVIESMART TURK",
        "|TR| MOVIESMART FEST HD",
        "|TR| MOVIESMART FAMILY HD",
        "|TR| MOVIESMART PREMIUM 2 HD",
        "|TR| MOVIESMART PREMIUM HD",
        "|TR| KRAL TV HD",
        "|TR| TLC",
        "|TR| TV8.5 HD",
        "|TR| TVNET",
        "|TR| BENGU TURK HD",
        "|TR| HISTORY CHANNEL HD",
        "|TR| BLOOMBERG HT HD",
        "|TR| CNN TURK HD",
        "|TR| BEIN GURME HD",
        "|TR| BEIN IZ HD",
        "|TR| BEIN MOVIES TURK HD",
        "|TR| BEIN BOX OFFICE 1",
        "|TR| BEIN BOX OFFICE 2",
        "|TR| BEIN BOX OFFICE 3",
        "|TR| SINEMAX TURK HD",
        "|TR| SALON 1 HD",
        "|TR| SALON 2 HD",
        "|TR| SALON 3 HD",
        "|TR| SINEMA AILE HD",
        "|TR| SINEMA TV 1 HD",
        "|TR| SINEMA TV 1001 HD",
        "|TR| HALK TV",
        "|TR| KANAL 3",
        "|TR| DIGI MAX",
        "|TR| VIASAT HISTORY HD",
        "|TR| TV 2",
        "|TR| TV 8 HD",
        "|TR| BBC EARTH HD",
        "|TR| ULKE TV HD",
        "|TR| TRT 1 HD",
        "|TR| TRT 3 HD",
        "|TR| TRT TURK",
        "|TR| TRT WORLD",
        "|TR| TRT MUZIK HD",
        "|TR| TRT OKUL",
        "|TR| TRT HABER HD",
        "|TR| TRT DIYANET",
        "|TR| TRT COCUK",
        "|TR| TRT BELGESEL HD",
        "|TR| TRT AVAZ",
        "|TR| TRT KURDI",
        "|TR| TGRT HABER",
        "|TR| TGRT BELGESEL",
        "|TR| TGRT EU",
        "|TR| EUROSPORT 1 HD",
        "|TR| EUROSPORT 2 HD",
        "|TR| BURSA SPOR TV",
        "|TR| GS TV",
        "|TR| FBTV HD",
        "|TR| A SPOR HD",
        "|TR| STAR TV HD",
        "|TR| SHOW TV HD",
        "|TR| POWER TURK HD",
        "|TR| PLANET TURK",
        "|TR| KANAL 42 KONYA",
        "|TR| NICK JR",
        "|TR| CAPTAIN MOVIES 4",
        "|TR| NICKELODEON HD",
        "|TR| CAPTAIN MOVIES 3",
        "|TR| CAPTAIN MOVIES 2",
        "|TR| 24 HABER HD",
        "|TR| EUROSTAR HD",
        "|TR| 360 TV HD",
        "|TR| MINIKA COCUK",
        "|TR| MINIKA GO",
        "|TR| KANAL A",
        "|TR| KANAL D",
        "|TR| KANAL B",
        "|TR| KANAL 7 HD",
        "|TR| KANAL 24",
        "|TR| A HABER HD",
        "|TR| HABER TURK HD",
        "|TR| FOX TV HD",
        "|TR| FX HD",
        "|TR| FOX CRIME",
        "|TR| DREAM TV",
        "|TR| DREAM TURK",
        "|TR| DISNEY CHANNEL",
        "|TR| DISNEY JR",
        "|TR| CARTON NETWORK",
        "|TR| SEMERKAND TV HD",
        "|TR| ATV HD",
        "|TR| BEYAZ HD",
        "|TR| TVEM",
        "|TR| CAY TV",
        "|TR| CEM",
        "|TR| NR1 HD",
        "|TR| NR1 TURK HD",
        "|TR| NR1 ASK",
        "|TR| NR1 DAMAR",
        "|TR| KRAL POP TV",
        "|TR| NATIONAL GEO PEOPLE HD",
        "|TR| NAT GEO WILD HD",
        "|TR| NAT GEO HD",
        "|TR| DISCOVERY CHANNEL HD",
        "|TR| DISCOVERY ID",
        "|TR| DISCOVERY SCIENCE HD",
        "|TR| DAVINCI LEARNING",
        "|TR| DMAX",
        "|TR| ANIMAL PLANET HD",
        "|TR| POWER TV HD",
        "|TR| SHOW MAX",
        "|TR| TEVE 2",
        "|TR| UCANKUS",
        "|TR| AS TV BURSA",
        "|TR| BABY TV",
        "|TR| BJK TV",
        "|TR| TRT EBA TV İlkokul",
        "TR: TRT 4K",
    ],
    [
        "DSTV (AF) CCTV 4",
        "DSTV (AF) DeutscheWelle",
        "DSTV (AF) RTP Internacional",
        "DSTV (AF) RAI Italia",
        "DSTV (AF) Africa news",
        "DSTV (AF) Euro News",
        "DSTV (AF) NDTV 247",
        "DSTV (AF) Business Day TV",
        "DSTV (AF) Bloomberg",
        "DSTV (AF) CNBC Africa",
        "DSTV (AF) CGTN News",
        "DSTV (AF) Parliamentary Service",
        "DSTV (AF) Russia Today",
        "DSTV (AF) AlJazeera",
        "DSTV (AF) Newzroom Afrika",
        "DSTV (AF) SABC News",
        "DSTV (AF) eNCA",
        "DSTV (AF) CNN International",
        "DSTV (AF) BBC World News",
        "DSTV (AF) Emmanuel TV",
        "DSTV (AF) Hilaal TV",
        "DSTV (AF) TBN Africa",
        "DSTV (AF) Faith",
        "DSTV (AF) Dumisa",
        "DSTV (AF) One Gospel",
        "DSTV (AF) eMovies Extra",
        "DSTV (AF) eMovies",
        "DSTV (AF) eTV",
        "DSTV (AF) SABC 3",
        "DSTV (AF) SABC 2",
        "DSTV (AF) SABC 1",
        "DSTV (AF) Boomerang",
        "DSTV (AF) Sound City",
        "DSTV (AF) Cartoon Network",
        "DSTV (AF) ZTN Prime",
        "DSTV (AF) 3KTV",
        "DSTV (AF) Yadah TV",
        "DSTV (AF) Zimbabwe TV",
        "DSTV (AF) Maximo 1 Champions",
        "DSTV (AF) Racing 240",
        "DSTV (AF) Commonwealth Games 2",
        "DSTV (AF) Commonwealth Games 1",
        "DSTV (AF) Big Brother Naija Xtra",
        "DSTV (AF) Big Brother Naija",
        "DSTV (AF) Idols Extra",
        "DSTV (AF) Ignition",
        "DSTV (AF) Curiosity Channel",
        "DSTV (AF) Wild Earth",
        "DSTV (AF) National Geographic WILD",
        "DSTV (AF) National Geographic",
        "DSTV (AF) Peoples Weather",
        "DSTV (AF) The Home Channel",
        "DSTV (AF) Food Network",
        "DSTV (AF) BBC Lifestyle",
        "DSTV (AF) Honey",
        "DSTV (AF) One ZED",
        "DSTV (AF) Investigation Discovery",
        "DSTV (AF) ROK",
        "DSTV (AF) Star Life",
        "DSTV (AF) Zee World",
        "DSTV (AF) Novela Magic",
        "DSTV (AF) Mzansi Bioskop",
        "DSTV (AF) Mzansi Wethu",
        "DSTV (AF) Zambezi Magic",
        "DSTV (AF) Mzansi Magic",
        "DSTV (AF) Moja Love",
        "DSTV (AF) Real Time",
        "DSTV (AF) Africa Magic Family",
        "DSTV (AF) Africa Magic Urban",
        "DSTV (AF) Africa Magic Epic",
        "DSTV (AF) Africa Magic Showcase",
        "DSTV (AF) kykNET Nou",
        "DSTV (AF) kykNET and Kie",
        "DSTV (AF) kykNET",
        "DSTV (AF) Maisha Magic Movies",
        "DSTV (AF) TNT Africa",
        "DSTV (AF) Discovery Family",
        "DSTV (AF) TLC Entertainment",
        "DSTV (AF) TL Novelas",
        "DSTV (AF) CBS Reality",
        "DSTV (AF) MTV",
        "DSTV (AF) Entertainment TV",
        "DSTV (AF) Comedy Central",
        "DSTV (AF) Discovery Channel",
        "DSTV (AF) BBC Brit",
        "DSTV (AF) Telemundo",
        "DSTV (AF) Universal TV",
        "DSTV (AF) Me",
        "DSTV (AF) KIX",
        "DSTV (AF) Studio Universal",
        "DSTV (AF) 1 Magic",
        "DSTV (AF) M-Net",
        "DSTV (AF) TRACE Africa",
        "DSTV (AF) TRACE Urban",
        "DSTV (AF) MTV Hits",
        "DSTV (AF) MTV Base",
        "DSTV (AF) Mzansi Magic Music",
        "DSTV (AF) Channel O",
        "DSTV (AF) Mindset",
        "DSTV (AF) Da Vinci",
        "DSTV (AF) PBS Kids",
        "DSTV (AF) Nickelodeon",
        "DSTV (AF) JimJam",
        "DSTV (AF) Disney Junior",
        "DSTV (AF) NickTOONS",
        "DSTV (AF) Nick JR",
        "DSTV (AF) Cbeebies",
        "DSTV (AF) Disney Channel",
        "DSTV (AF) HISTORY",
        "DSTV (AF) Spice TV",
        "DSTV (AF) TRACE Gospel",
        "DSTV (AF) Travel Channel",
        "DSTV (AF) fliekNET",
        "DSTV (AF) VIA",
        "DSTV (AF) M-Net Movies 1",
        "DSTV (AF) M-Net Movies 2",
        "DSTV (AF) M-Net Movies 3",
        "DSTV (AF) M-Net Movies 4",
        "DSTV (AF) Sky News",
        "DSTV (AF) Super Sports Action",
        "DSTV (AF) Super Sports Blitz",
        "DSTV (AF) BET",
        "DSTV (AF) WWE Channel",
        "DSTV (AF) Ginxe Sports TV",
        "DSTV (AF) Super Sports OTT 8",
        "DSTV (AF) Super Sports OTT 7",
        "DSTV (AF) Super Sports OTT 6",
        "DSTV (AF) Super Sports OTT 5",
        "DSTV (AF) Super Sports OTT 4",
        "DSTV (AF) Super Sports OTT 3",
        "DSTV (AF) Super Sports OTT 2",
        "DSTV (AF) Super Sports OTT 1",
        "DSTV (AF) Super Sports MAXimo 1",
        "DSTV (AF) Super Sports Motorsport",
        "DSTV (AF) Super Sports Tennis",
        "DSTV (AF) Super Sports Cricket",
        "DSTV (AF) Super Sports Variety 4",
        "DSTV (AF) Super Sports Variety 3",
        "DSTV (AF) Super Sports Variety 2",
        "DSTV (AF) Super Sports Variety 1",
        "DSTV (AF) Super Sports LaLiga",
        "DSTV (AF) Super Sports Rugby",
        "DSTV (AF) Super Sports Football",
        "DSTV (AF) Super Sports Premier League",
        "DSTV (AF) Super Sports Football Plus",
        "DSTV (AF) Super Sports Grandstand",
        "DSTV (AF) Super Sports PSL",
        "DSTV (AF) Dreamworks",
        "DSTV (AF) ESPN2",
        "DSTV (AF) ESPN",
    ],
    [
        "ZA | ESPN 1",
        "ZA | ESPN 2",
        "ZA | SABC 1",
        "ZA | SABC 2",
        "ZA | SABC 3",
        "ZA | SABC News",
        "ZA | M-Net",
        "ZA | M-Net +1",
        "ZA | M-Net Movies 1 HD",
        "ZA | M-Net Movies 2 HD",
        "ZA | M-Net Movies 3 HD",
        "ZA | M-Net Movies 4 HD",
        "ZA | M-Net City",
        "ZA | 1Magic",
        "ZA | Africa Magic Family",
        "ZA | Africa Magic Epic",
        "ZA | Africa Magic Urban",
        "ZA | Africa Magic Igbo",
        "ZA | Africa Magic Showcase",
        "ZA | Africa Magic Yoruba",
        "ZA | e.TV",
        "ZA | e TV Extra",
        "ZA | eMovies",
        "ZA | eMovies Extra",
        "ZA | eNCA",
        "ZA | eToonz",
        "ZA | kykNET",
        "ZA | kykNET Nou!",
        "ZA | KYKNET & KIE",
        "ZA | Mzansi Bioskop",
        "ZA | Mzansi Magic Music",
        "ZA | Mzansi Magic",
        "ZA | Mzansi Wethu",
        "ZA | Fox life",
        "ZA | BBC Brit",
        "ZA | BBC Earth",
        "ZA | BBC Lifestyle",
        "ZA | JimJam",
        "ZA | nickelodeon",
        "ZA | Nick JR",
        "ZA | Disney Channel",
        "ZA | Disney Junior",
        "ZA | Nicktoons",
        "ZA | PBS Kids",
        "ZA | Boomerang",
        "ZA | Cartoon Network",
        "ZA | Cbeebies",
        "ZA | Bay TV",
        "ZA | Comedy Central",
        "ZA | National Geographic",
        "ZA | Nat Geo Wild",
        "ZA | National Geo Wild",
        "ZA | Investigation Discovery",
        "ZA | History",
        "ZA | Discovery Family",
        "ZA | Discovery Channel",
        "ZA | Travel Channel",
        "ZA | Cape Town TV",
        "ZA | CBS Reality",
        "ZA | CBS Justice",
        "ZA | Channel O",
        "ZA | CNBC Africa",
        "ZA | Daystar",
        "ZA | Spice TV",
        "ZA | Dumisa",
        "ZA | Emmanuel TV",
        "ZA | EVA",
        "ZA | Fashion One",
        "ZA | GauTV",
        "ZA | Ginx eSports TV",
        "ZA | HGTV",
        "ZA | iTV Networks",
        "ZA | Star Life",
        "ZA | Zee World",
        "ZA | MojaLove",
        "ZA | MTV",
        "ZA | MTV Music 24",
        "ZA | MTV Base",
        "ZA | Newzroom Afrika",
        "ZA | People Weather",
        "ZA | ROK",
        "ZA | SOUND CITY",
        "ZA | TellyTrack",
        "ZA | The Home Channel",
        "ZA | TV Mall",
        "ZA | VIA",
        "ZA | WWE Superslam",
        "ZA | One Gospel",
        "ZA | Soweto TV",
        "ZA | Studio Universal",
        "ZA | Universal TV",
        "ZA | E! Entertainment",
        "ZA | Sundance HD",
        "ZA | Bet",
        "ZA | Lifetime",
        "ZA | Food Network",
        "ZA | TBN Africa",
        "ZA | TLC",
        "ZA | TNT Africa",
        "ZA | Tshwane TV",
        "ZA | Vuzu",
        "ZA | Sky News",
        "ZA | Business Day TV",
        "ZA | BVN",
        "ZA | Curiosity Stream HD",
        "ZA | Da Vinci",
        "ZA | Faith",
        "ZA | Ignition",
        "ZA | Lesotho TV",
        "ZA | Mindset",
        "ZA | 1KZN",
        "ZA | Parliamentary Service",
        "ZA | Big Brother Naija",
        "ZA | Joy Prime",
    ],
    [
        "XXX:Hustler HD (18+)",
        "XXX:Redlight HD (18+)",
        "XXX:Prıvate TV HD (18+)",
        "XXX:Dorcel HD (18+)",
        "XXX:Dorcel XXX HD (18+)",
        "XXX:Vivid Red HD (18+)",
        "XXX:Erox HD (18+)",
        "XXX:Erox XXX HD (18+)",
        "XXX:Penthouse Gold HD (18+)",
        "XXX:Penthouse Quickies HD (18+)",
        "XXX:Penthouse Passion HD (18+)",
        "XXX:Super One HD (18+)",
        "XXX:Evil Angel HD (18+)",
        "XXX:Dream Porn HD (18+)",
        "XXX:Vivid TV Europe (18+)",
        "XXX:DUSK! (18+)",
        "XXX:XXL (18+)",
        "XXX:Passie XXX (18+)",
        "XXX:Passion XXX (18+)",
        "XXX:Reality Kings TV (18+)",
        "XXX:Prive TV (18+)",
        "XXX:Sext & Senso (18+)",
        "XXX:SCT Classic (18+)",
        "XXX:Brazzers TV (18+)",
        "XXX:Brazzers TV Europe (18+)",
        "XXX:Brazzers TV Europe Russian (18+)",
        "XXX:Hot TV (18+)",
        "XXX:Venus TV (18+)",
        "XXX:Sextreme (18+)",
        "XXX:Sexy HOT (18+)",
        "XXX:Meiden Van Holland Hard (18+)",
        "XXX:X1 (18+)",
        "XXX:X Bizarre (18+)",
        "XXX:Balkan Erotic (18+)",
        "XXX:Taboo (18+)",
        "XXX:Net XX (18+)",
        "XXX:Milf TV (18+)",
        "XXX:Red XXX (18+)",
        "XXX:Extrem (18+)",
        "XXX:Bang U (18+)",
        "XXX:Lesbian Affair (18+)",
        "XXX:Emanuelle (18+)",
        "XXX:Шалун TV (18+)",
        "XXX:Playboy TV (18+)",
        "XXX:Playboy Europe HD (18+)",
        "XXX:Blue Hustler HD (18+)",
        "XXX:Blue Hustler Europe (18+)",
        "XXX:Russian Night HD (18+)",
        "XXX:Russian Night (18+)",
        "XXX:Barely Legal TV (18+)",
        "XXX:O la la TV (18+)",
        "XXX:Nuart TV HD (18+)",
        "XXX:Nuart TV (18+)",
        "XXX:French Lover (18+)",
        "XXX:French Lover Europe (18+)",
        "XXX:Secret Circle TV (18+)",
        "XXX:Candy TV (18+)",
        "XXX:Penthouse Black TV (18+)",
        "XXX_1: Pink Erotica 1",
        "XXX 4K porn",
        "XXX: Sextreme SD/hd",
        "XXX: NEW Sinsations HD",
        "XXX: ASS &ass HD",
        "XXX: BRattSIS HD",
        "XXX: Brazzers SD",
        "XXX: Naked TV HD",
        "XXX: NEO Gay 2",
        "XXX: HARD GAY HD",
        "XXX: GAY MAX HD",
        "XXX: Candy",
        "XXX: FaKINGS",
        "XXX: NUART HD",
        "XXX: DDFNET",
        "XXX: Legal PORN HD",
        "XXX: Legal porno HD",
        "XXX: CZECH STREET HD",
        "XXX: PINK EROTIC 7",
        "XXX: PINK EROTIC 6",
        "XXX: PINK EROTIC 5",
        "XXX: PINK EROTIC 4",
        "XXX: PINK EROTIC 3",
        "XXX: PINK EROTIC 2",
        "XXX: PINK EROTIC 1",
        "XXX: ALBA XXX 4",
        "XXX: ALBA XXX 3",
        "XXX: ALBA XXX 2",
        "XXX: ALBA 1 HD",
        "XXX: XY PLUS",
        "XXX: OH LA LA",
        "XXX: Desire",
        "XXX: VIVID RED",
        "XXX: POV",
        "XXX | PINK O TV",
        "XXX: Petite",
        "XXX: BRAZER COMP",
        "XXX: BANG BROS",
        "XXX: Brazzers euro",
        "XXX PUBLIC SEX",
        "XXX: Super One",
        "XXX: GLORY HOLE",
        "XXX: Penthouse Gold",
        "XXX: Dorcel XXX",
        "XXX: REALITY KINGS",
        "XXX: JASMIN TV.",
        "XXX EVIL Y ANGEL",
        "XXX TUSHI RAW",
        "XXX: BUNGA",
        "XXX MILF TV",
        "XXX Dorcel Delux",
        "XXX: BIG CALEMBO",
        "XXX PAJEROS",
        "XXX: SEMEX",
        "XXX: NEGRAS Y NEGROS",
        "XXX: Playboy Europe",
        "XXX: Bang.com",
        "XXX Fake Taxi",
        "XXX: MOFOS",
        "XXX: Creampie",
        "XXX BRAZzERS LIVE",
        "XXX: FETISH HC",
        "XXX Glamour porn",
        "XXX: TEENS FOR CASH",
        "XXX GIRLS GONE WILD",
        "XXX Balkan Erotic",
        "XXX BABES TV",
        "XXX GROUP SEX",
        "XXX: Casting Couch HD2",
        "XXX REALKINGS",
        "XXX PRIVATE TV HD",
        "XXX: ALBA 5",
        "XXX Midget 1",
        "XXX: Interracial Porn",
        "XXX BLUE",
        "XXX Daddy Porn HD",
        "XXX GONZO com HD",
        "XXX DDF Network 1",
        "XXX Perfect MILF com",
        "XXX DDF Network 2",
        "XXX Asian exotic",
        "XXX Casting Couch HD",
        "XXX: Taboo",
        "XXX: Erotic 8",
        "XXX: SEXT6SENSO",
        "XXX: SCT",
        "XXX: SHALUN TV",
        "XXX: HotClub FHD",
        "XXX: Blacked",
        "XXX Mia",
        "XXX TEENS",
        "XXX ANMIE",
        "XXX: Fap TV Compilation",
        "XXX: Fap TV Lesbian",
        "XXX: Fap TV Teens",
        "XXX: Private TV",
        "XXX: Penthouse Quickies HD",
        "XXX: Penthouse 1",
        "XXX: Erox-hd",
        "XXX: Redlight HD",
        "XXX: Vivid Red HD",
        "XXX Meiden Van Holland",
        "XXX PLAYBOY",
        "XXX HUSTLER TV",
        "XXX DUSK",
        "XXX PENTHOUSE TV",
        "XXX SEXTREME",
        "XXX BRAZZERs TV",
        "XXX Visit-X TV",
        "XXX: Miami TV (after 8pm)",
        "XXX Barely Legal",
        "XXX: Rough SEX",
        "XXX POV",
        "XXX MILF",
        "XXX Hardcore",
        "XXX Visit-X TV (Opt-1)",
        "XXX FTV Midnight Secrets (HD)",
        "XXX : Leo TV - Nights Only",
        "XXX : Vixen HD",
        "XXX : Penthouse Quickies CZ",
        "XXX : Penthouse Quickies",
        "XXX : Penthouse Gold CZ",
        "XXX : Penthouse Gold",
        "XXX : Man-X (GAY) - Nights Only",
        "XXX | fap-tv-compilation HD",
        "XXX | fap-tv-teens HD",
        "XXX | fap-tv-lesbian HD",
        "XXX | fap-tv-parody HD",
        "XXX | fap-tv-anal HD",
        "XXX | oh-ah HD",
        "XXX | fap-tv-4 HD",
        "XXX | fap-tv-3 HD",
        "XXX | fap-tv-2 HD",
        "XXX | hustler HD",
        "XXX Anal",
        "XXX Asian",
        "XXX Big Ass",
        "XXX Big Dick",
        "XXX Big Tits",
        "XXX Blonde",
        "XXX Blowjob",
        "XXX Brunette",
        "XXX Compilation",
        "XXX Cuckold",
        "XXX Fetish",
        "XXX Gangbang",
        "XXX Gay",
        "XXX Hardcore",
        "XXX Interracial",
        "XXX Latina",
        "XXX Lesbian",
        "XXX Live Cams",
        "XXX MILF",
        "XXX Pornstar",
        "XXX POV",
        "XXX Rough",
        "XXX Russian",
        "XXX Teen",
        "XXX Threesome",
        "XXX GANGBANG",
        "XXX SEX",
        "XXX sex",
        "Jasmin TV (HD)",
        "Jasmin TV (SD)",
        "Miami TV",
        "RedTraffic Big Ass",
        "RedTraffic Big Dick",
        "RedTraffic Big Tits",
        "RedTraffic Blowjob",
        "RedTraffic Cuckold",
        "RedTraffic Fetish",
        "RedTraffic Hardcore",
        "RedTraffic Interracial",
        "RedTraffic Latina",
        "RedTraffic Lesbian",
        "RedTraffic Milf",
        "RedTraffic Pornstars",
        "RedTraffic POV",
        "RedTraffic Russian",
        "RedTraffic Teen",
        "RedTraffic Threesome",
        "Visit-X TV",
        "Visit-X TV",
        "Visit-X TV",
        "MyCamTV Teen Girls",
        "MyCamTV MILF",
        "MyCamTV Young Girls",
        "MyCamTV Mature",
        "MyCamTV Arab Girls",
        "MyCamTV Ebony Girls",
        "MyCamTV Asian Girls",
        "MyCamTV Brunette Girls",
        "MyCamTV Latina Girls",
        "MyCamTV White Girls",
        "MyCamTV Blonde Girls",
        "MyCamTV Anal",
        "MyCamTV Big Ass Girls",
        "MyCamTV Girls Squirt",
        "MyCamTV Big Tits Girls",
        "MyCamTV Skinny Girls",
        "MyCamTV Medium Girls",
        "MyCamTV Blowjob",
        "AdultIPTV.net Live Cams",
        "AdultIPTV.net MILF",
        "AdultIPTV.net Big Dick",
        "AdultIPTV.net Big Tits",
        "AdultIPTV.net Fetish",
        "AdultIPTV.net Pornstar",
        "AdultIPTV.net Big Ass",
        "AdultIPTV.net Interracial",
        "AdultIPTV.net Latina",
        "AdultIPTV.net POV",
        "AdultIPTV.net Blowjob",
        "AdultIPTV.net Teen",
        "AdultIPTV.net Hardcore",
        "Adult◈ OnePlay extra HOT 1 ᴴᴰ",
        "Adult◈ OnePlay extra HOT 2 ᴴᴰ",
        "Adult◈ OnePlay extra HOT 3 ᴴᴰ",
        "Adult◈ OnePlay extra HOT 4 ᴴᴰ",
        "Adult◈ OnePlay extra HOT 5 ᴴᴰ",
        "Adult◈ OnePlay extra HOT 6 ᴴᴰ",
        "Adult◈ OnePlay extra HOT 7 ᴴᴰ",
        "Adult◈ OnePlay extra HOT 8 ᴴᴰ",
        "Adult◈ OnePlay extra HOT 9 ᴴᴰ",
        "Adult◈ OnePlay extra HOT 10 ᴴᴰ",
        "Adult◈ OnePlay extra HOT 11 ᴴᴰ",
        "Adult◈ OnePlay extra HOT 12 ᴴᴰ",
        "Adult◈ OnePlay extra HOT 13 ᴴᴰ",
        "Adult◈ OnePlay extra HOT 14 ᴴᴰ",
        "Adult◈ OnePlay extra HOT 15 ᴴᴰ",
        "Adult◈ OnePlay extra HOT 16 ᴴᴰ",
        "Adult◈ OnePlay extra HOT 17 ᴴᴰ",
        "Adult◈ OnePlay extra HOT 18 ᴴᴰ",
        "Adult◈ OnePlay extra HOT 19 ᴴᴰ",
        "Adult◈ OnePlay extra HOT 20 ᴴᴰ",
    ],

]