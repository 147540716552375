import React from 'react'
import Pricing from '../components/Pricing';
import ResellerHead from '../components/ResellerHead';
import ResellersReason from '../components/ResellersReason';
import darkHeaderImg from "../assets/ResellerHeadDark.svg"
import lightHeaderImg from "../assets/ResellerHeadLight.svg"
//import FAQ  from '../components/Faq'
import ResellerFAQ from '../components/ResellerFAQ';
const Resellers = () => {
  return (
  <>
  <div className='ccc_resellers' style={{padding:"165px 0px 0px"}}>
  <ResellerHead  bef_text="The World's" bold_text =' BEST' aft_text='IPTV RESELLER PROGRAM'
   sub_text='Earn Easy Money by Selling A Super-Fast, Super-Secure, And The Most Reliable IPTV Service.' btn_text='BECOME RESELLER' btn_sub='INSTANT ACCESS' darkImg={darkHeaderImg} lightImg={lightHeaderImg}
   url='https://maxcotv.com/store/resellers'
   > </ResellerHead>
  <ResellersReason></ResellersReason>
  <Pricing></Pricing>
  <ResellerFAQ></ResellerFAQ>
  
  </div>

  </>
  )
}

export default Resellers