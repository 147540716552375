export const Languages = [
    "Events",
"UK | Entertainment",
"UK | Regional Channels",
"UK | News",
"UK | Documentaries",
"UK | Movies",
"UK | Kids",
"UK | Irish TV",
"UK | Asian",
"PK | PAKISTAN",
"UK | Sky Sports",
"UK | Sky Sports UHD 50FPS",
"UK | BT Sports",
"UK | Other Sports",
"VIP | AU / NZ Sports",
"UK | SPFL Scottish Clubs",
"UK | EFL CLUBS",
"US | PEACOCK LIVE",
"UK | Betting Channels",
"UK | Music",
"UK | Religion",
"UK | Shopping",
"ZA | Super Sports",
"AR | BeIN Sports",
"VIP | 24/7 Channels",
"VIP | 24/7 (Set 2)",
"US | HULU Channels",
"US | SLING TV",
"US | Entertainment",
"US | Movies",
"US | Documentaries",
"US | Kids",
"US | News",
"US | Sports",
"US | Locals",
"AF | AFRICA",
"AR | ARABIC",
"AR | OSN - MBC - MyHD",
"AL | ALBANIA",
"AU | AUSTRALIA",
"BD | BANGLADESH",
"BR | BRAZIL",
"BG | BULGARIA",
"CA | CANADA",
"DE | GERMANY",
"ES | SPAIN",
"ES | FUTBOL",
"ES | DEPORTES",
"FR | FRANCE",
"GR | GREECE",
"HU | HUNGARY",
"IN | INDIA",
"IT | ITALY",
"NL | NETHERLANDS",
"PL | POLAND",
"PT | PORTUGAL",
"RO | ROMANIA",
"RU | RUSSIA",
"SB | SERBIA EX-YU",
"SE | SWEDEN - DENMARK - NORWAY",
"TR | Turkey",
"ZA | SOUTH AFRICA",
"ZA | DSTV SA - BACKUP",
"XXX | ADULTS",
]