import React from 'react'
import Discover from '../components/Discover'
import Faq from '../components/Faq'
import Features from '../components/Features'
//import Footer from '../components/Footer'
//import Footer from '../components/Footer'
import GuranteeCard from '../components/GuranteeCard'
import {History} from '../components/History'
import InfoCard1 from '../components/InfoCard1'
import InfoCard2 from '../components/InfoCard2'
import InfoCard3 from '../components/InfoCard3'
import Reviews from '../components/Reviews'
import Uptime from '../components/Uptime'
import arrow from "../assets/rightArrowImage.svg";
import  './Home.css'
import InfoCard4 from '../components/InfoCard4'
import Tv1 from "../assets/Tv.png"
import card2 from "../assets/infoCard2.png"
import card3 from '../assets/infoCard3.png'
const Home = () => {

  return (
   <>
   <div className='Home_Section'>  
  <Discover  btn_bottom=' 7-Days Money-Back Guarantee' btn_title="24 Hours Free Trial" Dis_img={Tv1}></Discover>
  <Features></Features>
  <History></History>
  <Uptime></Uptime>
  <InfoCard1  btn_title='Join Us Now' ></InfoCard1>
  <InfoCard2  img={card2} btn_title='Join Us Now' 
  btn_bottom=' 7-Days Money-Back Guarantee'
  title='EPG' sub ='in Most Channels'
   desc='EPG is one of our most requested features and providing a full and updated EPG has been our priority since day one. We can proudly say that we have been able to cover the majority of our IPTV channels.' url='https://maxcotv.com/store/subscriptions'></InfoCard2>
  
  <InfoCard3 img={card3} btn_bottom=' 7-Days Money-Back Guarantee' btn_title='Join Us Now'
  title='Thousands' sub ='of Movies & Series' desc='EPG is one of our most requested features and providing a full and updated EPG has been our priority since day one. We can proudly say that we have been able to cover the majority of our IPTV channels.'
  url='https://maxcotv.com/store/subscriptions'></InfoCard3>
  {/* <InfoCard4></InfoCard4> */}
  <Reviews></Reviews>
  <GuranteeCard></GuranteeCard>
  <Faq></Faq>
   {/* <div class="ccc_getAccess">

<button onClick={()=>window.location.href='https://maxcotv.com/store/subscriptions'} class="ccc_btn">  Get My Instant Access Now<img src={arrow} alt="arrow" /></button> <p class="ccc_notice">7-Days Money-Back Guarantee</p></div> */}


 
  
  
   </div>
   {/* <Footer></Footer> */}
   </>
  )
}

export default Home