import React from 'react'
import "./Footer.css";
const Footer = () => {
  return (
    <div id="ccc_footer">
    <div class="ccc_component">
    MAXCO TV | All Rights Reserved 2010 - 2022 |
    Terms of Service
    |
  Privacy Policy</div></div>
  )
}

export default Footer