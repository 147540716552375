import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Footer from './components/Footer';
import { HashRouter } from "react-router-dom";
import ScrollTop from './ScrollTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
<HashRouter>
  <ScrollTop></ScrollTop>
<App />
</HashRouter>
  
   
  </React.StrictMode>
);


