import ChannelsHeading from './../components/ChannelsHeading';
//import ChannelsLeftSidebar from '../components/ChannelsGrid';
const Channels = () => {
    return (
        <>
            <ChannelsHeading></ChannelsHeading>
            {/* <div className="ccc_container">
                <ChannelsLeftSidebar></ChannelsLeftSidebar>
            </div> */}
        </>
    )

}

export default Channels;