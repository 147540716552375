import React from 'react'
import Discover from '../components/Discover'
// import Faq from '../components/Faq'
// import Features from '../components/Features'
//import Footer from '../components/Footer'
//import Footer from '../components/Footer'
// import GuranteeCard from '../components/GuranteeCard'
// import { History } from '../components/History'
// import InfoCard1 from '../components/InfoCard1'
import InfoCard2 from '../components/InfoCard2'
import InfoCard3 from '../components/InfoCard3'
// import Reviews from '../components/Reviews'
// import Uptime from '../components/Uptime'
import arrow from "../assets/rightArrowImage.svg";
import './Home.css'
import InfoCard4 from '../components/InfoCard4'
import InfoCard5 from '../components/infoCard5'

// images 
//import fireStickTv from '../assets/FireStickTv.png'
import card1 from "../assets/fireStickCard1.png"
import card2 from '../assets/fireStickCard2.png'
import card3 from "../assets/fireStickCard3.png"
import card4 from '../assets/fireStickCard4.png'
import ResellerHead from '../components/ResellerHead';
import MainFireStick from '../assets/fireStickMain.png'

const FireStick = () => {
  return (
    <>
      <div className='Home_Section'>
      <ResellerHead bef_text="Order your" bold_text =' Amazon' aft_text='FireStick Device'
   sub_text='Earn Easy Money by Selling A Super-Fast, Super-Secure, And The Most Reliable IPTV Service.' btn_text='ORDER NOW' btn_sub='Free Delivery' darkImg={MainFireStick} lightImg={MainFireStick}
   url='https://maxcotv.com/store/preloaded-devices'
   > </ResellerHead>

        <InfoCard5 sub_btn='Free Delivery' img={card1} btn_bottom='Include 12 Months Subscription' btn_title='Order Now' 
          title='Firestick Lite' sub='£119.99' desc='Get your Amazon Firestik Lite with a full year subscription and our Sleek Desgin Application.' 
          url='https://maxcotv.com/store/preloaded-devices/amazon-firestick-lite'></InfoCard5>

        <InfoCard2 sub_btn='Free Delivery' img={card2} btn_bottom='Include 12 Months Subscription' btn_title='Order Now'
          title='Firestick Ultra' sub='£139.99' desc='Get your Amazon Firestik Ultra with a full year subscription and our Sleek Desgin Application.'
          url='https://maxcotv.com/store/preloaded-devices/amazon-firestick-4k-ultra-hd-12-month-sub' ></InfoCard2>

        <InfoCard3 sub_btn='Free Delivery' img={card3} btn_bottom='Include 12 Months Subscription' btn_title='Order Now'
          title='Firestick Max' sub='£144.99' desc='Get your Amazon Firestik Mac with a full year subscription and our Sleek Desgin Application.'
          url='https://maxcotv.com/store/preloaded-devices/amazon-firestick-4k-max-ultra-hd-12-month-sub'></InfoCard3>

        <InfoCard4 sub_btn='Free Delivery' img={card4} btn_bottom='Include 12 Months Subscription' btn_title='Order Now'
          title='Fire TV Cube' sub='£199.99' desc='Get your Amazon Fire TV Cube with a full year subscription and our Sleek Desgin Application.'
          url='https://maxcotv.com/store/preloaded-devices/amazon-fire-cube-12-month-sub'></InfoCard4>


        {/* <Faq></Faq> */}
        <div class="ccc_getAccess">

          <button style={{gridTemplateColumns:'auto',alignItems:"center"}}
          onClick={() => window.location.href = 'https://maxcotv.com/store/preloaded-devices'} class="ccc_btn">Order a Pre-Loaded Device Now<img src={arrow} alt="arrow" /></button> <p class="ccc_notice">Include 12 Months Subscription</p></div>





      </div>
      {/* <Footer></Footer> */}
    </>
  )
}

export default FireStick