import React from 'react'
// import darkHeaderImg from "../assets/ResellerHeadDark.svg"
// import lightHeaderImg from "../assets/ResellerHeadLight.svg"
import "./ResellerHead.css";
const ResellerHead = (props) => {
    return (
        <>
        
            <section id="ccc_head"><div className="ccc_container"><div className="ccc_column"><div className="ccc_row"><h1 className="ccc_title">{props.bef_text}<span>{props.bold_text}</span> {props.aft_text}</h1> <h2 className="ccc_subtitle">{props.sub_text}.</h2></div> <div className="ccc_row"><button className="ccc_btn" onClick={()=>window.location.href=props.url}>
               {props.btn_text}
                <span>{props.btn_sub}</span></button></div></div> <div className="ccc_column">
                    <img  id='reseller_dark_head' src={props.darkImg} alt="benef" rel="preload" />
                    <img  id='reseller_light_head' src={props.lightImg} alt="benef" rel="preload" />
                    </div></div></section>
        </>
    )
}

export default ResellerHead