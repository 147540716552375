import React from 'react'
import dollar from "../assets/dollar.svg";
// import fire2 from "../assets/infoCard2.png"
import "./InfoCard2.css"
const InfoCard2 = (props) => {
    return (
        <section id='info2_Section'>
            <div className="ccc_container"><div className="ccc_column"><p className="ccc_title">
                {props.title}<span>{props.sub}</span></p> <h2 className="ccc_info">{props.desc}</h2> <div className="ccc_joinus ccc_info2"><button onClick={()=>window.location.href=props.url} className="join">
                    {props.btn_title}
                    <span>{props.sub_btn}</span></button> <div>
                        <img className='dolimg' src={dollar} alt="moneyback" /> <p className="black">
                           {props.btn_bottom}
                        </p></div></div></div> <div className="ccc_column"><div><img  src={props.img} className="ccc_app" lazy="loaded" /></div></div></div>
        </section>
    )
}

export default InfoCard2