import React from 'react'
import "./InfoCard3.css";
// import fire3 from '../assets/infoCard3.png'
import dollar from "../assets/dollar.svg";
const InfoCard3 = (props) => {
    return (
        <section id="info3_Section"><div className="ccc_container"><div className="ccc_column"><p className="ccc_title">
           {props.title}<span>{props.sub}</span></p> <h2 className="ccc_info">{props.desc}</h2> <div className="ccc_joinus ccc_info3"><button onClick={()=>window.location.href=props.url} className="join">
               {props.btn_title}
                <span>{props.sub_btn}</span></button> <div><img src={dollar} className='dolimg' alt="moneyback" /> <p className="black">
                {props.btn_bottom}
                </p></div></div></div> <div className="ccc_column"><div><img  src={props.img} className="ccc_app" lazy="loaded" /></div></div></div></section>
    )
}

export default InfoCard3